import { createReducer, on } from '@ngrx/store';
import { ListState, RelatedAssetListWidgetStateModel } from '../../AppState';
import { ListStateReducerHelper } from '../../helpers/list-state-reducer-helper';
import { addRelatedAssetsDone, getRelatedAssetsBySearchDone, getRelatedAssetsBySearchError, removeRelatedAssetsDone } from './related-assets.actions';


export const initialRelatedAssetListState: ListState<RelatedAssetListWidgetStateModel>[] = [];

export const RelatedAssetListReducer = createReducer<ListState<RelatedAssetListWidgetStateModel>[]>(initialRelatedAssetListState,
    on(getRelatedAssetsBySearchDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<RelatedAssetListWidgetStateModel>(state, action.dataSourceId, action.stateModel)),
    on(getRelatedAssetsBySearchError, (state, action) => ListStateReducerHelper.GetUpdatedListState<RelatedAssetListWidgetStateModel>(state, action.dataSourceId, action.stateModel)),

    on(addRelatedAssetsDone, (state, action) => ListStateReducerHelper.GetUpdatedListState(state, action.dataSourceId, action.stateModel)),

    on(removeRelatedAssetsDone, (state, action) => ListStateReducerHelper.removeItemFromState(state, action.dataSourceId, action.relatedAssetIds)),
);
