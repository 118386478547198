import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { BASE_URL } from 'src/app/helpers/base-url';
import { DocumentCreateDto } from '../../data-transfer-objects/document/document-create-dto';
import { DocumentReplaceDto } from '../../data-transfer-objects/document/document-replace-dto';
import { DocumentListViewDTO } from '../../data-transfer-objects/document/document-list-view-dto';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';
import { PaginationModel } from '../../models/pagination-models';
import { HttpServiceBase } from "../class-definitions/http-service-base";

@Injectable({
    providedIn: 'root'
})
export class DocumentDraftHttpService extends AuthenticatedHttpServiceBase {

    constructor(protected httpClient: HttpClient, @Inject(BASE_URL) protected baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public removeDocument(documentDraftId: string): Observable<null> {
        return new Observable<null>((observer: Subscriber<null>) => {
            HttpServiceBase.RequestCount++;

            this.httpClient.delete<null>(`${this.baseUrl}api/documentDraft/${documentDraftId}`).subscribe(
                _ => {
                    observer.next();
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                }, error => {
                    observer.error(error);
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                });
        });
    }

    public replaceDocument(documentReplaceDto: DocumentReplaceDto): Observable<null> {
        return new Observable<null>((observer: Subscriber<null>) => {
            HttpServiceBase.RequestCount++;

            this.httpClient.put<null>(`${this.baseUrl}api/documentDraft`, this.getFormData(documentReplaceDto)).subscribe(
                _ => {
                    observer.next();
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                }, error => {
                    observer.error(error);
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                });
        });
    }

    protected AddDocument(documentCreateDto: DocumentCreateDto, url: string): Observable<null> {
        return new Observable<null>((observer: Subscriber<null>) => {
            HttpServiceBase.RequestCount++;

            this.httpClient.post<null>(`${this.baseUrl}api/documentDraft/${url}`, this.getFormData(documentCreateDto)).subscribe(
                _ => {
                    observer.next();
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                }, error => {
                    observer.error(error);
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                });
        });
    }

    protected GetDocumentsByEntityDraft(url: string): Observable<DocumentListViewDTO[]> {
        return new Observable<DocumentListViewDTO[]>((observer: Subscriber<DocumentListViewDTO[]>) => {
            HttpServiceBase.RequestCount++;

            this.httpClient.get<DocumentListViewDTO[]>(`${this.baseUrl}api/documentDraft/${url}`).subscribe(
                (documentListViewDTO: DocumentListViewDTO[]) => {
                    observer.next(documentListViewDTO);
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                }, error => {
                    observer.error(error);
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                });
        });
    }

    protected GetDocumentsByEntityDraftWithHeaders(url: string, widgetId: string, paginationModel: PaginationModel): Observable<DocumentListViewDTO[]> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.GetStandardHeaders(), widgetId);
        return this.GetAuthenticated<DocumentListViewDTO[]>(`${this.baseUrl}api/documentDraft/${url}`, headers, [], paginationModel);
    }

    protected RemoveEntityDraft(url: string): Observable<null> {
        return new Observable<null>((observer: Subscriber<null>) => {
            HttpServiceBase.RequestCount++;

            this.httpClient.delete<null>(`${this.baseUrl}api/documentDraft/${url}`).subscribe(
                _ => {
                    observer.next();
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                }, error => {
                    observer.error(error);
                    observer.complete();

                    HttpServiceBase.RequestCount--;
                });
        });
    }
}
