import { Component, ComponentFactoryResolver, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { StructuredDataLibraryService, ValueType } from '@landadmin/structured-data';
import { IDisplayMapper } from '@landadmin/structured-data/lib/interfaces/i-display-mapper';
import { IEditOverride } from '@landadmin/structured-data/lib/interfaces/i-edit-override';
import { LookupConfigurationModel } from '@landadmin/structured-data/lib/models/lookup-configuration-model';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { StructuredDataModel } from '@landadmin/structured-data/lib/models/structured-data-model';
import { PaginatorPageChange } from '@landadmin/structured-data/lib/models/structured-data-pagination-override';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { StructuredDataLookupFacade } from '../../../../../facade/configuration/structured-data-lookup.facade';
import { StructuredDataNodeFacade } from '../../../../../facade/configuration/structured-data-node.facade';
import { ListState, StructuredDataLookupConfigurationState, StructuredDataNodeConfigurationState } from '../../../../../AppState';
import { StructuredDataMapperHelper } from '../../../../../configuration/structured-data-configuration-mapper-helper';
import { severity_warning } from '../../../../../consts/severity-options';
import { StructuredDataListWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/widgets/structured-data-list-widget-configuration-view-dto';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { StructuredDataDisplayStyle } from '../../../../../enums/display-style';
import { WidgetLoadMode } from '../../../../../enums/widget-load-mode';
import { GuidHelper } from '../../../../../helpers/guid-helper';
import { ObjectHelper } from '../../../../../helpers/object-helper';
import { StructuredDataHelper } from '../../../../../helpers/structured-data-helper';
import { PaginationModel, PaginationResultModel } from '../../../../../models/pagination-models';
import { LocaleDateFormatPipe } from '../../../../../pipes/locale-date-format.pipe';
import { ToastApplicationService } from '../../../../../services/application/toast-application.service';
import { LanguageHttpService } from '../../../../../services/http/language-http.service';
import { SiteSettingsService } from '../../../../../services/deprecated/site-settings.service';
import { BaseWidget } from '../base-widget';
import { AbstractStructuredDataEntryFacade } from '../../../../../facade/abstract/abstract-structured-data.facade';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'fw-structured-data-widget',
    templateUrl: './structured-data-widget.component.html',
})

export class StructuredDataWidgetComponent extends BaseWidget implements OnInit, OnDestroy {

    public addEnabled: boolean = true;
    public addNewBlankEntry: boolean = false;

    public deleteEnabled: boolean = true;
    public displayMappers: IDisplayMapper[] = [];
    public displayNextPage: boolean = false;

    public editMode: boolean = false;

    public instanceId: string = null

    public loaded: boolean = false;
    public lookups: LookupConfigurationModel = null;
    public paginationEnabled: boolean;
    public emptyListMessage: string;

    public setPageSubject: Subject<number> = new Subject<number>();
    public setTotalRecordsSubject: Subject<number> = new Subject<number>();
    public structuredDataClass: string;
    public structuredDataEntriesLoaded: boolean = false;
    public structuredDataLookupsLoaded: boolean = false;
    public structuredDataModel: StructuredDataModel;
    public structuredDataNodesLoaded: boolean = false;
    public structuredDataRegistered: boolean;
    public structuredDataModelAdded: StructuredDataEntryModel;

    public totalRecords: number = -1;

    @Input()
    public StructuredDataListWidgetConfigurationViewDTO: StructuredDataListWidgetConfigurationViewDTO;

    private currentPage: number = 1;
    private disableAddButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private structuredDataIdsToAdd: string[] = null;
    private subscriptions: Subscription[] = [];
    private previousStructuredDataModel: StructuredDataModel;

    constructor(
        private structuredDataFacade: AbstractStructuredDataEntryFacade,
        private structuredDataNodeFacade: StructuredDataNodeFacade,
        private languageHttpService: LanguageHttpService,
        private translocoService: TranslocoService,
        private siteSettingsService: SiteSettingsService,
        private toastApplicationService: ToastApplicationService,
        public _structuredDataLibraryService: StructuredDataLibraryService,
        public _componentFactoryResolver: ComponentFactoryResolver,
        public structuredDataLookupFacade: StructuredDataLookupFacade,
        public elementRef: ElementRef) {

        super(elementRef);
        this.structuredDataNodesLoaded = false;
        this.structuredDataEntriesLoaded = false;
        this.structuredDataLookupsLoaded = false;
    }


    public Add(structuredDataModel: StructuredDataEntryModel): void {

        this.structuredDataModelAdded = ObjectHelper.deepCopy(structuredDataModel);

        if (this.structuredDataIdsToAdd === null) {
            this.structuredDataIdsToAdd = [];
        }
        StructuredDataHelper.AppendInflatedEntries(structuredDataModel.ChildEntries, this.structuredDataIdsToAdd);


        if (this.paginationEnabled) {
            this.totalRecords++;
            this.setTotalRecordsSubject.next(this.totalRecords);

            const lastPage = this.GetLastPage();

            if (this.currentPage !== lastPage) {
                this.setPageSubject.next(lastPage);
            }
        }

        this.ToggleAddButtonEnabled();
    }



    public Delete(structuredDataModel: StructuredDataEntryModel): void {

        const deepCopyEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries) as StructuredDataEntryModel[];

        const index = deepCopyEntries.findIndex(x => x.Id === structuredDataModel.Id)
        if (index > -1) {
            deepCopyEntries.splice(index, 1);
            this.structuredDataModel.Entries = ObjectHelper.deepCopyJsonParse(deepCopyEntries);
            this.previousStructuredDataModel = ObjectHelper.deepCopyJsonParse(this.structuredDataModel);
        }

        if ((this.structuredDataModelAdded && structuredDataModel.Id !== this.structuredDataModelAdded.Id) || !this.structuredDataModelAdded) {
            const deepCopyStructuredDataModel = ObjectHelper.deepCopyJsonParse(structuredDataModel);


            this.structuredDataFacade.DeleteStructuredDataEntry(
                this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId,
                deepCopyStructuredDataModel,
                deepCopyEntries,
                this.displayNextPage,
                this.totalRecords,
                this.StructuredDataListWidgetConfigurationViewDTO.Id,
                WidgetLoadMode.SiblingWidgets,
                true);
        } else {

            this.structuredDataModelAdded = null;
        }

        this.ToggleAddButtonEnabled();

    }

    public Paginate(pageChange: PaginatorPageChange) {

        const paginationModel: PaginationModel = {
            Limit: pageChange.Limit,
            OrderByList: null,
            Page: pageChange.Page,
            ShowAll: false
        }

        this.currentPage = pageChange.Page;

        this.ProccessPaging(paginationModel);
    }

    public Update(structuredDataModel: StructuredDataEntryModel): void {
        if (this.IsOptionListEntryCommonlyUsed(structuredDataModel)) {
            if (this.previousStructuredDataModel.Entries.length > 0 && this.previousStructuredDataModel.Entries.length == this.structuredDataModel.Entries.length) {
                this.structuredDataModel.Entries = ObjectHelper.deepCopyJsonParse(this.previousStructuredDataModel.Entries);
            } else {
                const entryIndex = this.structuredDataModel.Entries.findIndex(x => x.Id === this.structuredDataModelAdded.Id);

                const structuredDataModelCopy = ObjectHelper.deepCopyJsonParse(this.structuredDataModel);
                structuredDataModelCopy.Entries[entryIndex].ChildEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModelAdded.ChildEntries);
                this.structuredDataModel.Entries = ObjectHelper.deepCopyJsonParse(structuredDataModelCopy.Entries);
            }
            this.toastApplicationService.showToast([{ summary: 'StructuredData.Widget.InvalidOptionListItem', detail: 'StructuredData.Widget.InvalidOptionListItem.Detail', severity: severity_warning }]);
            return;
        }

        const deepCopyStructuredDataModel = ObjectHelper.deepCopy(structuredDataModel);
        const parentEntry = StructuredDataHelper.GetParentEntry(this.structuredDataModel.Entries, deepCopyStructuredDataModel, null);
        const deepCopyParentEntry = ObjectHelper.deepCopy(parentEntry);
        if (StructuredDataHelper.IsStructuredDataModelEmpty(ObjectHelper.deepCopy(deepCopyParentEntry))) {
            this.Delete(deepCopyParentEntry);
        }
        else if (StructuredDataHelper.IsValueValid(deepCopyStructuredDataModel)) {

            const addedIndex = this.structuredDataIdsToAdd === null ? -1 : this.structuredDataIdsToAdd.findIndex(x => x === deepCopyStructuredDataModel.Id);

            if (addedIndex > -1 && this.structuredDataModelAdded && deepCopyParentEntry.Id === this.structuredDataModelAdded.Id) {

                const deepCopyNewStructuredDataEntryModel = ObjectHelper.deepCopyJsonParse(this.structuredDataModelAdded);
                this.structuredDataModelAdded = null;
                if (this.structuredDataFacade._formEditStyle === FormEditStyle.Form) {
                    const index = deepCopyNewStructuredDataEntryModel.ChildEntries.findIndex(x => x.Id === deepCopyStructuredDataModel.Id)
                    if (index > -1) {
                        deepCopyNewStructuredDataEntryModel.ChildEntries.splice(index, 1);
                    }
                } else {
                    deepCopyNewStructuredDataEntryModel.ChildEntries = [];
                }

                if (addedIndex > -1) {
                    this.structuredDataIdsToAdd.splice(addedIndex, 1);
                }


                deepCopyNewStructuredDataEntryModel.ChildEntries.push(deepCopyStructuredDataModel);
                this.AddStrucutredData(deepCopyNewStructuredDataEntryModel);

            } else {

                const index = this.structuredDataIdsToAdd === null ? -1 : this.structuredDataIdsToAdd.findIndex(x => x === deepCopyStructuredDataModel.Id);
                if (index > -1
                    && this.structuredDataFacade._formEditStyle === FormEditStyle.Inline
                    && this.structuredDataFacade._enablePaging) {

                    this.structuredDataIdsToAdd.splice(index, 1);

                    //Set isRoot to null because the root as already been added at this point.
                    deepCopyParentEntry.IsRoot = null;
                    deepCopyParentEntry.ChildEntries = [];
                    deepCopyParentEntry.ChildEntries.push(deepCopyStructuredDataModel);

                    const deepCopyEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries);

                    this.structuredDataFacade.CreateStructuredDataEntry(this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId,
                        deepCopyParentEntry,
                        deepCopyEntries,
                        this.displayNextPage,
                        this.totalRecords,
                        this.StructuredDataListWidgetConfigurationViewDTO.Id,
                        WidgetLoadMode.SiblingWidgets,
                        false);

                } else {


                    const deepCopyStructuredDataEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries);

                    this.structuredDataFacade.UpdateStructuredDataEntry(
                        this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId,
                        deepCopyStructuredDataModel,
                        deepCopyStructuredDataEntries,
                        this.displayNextPage,
                        this.totalRecords,
                        this.StructuredDataListWidgetConfigurationViewDTO.Id,
                        WidgetLoadMode.SiblingWidgets);
                }
            }
        }

        this.previousStructuredDataModel = ObjectHelper.deepCopyJsonParse(this.structuredDataModel);

        this.ToggleAddButtonEnabled();
    }

    ngOnDestroy(): void {

        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            })
        }


        this._structuredDataLibraryService.DiscardInstance(this.instanceId);


        this.structuredDataFacade.ResetState();
    }

    ngOnInit(): void {

        this.instanceId = GuidHelper.NewGuid();
        this.setPageSubject = new Subject<number>();
        this.emptyListMessage = this.translocoService.translate(this.StructuredDataListWidgetConfigurationViewDTO.EmptyListResourceId);

        this.structuredDataModel = {
            Entries: [],
            Node: null
        };

        this.previousStructuredDataModel = {
            Entries: [],
            Node: null
        };

        const reloadSubscription = this.structuredDataFacade._setLoadSubject.subscribe((readyToLoad: boolean) => {
            if (readyToLoad === true) {

                if (this.StructuredDataListWidgetConfigurationViewDTO.ReadOnly) {
                    this.structuredDataFacade._formEditStyle = FormEditStyle.ReadOnly;
                }

                this.editMode = this.structuredDataFacade._formEditStyle !== FormEditStyle.ReadOnly;
                if (this.structuredDataFacade._enablePaging === false) {
                    this.structuredDataFacade.LoadStructuredDataEntry(this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId, null, this.StructuredDataListWidgetConfigurationViewDTO.Id, WidgetLoadMode.Always);
                } else {

                    const paginationModel: PaginationModel = {
                        Limit: this.StructuredDataListWidgetConfigurationViewDTO.PageSize,
                        OrderByList: null,
                        Page: 1,
                        ShowAll: false
                    }

                    this.ProccessPaging(paginationModel);
                }
            }
        });


        this.structuredDataLookupFacade.LoadStructuredDataLookups();
        const loadStructuredDataLookupsSubscription = this.structuredDataLookupFacade.LoadStructuredDataLookupsSelector().subscribe((structuredDataLookupConfigurationState: StructuredDataLookupConfigurationState) => {

            if (structuredDataLookupConfigurationState.viewModel) {
                this.lookups = structuredDataLookupConfigurationState.viewModel;
                this.structuredDataLookupsLoaded = true;
                this.PopulateStructuredData();
            }
        });

        this.subscriptions.push(loadStructuredDataLookupsSubscription);

        this.subscriptions.push(reloadSubscription);

        const loadStructuredDataNodesSubscription = this.structuredDataNodeFacade.LoadStructuredDataNodesSelector().subscribe((structuredDataNodesStates: ListState<StructuredDataNodeConfigurationState>[]) => {

            if (structuredDataNodesStates.length > 0) {

                const structuredDataNodesState = structuredDataNodesStates.find(x => x.dataSourceId === this.structuredDataFacade._entityTypeId);

                if (structuredDataNodesState) {

                    const rootNode = structuredDataNodesState.StateModel.viewModel.find(x => x.Id === this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId);

                    const copy = Object.assign([], rootNode);
                    if (rootNode) {
                        if (!this.structuredDataModel.Node) {
                            this.structuredDataModel.Node = copy;
                            this.structuredDataNodesLoaded = true;
                            this.previousStructuredDataModel = ObjectHelper.deepCopyJsonParse(this.structuredDataModel);
                        }
                    }

                    this.PopulateStructuredData();
                }
            }
        });

        this.subscriptions.push(loadStructuredDataNodesSubscription);

        const loadStructuredDataEntrySubscription = this.structuredDataFacade.LoadStructuredDataEntrySelector().subscribe((structuredDataWidgetStates) => {

            const structuredDataWidgetState = structuredDataWidgetStates.find(x => x.dataSourceId === this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId);
            if (structuredDataWidgetState) {

                const viewModel = structuredDataWidgetState.StateModel.viewModel;
                if (viewModel) {
                    if (viewModel.Models) {
                        this.RebindStructuredDataList(viewModel, structuredDataWidgetState.StateModel.navigateToLastPage, structuredDataWidgetState.StateModel.reloadPage, structuredDataWidgetState.StateModel.widgetId, structuredDataWidgetState.StateModel.loadMode);
                    }
                } else {
                    this.structuredDataModel.Entries = [];

                    //This logic is done to add a new blank entry after discarding the asset.
                    if (this.StructuredDataListWidgetConfigurationViewDTO.LayoutMode === StructuredDataDisplayStyle.SingleEntry && this.addNewBlankEntry === true) {
                        this.AddBlankEntry();
                    }
                }
                this.structuredDataEntriesLoaded = true;
                this.PopulateStructuredData();
            } else {

                if (this.structuredDataFacade._formEditStyle === FormEditStyle.Form)
                    this.addNewBlankEntry = true;
            }

            this.previousStructuredDataModel = ObjectHelper.deepCopyJsonParse(this.structuredDataModel);
        });

        this.subscriptions.push(loadStructuredDataEntrySubscription);
    }

    private IsOptionListEntryCommonlyUsed(structuredDataModel: StructuredDataEntryModel): boolean {
        const commonlyUsedId = '----------------';

        return (structuredDataModel.ValueType === ValueType.OptionList &&
            structuredDataModel.Value.Id === commonlyUsedId);
    }

    private AddBlankEntry(): void {

        if (this.structuredDataModel.Entries === null || this.structuredDataModel.Entries.length === 0) {

            const rootEntry = StructuredDataHelper.GetNullEntryFromNode(this.structuredDataModel.Node);
            const structuredDataEntries: StructuredDataEntryModel[] = [];
            structuredDataEntries.push(rootEntry);
            this.CopyEntries(structuredDataEntries);

            const deepCopyStructuredDataModel = ObjectHelper.deepCopyJsonParse(rootEntry);
            const deepCopyEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries);

            this.addNewBlankEntry = false;
            this.structuredDataFacade.CreateStructuredDataEntry(this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId,
                deepCopyStructuredDataModel,
                deepCopyEntries,
                this.displayNextPage,
                this.totalRecords,
                this.StructuredDataListWidgetConfigurationViewDTO.Id,
                WidgetLoadMode.Always,
                false);
        }
    }

    private IsLastPage(): boolean {

        return this.currentPage === this.GetLastPage();
    }

    private GetLastPage(): number {
        return Math.ceil((this.totalRecords) / this.StructuredDataListWidgetConfigurationViewDTO.PageSize);
    }

    private AddStrucutredData(structuredDataModel: StructuredDataEntryModel): void {

        const deepCopyStructuredDataModel = ObjectHelper.deepCopyJsonParse(structuredDataModel);
        const deepCopyEntries = ObjectHelper.deepCopyJsonParse(this.structuredDataModel.Entries);

        const navigateToLastPage = this.paginationEnabled;
        this.structuredDataFacade.CreateStructuredDataEntry(this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId,
            deepCopyStructuredDataModel,
            deepCopyEntries,
            this.displayNextPage,
            this.totalRecords,
            this.StructuredDataListWidgetConfigurationViewDTO.Id,
            WidgetLoadMode.SiblingWidgets,
            navigateToLastPage);

        this.ToggleAddButtonEnabled();
    }

    private ToggleAddButtonEnabled(): void {
        setTimeout(() => {
            const lastEntry = this.structuredDataModel.Entries[this.structuredDataModel.Entries.length - 1];

            if (lastEntry && lastEntry.ChildEntries && StructuredDataHelper.IsStructuredDataModelEmpty(lastEntry)) {
                this.disableAddButtonSubject.next(true);
            } else {
                this.disableAddButtonSubject.next(false);
            }
        })
    }

    private CopyEntries(structuredDataEntries: StructuredDataEntryModel[]) {
        if (JSON.stringify(this.structuredDataModel.Entries) === JSON.stringify(structuredDataEntries)) {
            return;
        }

        this.structuredDataModel.Entries = ObjectHelper.deepCopyJsonParse(structuredDataEntries);

        if (this.structuredDataFacade._formEditStyle === FormEditStyle.ReadOnly) {
            this.FormatStructuredDataEntries(this.structuredDataModel.Entries);
        }

        if (!this.structuredDataIdsToAdd) {
            this.structuredDataIdsToAdd = [];
            StructuredDataHelper.AppendInflatedEntries(this.structuredDataModel.Entries, this.structuredDataIdsToAdd);
        }

        this.previousStructuredDataModel = ObjectHelper.deepCopyJsonParse(this.structuredDataModel);
    }

    private FormatStructuredDataEntries(entries: StructuredDataEntryModel[]) {
        entries.forEach((entryModel: StructuredDataEntryModel) => {
            if (entryModel.Value) {
                //added a switch case instead we need to format other readonly values in future
                switch (entryModel.ValueType) {
                    case ValueType.Date:
                        const localeDateFormatPipe = new LocaleDateFormatPipe(this.languageHttpService, this.siteSettingsService);
                        entryModel.Value = localeDateFormatPipe.transform(new Date(entryModel.Value));
                }
            } else {
                if (entryModel.ChildEntries && entryModel.ChildEntries.length > 0) {
                    this.FormatStructuredDataEntries(entryModel.ChildEntries);
                }
            }
        });
    }

    private GetEditMappers(): Array<IEditOverride> {

        if (this.StructuredDataListWidgetConfigurationViewDTO.LayoutMode === StructuredDataDisplayStyle.MultipleEntry) {
            return StructuredDataMapperHelper.GetStructuredDataFormEditMappers(this._componentFactoryResolver, this.subscriptions);
        } else {
            switch (this.structuredDataFacade._formEditStyle) {
                case FormEditStyle.Form:
                case FormEditStyle.ReadOnly:
                    return StructuredDataMapperHelper.GetStructuredDataEditMappers(this._componentFactoryResolver);
                case FormEditStyle.Inline:
                    return StructuredDataMapperHelper.GetStructuredDataInlineEditMappers(this._componentFactoryResolver, this.subscriptions, this._structuredDataLibraryService, this.structuredDataModel.Node);
                default:
                    throw new Error('FormEditStyle is not supported.');
            }
        }
    }

    private PopulateStructuredData(): void {

        if (this.structuredDataModel
            && this.structuredDataModel.Node
            && this.structuredDataEntriesLoaded === true
            && this.structuredDataNodesLoaded === true
            && this.structuredDataLookupsLoaded === true
            && this.loaded === false) {

            this.paginationEnabled = this.structuredDataFacade._enablePaging && this.StructuredDataListWidgetConfigurationViewDTO.LayoutMode === StructuredDataDisplayStyle.MultipleEntry;

            switch (this.StructuredDataListWidgetConfigurationViewDTO.LayoutMode) {
                case StructuredDataDisplayStyle.MultipleEntry:
                    this.structuredDataClass = 'structured-data';
                    break;
                case StructuredDataDisplayStyle.SingleEntry:
                    this.structuredDataClass = 'structured-data propval';
                    if (this.structuredDataFacade._formEditStyle === FormEditStyle.Form)
                        this.AddBlankEntry();
                    this.deleteEnabled = false;
                    this.addEnabled = false;
                    break;
                default:
                    throw new Error('Display style is not supported.');
            }


            switch (this.structuredDataFacade._formEditStyle) {
                case FormEditStyle.Form:
                    this.structuredDataClass += ' sd-form-edit';
                    break;
                case FormEditStyle.Inline:
                    this.structuredDataClass += ' sd-inline-edit';
                    break
                case FormEditStyle.ReadOnly:
                    this.structuredDataClass += ' sd-readonly';
                    break
                default:
                    throw new Error('FormEditStyle is not supported.');
            }

            if (this._structuredDataLibraryService.IsLookupConfigurationRegistered() === false) {

                this._structuredDataLibraryService.RegisterLookupConfiguration(this.lookups);
            };

            this._structuredDataLibraryService.RegisterInstance(
                this.instanceId,
                this.displayMappers,
                this.GetEditMappers(),
                null,
                StructuredDataMapperHelper.GetAddButtonOverride(this._componentFactoryResolver, this.subscriptions, this.disableAddButtonSubject),
                StructuredDataMapperHelper.GetPaginateOverride(this._componentFactoryResolver,
                    this.subscriptions,
                    this.StructuredDataListWidgetConfigurationViewDTO.PageSize,
                    this.totalRecords,
                    this.setPageSubject,
                    this.setTotalRecordsSubject)
            );

            this.loaded = true;
        }
    }

    private ProccessPaging(paginationModel: PaginationModel): void {
        this.structuredDataFacade.LoadStructuredDataEntry(this.StructuredDataListWidgetConfigurationViewDTO.RootNodeId, paginationModel, this.StructuredDataListWidgetConfigurationViewDTO.Id, WidgetLoadMode.Always);
    }

    private RebindStructuredDataList(paginationResultModel: PaginationResultModel<StructuredDataEntryModel>, navigateToLastPage: boolean, reloadPage: boolean, widgetId: string, widgetLoadMode: WidgetLoadMode): void {

        //This code runs when an entry is deleted.
        if (this.paginationEnabled === true && reloadPage === true) {
            this.setPageSubject.next(this.currentPage);
        }
        else if (navigateToLastPage) {
            this.setTotalRecordsSubject.next(this.totalRecords);
            const lastPage = Math.ceil((this.totalRecords) / this.StructuredDataListWidgetConfigurationViewDTO.PageSize);

            if (this.currentPage !== lastPage) {
                this.setPageSubject.next(lastPage);
            }

        } else {

            if (paginationResultModel.Models.length === 0 && this.structuredDataModelAdded && this.IsLastPage()) {

                this.totalRecords = paginationResultModel.TotalRecords + 1;
                const blankEntries: StructuredDataEntryModel[] = [];
                blankEntries.push(this.structuredDataModelAdded);
                this.CopyEntries(blankEntries);

            } else if (paginationResultModel.Models.length > 0 && this.structuredDataModelAdded && this.IsLastPage()) {
                this.totalRecords = paginationResultModel.TotalRecords + 1;
                const entires = ObjectHelper.deepCopyJsonParse(paginationResultModel.Models);
                entires.push(this.structuredDataModelAdded);
                this.CopyEntries(entires);
            }
            else {

                if ((widgetLoadMode === WidgetLoadMode.Always) ||
                    (widgetLoadMode === WidgetLoadMode.SiblingWidgets && widgetId !== this.StructuredDataListWidgetConfigurationViewDTO.Id)) {
                    const entries = ObjectHelper.deepCopyJsonParse(paginationResultModel.Models);
                    this.CopyEntries(entries);
                    StructuredDataHelper.AppendInflatedEntries(entries, this.structuredDataIdsToAdd);
                }
                this.displayNextPage = false;
                this.totalRecords = paginationResultModel.TotalRecords;
                this.setTotalRecordsSubject.next(paginationResultModel.TotalRecords);
            }

            this.ToggleAddButtonEnabled();
        }
    }
}
