import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fw-message',
    templateUrl: './message.component.html',
    host: { class: 'alert-message' },
})
export class MessageComponent {
    @Input()
    public Message: string;
    @Input()
    public MessageLink: string;
    @Input()
    public Severity: string;

    @Output()
    public ViewDetails: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    public ViewDetailsClicked(): void {
        this.ViewDetails.emit(true);
    }
}
