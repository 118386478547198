import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressValidatorDirective } from './address-validator.directive';
import { AutoFocusDirective } from './auto-focus.directive';
import { ListMinCountDirective } from './list-min-count.directive';
import { PasswordValidator } from './password-validator';
import { TextMatchValidator } from './text-match-validator';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
    declarations: [
        TooltipDirective,
        AutoFocusDirective,
        AddressValidatorDirective,
        ListMinCountDirective,
        PasswordValidator,
        TextMatchValidator
    ],    
    imports: [
        CommonModule
    ],
    exports: [
        TooltipDirective,
        AutoFocusDirective,
        AddressValidatorDirective,
        ListMinCountDirective,
        PasswordValidator,
        TextMatchValidator
    ]
})
export class DirectivesModule { }
