import { Component, EventEmitter, Input, Output, TemplateRef, Type } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { first } from 'rxjs/operators';
import { SelectMode } from 'src/app/enums/select-mode';
import { SelectableListItem } from 'src/app/models/selectable-list-item';
import { BaseAssetListWidgetComponent } from 'src/app/modules/dynamic/widgets/types/lists/base-asset-list-widget.component';
import { LocaleDateFormatPipe } from 'src/app/pipes/locale-date-format.pipe';
import { DialogApplicationService, DialogOptions } from 'src/app/services/application/dialog-application.service';
import { LanguageHttpService } from 'src/app/services/http/language-http.service';
import { SiteSettingsService } from 'src/app/services/deprecated/site-settings.service';
import { ListFieldConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { BaseListConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/widgets/lists/base-list-configuration-view-dto';
import { OrderByDirection } from '../../../../enums/comparator-enum';
import { HeaderStyle } from '../../../../enums/configuration/header-style';
import { OrderByModel, PaginationModel, PaginationResultModel } from '../../../../models/pagination-models';
import { NavigateWrapper, OnLoadWrapper } from '../../../dynamic/widgets/types/lists/list-widget-interface';
import { TranslocoService } from '@ngneat/transloco';
import { TranslationHelper } from 'src/app/helpers/translation-helper';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';

@Component({
    selector: 'fw-list',
    templateUrl: './list.component.html',
})
export class ListComponent {
    public emptyListResourceId: string;
    public first: number = 0;
    public isLoading: boolean = true;
    public firstTimeLoad: boolean = true;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public models: any;
    public totalRecords: number;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public selectedItems: any = [];
    public isVisible: boolean = true;
    public headerResourceId: string;
    public headerStyle: HeaderStyle;
    public headerStyleEnum = HeaderStyle;
    public currentPageReportTemplate: string;

    @Input()
    public AddButtonDisabledState: boolean = false;

    @Input()
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public CustomHeaderTemplate: TemplateRef<any>;

    @Input()
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public CustomBodyTemplate: TemplateRef<any>;

    @Input()
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public AdditionalColumnsBodyTemplate: TemplateRef<any>;

    @Input()
    public IsSelectable: boolean = false;

    @Input()
    public IsPageable: boolean = true;

    @Input()
    public IsUserList: boolean;

    @Input()
    public ListConfigurationViewDTO: BaseListConfigurationViewDTO;

    @Input()
    public set ErrorMessageResourceId(errorMessageResourceId: string) {
        if (errorMessageResourceId) {
            this.emptyListResourceId = errorMessageResourceId;
        } else {
            this.emptyListResourceId =
                this.ListConfigurationViewDTO.EmptyListResourceId;
        }
    }

    @Input()
    public set PaginationResultModel(
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        paginationResultModel: PaginationResultModel<any>
    ) {
        if (paginationResultModel) {
            this.models = paginationResultModel.Models;
            this.totalRecords = paginationResultModel.TotalRecords;
            this.IsPageable = this.IsPageable && paginationResultModel.Models.length > 0;
            this.isLoading = false;
            this.firstTimeLoad = false;
            this.headerResourceId = this.ListConfigurationViewDTO.HeaderResourceId;
            this.headerStyle = this.ListConfigurationViewDTO.HeaderStyle;
            if (this.IsSelectable
                && this.selectMode === SelectMode.Single
                && this.autoSelectFirstItem
                && paginationResultModel.Models.length > 0) {
                this.selectedItems = paginationResultModel.Models[0];
                this.SelectableFieldClickedHandler();
            }
            if (this.ListConfigurationViewDTO.HideWhenEmpty === true && paginationResultModel.Models.length === 0) {
                this.isVisible = false;
            }
        }
    }

    @Input()
    public addEnabled: boolean = false;

    @Input()
    public required: boolean = false;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public additionalAddData: any;

    @Input()
    public selectMode: SelectMode = SelectMode.None;

    @Input()
    public deleteEnabled: boolean = false;

    @Input()
    public showOptionItems: boolean = false;

    @Input()
    public dialogHeaderText: string;

    @Input()
    public dialogLoadButtonText: string

    @Input()
    public dialogComponentToShow: Type<BaseAssetListWidgetComponent>;

    @Input()
    public autoSelectFirstItem: boolean = false;

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onItemsAddedEvent = new EventEmitter<SelectableListItem[]>();

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onItemDeleteEvent = new EventEmitter<string>();

    @Output()
    public OnLoad: EventEmitter<OnLoadWrapper> = new EventEmitter<OnLoadWrapper>();

    @Output()
    public RoutingFieldClicked: EventEmitter<NavigateWrapper> = new EventEmitter<NavigateWrapper>();

    @Output()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public SelectableFieldClicked: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(
        private languageHttpService: LanguageHttpService,
        private siteSettingsService: SiteSettingsService,
        private dialogApplicationService: DialogApplicationService,
        private translocoService: TranslocoService
    ) 
    {
        this.currentPageReportTemplate = this.translocoService.translate(TranslationHelper.CurrentPageReportTemplateResourceId);
    }

    public getTableStyleClass(): string {
        let tableStyleClass = '';

        if (this.totalRecords <= this.ListConfigurationViewDTO?.PageSize) {
            tableStyleClass += UIStylingHelper.hidePagingStyleClass;
        }

        return tableStyleClass;
    }

    public get showCheckbox(): boolean {
        return this.selectMode !== SelectMode.None;
    }

    public get selectionMode(): string {
        if (this.IsSelectable) {
            switch (this.selectMode) {
                case SelectMode.None:
                    break;

                case SelectMode.Single:
                    return 'single';

                case SelectMode.Multiple:
                    return 'multiple';
            }
        }
    }

    public loadClick(): void {
        const dialogOptions: DialogOptions<
            SelectableListItem[],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any
        > = {
            closable: false,
            footer: '',
            header: this.dialogHeaderText,
            dataModel: null,
            configuration: this.additionalAddData,
            showHeader: true,
            styleClass: 'dialog-xl',

        };

        const dialogReference = this.dialogApplicationService.showFormDialog(
            this.dialogComponentToShow,
            dialogOptions
        );

        dialogReference.onClose
            .pipe(first())
            .subscribe(
                (selectedItems: SelectableListItem[]) => {
                    this.onItemsAddedEvent.emit(selectedItems as SelectableListItem[]);
                }
            );
    }

    public deleteClick(itemId: string): void {
        this.onItemDeleteEvent.emit(itemId);
    }

    onRowUnselect() {
        if (this.selectMode === SelectMode.Multiple) {
            this.SelectableFieldClickedHandler();
        }
    }

    onRowSelect() {
        this.SelectableFieldClickedHandler();
    }

    public LoadEntities(event: LazyLoadEvent): void {
        this.isLoading = true;
        const paginationModel: PaginationModel = new PaginationModel();

        paginationModel.Limit = this.ListConfigurationViewDTO.PageSize;
        paginationModel.Page = event.first / event.rows + 1;

        //Implement when the ordering list story in being implemented.
        const orderByModel = new OrderByModel();
        orderByModel.Field = this.ListConfigurationViewDTO.DefaultSort;
        orderByModel.OrderByDirection = OrderByDirection.Ascending;

        paginationModel.OrderByList = Array<OrderByModel>();
        paginationModel.OrderByList.push(orderByModel);

        this.OnLoad.emit({
            paginationModel: paginationModel,
            searchModel: null,
            event: event
        });
    }

    public RoutingFieldClickedHandler(
        fieldConfig: ListFieldConfigurationViewDTO,
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        model: any
    ): void {
        this.RoutingFieldClicked.emit({
            fieldConfiguration: fieldConfig,
            model: model,
        });
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public formatCellValue(value: any, column: ListFieldConfigurationViewDTO): string {
        if (isNaN(value)) {
            return !isNaN(Date.parse(value)) && column.PropertyName.toLocaleLowerCase().indexOf('date') !== -1
                ? new LocaleDateFormatPipe(this.languageHttpService, this.siteSettingsService).transform(value, { stringDate: true })
                : value;
        }
        else {
            return value;
        }
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public SelectableFieldClickedHandler(): void {
        this.SelectableFieldClicked.emit(this.selectedItems);
    }
}
