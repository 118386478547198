/* eslint-disable */

export function isStringEmptyOrUndefined(value: any): value is string {
    return (!value || value == undefined || value == '' || value.length == 0);
}

export function convertStringToByteArray(value: string): Uint8Array {
    const byteCharacters = atob(value);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return byteArray;

}

export function capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
}