import { Component } from '@angular/core';
import { ControlBaseWrapper } from '../control-wrapper-base';

@Component({
  selector: 'fw-checkbox-wrapper',
  templateUrl: './checkbox-wrapper.component.html',
})
export class CheckboxWrapperComponent extends ControlBaseWrapper {
  constructor() {
    super();
  }
}
