import { UserPersonViewDTO } from './data-transfer-objects/user/user-person-view-dto';
import { ShapeViewDTO } from './data-transfer-objects/shape/shape-view-dto';
import { AssetListViewDTO } from './data-transfer-objects/asset/asset-list-view-dto';
import { PaginationResultModel } from './models/pagination-models';
import { AssetViewDTO } from './data-transfer-objects/asset/asset-view-dto';
import { DocumentListViewDTO } from './data-transfer-objects/document/document-list-view-dto';
import { CommodityViewDTO } from './data-transfer-objects/commodity/commodity-view-dto';
import { ActionViewDTO } from './data-transfer-objects/action/action-view-dto';
import { ActionListViewDTO } from './data-transfer-objects/action/action-list-view-dto';
import { JobSchedulerViewDTO } from './data-transfer-objects/job/job-scheduler-view-dto';
import { ShapeValidationResultsViewDTO } from './data-transfer-objects/shape/shape-validation-results-view-dto';
import { JobQueryResultViewDTO } from './data-transfer-objects/job/job-query-result-view-dto';
import { FormInputStyle } from './enums/configuration/form-input-style';
import { UserListViewDTO } from './data-transfer-objects/users/user-list-view-dto';
import { LookupConfigurationModel } from '@landadmin/structured-data/lib/models/lookup-configuration-model';
import { StructuredDataNodeModel } from '@landadmin/structured-data/lib/models/structured-data-node-model';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { WidgetLoadMode } from './enums/widget-load-mode';
import { DiscussionViewDTO } from './data-transfer-objects/discussion/discussion-view-dto';
import { DiscussionCommentViewDTO } from './data-transfer-objects/discussion-comment/discussion-comment-view-dto';
import { DiscussionParticipantViewDTO } from './data-transfer-objects/discussion-participant/discussion-participant-view-dto';
import { DiscussionLoadMode } from './data-transfer-objects/discussion/discussion-load-mode';
import { PageConfigurationViewDTO } from './data-transfer-objects/configuration/page-configuration-view.dto';
import { RelatedAssetListViewDTO } from './data-transfer-objects/related-asset/related-asset-list-view-dto';
import { RoleViewDTO } from './data-transfer-objects/role/role-view-dto';
import { IdPConfigurationViewDTO } from './data-transfer-objects/configuration/idp-configuration-view-dto';
import { PartyViewDTO } from './data-transfer-objects/party/party-view-dto';
import { RepresentativeListWidgetDataModel } from './data-transfer-objects/representative/representative-list-widget-data-model';
import { GoogleAnalyticsConfigurationViewDTO } from './data-transfer-objects/configuration/google-analytics-configuration-view-dto';

export const BasicPageStateConst = 'basicPageState';
export const AssetDraftPageStateConst = 'assetDraftPageState';
export const ActionDraftPageStateConst = 'actionDraftPageState';
export const AssetDetailsPageStateConst = 'assetDetailsPageState';
export const ActionDetailsPageStateConst = 'actionDetailsPageState';
export const ActionDetailsFormPageStateConst = 'actionDetailsFormPageState';
export const UserDetailsPageStateConst = 'userDetailsPageState';
export const UIStyleConfigurationStateConst = 'uiStyleConfigurationState';
export const WidgetStateConst = 'widgetState';
export const ConfigurationStateConst = 'configurationState';
export const SiteSettingsConfigurationStateConst = 'siteSettingsConfigurationState';
export const IdPsConfigurationStateConst = 'idPsConfigurationState';
export const GoogleAnalyticsConfigurationStateConst = 'GoogleAnalyticsConfigurationState';

export interface AppState {
    updateUserError: SavedSuccessfullyModel,
    assetDraftPageState: AssetDraftPageState,
    actionDraftPageState: ActionDraftPageState,
    assetListWidgetState: ListState<AssetListWidgetStateModel>[],
    actionListWidgetState: ListState<ActionListWidgetStateModel>[],
    userListWidgetState: ListState<UserListWidgetStateModel>[],
    basicPageState: BasicPageState,
    assetDetailsPageState: AssetDetailsPageState,
    uiStyleConfigurationState: UIStyleConfigurationState,
    widgetState: WidgetState,
    configurationState: ConfigurationState,
    siteSettingsConfigurationState: SiteSettingsConfigurationState,
    idPsConfigurationState: IdPsConfigurationState
}

export interface ConfigurationState {
    structuredDataLookupConfigurationState: StructuredDataLookupConfigurationState,
    structuredDataNodeConfigurationState: ListState<StructuredDataNodeConfigurationState>[],
    pageConfigurationState: ListState<PageConfigurationState>[]
}

export interface WidgetState {
    structuredDataListWidgetState: ListState<StructuredDataListWidgetState>[],
    discussionTopicListWidgetState: ListState<DiscussionsWidgetStateModel>[],
    discussionCommentsWidgetState: DiscussionCommentsWidgetState,
    relatedAssetListWidgetState: ListState<RelatedAssetListWidgetStateModel>[]
    discussionParticipantsWidgetState: DiscussionParticipantsWidgetState,
    assetWidgetState: AssetWidgetState,
    actionWidgetState: ActionWidgetState,
    userRoleWidgetState: UserRoleWidgetState,
    partyListWidgetState: ListState<PartyListWidgetStateModel>[],
    commodityWidgetState: CommodityWidgetState,
    representativeWidgetState: RepresentativeWidgetState,
    companyListState: CompanyListState,
    powerBIWidgetState: PowerBIWidgetState
}

export interface PageConfigurationState extends GetState<PageConfigurationViewDTO> {

}

export interface StructuredDataLookupConfigurationState extends GetState<LookupConfigurationModel> {

}

export interface StructuredDataNodeConfigurationState extends GetState<StructuredDataNodeModel[]> {

}

export interface GetState<TModel> {
    viewModel: TModel,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getError: any,
}

export interface UpdateState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateError: any,
}


export interface StructuredDataListWidgetState extends GetState<PaginationResultModel<StructuredDataEntryModel>>, UpdateState {
    widgetId: string,
    loadMode: WidgetLoadMode
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addError: any,
    navigateToLastPage: boolean,
    reloadPage: boolean
}

export interface BasicPageState {
    ShapeDraftWidgetState: ShapeWidgetState
}

export interface SavedSuccessfullyModel {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    successfull: boolean
}

export interface Saved {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
}

export interface AssetListWidgetStateModel {
    paginationResult: PaginationResultModel<AssetListViewDTO>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
}

export interface ActionListWidgetStateModel {
    paginationResult: PaginationResultModel<ActionListViewDTO>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
}

export interface UserListWidgetStateModel {
    paginationResult: PaginationResultModel<UserListViewDTO>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
}

export interface DiscussionsWidgetStateModel {
    paginationResult: PaginationResultModel<DiscussionViewDTO>
    discussionIdToBeUpdated?: string
    latestComment?: string,
    lastUpdated?: Date,
    loadMode: DiscussionLoadMode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
}

export interface RelatedAssetListWidgetStateModel {
    paginationResult: PaginationResultModel<RelatedAssetListViewDTO>;
    reloadList: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
}

export interface ListState<TModel> {
    dataSourceId: string,
    StateModel: TModel
}

export interface DocumentGroupWidgetState {
    listState: ListState<DocumentWidgetState>[],
    triggeredDataSourceId: string,
    widgetConfigurationId: string
}



export interface ActionDraftPageState {
    DocumentGroupWidgetState: DocumentGroupWidgetState,
    ShapeDraftWidgetState: ShapeWidgetState,
    RunShapeValidationState: RunShapeValidationState,
    ShapeValidationResultsState: ShapeValidationResultsState,
    DeleteJobState: DeleteJobState
}

export interface ActionDetailsPageState {
    ActionDraftLoaded: boolean,
    IsSaving: boolean,
    DocumentGroupWidgetState: DocumentGroupWidgetState,
    ShapeWidgetState: ShapeWidgetState,
    RunShapeValidationState: RunShapeValidationState,
    ShapeValidationResultsState: ShapeValidationResultsState,
    DeleteJobState: DeleteJobState
}

export interface ShapeWidgetState {
    ListState: ListState<ShapeViewDTO>[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetShapeError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UpdateShapeError: any
}

export interface RunShapeValidationState {
    JobSchedulerViewDTO: JobSchedulerViewDTO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RunShapeValidationError: any
}

export interface ShapeValidationResultsState {
    JobQueryResultViewDTO: JobQueryResultViewDTO<ShapeValidationResultsViewDTO>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetShapeValidationResultsError: any
}

export interface DeleteJobState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DeleteJobError: any
}

export interface AssetDraftPageState {
    DocumentGroupWidgetState: DocumentGroupWidgetState,
    ShapeDraftWidgetState: ShapeWidgetState,
    RunShapeValidationState: RunShapeValidationState,
    ShapeValidationResultsState: ShapeValidationResultsState,
    DeleteJobState: DeleteJobState
}

export interface AssetDetailsPageState {
    DocumentGroupWidgetState: DocumentGroupWidgetState,
    ShapeWidgetState: ShapeWidgetState,
    ActionListWidgetState: ListState<ActionListWidgetStateModel>[]
}

export interface AssetWidgetState {
    AssetViewDTO: AssetViewDTO,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetAssetError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UpdateAssetError: any,
}

export interface ActionWidgetState {
    ActionViewDTO: ActionViewDTO,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetActionError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UpdateActionError: any,
}

export interface DocumentWidgetState {
    DocumentDrafts: DocumentListViewDTO[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetDocumentsError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UpdateDocumentsError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AddDocumentsError: any,
    DocumentAdded: boolean,
    DocumentReplaced: boolean
}

export interface CommodityWidgetState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UpdateError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetError: any,
    Commodities: CommodityViewDTO[];
}

export interface RepresentativeWidgetState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AddError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RemoveError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetError: any,    
    Representatives: RepresentativeListWidgetDataModel    
}

export interface CompanyListState {
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetError: any,
    Companies: RepresentativeListWidgetDataModel    
}

export interface UserDetailsPageState {
    UserDetailsWidgetState: UserDetailsWidgetState,
    DocumentGroupWidgetState: DocumentGroupWidgetState
}

export interface UserDetailsWidgetState {
    UserDetailsViewDTO: UserPersonViewDTO,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetUserDetailsError: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UpdateUserDetailsError: any,
}

export interface UIStyleConfigurationState {
    FormInputStyle: FormInputStyle
}

export interface SiteSettingsConfigurationState {
    dateFormat: string
}

export interface LanguageState {
    languageLocale: string
}

export interface DiscussionCommentsWidgetState {
    DiscussionComments: DiscussionCommentViewDTO[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetDiscussionCommentsError: any,
    loadMode?: DiscussionLoadMode
}

export interface DiscussionParticipantsWidgetState {
    DiscussionParticipants: DiscussionParticipantViewDTO[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetDiscussionParticipantsError: any
}

export interface UserRoleWidgetState {
    Roles: RoleViewDTO[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Error: any
}

export interface IdPsConfigurationState {
    IdPsConfiguration: IdPConfigurationViewDTO,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetIdPsConfigurationError: any
}

export interface PartyListWidgetStateModel {
    paginationResult: PaginationResultModel<PartyViewDTO>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
}

export interface GoogleAnalyticsConfigurationState {
    GoogleAnalyticsConfiguration: GoogleAnalyticsConfigurationViewDTO,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetGoogleAnalyticsConfigurationError: any
}

export interface PowerBIWidgetState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GetError: any,
    EmbedAccessToken: string
}