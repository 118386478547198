export enum TextDisplayStyle {
    Default = 0,
    h1 = 1,
    h2 = 2,
    h3 = 3,
    h4 = 4,
    h5 = 5,
    h6 = 6,
    body = 7,
    label = 8,
    bold = 9,
    note = 10
}