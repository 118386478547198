import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActionWidgetState } from '../../AppState';
import { ActionUpdateDTO } from '../../data-transfer-objects/action/action-update-dto';
import { ActionViewDTO } from '../../data-transfer-objects/action/action-view-dto';
import { DateHelper } from '../../helpers/date-helper';
import { ActionDraftBehaviourContextModel } from '../../models/behaviours/context-models/action/action-draft-behaviour-context-model';
import { ActionHttpService } from '../../services/http/action-http.service';
import { executeActionBehaviourDone, executeActionBehaviourRequest, getActionDone, getActionError, getActionRequest, updateActionDone, updateActionError, updateActionRequest } from './action.actions';

@Injectable()
export class NewActionEffects {

    updateAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateActionRequest),
            switchMap((action) => {
                let updateActionDTO: ActionUpdateDTO;
                // eslint-disable-next-line prefer-const
                updateActionDTO = {
                    ...updateActionDTO,
                    ...action.actionViewDTO,
                };

                return this.actionHttpService.newUpdateAction(action.pageId, updateActionDTO).pipe(
                    map(() => {
                        const actionWidgetState: ActionWidgetState = {
                            ActionViewDTO: action.actionViewDTO,
                            UpdateActionError: null,
                            GetActionError: null,
                        };

                        return updateActionDone({
                            actionWidgetState: actionWidgetState
                        });
                    }),
                    catchError((err) => {
                        return of(
                            updateActionError({
                                actionWidgetState: {
                                    ActionViewDTO: null,
                                    GetActionError: null,
                                    UpdateActionError: err,
                                }
                            })
                        );
                    })
                );
            })
        )
    );

    getAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getActionRequest),
            switchMap((action) => {
                return this.actionHttpService.getAction(action.pageId, action.entityId)
                    .pipe(
                        map((actionDraftFromServer) => {
                            const actionDraft: ActionViewDTO = this.ConfigureActionDraft(actionDraftFromServer);

                            return getActionDone({
                                actionWidgetState: {
                                    ActionViewDTO: actionDraft,
                                    GetActionError: null,
                                    UpdateActionError: null,
                                },
                            });
                        }),
                        catchError((err) => {
                            return of(
                                getActionError({
                                    actionWidgetState: {
                                        ActionViewDTO: null,
                                        GetActionError: err,
                                        UpdateActionError: null,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    executeActionBehaviourRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(executeActionBehaviourRequest),
            switchMap((action) => {
                const actionDraftBehaviourContext: ActionDraftBehaviourContextModel = {
                    TaskId: action.taskId,
                    PageId: action.pageId,
                    ClientId: action.clientId,
                    AssetId: action.assetId,
                    ActionId: action.actionId
                };

                //TODO: Will need error handling here. Same for asset.
                return this.actionHttpService
                    .executeActionBehaviour(actionDraftBehaviourContext, action.widgetId, action.pageId)
                    .pipe(
                        map(() => {
                            return executeActionBehaviourDone();
                        })
                    );
            })
        )
    );

    constructor(
        private actions$: Actions,
        public actionHttpService: ActionHttpService,
    ) { }

    //TODO: Fix small hack here. Move this logic to the reducer.
    private ConfigureActionDraft(actionViewDTO: ActionViewDTO): ActionViewDTO {

        actionViewDTO.PeriodFrom = DateHelper.InitialiseDate(actionViewDTO.PeriodFrom);
        actionViewDTO.AdditionalFieldDate = DateHelper.InitialiseDate(actionViewDTO.AdditionalFieldDate);
        actionViewDTO.PeriodTo = DateHelper.InitialiseDate(actionViewDTO.PeriodTo);
        actionViewDTO.DueDate = DateHelper.InitialiseDate(actionViewDTO.DueDate);
        actionViewDTO.CompletedDate = DateHelper.InitialiseDate(actionViewDTO.CompletedDate);

        return actionViewDTO;
    }

}
