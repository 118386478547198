import { createReducer, on } from '@ngrx/store';
import { ActionWidgetState, DeleteJobState, DocumentGroupWidgetState, RunShapeValidationState, ShapeValidationResultsState, ShapeWidgetState } from '../../../AppState';
import { ListStateReducerHelper } from '../../../helpers/list-state-reducer-helper';
import { actionShapeFormResetState, addActionDetailsFormDocumentDraftDone, addActionDetailsFormDocumentDraftError, createActionDetailsFormDocumentDraftResetState, createActionFormDone, createActionFormIsSaving, createActionFormIsSavingResetState, createActionFormResetState, actionFormDeleteJobDone, actionFormDeleteJobError, getActionDetailsFormDocumentDraftsDone, getActionDetailsFormDocumentDraftsError, getActionFormShapeDone, getActionFormShapeError, getActionFormShapeValidationResultsDone, getActionFormShapeValidationResultsError, runActionFormShapeValidationDone, runActionFormShapeValidationError, actionFormShapeValidationResetState, actionFormShapeValidationResultsResetState, updateActionDetailsFormDocumentDraftDone, updateActionDetailsFormDocumentDraftError, updateActionFormShapeDone, updateActionFormShapeError } from './action-details-form.actions';
export const initialCreateActionActionWidgetState: ActionWidgetState = {
    ActionViewDTO: {
        Id: '', TypeId: '', AccountingCode: '', AdditionalFieldDate: null,
        DueDate: null, ReferenceNumber: '', PeriodFrom: null,
        PeriodTo: null, Code: '', Comments: '', Name: '', AssetId: null
    },
    GetActionError: null,
    UpdateActionError: null
};

export const initialCreateActionDraftIsSaving = false;
export const ActionFormIsSavingReducer = createReducer<boolean>(initialCreateActionDraftIsSaving,
    on(createActionFormIsSaving, (_, action) => action.IsSaving),
    on(createActionFormIsSavingResetState, (_, action) => initialCreateActionDraftIsSaving),
);

export const initialActionDraftExists = false;
export const ActionFormLoadedReducer = createReducer<boolean>(initialActionDraftExists,
    on(createActionFormDone, (_, action) => action.actionDraftLoaded),
    on(createActionFormResetState, (_, action) => initialActionDraftExists),
);

export const initialCreateActionDocumentDraftsDTO: DocumentGroupWidgetState =
{
    triggeredDataSourceId: null,
    listState: [],
    widgetConfigurationId: null
};

export const DocumentDraftsReducer = createReducer<DocumentGroupWidgetState>(initialCreateActionDocumentDraftsDTO,
    on(getActionDetailsFormDocumentDraftsDone, (state, action) => {
        return {
            listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState),
            triggeredDataSourceId: action.dataSourceId,
            widgetConfigurationId: action.widgetId
        }
    }),

    on(getActionDetailsFormDocumentDraftsError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(addActionDetailsFormDocumentDraftDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(addActionDetailsFormDocumentDraftError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(updateActionDetailsFormDocumentDraftDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(updateActionDetailsFormDocumentDraftError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(createActionDetailsFormDocumentDraftResetState, () => initialCreateActionDocumentDraftsDTO)
);

export const initialFormShapes: ShapeWidgetState = {
    GetShapeError: null,
    ListState: null,
    UpdateShapeError: null
}

export const ActionShapeFormReducer = createReducer<ShapeWidgetState>(initialFormShapes,
    on(updateActionFormShapeDone, (_, action) => action.shapeWidgetState),
    on(updateActionFormShapeError, (_, action) => action.shapeWidgetState),
    on(getActionFormShapeDone, (_, action) => action.shapeWidgetState),
    on(getActionFormShapeError, (_, action) => action.shapeWidgetState),
    on(actionShapeFormResetState, (_, action) => initialFormShapes)
);

export const initialShapeValidationState: RunShapeValidationState = {
    JobSchedulerViewDTO: null,
    RunShapeValidationError: null
};

export const RunActionFormShapeValidationReducer = createReducer<RunShapeValidationState>(initialShapeValidationState,
    on(runActionFormShapeValidationDone, (_, action) => action.runShapeValidationState),
    on(runActionFormShapeValidationError, (_, action) => action.runShapeValidationState),
    on(actionFormShapeValidationResetState, () => initialShapeValidationState)
);

export const initialShapeValidationResultsState: ShapeValidationResultsState = {
    JobQueryResultViewDTO: null,
    GetShapeValidationResultsError: null
};

export const GetActionFormShapeValidationResultsReducer = createReducer<ShapeValidationResultsState>(initialShapeValidationResultsState,
    on(getActionFormShapeValidationResultsDone, (_, action) => action.getShapeValidationResultsState),
    on(getActionFormShapeValidationResultsError, (_, action) => action.getShapeValidationResultsState),
    on(actionFormShapeValidationResultsResetState, () => initialShapeValidationResultsState)
);

export const initialDeleteJobState: DeleteJobState = {
    DeleteJobError: null
};

export const ActionFormDeleteJobReducer = createReducer<DeleteJobState>(initialDeleteJobState,
    on(actionFormDeleteJobDone, (_, action) => action.deleteJobState),
    on(actionFormDeleteJobError, (_, action) => action.deleteJobState)
);
