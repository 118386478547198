import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserRoleWidgetState } from '../../AppState';
import { RoleViewDTO } from '../../data-transfer-objects/role/role-view-dto';
import { UserRoleHttpService } from '../../services/http/user-role-http.service';
import { loadUserRoleDone, loadUserRoleError, loadUserRoleRequest, updateUserRoleDone, updateUserRoleError, updateUserRoleRequest } from './user-role.actions';

@Injectable()
export class UserRoleEffects {

    getRolesForUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadUserRoleRequest),
            switchMap((action) => {
                return this.userRoleHttpService.GetUserRoles(action.userId).pipe(
                    map((roles: RoleViewDTO[]) => {

                        return loadUserRoleDone({ userRoleWidgetState: { Error: null, Roles: roles } });
                    }),
                    catchError((err) => {
                        return of(
                            loadUserRoleError({ userRoleWidgetState: { Error: err, Roles: null } }));
                    })
                );
            })
        ));

    updateRolesForUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserRoleRequest),
            switchMap((action) => {

                return this.userRoleHttpService.UpdateUserRoles(action.userRoleUpdateDTO).pipe(
                    map(() => {
                        const userRoleWidgetState: UserRoleWidgetState = {
                            Error: null,
                            Roles: action.userRoleUpdateDTO.RoleIds.map((roleId => { return { Id: roleId, Name: null } }))
                        };

                        return updateUserRoleDone({
                            userRoleWidgetState: userRoleWidgetState
                        });
                    }),
                    catchError((err) => {
                        return of(
                            updateUserRoleError({
                                userRoleWidgetState: {
                                    Error: err,
                                    Roles: null
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private userRoleHttpService: UserRoleHttpService
    ) { }
}
