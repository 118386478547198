<div>
    <div (focusout)="onFocusOut($event)" [ngClass]="{'view-mode': editMode === false, 'edit-mode': editMode === true }" [attr.tabindex]="FormEditStyle === FormEditStyles.ReadOnly ? -1 : 0" (keydown.enter)="onKeyDownEnter($event)" (keydown.escape)="onCancelChanges()">
        <ng-template [ngIf]="ShowLabel()">
	        <label [ngClass]="GetLabelStylingClass()">
	            {{Label | transloco}}
	            <span *ngIf="Dropdown.Validation.Required && editMode">*</span>
	        </label>
        </ng-template>
        <div [formGroup]="CustomFormGroup">
            <div *ngIf="!editMode" (click)="editMode = FormEditStyle !== FormEditStyles.ReadOnly" class="view-fields" [ngClass]="GetValueStylingClass()">{{StorePreviousValue()}}{{GetDisplayValue()}}<button tabindex="-1" class="pi pi-pencil"></button>
            </div>
            <div class="edit-fields" *ngIf="editMode" >
                <p-dropdown [formControlName]="PropertyName"
                            *ngIf="editMode"
                            [options]="Options"
                            optionLabel="Value"
                            optionValue="Id"
                            placeholder="{{Dropdown.PlaceHolderItemResourceId | transloco}}"
                            fwAutoFocus>
                </p-dropdown>
                <div class="buttons">
                    <button class="pi pi-check" [disabled]="IsAcceptDisabled()" *ngIf="editMode" (click)="onAcceptChanges()"></button>
                    <button class="pi pi-times" *ngIf="editMode" (click)="onCancelChanges()"></button>
                </div>
                <fw-general-validator [PropertyName]="PropertyName"
                                      [(CustomFormGroup)]="CustomFormGroup"
                                      [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
            </div>
        </div>
    </div>
</div>
