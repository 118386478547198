import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StructuredDataModule } from '@landadmin/structured-data';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PipeModule } from 'src/app/pipes/pipe-module';
import { TranslocoRootModule } from '../../transloco/transloco-root.module';
import { DirectivesModule } from '../directives/directives.module';
import { GenericControlsModule } from '../static/generics/generic-controls.module';
import { StaticModule } from '../static/static.module';
import { ContainerComponent } from './container/container/container.component';
import { ComplexFormHandlerComponent } from './controls/complex-form-handler/complex-form-handler.component';
import { FormEditCalendarComponent } from './controls/form-edit/form-edit-calendar/form-edit-calendar.component';
import { FormEditCheckboxComponent } from './controls/form-edit/form-edit-checkbox/form-edit-checkbox.component';
import { FormEditCommodityComponent } from './controls/form-edit/form-edit-commodity/form-edit-commodity.component';
import { FormEditDropdownComponent } from './controls/form-edit/form-edit-dropdown/form-edit-dropdown.component';
import { FormEditInputNumberComponent } from './controls/form-edit/form-edit-input-number/form-edit-input-number.component';
import { FormEditTextAreaComponent } from './controls/form-edit/form-edit-text-area/form-edit-text-area.component';
import { FormEditTextFieldComponent } from './controls/form-edit/form-edit-text-field/form-edit-text-field.component';
import { FormEditUserRoleComponent } from './controls/form-edit/form-edit-user-role/form-user-role.component';
import { PaginatorComponent } from './controls/general/paginator/paginator.component';
import { InlineEditCalendarComponent } from './controls/inline-edit/inline-edit-calendar/inline-edit-calendar.component';
import { InlineEditCheckboxComponent } from './controls/inline-edit/inline-edit-checkbox/inline-edit-checkbox.component';
import { InlineEditCommodityComponent } from './controls/inline-edit/inline-edit-commodity/inline-edit-commodity.component';
import { InlineEditDropdownComponent } from './controls/inline-edit/inline-edit-dropdown/inline-edit-dropdown.component';
import { InlineEditInputNumberComponent } from './controls/inline-edit/inline-edit-input-number/inline-edit-input-number.component';
import { InlineEditTextAreaComponent } from './controls/inline-edit/inline-edit-text-area/inline-edit-text-area.component';
import { InlineEditTextFieldComponent } from './controls/inline-edit/inline-edit-text-field/inline-edit-text-field.component';
import { InlineEditUserRoleComponent } from './controls/inline-edit/inline-edit-user-role/inline-user-role.component';
import { ListBoxBaseComponent } from './controls/listbox/listbox-base.component';
import { ReadOnlyTextFieldComponent } from './controls/read-only/read-only-text-field/read-only-text-field.component';
import { SelectCommoditiesComponent } from './controls/select-commodities/select-commodities.component';
import { LookupCellComponent } from './controls/structured-data-cell-overrides/option-list-cell/look-up-cell.component';
import { UnitValueComponent } from './controls/structured-data-cell-overrides/unit-value-cell/unit-value-cell.component';
import { YesNoCellComponent } from './controls/structured-data-cell-overrides/yes-no-cell/yes-no-cell.component';
import { GeneralValidatorComponent } from './controls/validators/general-validator/general-validator.component';
import { CalendarWrapperComponent } from './controls/wrappers/calendar-wrapper/calendar-wrapper.component';
import { CheckboxWrapperComponent } from './controls/wrappers/checkbox-wrapper/checkbox-wrapper.component';
import { ControlGroupWrapperComponent } from './controls/wrappers/control-group-wrapper/control-group-wrapper.component';
import { ControlWrapperComponent } from './controls/wrappers/control-wrapper/control-wrapper.component';
import { DropdownWrapperComponent } from './controls/wrappers/dropdown-wrapper/dropdown-wrapper.component';
import { InputNumberWrapperComponent } from './controls/wrappers/input-number-wrapper/input-number-wrapper.component';
import { TextAreaWrapperComponent } from './controls/wrappers/text-area-wrapper/text-area-wrapper.component';
import { TextFieldWrapperComponent } from './controls/wrappers/text-field-wrapper/text-field-wrapper.component';
import { SectionComponent } from './section/section/section.component';
import { CarouselSectionComponent } from './section/types/carousel-section/carousel-section.component';
import { ColumnSectionComponent } from './section/types/column-section/column-section.component';
import { TabSectionComponent } from './section/types/tab-section/tab-section.component';
import { ActionDetailsWidgetComponent } from './widgets/types/action-details-widget/action-details-widget.component';
import { AssetDetailsWidgetComponent } from './widgets/types/asset-details-widget/asset-details-widget.component';
import { CommoditiesWidgetComponent } from './widgets/types/commodities-widget/commodities-widget.component';
import { ContentBlockWidgetComponent } from './widgets/types/content-block-widget/content-block-widget.component';
import { DiscussionsWidgetComponent } from './widgets/types/discussions-widget/discussions-widget.component';
import { DocumentListWidgetComponent } from './widgets/types/document-list-widget/document-list-widget.component';
import { ActionListWidgetComponent } from './widgets/types/lists/action-list-widget/action-list-widget.component';
import { AssetListWidgetComponent } from './widgets/types/lists/asset-list-widget/asset-list-widget.component';
import { AssetListWithRelationshipWidgetComponent } from './widgets/types/lists/asset-list-with-relationship-widget/asset-list-with-relationship-widget.component';
import { PartyListWidgetComponent } from './widgets/types/lists/party-list-widget/party-list-widget.component';
import { RelatedAssetListWidgetComponent } from './widgets/types/lists/related-asset-list-widget/related-asset-list-widget.component';
import { UserListWidgetComponent } from './widgets/types/lists/user-list-widget/user-list-widget.component';
import { MapWidgetComponent } from './widgets/types/map-widget/map-widget.component';
import { ModalMapComponent } from './widgets/types/map-widget/modal-map/modal-map.component';
import { PowerBIWidgetComponent } from './widgets/types/power-bi-widget/power-bi-widget.component';
import { RepresentativeListWidgetComponent } from './widgets/types/representative-list-widget/representative-list-widget.component';
import { SessionDetailsWidgetComponent } from './widgets/types/session-details-widget/session-details-widget.component';
import { StructuredDataWidgetComponent } from './widgets/types/structured-data-widget/structured-data-widget.component';
import { TaskWidgetComponent } from './widgets/types/task-widget/task-widget.component';
import { TileWidgetComponent } from './widgets/types/tile-widget/tile-widget.component';
import { UserDetailsWidgetComponent } from './widgets/types/user-details-widget/user-details-widget.component';
import { UserRolesWidgetComponent } from './widgets/types/user-roles-widget/user-roles-widget.component';
import { WidgetComponent } from './widgets/widget/widget.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

@NgModule({
    declarations: [
        ContainerComponent,
        SectionComponent,
        CarouselSectionComponent,
        ColumnSectionComponent,
        TabSectionComponent,
        WidgetComponent,
        ActionDetailsWidgetComponent,
        AssetDetailsWidgetComponent,
        CommoditiesWidgetComponent,
        ContentBlockWidgetComponent,
        DiscussionsWidgetComponent,
        DocumentListWidgetComponent,
        MapWidgetComponent,
        ModalMapComponent,
        RepresentativeListWidgetComponent,
        SessionDetailsWidgetComponent,
        StructuredDataWidgetComponent,
        TaskWidgetComponent,
        TileWidgetComponent,
        UserDetailsWidgetComponent,
        UserRolesWidgetComponent,
        ActionListWidgetComponent,
        AssetListWidgetComponent,
        AssetListWithRelationshipWidgetComponent,
        PartyListWidgetComponent,
        RelatedAssetListWidgetComponent,
        UserListWidgetComponent,
        ComplexFormHandlerComponent,
        FormEditCalendarComponent,
        InlineEditCalendarComponent,
        FormEditCheckboxComponent,
        InlineEditCheckboxComponent,
        FormEditCommodityComponent,
        InlineEditCommodityComponent,
        FormEditDropdownComponent,
        InlineEditDropdownComponent,
        FormEditInputNumberComponent,
        InlineEditInputNumberComponent,
        FormEditTextAreaComponent,
        InlineEditTextAreaComponent,
        FormEditTextFieldComponent,
        InlineEditTextFieldComponent,
        FormEditUserRoleComponent,
        InlineEditUserRoleComponent,
        PaginatorComponent,
        ListBoxBaseComponent,
        ReadOnlyTextFieldComponent,
        LookupCellComponent,
        UnitValueComponent,
        YesNoCellComponent,
        GeneralValidatorComponent,
        CalendarWrapperComponent,
        CheckboxWrapperComponent,
        ControlGroupWrapperComponent,
        ControlWrapperComponent,
        DropdownWrapperComponent,
        InputNumberWrapperComponent,
        TextAreaWrapperComponent,
        TextFieldWrapperComponent,
        SelectCommoditiesComponent,
        PowerBIWidgetComponent
    ],
    imports: [
        CommonModule,
        TranslocoRootModule,
        CarouselModule,
        TabViewModule,
        FormsModule,
        InputTextModule,
        CalendarModule,
        DropdownModule,
        InputNumberModule,
        InputTextModule,
        EditorModule,
        TableModule,
        MultiSelectModule,
        RouterModule,
        ReactiveFormsModule,
        ToggleButtonModule,
        PaginatorModule,
        DataViewModule,
        ButtonModule,
        ProgressBarModule,
        ListboxModule,
        ChipsModule,
        ConfirmDialogModule,
        DialogModule,
        ProgressSpinnerModule,
        MessagesModule,
        StructuredDataModule,
        PipeModule,
        DirectivesModule,
        GenericControlsModule,
        StaticModule,
        PowerBIEmbedModule
    ],
    exports: [
        SectionComponent,
        ContainerComponent,
        WidgetComponent,
        ContentBlockWidgetComponent,
        CarouselSectionComponent,
        ColumnSectionComponent,
        TabSectionComponent,
        ActionDetailsWidgetComponent,
        AssetDetailsWidgetComponent,
        CommoditiesWidgetComponent,
        DiscussionsWidgetComponent,
        DocumentListWidgetComponent,
        MapWidgetComponent,
        ModalMapComponent,
        RepresentativeListWidgetComponent,
        SessionDetailsWidgetComponent,
        StructuredDataWidgetComponent,
        TaskWidgetComponent,
        TileWidgetComponent,
        UserDetailsWidgetComponent,
        UserRolesWidgetComponent,
        ActionListWidgetComponent,
        AssetListWidgetComponent,
        AssetListWithRelationshipWidgetComponent,
        PartyListWidgetComponent,
        RelatedAssetListWidgetComponent,
        UserListWidgetComponent,
        ComplexFormHandlerComponent,
        FormEditCalendarComponent,
        InlineEditCalendarComponent,
        FormEditCheckboxComponent,
        InlineEditCheckboxComponent,
        FormEditCommodityComponent,
        InlineEditCommodityComponent,
        FormEditDropdownComponent,
        InlineEditDropdownComponent,
        FormEditInputNumberComponent,
        InlineEditInputNumberComponent,
        FormEditTextAreaComponent,
        InlineEditTextAreaComponent,
        FormEditTextFieldComponent,
        InlineEditTextFieldComponent,
        FormEditUserRoleComponent,
        InlineEditUserRoleComponent,
        PaginatorComponent,
        ListBoxBaseComponent,
        ReadOnlyTextFieldComponent,
        LookupCellComponent,
        UnitValueComponent,
        YesNoCellComponent,
        GeneralValidatorComponent,
        CalendarWrapperComponent,
        CheckboxWrapperComponent,
        ControlGroupWrapperComponent,
        ControlWrapperComponent,
        DropdownWrapperComponent,
        InputNumberWrapperComponent,
        TextAreaWrapperComponent,
        TextFieldWrapperComponent,
        SelectCommoditiesComponent,
        PowerBIWidgetComponent
    ], providers: [ConfirmationService]
})
export class DynamicModule { }
