import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssetListViewDTO } from '../../../../../../data-transfer-objects/asset/asset-list-view-dto';
import { ToastService } from '../../../../../../services/deprecated/toast.service';
import { IListWidget } from '../list-widget-interface';
import { AbstractAssetListFacade } from '../../../../../../facade/abstract/abstract-asset-list.facade';
import { BaseAssetListWidgetComponent } from '../base-asset-list-widget.component';

@Component({
    selector: 'fw-asset-list-widget',
    templateUrl: './asset-list-widget.component.html'
})
export class AssetListWidgetComponent extends BaseAssetListWidgetComponent implements OnInit, OnDestroy, IListWidget<AssetListViewDTO> {
    constructor(
        abstractAssetListFacade: AbstractAssetListFacade,
        toastService: ToastService,
        router: Router,
        cdr: ChangeDetectorRef
    ) {
        super(abstractAssetListFacade, cdr, router, toastService);
    }
}
