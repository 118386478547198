import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LookupConfigurationModel } from '@landadmin/structured-data/lib/models/lookup-configuration-model';
import { Observable } from 'rxjs';
import { BASE_URL } from '../../helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class StructuredDataLookupHttpService extends AuthenticatedHttpServiceBase {

    constructor(
        httpClient: HttpClient,
        @Inject(BASE_URL)
        private baseUrl: string,
        errorManager: ErrorManagerService,
        toastService: ToastService
    ) {
        super(httpClient, errorManager, toastService);
    }

    public GetStructuredDataLookupConfiguration(): Observable<LookupConfigurationModel> {

        return this.GetAuthenticated<LookupConfigurationModel>(`${this.baseUrl}api/configuration/StructuredDataLookup`, this.GetStandardHeaders());
    }
}
