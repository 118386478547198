import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscussionParticipantViewDTO } from '../../data-transfer-objects/discussion-participant/discussion-participant-view-dto';
import { BASE_URL } from '../../helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class DiscussionParticipantHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private _baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public getDiscussionParticipants(topicId: string, entityId: string, pageId: string): Observable<DiscussionParticipantViewDTO[]> {
        return this.GetAuthenticated<DiscussionParticipantViewDTO[]>(`${this._baseUrl}api/discussionParticipant/entityId/${entityId}/discussionId/${topicId}/pageId/${pageId}`, this.GetStandardHeaders(), []);
    }
}
