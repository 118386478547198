import { Directive, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';
import { PropertyConfiguration } from '../../../../models/configuration/widgets/properties/property-configuration';

@Directive()
export abstract class ControlBaseWrapper {
    public FormEditStyles = FormEditStyle;

    @Input()
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public CustomFormGroup: FormGroup;

    @Input()
    public FormEditStyle: FormEditStyle;

    @Input()
    public Property: PropertyConfiguration;
}