export class ErrorModel {

  public ErrorMessages: string[] = [];

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public AnonymousErrorObject: any,
    private _messages: string[],
  ) {
    for (let messageCount = 0; messageCount < _messages.length; messageCount++)
      this.ErrorMessages.push(`Time: ${new Date().toTimeString()} \n Message: ${_messages[messageCount]}`);
  }
}