import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TextAreaConfiguration } from '../../../../../models/configuration/widgets/properties/controls/textarea/text-area-configuration';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';

@Component({
    selector: 'fw-inline-edit-text-area',
    templateUrl: './inline-edit-text-area.component.html',
    host: { 'class': 'inline-edit' }
})
export class InlineEditTextAreaComponent extends ValidatableInputControlBase<string> implements OnInit {

    @Input()
    public TextArea: TextAreaConfiguration;

    constructor(protected transloco: TranslocoService) {
        super(transloco);
    }

    ngOnInit(): void {
        this.ConfigureTextAreaValidation(this.TextArea.Validation);
    }
}
