import { createReducer, on } from '@ngrx/store';
import { CommodityWidgetState } from '../../AppState';
import { CommodityViewDTO } from '../../data-transfer-objects/commodity/commodity-view-dto';
import { ObjectHelper } from '../../helpers/object-helper';
import { addCommodityRequest, addCommodityError, getCommodityDone, getCommodityError, removeCommodityRequest, removeCommodityError, resetCommodity } from './commodity.actions';

export const initialCommoditiesState: CommodityWidgetState = { Commodities: [], GetError: null, UpdateError: null };

export const CommodityReducer = createReducer<CommodityWidgetState>(initialCommoditiesState,

    on(getCommodityDone, (state, action) => action.commodityWidgetState),
    on(getCommodityError, (state, action) => action.commodityWidgetState),

    on(addCommodityRequest, (state, action) => {
        const stateToReturn: CommodityWidgetState = ObjectHelper.deepCopyJsonParse(state);
        stateToReturn.Commodities.push({ CommodityId: action.commodityId });
        return stateToReturn;
    }),
    on(addCommodityError, (state, action) => {
        const stateToReturn: CommodityWidgetState = ObjectHelper.deepCopyJsonParse(state);
        stateToReturn.UpdateError = action.addError;

        const index = stateToReturn.Commodities.findIndex((item: CommodityViewDTO) => item.CommodityId === action.commodityId);
        stateToReturn.Commodities.splice(index, 1);
        return stateToReturn;
    }),

    on(removeCommodityRequest, (state, action) => {
        const stateToReturn: CommodityWidgetState = ObjectHelper.deepCopyJsonParse(state);

        const index = stateToReturn.Commodities.findIndex((item: CommodityViewDTO) => item.CommodityId === action.commodityId);
        stateToReturn.Commodities.splice(index, 1);
        return stateToReturn;
    }),
    on(removeCommodityError, (state, action) => {
        const stateToReturn: CommodityWidgetState = ObjectHelper.deepCopyJsonParse(state);
        stateToReturn.UpdateError = action.removeError;
        stateToReturn.Commodities.push({ CommodityId: action.commodityId });
        return stateToReturn;
    }),
    on(resetCommodity, () => initialCommoditiesState)
);
