import { createReducer, on } from '@ngrx/store';
import { ListState, StructuredDataNodeConfigurationState } from '../../../../AppState';
import { ListStateReducerHelper } from '../../../../helpers/list-state-reducer-helper';
import { loadStructuredDataNodesDone, loadStructuredDataNodesError, resetStructuredDataNodes } from './structured-data-node.actions';


//Assets
export const initialStructuredDataNodeState: ListState<StructuredDataNodeConfigurationState>[] = [];

export const StructuredDataNodeReducer = createReducer<ListState<StructuredDataNodeConfigurationState>[]>(initialStructuredDataNodeState,
    on(loadStructuredDataNodesDone, (state, action) => { return ListStateReducerHelper.GetUpdatedListState<StructuredDataNodeConfigurationState>(state, action.entityTypeId, action.structuredDataNodeConfigurationState) }),
    on(loadStructuredDataNodesError, (state, action) => { return ListStateReducerHelper.GetUpdatedListState<StructuredDataNodeConfigurationState>(state, action.entityTypeId, action.structuredDataNodeConfigurationState) }),
    on(resetStructuredDataNodes, () => initialStructuredDataNodeState),
);

