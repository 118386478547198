import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppState } from 'src/app/AppState';
import { PaginationResultModel } from '../../models/pagination-models';
import { PartyHttpService } from '../../services/http/party-http-service';
import { getAssetPartiesDone, getAssetPartiesError, getAssetPartiesRequest } from './party.actions';

@Injectable()
export class PartyEffects {
    
    getAssetParties$ = createEffect(() => this.actions$.pipe(
        ofType(getAssetPartiesRequest),
        mergeMap((action) => {

            return this.partyHttpService.getPartiesByAssetId(action.assetId, action.paginationModel, action.pageId, action.widgetId).pipe(
                map(paginationResultModel => getAssetPartiesDone({
                    dataSourceId: action.dataSourceId,
                    stateModel: {
                        error: null,
                        paginationResult: paginationResultModel
                    }
                })),
                catchError((err) => {
                    return of(getAssetPartiesError({
                        stateModel: {
                            error: err,
                            paginationResult: null
                        },
                        dataSourceId: action.dataSourceId
                    }))
                })
            );

        })
    ));

    constructor(
        private actions$: Actions,
        private partyHttpService: PartyHttpService,
        private store: Store<AppState>
    ) { }
}
