import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListState, PartyListWidgetStateModel } from 'src/app/AppState';
import { ListFieldConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { PaginationModel } from 'src/app/models/pagination-models';
import { SearchModel } from 'src/app/models/search-model';
import { PartyQueryDefinitionConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/party-query-definition-view-dto';
import { AbstractListFacade } from './abstract-list.facade';

@Injectable()
export abstract class AbstractPartyListFacade extends AbstractListFacade<PartyQueryDefinitionConfigurationViewDTO, PartyListWidgetStateModel> {

    public abstract EntityId: string;
    public abstract WidgetId: string;

    public abstract GetListItems(): Observable<ListState<PartyListWidgetStateModel>[]>;
    public abstract LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, queryDefinition: PartyQueryDefinitionConfigurationViewDTO, dataSourceId: string): void;
}
