<div *ngIf="doneLoading">
    <fw-inline-edit-commodity *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                              [Configuration]="CommoditiesWidgetConfigurationViewDTO"
                              [CustomFormGroup]="FormGroup"
                              [Commodities]="Commodities"
                              [AcceptChanges]="AcceptChanges"
                              [FormEditStyle]="FormEditStyle"
                              [DataSourceId]="dataSourceId"
                              [ReadyToStorePreviousValue]="readyToStorePreviousValue">
    </fw-inline-edit-commodity>
    <fw-form-edit-commodity *ngIf="FormEditStyle === FormEditStyles.Form"
                            [HeaderLabel]="('FormEditCommodity.HeaderLabel' | transloco)"
                            [Configuration]="CommoditiesWidgetConfigurationViewDTO"
                            [CustomFormGroup]="FormGroup"
                            [Commodities]="Commodities"
                            [AcceptChanges]="AcceptChanges"
                            [AcceptCommodityChanges]="AcceptCommodityChanges"
                            [DataSourceId]="dataSourceId">
    </fw-form-edit-commodity>
</div>
