<fw-inline-edit-user-roles *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                           [UserRolesWidgetConfigurationViewDTO]="UserRolesWidgetConfigurationViewDTO"
                           [CustomFormGroup]="FormGroup"
                           [AcceptChanges]="AcceptChanges"
                           [FormEditStyle]="FormEditStyle"
                           [SelectedOptions]="CurrentUserRoles"
                           [ReadyToStorePreviousValue]="readyToStorePreviousValue"
                           [PropertyName]="PropertyName">
</fw-inline-edit-user-roles>
<fw-form-edit-user-roles *ngIf="FormEditStyle === FormEditStyles.Form"
                         [UserRolesWidgetConfigurationViewDTO]="UserRolesWidgetConfigurationViewDTO"
                         [CustomFormGroup]="FormGroup"
                         [AcceptChanges]="AcceptChanges">
</fw-form-edit-user-roles>