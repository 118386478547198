import { Component, Input } from '@angular/core';
import { CarouselSectionConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/carousel-section-configuration-view-dto';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';

@Component({
    selector: 'fw-carousel-section',
    templateUrl: './carousel-section.component.html',
})
export class CarouselSectionComponent {
    @Input()
    public FormEditStyle: FormEditStyle;

    @Input()
    public carouselSectionConfiguration: CarouselSectionConfigurationViewDTO;

    constructor() { }

    public getBackground(): object {

        return UIStylingHelper.getSectionBackground(this.carouselSectionConfiguration);
    }

    public getBackgroundWidth(): string {

        return UIStylingHelper.getSectionBackgroundWidth(this.carouselSectionConfiguration);
    }

    public getSectionStyling(): string {

        return UIStylingHelper.getSectionStyling(this.carouselSectionConfiguration);
    }
}
