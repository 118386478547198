<fw-inline-edit-calendar *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                         [CustomFormGroup]="CustomFormGroup"
                         [PropertyName]="Property.PropertyName"
                         [Label]="Property.LabelResourceId"
                         [LabelStyle]="Property.LabelStyle"
                         [ValueStyle]="Property.ValueStyle"
                         [Calendar]="Property.Calendar"
                         [AcceptChanges]="AcceptChanges"
                         [FormEditStyle]="FormEditStyle">
</fw-inline-edit-calendar>
<fw-form-edit-calendar *ngIf="FormEditStyle === FormEditStyles.Form"
                       [CustomFormGroup]="CustomFormGroup"
                       [PropertyName]="Property.PropertyName"
                       [Label]="Property.LabelResourceId"
                       [LabelStyle]="Property.LabelStyle"
                       [Calendar]="Property.Calendar"
                       [AcceptChanges]="AcceptChanges">
</fw-form-edit-calendar>