import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { BehaviourContextBase } from '../../models/behaviours/context-models/behaviour-context-base';
import { BehaviourHttpService } from '../../services/http/behaviour-http.service';
import { executePageBehaviourDone, executePageBehaviourRequest } from './behaviour.actions';

@Injectable()
export class BehaviourEffects {

    executePageBehaviourRequest$ = createEffect(() => this.actions$.pipe(
        ofType(executePageBehaviourRequest),
        switchMap((action) => {

            const behaviourContext: BehaviourContextBase = {
                PageId: action.pageId,
                ClientId: action.clientId
            };

            return this.behaviourHttpService.executePageBehaviour(behaviourContext, action.pageId).pipe(
                map(() => {
                    return executePageBehaviourDone();
                })
            );
        })
    ));   

    constructor(private actions$: Actions,
        public behaviourHttpService: BehaviourHttpService) {

    }
}
