<ng-container>
    <ng-template [ngTemplateOutlet]="customTemplate">
    </ng-template>
</ng-container>
<div class="p-dialog-footer justify-content-end">
    <p-button styleClass="btn btn-lg" 
              type="button" 
              (onClick)="cancelClicked()"
              label="{{CancelButtonText}}">
    </p-button>
    <p-button styleClass="btn btn-primary btn-lg" 
              type="button"
              (onClick)="confirmClicked()" 
              label="{{ConfirmButtonText}}">
    </p-button>
</div>