import { createAction, props } from '@ngrx/store';
import { DiscussionsWidgetStateModel } from '../../AppState';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { DiscussionQueryDefinitionFilterViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/discussion-query-definition-filter-view-dto';
import { DiscussionCreateDTO } from '../../data-transfer-objects/discussion/discussion-create-dto';
import { DiscussionViewDTO } from '../../data-transfer-objects/discussion/discussion-view-dto';
import { PaginationModel } from '../../models/pagination-models';

//load
export const loadDiscussionsRequest = createAction('[Discussions] Load Discussions Request',
    props<{
        pageId: string,
        entityId: string,
        fields: ListFieldConfigurationViewDTO[],
        dataSourceId: string,
        paginationModel: PaginationModel,
        discussionQueryDefinitionFilter: DiscussionQueryDefinitionFilterViewDTO
    }>());

export const loadDiscussionsRequestDone = createAction('[Discussions] Load Discussions Done',
    props<{
        dataSourceId: string,
        discussionTopicListWidgetStateModel: DiscussionsWidgetStateModel
    }>());

export const loadDiscussionsRequestError = createAction('[Discussions] Load Discussions Error',
    props<{
        dataSourceId: string,
        discussionTopicListWidgetStateModel: DiscussionsWidgetStateModel
    }>());

//add
export const addDiscussionRequest = createAction('[Discussions] Add Discussion Request',
    props<{
        pageId: string,
        entityId: string,
        dataSourceId: string,
        widgetId: string,
        discussionCreateDto: DiscussionCreateDTO
    }>());

export const addDiscussionRequestDone = createAction('[Discussions] Add Discussion Done',
    props<{
        dataSourceId: string,
        discussion: DiscussionViewDTO
    }>());

export const addDiscussionRequestError = createAction('[Discussions] Add Discussion Error',
    props<{
        dataSourceId: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        addError: any,
        discussion: DiscussionViewDTO
    }>());

//update
export const updateDiscussionRequestDone = createAction('[Discussions] Update Discussion Done',
    props<{
        dataSourceId: string,
        discussionId: string,
        latestComment: string,
        lastUpdated: Date
    }>());


//reset
export const resetDiscussions = createAction('[Discussions] Reset Discussions');
