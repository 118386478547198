import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserListWidgetStateModel, ListState } from '../../AppState';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { UserQueryDefinitionSearchViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/user-query-definition-search-view-dto';
import { PaginationModel } from '../../models/pagination-models';
import { SearchModel } from '../../models/search-model';
import { AbstractListFacade } from './abstract-list.facade';

@Injectable()
export abstract class AbstractUserListFacade extends AbstractListFacade<UserQueryDefinitionSearchViewDTO, UserListWidgetStateModel> {

    public abstract GetListItems(): Observable<ListState<UserListWidgetStateModel>[]>;

    public abstract LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, userQueryDefinition: UserQueryDefinitionSearchViewDTO, dataSourceId: string): void;

    public abstract LoadUserListItems(searchModel: SearchModel, paginationModel: PaginationModel, pageId: string, widgetId: string, dataSourceId: string): void;
}
