/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';
import { NavigationLinkModel } from 'src/app/models/navigation-link-model';

@Component({
  selector: 'portal-navigation-links',
  templateUrl: './navigation-links.component.html',
})
export class NavigationLinksComponent {
  @Input('navigation-links')
  public _navigationLinks: NavigationLinkModel[] = [];

  constructor() {}
}
