import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PowerBIWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/widgets/power-bi-widget-configuration-view-dto';
import * as pbi from 'powerbi-client'
import { AbstractPowerBIFacade } from '../../../../../facade/abstract/abstract-power-bi.facade';
import { Subscription } from 'rxjs';
import { PowerBIWidgetState } from '../../../../../AppState';
import { ToastService } from '../../../../../services/deprecated/toast.service';
import { StyleVariants } from '../../../../../enums/bootstrap/style-variants';

@Component({
    selector: 'fw-power-bi-widget',
    templateUrl: './power-bi-widget.component.html'
})
export class PowerBIWidgetComponent implements OnInit, OnDestroy {

    @Input()
    public PowerBIWidgetConfiguration: PowerBIWidgetConfigurationViewDTO;
    @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;

    public getError: boolean = false;

    private subcriptions: Subscription[] = [];

    constructor(private powerBIFacade: AbstractPowerBIFacade, private toastService: ToastService) {
    }

    ngOnInit(): void {

        this.powerBIFacade.LoadEmbedAccessToken(this.PowerBIWidgetConfiguration.Embed);

        const getAccessTokenSubscription = this.powerBIFacade.GetEmbedAccessToken().subscribe((state: PowerBIWidgetState) => {

            if (state) {

                if (state.GetError) {
                    this.getError = true;

                } else {

                    if (!state.EmbedAccessToken) {
                        return;
                    }

                    this.setEmbedConfiguration(state.EmbedAccessToken);
                }
            }
        });

        this.subcriptions.push(getAccessTokenSubscription);
    }

    ngOnDestroy(): void {
        if (this.subcriptions) {
            this.subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
    }

    private setEmbedConfiguration(embedAccessToken: string) {
        const embedConfig: pbi.IEmbedConfiguration = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: embedAccessToken,
            embedUrl: this.PowerBIWidgetConfiguration.Embed.EmbedUrl,
            groupId: this.PowerBIWidgetConfiguration.Embed.GroupId,
            id: this.PowerBIWidgetConfiguration.Embed.ReportId
        };

        const reportContainer = this.reportContainer.nativeElement;
        const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        powerbi.embed(reportContainer, embedConfig);
    }
}