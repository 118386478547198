import { ActionReducerMap } from '@ngrx/store';
import { ConfigurationState } from '../../AppState';
import { PageConfigurationReducer } from './site/site-configuration.reducers';
import { StructuredDataLookupReducer } from './structured-data/look-up/structured-data-look-up.reducers';
import { StructuredDataNodeReducer } from './structured-data/node/structured-data-node.reducers';


export const ConfigurationReducerMapper: ActionReducerMap<ConfigurationState> = {
    structuredDataLookupConfigurationState: StructuredDataLookupReducer,
    structuredDataNodeConfigurationState: StructuredDataNodeReducer,
    pageConfigurationState: PageConfigurationReducer
};
