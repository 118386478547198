import { createAction, props } from '@ngrx/store';
import { ShapeWidgetState } from '../../../AppState';
import { ActionListViewDTO } from '../../../data-transfer-objects/action/action-list-view-dto';
import { ActionSearchModel } from '../../../data-transfer-objects/action/action-search-dto';
import { AssetListViewDTO } from '../../../data-transfer-objects/asset/asset-list-view-dto';
import { AssetSearchModel } from '../../../data-transfer-objects/asset/asset-search-dto';
import { ListFieldConfigurationViewDTO } from '../../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { UserListViewDTO } from '../../../data-transfer-objects/users/user-list-view-dto';
import { PaginationModel, PaginationResultModel } from '../../../models/pagination-models';
import { SearchModel } from '../../../models/search-model';


export const getBasicAssetsBySearchRequest = createAction('[BasicPage] Get Assets By Search Request', props<{ fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, assetSearchDTO: AssetSearchModel, dataSourceId: string }>());
export const getBasicAssetsBySearchDone = createAction('[BasicPage] Get Assets By Search Done', props<{ paginationResult: PaginationResultModel<AssetListViewDTO>, dataSourceId: string }>());
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getBasicAssetsBySearchRequestError = createAction('[BasicPage] Save Assets Request Error', props<{ error: any, dataSourceId: string }>());

export const getBasicActionsBySearchRequest = createAction('[BasicPage] Get Actions By Search Request', props<{ fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, actionSearchDTO: ActionSearchModel, dataSourceId: string }>());
export const getBasicActionsBySearchDone = createAction('[BasicPage] Get Actions By Search Done', props<{ paginationResult: PaginationResultModel<ActionListViewDTO>, dataSourceId: string }>());
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getBasicActionsBySearchRequestError = createAction('[BasicPage] Save Actions Request Error', props<{ error: any, dataSourceId: string }>());

export const getBasicUsersBySearchRequest = createAction('[BasicPage] Get Users By Search Request', props < { userSearchDTO: SearchModel, paginationModel: PaginationModel, pageId: string, widgetId: string, dataSourceId: string }>());
export const getBasicUsersBySearchDone = createAction('[BasicPage] Get Users By Search Done', props<{ paginationResult: PaginationResultModel<UserListViewDTO>, dataSourceId: string }>());
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getBasicUsersBySearchRequestError = createAction('[BasicPage] Save Users Request Error', props<{ error: any, dataSourceId: string }>());

export const updateShape = createAction('[BasicPage] Update Shape', props<{ shapeDraftWidgetState: ShapeWidgetState }>());

export const executeBasicBehaviourRequest = createAction('[BasicPage] Execute Basic Behaviour Request', props<{ taskId: string, pageId: string, clientId: string, widgetId: string }>());
export const executeBasicBehaviourDone = createAction('[BasicPage] Execute Basic Behaviour Done');
