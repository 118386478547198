import { BaseDisplayStyleConfigurationViewDTO } from '../data-transfer-objects/configuration/base-display-style-configuration-view-dto';
import { BaseSectionConfigurationViewDTO } from '../data-transfer-objects/configuration/base-section-configuration-view-dto';
import { BorderStyle } from '../enums/configuration/border-style';
import { ButtonStyle } from '../enums/configuration/button-style';
import { FormInputStyle } from '../enums/configuration/form-input-style';
import { HorizontalAlignmentStyle } from '../enums/configuration/horizontal-alignment-style';
import { TextDisplayStyle } from '../enums/configuration/text-display-style-enum';
import { VerticalAlignmentStyle } from '../enums/configuration/vertical-alignment-style';
import { WidthStyle } from '../enums/configuration/width-style';

export class UIStylingHelper {

    public static hidePagingStyleClass: string = 'hide-paging';

    public static ApplyFormInputStyling(formInputStyle: FormInputStyle): void {
        let cssClass: string = '';
        switch (formInputStyle) {
            case FormInputStyle.Floating:
                cssClass = 'floating-form';
                break;
            case FormInputStyle.Row:
                cssClass = 'row-form';
                break;
            default:
        }
        if (cssClass !== '') {
            document.getElementsByTagName('body').item(0).classList.add(cssClass);
        }
    }

    public static GetTextDisplayStyling(styleValue: TextDisplayStyle): string {
        let cssClass: string = '';

        switch (styleValue) {
            case TextDisplayStyle.h1:
                cssClass = 'h1';
                break;
            case TextDisplayStyle.h2:
                cssClass = 'h2';
                break;
            case TextDisplayStyle.h3:
                cssClass = 'h3';
                break;
            case TextDisplayStyle.h4:
                cssClass = 'h4';
                break;
            case TextDisplayStyle.h5:
                cssClass = 'h5';
                break;
            case TextDisplayStyle.h6:
                cssClass = 'h6';
                break;
            case TextDisplayStyle.body:
                cssClass = 'body';
                break;
            case TextDisplayStyle.label:
                cssClass = 'label';
                break;
            case TextDisplayStyle.bold:
                cssClass = 'bold';
                break;
            case TextDisplayStyle.note:
                cssClass = 'note';
                break;
        }

        return cssClass;
    }

    public static GetHorizontalAlignmentStyling(horizontalAlignmentStyle: HorizontalAlignmentStyle): string {

        let cssClass: string = '';

        switch (horizontalAlignmentStyle) {
            case HorizontalAlignmentStyle.start:
                cssClass = 'align-items-start';
                break;
            case HorizontalAlignmentStyle.center:
                cssClass = 'align-items-center';
                break;
            case HorizontalAlignmentStyle.end:
                cssClass = 'align-items-end';
                break;
            case HorizontalAlignmentStyle.stretch:
                cssClass = 'align-items-stretch';
                break;
        }
        return cssClass;
    }

    public static GetVerticalAlignmentStyling(verticalAlignmentStyle: VerticalAlignmentStyle): string {

        let cssClass: string = '';

        switch (verticalAlignmentStyle) {
            case VerticalAlignmentStyle.top:
                cssClass = 'justify-content-start';
                break;
            case VerticalAlignmentStyle.middle:
                cssClass = 'justify-content-center';
                break;
            case VerticalAlignmentStyle.bottom:
                cssClass = 'justify-content-end';
                break;
        }
        return cssClass;
    }

    public static GetSelfAlignmentStyling(selfAlignmentStyle: HorizontalAlignmentStyle): string {

        let cssClass: string = '';

        switch (selfAlignmentStyle) {
            case HorizontalAlignmentStyle.start:
                cssClass = 'align-self-start';
                break;
            case HorizontalAlignmentStyle.center:
                cssClass = 'align-self-center';
                break;
            case HorizontalAlignmentStyle.end:
                cssClass = 'align-self-end';
                break;
            case HorizontalAlignmentStyle.stretch:
                cssClass = 'align-self-stretch';
                break;
        }
        return cssClass;
    }

    public static GetBorderStyling(borderStyle: BorderStyle): string {

        let cssClass: string = '';

        switch (borderStyle) {
            case BorderStyle.none:
                cssClass = '';
                break;
            case BorderStyle.card:
                cssClass = 'card-style';
                break;
            case BorderStyle.inset:
                cssClass = 'inset-style';
                break;
            case BorderStyle.border:
                cssClass = 'border-style';
                break;
        }
        return cssClass;
    }

    public static GetWidthStyling(widthStyle: WidthStyle): string {

        let cssClass: string = 'content';

        switch (widthStyle) {
            case WidthStyle.content:
                cssClass = 'content';
                break;
            case WidthStyle.fullpage:
                cssClass = 'fullpage';
                break;
        }
        return cssClass;
    }

    public static getButtonStyle(buttonStyle: ButtonStyle): string {
        let cssClass: string = '';

        switch (buttonStyle) {
            case ButtonStyle.Primary:
                cssClass = 'btn-primary';
                break;
            case ButtonStyle.Secondary:
                cssClass = 'btn-secondary';
                break;
            case ButtonStyle.Basic:
                cssClass = '';
                break;
            case ButtonStyle.Hyperlink:
                cssClass = 'btn-link';
                break;
        }
        return cssClass;
    }

    public static GetCssClass(baseDisplayStyleConfigurationViewDTO: BaseDisplayStyleConfigurationViewDTO): string {
        let cssClass: string = ''

        if (baseDisplayStyleConfigurationViewDTO && baseDisplayStyleConfigurationViewDTO.CssClass != '') {
            cssClass = baseDisplayStyleConfigurationViewDTO.CssClass;
        }

        return cssClass;
    }

    public static getSectionStyling(baseSectionConfigurationViewDTO: BaseSectionConfigurationViewDTO): string {

        let sectionStyling: string = '';

        if (baseSectionConfigurationViewDTO && baseSectionConfigurationViewDTO.DisplayStyle) {

            const borderStyle: string = this.GetBorderStyling(baseSectionConfigurationViewDTO.DisplayStyle.BorderStyle);
            const sectionWidthStyle: string = this.GetWidthStyling(baseSectionConfigurationViewDTO.DisplayStyle.SectionWidth);
            const cssClass: string = this.GetCssClass(baseSectionConfigurationViewDTO.DisplayStyle);
            sectionStyling = `${borderStyle} ${sectionWidthStyle} ${cssClass}`;
        }

        return sectionStyling;
    }

    public static getSectionBackground(baseSectionConfigurationViewDTO: BaseSectionConfigurationViewDTO): object {

        let background: object = null;

        if (baseSectionConfigurationViewDTO && baseSectionConfigurationViewDTO.DisplayStyle && baseSectionConfigurationViewDTO.DisplayStyle.Background != '') {

            background = { 'background': `${baseSectionConfigurationViewDTO.DisplayStyle.Background}` };
        }

        return background;
    }

    public static getSectionBackgroundWidth(baseSectionConfigurationViewDTO: BaseSectionConfigurationViewDTO): string {

        let backgroundWidth: string = '';

        if (baseSectionConfigurationViewDTO && baseSectionConfigurationViewDTO.DisplayStyle && baseSectionConfigurationViewDTO.DisplayStyle.Background != '') {

            backgroundWidth = UIStylingHelper.GetWidthStyling(baseSectionConfigurationViewDTO.DisplayStyle.BackgroundWidth)
        }

        return backgroundWidth;
    }

}