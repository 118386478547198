<div class="d-flex justify-content-end mb-10">
    <p-button styleClass="btn rep-btn-add" type="button" label="{{'List.Buttons.Add' | transloco }}"
        (onClick)="addRepresentation()"></p-button>
</div>
<p-table #dt 
    [value]="RepresentativeListWidgetDataModel?.representatives" 
    [lazy]="true"
    (onLazyLoad)="loadRepresentatives($event)" 
    reflow="true"
    [paginator]="RepresentativeListWidgetDataModel?.representatives?.length > 0"
    [rows]="RepresentativeListWidgetConfiguration.PageSize"
    [totalRecords]="RepresentativeListWidgetDataModel?.totalRecords"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="currentPageReportTemplate"
    [showPageLinks]="RepresentativeListWidgetDataModel?.totalRecords > RepresentativeListWidgetConfiguration.PageSize"
    [showFirstLastIcon]="RepresentativeListWidgetDataModel?.totalRecords > RepresentativeListWidgetConfiguration.PageSize" 
    [ngClass]="getTableStyleClass()"
    [loading]="loading"
    [columns]="RepresentativeListWidgetConfiguration.Fields"
    responsiveLayout="scroll"
    dataKey="id" 
    [first]="first">

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ngStyle]="{'width':col.PropertyName === 'Code' ? '100px' : 'auto' }">
                {{col.HeaderLabelResourceId | transloco}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-representative let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                <span class="p-column-title">{{col.HeaderLabelResourceId | transloco}}</span>
                {{ representative[col.PropertyName] }}
                <p-button *ngIf="col.PropertyName === 'FullName'"
                    [ngClass]="{ 'hover-btn': RepresentativeListWidgetDataModel?.FormEditStyle === formEditStyle.Inline}"
                    styleClass="btn btn-sm btn-danger" type="button"
                    label="{{'List.Buttons.Remove' | transloco }}" (onClick)="removeRepresentation(representative.Id)">
                </p-button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                {{RepresentativeListWidgetConfiguration.EmptyListResourceId | transloco}}
            </td>
        </tr>
    </ng-template>
</p-table>
<p-confirmDialog acceptLabel="{{'List.Buttons.Remove' | transloco }}"
    rejectLabel="{{'List.Buttons.Cancel' | transloco }}" rejectButtonStyleClass="btn"
    acceptButtonStyleClass="btn btn-primary btn-danger" rejectIcon="" acceptIcon=""
    icon="pi pi-exclamation-triangle danger"></p-confirmDialog>