import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationDictionaryModel } from '../../../../../models/validation/validation-dictionary-model';

@Component({
  selector: 'fw-general-validator',
  templateUrl: './general-validator.component.html',
})
export class GeneralValidatorComponent {
  @Input()
  public CustomFormGroup: FormGroup;

  @Input()
  public PropertyName: string;

  @Input()
  public ValidationDictionary: ValidationDictionaryModel[];

  constructor() { }
}
