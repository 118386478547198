export enum Comparator {

    Like = 1,
    Exact = 2,
    GreaterThan = 3,
    LessThan = 4

}

export enum OrderByDirection {
    Descending = 1,
    Ascending = 2
}