import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IdPConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/idp-configuration-view-dto';
import { DialogComponentBase } from '../../../../services/application/dialog-application.service';

@Component({
    selector: 'fw-idp-selection-form',
    templateUrl: './idp-selection-form.component.html'
})
export class IdpSelectionFormComponent extends DialogComponentBase<IdPConfigurationViewDTO> {
    public model: IdPConfigurationViewDTO = this.dialogOptions.dataModel;

    constructor(
        private _router: Router,
        private dynamicDialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig,) {
        super(dynamicDialogRef, dynamicDialogConfig);
    }

    private navigateToInternalIdentityProvider(): void {
        this._router.navigate(['/page/register']);
        this.dynamicDialogRef.destroy();
    }
}
