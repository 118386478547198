import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DiscussionLoadMode } from '../../data-transfer-objects/discussion/discussion-load-mode';
import { DiscussionViewDTO } from '../../data-transfer-objects/discussion/discussion-view-dto';
import { HttpFieldModel } from '../../models/field-list-model';
import { PaginationResultModel } from '../../models/pagination-models';
import { DiscussionHttpService } from '../../services/http/discussion-http.service';
import { addDiscussionRequest, addDiscussionRequestDone, addDiscussionRequestError, loadDiscussionsRequest, loadDiscussionsRequestDone, loadDiscussionsRequestError } from './discussion.actions';

@Injectable()
export class DiscussionEffects {

    addDiscussion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addDiscussionRequest),
            switchMap((action) => {

                return this.discussionHttpService.addDiscussion(action.entityId, action.pageId, action.widgetId, action.discussionCreateDto).pipe(
                    map((discussion: DiscussionViewDTO) => {
                        return addDiscussionRequestDone({ dataSourceId: action.dataSourceId, discussion: discussion });
                    }),
                    catchError((err) => {
                        return of(addDiscussionRequestError({ dataSourceId: action.dataSourceId, discussion: null, addError: err }));
                    })
                );
            })
        ));


    loadDiscussions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDiscussionsRequest),
            switchMap((action) => {

                const httpFieldModel = new HttpFieldModel();
                httpFieldModel.Fields = new Array<string>();

                action.fields.forEach(field => {
                    httpFieldModel.Fields.push(field.PropertyName);
                })

                return this.discussionHttpService.getDiscussionsByFilter(action.entityId, action.pageId, action.discussionQueryDefinitionFilter, httpFieldModel).pipe(
                    map((discussions: DiscussionViewDTO[]) => {
                        const paginationResultModel: PaginationResultModel<DiscussionViewDTO> = {
                            DisplayNextPage: false,
                            Models: discussions,
                            TotalRecords: discussions.length
                        };

                        return loadDiscussionsRequestDone({ dataSourceId: action.dataSourceId, discussionTopicListWidgetStateModel: { error: null, paginationResult: paginationResultModel, loadMode: DiscussionLoadMode.Populate } });
                    }),
                    catchError((err) => {
                        return of(
                            loadDiscussionsRequestError({ dataSourceId: action.dataSourceId, discussionTopicListWidgetStateModel: { error: err, paginationResult: null, loadMode: DiscussionLoadMode.Populate } }));
                    })
                );
            })
        ));

    constructor(
        private actions$: Actions,
        private discussionHttpService: DiscussionHttpService
    ) { }

}
