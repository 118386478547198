<div class="container">
    <span class="pi pi-file"></span>
    <strong>{{ _secondaryText }} for {{ _mainText }}</strong>
    <span #infoTooltip class="pi pi-info-circle"></span>
    <div class="float-right">
        <div class="position-relative d-inline-block">
            <div #loadingReference></div>
        </div>
        <portal-button (click)="_discard()" class="ml-10 link">Discard</portal-button>
    </div>
</div>