<form novalidate [formGroup]="FormGroup">
    <ng-container>
        <fw-control-wrapper [Properties]="UserDetailsWidgetConfiguration.Properties"
                            [CustomFormGroup]="FormGroup"
                            [AcceptChanges]="AcceptChanges"
                            [AcceptGroupChanges]="AcceptGroupChanges"
                            [DiscardChanges]="DiscardChanges"
                            [FormEditStyle]="FormEditStyle"></fw-control-wrapper>
    </ng-container>

</form>