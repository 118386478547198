<div class="form-edit">
    <div>
        <label>
            {{ 'SelectDocumentType.Form.DocumentType' | transloco}}
        </label>
        <div>
            <p-dropdown [options]="documentTypes"
                        [(ngModel)]="currentDocumentType"
                        optionLabel="Value"
                        optionValue="Id"
                        appendTo="body"></p-dropdown>
        </div>
    </div>
</div>

<div class="p-dialog-footer justify-content-end">
    <p-button styleClass="btn btn-lg" type="button" (onClick)="cancelClicked()" label="{{'SelectDocumentType.Form.Cancel' | transloco }}"></p-button>
    <p-button [disabled]="!currentDocumentType" styleClass="btn btn-primary btn-lg" type="button" (onClick)="proceedClicked()" label="{{'SelectDocumentType.Form.Proceed' | transloco }}"></p-button>
</div>