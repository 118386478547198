<div [formGroup]="CustomFormGroup">
    <fw-general-validator [PropertyName]="PropertyName"
                          [(CustomFormGroup)]="CustomFormGroup"
                          [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
    <p-inputNumber [formControlName]="PropertyName" [showButtons]="true" (onInput)="onNumberValueChange($event.value)">
    </p-inputNumber>
    <ng-template [ngIf]="ShowLabel()">
	    <label [ngClass]="GetLabelStylingClass()">
	        {{Label | transloco}}
	        <span *ngIf="InputNumber.Validation.Required">*</span>
	    </label>
    </ng-template>
</div>
