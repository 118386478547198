export enum DynamicControlType {
    TextField = 1,
    InputNumber = 2,
    Calendar = 3,
    Checkbox = 4,
    TextArea = 5,
    Dropdown = 6,
    ComplexProperty = 7,
    ReadOnlyTextField = 8,
    PropertyGroup = 9
}