import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { InputNumberConfiguration } from '../../../../../models/configuration/widgets/properties/controls/inputnumber/input-number-configuration';
import { DebounceTimeControlBase } from '../../input-controls/debounce-time-control-base';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';

@Component({
    selector: 'fw-form-edit-input-number',
    templateUrl: './form-edit-input-number.component.html',
    host: { 'class': 'form-field' }
})
export class FormEditInputNumberComponent extends DebounceTimeControlBase<number> implements OnInit {

    @Input()
    public InputNumber: InputNumberConfiguration;

    constructor(protected transloco: TranslocoService) {
        super(transloco);
    }

    ngOnInit(): void {
        this.ConfigureInputNumberValidation(this.InputNumber.Validation);
    }

}
