import { createReducer, on } from '@ngrx/store';
import { ListState, PartyListWidgetStateModel } from '../../AppState';
import { ListStateReducerHelper } from '../../helpers/list-state-reducer-helper';
import { getAssetPartiesDone, getAssetPartiesError, resetPartyList } from './party.actions';

export const initialPartyListState: ListState<PartyListWidgetStateModel>[] = [];

export const PartyListReducer = createReducer<ListState<PartyListWidgetStateModel>[]>(initialPartyListState,
    on(getAssetPartiesDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<PartyListWidgetStateModel>(state, action.dataSourceId, action.stateModel)),
    on(getAssetPartiesError, (state, action) => ListStateReducerHelper.GetUpdatedListState<PartyListWidgetStateModel>(state, action.dataSourceId, action.stateModel)),
    on(resetPartyList, () => initialPartyListState)
);
