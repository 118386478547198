import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyListState } from '../../AppState';
import { UserRepresentationListWidetConfigurationViewDTO } from '../../data-transfer-objects/configuration/user-representation-list-widget-configuration-view-dto';
import { FormEditStyle } from '../../enums/configuration/form-edit-style';

@Injectable()
export abstract class AbstractCompanyListFacade {
    public UserRepresentationListWidgetConfiguration: UserRepresentationListWidetConfigurationViewDTO;
    public FormEditStyle: FormEditStyle;

    public abstract GetRepresentativesBySearch(): Observable<CompanyListState>;
    public abstract LoadRepresentativesBySearch(): void;
    public abstract ResetState(): void;
}
