import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { severity_error } from '../../../../../consts/severity-options';
import { ToastApplicationService } from '../../../../../services/application/toast-application.service';
import { CommodityWidgetState } from '../../../../../AppState';
import { DynamicPageIdParameter } from '../../../../../consts/portal-base-routes';
import { CommodityViewDTO } from '../../../../../data-transfer-objects/commodity/commodity-view-dto';
import { CommoditiesWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/commodities-widget-configuration-view-dto';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { AbstractCommoditiesFacade } from '../../../../../facade/abstract/abstract-commodities-facade';
import { BaseWidget } from '../base-widget';

@Component({
    selector: 'fw-commodities-widget',
    templateUrl: './commodities-widget.component.html',
})
export class CommoditiesWidgetComponent extends BaseWidget implements OnInit, OnDestroy {
    public doneLoading: boolean = false;
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    public AcceptCommodityChanges: EventEmitter<{ optionId: string, add: boolean }> = new EventEmitter<{ optionId: string, add: boolean }>();

    Commodities: CommodityViewDTO[] = [];
    FormEditStyle: FormEditStyle;
    FormEditStyles = FormEditStyle;
    FormGroup: FormGroup;

    @Input()
    CommoditiesWidgetConfigurationViewDTO: CommoditiesWidgetConfigurationViewDTO;

    private _subcriptions: Subscription[] = [];
    private acceptedChangedExecutedFromThisWidget: boolean = false;
    private readyToStorePreviousValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private commoditiesFacade: AbstractCommoditiesFacade,
        private route: ActivatedRoute,
        public toastApplicationService: ToastApplicationService,
        public elementRef: ElementRef
    ) {
        super(elementRef);

        this.FormEditStyle = commoditiesFacade.FormEditStyle;
    }


    ngOnDestroy(): void {
        if (this._subcriptions) {
            this._subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
        this.commoditiesFacade.ResetState();
    }

    ngOnInit(): void {
        const pageId: string = this.route.snapshot.params[DynamicPageIdParameter];

        this._subcriptions.push(this.commoditiesFacade.ReadyToLoad.subscribe((readyToLoad: boolean) => {
            if (readyToLoad === true) {
                this.commoditiesFacade.LoadCommodities();
            }
        }));

        if (!this.acceptedChangedExecutedFromThisWidget) {
            this.FormGroup = new FormGroup({});
            this.acceptedChangedExecutedFromThisWidget = false;
        }

        const getCommoditiesSubscription = this.commoditiesFacade
            .GetCommodities()
            .subscribe((commodityDraftWidgetState: CommodityWidgetState) => {
                if (commodityDraftWidgetState.UpdateError) {
                    const message: Message = {
                        summary: 'Commodity.UpdateError',
                        detail: 'Commodity.UpdateError.Detail',
                        severity: severity_error,
                    };
                    this.toastApplicationService.showToast([message]);
                } else if (commodityDraftWidgetState.GetError) {
                    const message: Message = {
                        summary: 'Commodity.GetError',
                        detail: 'Commodity.GetError.Detail',
                        severity: severity_error,
                    };
                    this.toastApplicationService.showToast([message]);
                } else {
                    this.Commodities = commodityDraftWidgetState.Commodities;
                    this.buildControls();
                }

                const commodityIds: string[] = this.FormGroup.value['Commodities'];

                this.commoditiesFacade._originalCommodityIds = [];

                commodityIds.forEach((commodityId) => {
                    this.commoditiesFacade._originalCommodityIds.push({
                        CommodityId: commodityId,
                    });
                });

                this.commoditiesFacade.SetValidity(
                    this.dataSourceId,
                    this.FormGroup.valid
                );

                this.doneLoading = true;
            });

        this._subcriptions.push(getCommoditiesSubscription);

        const acceptChangesSubscription = this.AcceptChanges.subscribe(() => {
            this.acceptedChangedExecutedFromThisWidget = true;

            const commodityIds: string[] = this.FormGroup.value['Commodities'];

            const commodities: CommodityViewDTO[] = [];

            commodityIds.forEach((commodityId) => {
                commodities.push({ CommodityId: commodityId });
            });

            this.commoditiesFacade.UpdateCommodities(this.commoditiesFacade._entityId, commodities);
        });

        this._subcriptions.push(acceptChangesSubscription);       
    }

    private buildControls(): void {
        const propertyName = 'Commodities';
        const data = this.Commodities.map((commodity) => commodity.CommodityId);

        const abstractControl = this.FormGroup.controls[propertyName];
        if (!abstractControl) {
            this.FormGroup.addControl(propertyName, new FormControl(data));
        } else {
            abstractControl.setValue(data);
            this.readyToStorePreviousValue.next(true);
        }
    }
}
