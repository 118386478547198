export abstract class StructuredDataCellBase {

    public abstract onCancelChanges();

    public onFocusOut(event) {

        if (!event.sourceCapabilities &&
            (event.target && (event.target.nodeName === 'INPUT'))
        ) {
            return;
        }

        if (!event.relatedTarget || (event.relatedTarget.className && event.relatedTarget.className === 'view-mode')) {
            this.onCancelChanges();
        }
    }
}