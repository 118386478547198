import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommodityWidgetState } from '../../AppState';
import { CommodityViewDTO } from '../../data-transfer-objects/commodity/commodity-view-dto';
import { FormEditStyle } from '../../enums/configuration/form-edit-style';
import { FacadeValidation } from '../../modules/dynamic/widgets/types/validation/facade-validation';

@Injectable({
    providedIn: 'root'
})

export abstract class AbstractCommoditiesFacade extends FacadeValidation {

    public ReadyToLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public FormEditStyle: FormEditStyle;
    public _entityId: string;
    public _pageId: string;
    
    public _originalCommodityIds: CommodityViewDTO[];

    public abstract GetCommodities(): Observable<CommodityWidgetState>;
    public abstract LoadCommodities(): void;
    public abstract ResetState(): void;    
    public abstract UpdateCommodities(assetId: string, commodityViewDTO: CommodityViewDTO[]): void;
}
