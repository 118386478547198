import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { addDiscussionRequest, loadDiscussionsRequest, resetDiscussions, updateDiscussionRequestDone } from '../../state/discussion/discussion.actions';
import { getDiscussionTopicListWidgetState } from '../../state/widgets.selectors';
import { ListState, DiscussionsWidgetStateModel, AppState } from '../../AppState';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { DiscussionQueryDefinitionFilterViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/discussion-query-definition-filter-view-dto';
import { DiscussionCreateDTO } from '../../data-transfer-objects/discussion/discussion-create-dto';
import { DiscussionViewDTO } from '../../data-transfer-objects/discussion/discussion-view-dto';
import { PaginationModel, PaginationResultModel } from '../../models/pagination-models';
import { SearchModel } from '../../models/search-model';
import { AbstractDiscussionTopicListFacade } from '../abstract/abstract-discussion-topic-list.facade';

@Injectable()
export class DiscussionsFacade extends AbstractDiscussionTopicListFacade {
    public PageId: string;
    public _discussionsExist: boolean;

    public PaginationResultModel: PaginationResultModel<DiscussionViewDTO>;
    public _widgetId: string;
    public _setLoadSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public _entityId: string;

    constructor(private store: Store<AppState>) {
        super();
    }

    public CreateDiscussion(discussionCreateDto: DiscussionCreateDTO, dataSourceId: string): void {
        this.store.dispatch(addDiscussionRequest({
            dataSourceId: dataSourceId,
            discussionCreateDto: discussionCreateDto,
            entityId: this._entityId,
            pageId: this.PageId,
            widgetId: this._widgetId
        }));
    }

    public GetListItems(): Observable<ListState<DiscussionsWidgetStateModel>[]> {
        const discussionTopicList = this.store.pipe(select(getDiscussionTopicListWidgetState));
        return discussionTopicList;
    }
    public LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, discussionQueryDefinition: DiscussionQueryDefinitionFilterViewDTO, dataSourceId: string): void {

        this.store.dispatch(loadDiscussionsRequest({
            dataSourceId: dataSourceId,
            discussionQueryDefinitionFilter: discussionQueryDefinition,
            entityId: this._entityId,
            pageId: this.PageId,
            fields: fields,
            paginationModel: paginationModel
        }));
    }

    public UpdateDiscussion(dataSourceId: string, discussionId: string, latestComment: string, lastUpdated: Date): void {
        this.store.dispatch(updateDiscussionRequestDone({
            dataSourceId: dataSourceId,
            discussionId: discussionId,
            latestComment: latestComment,
            lastUpdated: lastUpdated
        }));
    }

    public ResetState(): void {
        this.store.dispatch(resetDiscussions());
    }

    public LoadUserListItems(searchModel: SearchModel, paginationModel: PaginationModel, pageId: string, widgetId: string, dataSourceId: string): void {
        throw new Error('Method not implemented.');
    }
}
