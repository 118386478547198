import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetListWidgetStateModel, ListState } from '../../AppState';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { AssetQueryDefinitionSearchViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/asset-query-definition-search-view-dto';
import { PaginationModel } from '../../models/pagination-models';
import { SearchModel } from '../../models/search-model';
import { AbstractListFacade } from './abstract-list.facade';

@Injectable()
export abstract class AbstractAssetListFacade extends AbstractListFacade<AssetQueryDefinitionSearchViewDTO, AssetListWidgetStateModel> {

    public abstract AssetId: string;

    public abstract GetListItems(): Observable<ListState<AssetListWidgetStateModel>[]>;
    public abstract LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, assetQueryDefinition: AssetQueryDefinitionSearchViewDTO, dataSourceId: string): void;
}
