export enum FieldType {
    Lookup = 1,
    LandfolioLookup = 2,
    String = 3,
    Number = 4,
    Date = 5,
    Password = 6,
    Boolean = 7,
    Button = 8,
    Route = 9,
    Byte = 10,
    LookupArray = 11,
    LandfolioLookupArray = 12,
    DateRange = 13,
    MultiField = 14,
    Icon = 15,
    ExternalLink = 16,
    FilteredLandfolioLookup = 17,
    DateTime = 18,
    LinkButton = 19,
    InvertedBoolean = 20
}
