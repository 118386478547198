<ng-container>
    <div>
        <ng-container *ngIf="!getReadOnlyControl()">
            <div class="view-mode" tabindex="0" (keydown.enter)="onKeyDownEnter()">
                <label>
                    {{Property.LabelResourceId | transloco}}
                </label>
                <div class="view-fields group-fields" (click)="editClicked()">
                    <button tabindex="-1" class="pi pi-pencil"></button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="getReadOnlyControl()">
            <div class="view-mode" tabindex="0" (keydown.enter)="onKeyDownEnter()">
                <fw-read-only-text-field [CustomFormGroup]="ChildFormGroup"
                                         [PropertyName]="Property.PropertyName"
                                         [Label]="Property.LabelResourceId"
                                         [LabelStyle]="Property.LabelStyle"
                                         [ValueStyle]="Property.ValueStyle">
                </fw-read-only-text-field>
                <div class="view-fields group-fields" (click)="editClicked()">
                    <button tabindex="-1" class="pi pi-pencil"></button>
                </div>
            </div>
        </ng-container>
    </div>
    <p-dialog [header]="Property.LabelResourceId | transloco" [(visible)]="Display" [closable]="false">
        <fw-control-wrapper #ControlWrapper id="ControlWrapper"
                            [Properties]="Properties"
                            [FormEditStyle]="FormEditStyle"
                            [CustomFormGroup]="ChildFormGroup">
        </fw-control-wrapper>
        <ng-template pTemplate="footer">
            <p-button styleClass="btn btn-lg" type="button" label="{{'AssetPage.Form.Discard' | transloco }}" (onClick)="discardClicked()"></p-button>
            <p-button styleClass="btn btn-primary btn-lg" type="button" label="{{'AssetPage.Form.Submit' | transloco }}" [disabled]="!ChildFormGroup.valid" (onClick)="submitClicked()"></p-button>
        </ng-template>
    </p-dialog>
</ng-container>
