import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ConfigurationHttpService } from '../../../services/http/configuration-http.service';
import { getUIStyleConfigurationDone, getUIStyleConfigurationRequest, getUIStyleConfigurationRequestError } from './ui-style.actions';

@Injectable()
export class UIStyleEffects {
    getUIStyleConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(getUIStyleConfigurationRequest),
        mergeMap(() => {
            return this.configHttpService.GetSiteUIStyleConfiguration().pipe(
                map(uiStyleConfiguration => {
                    return getUIStyleConfigurationDone({ configuration: uiStyleConfiguration });
                }), catchError((err) => {
                    return of(getUIStyleConfigurationRequestError({
                        error: err
                    }));
                })
            )
        })
    ));

    constructor(
        private actions$: Actions,
        public configHttpService: ConfigurationHttpService) {
    }
}
