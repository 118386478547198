<fw-inline-edit-text-area *ngIf="FormEditStyle === FormEditStyles.Inline || FormEditStyle === FormEditStyles.ReadOnly"
                          [CustomFormGroup]="CustomFormGroup"
                          [PropertyName]="Property.PropertyName"
                          [Label]="Property.LabelResourceId"
                          [LabelStyle]="Property.LabelStyle"
                          [ValueStyle]="Property.ValueStyle"
                          [TextArea]="Property.TextArea"
                          [AcceptChanges]="AcceptChanges"
                          [FormEditStyle]="FormEditStyle">
</fw-inline-edit-text-area>
<fw-form-edit-text-area *ngIf="FormEditStyle === FormEditStyles.Form"
                          [CustomFormGroup]="CustomFormGroup"
                          [PropertyName]="Property.PropertyName"
                          [Label]="Property.LabelResourceId"
                          [LabelStyle]="Property.LabelStyle"
                          [TextArea]="Property.TextArea"
                          [AcceptChanges]="AcceptChanges">
</fw-form-edit-text-area>