import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocaleDateFormatPipe } from './locale-date-format.pipe';
import { FieldFormatPipe } from './field-format.pipe';
import { BytePipe } from './byte.pipe';

@NgModule({
    declarations: [FieldFormatPipe, LocaleDateFormatPipe, BytePipe],
    imports: [CommonModule],
    exports: [FieldFormatPipe, LocaleDateFormatPipe, BytePipe]
})

export class PipeModule { }
