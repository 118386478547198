import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorModel } from 'src/app/models/error-model';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorManagerService {

    private _errors: ErrorModel[] = [];
    private _errorSubject: Subject<ErrorModel> = new Subject<ErrorModel>(); 
    public AppConnectedToServer: boolean = false;

    public get Error(): Subject<ErrorModel> {
        return this._errorSubject;
    }
    
    constructor(private _router: Router) {

    }

    public AddError(error: ErrorModel): void {
        this._errors.push(error);
    }

    public ClearError(): void {
        this._errors = [];
    }

    public GetAll(): ErrorModel[] {
        return this._errors;
    }

    public HandleError(error: ErrorModel): void {
        //if signal-r is not connected, we don't want to navigate to the error page.
        //when the signal-r connection is restored the page will refresh, and we want that to happen on the original page.
        const pageNotFound = error?.AnonymousErrorObject?.status === 404;

        //this is a hack so the app component can perform logic for errors with status 503
        this._errorSubject.next(error);
        
        if (this.AppConnectedToServer || pageNotFound) {
            this.AddError(error);
            this._router.navigate(['error']);
        } else {
            console.log(error);
        }
    }
}
