import { ListState } from '../AppState';
import { ObjectHelper } from './object-helper';

export class ListStateReducerHelper {
    public static GetUpdatedListState<T>(state: ListState<T>[], dataSourceId: string, stateModel: T): ListState<T>[] {
        let stateToReturn: ListState<T>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find((s) => s.dataSourceId === dataSourceId)) {
            const stateEntry = stateToReturn.find(
                (s) => s.dataSourceId === dataSourceId
            );
            stateEntry.StateModel = stateModel;
        } else {
            stateToReturn.push({
                dataSourceId: dataSourceId,
                StateModel: stateModel,
            });
        }

        return stateToReturn;
    }

    public static removeItemFromState<T>(state: ListState<T>[], dataSourceId: string, itemIds: string[]): ListState<T>[] {
        const stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (stateToReturn.length > 0 && stateToReturn.find((s: any) => s.dataSourceId === dataSourceId)
        ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const stateEntry = stateToReturn.find((s: any) => s.dataSourceId === dataSourceId);

            itemIds.forEach((itemId: string) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const index = stateEntry.StateModel.paginationResult.Models.findIndex((item: any) => item.Id === itemId);
                stateEntry.StateModel.paginationResult.Models.splice(index, 1);
            });
        }

        return stateToReturn;
    }
}
