import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { TextFieldConfiguration } from '../../../../../models/configuration/widgets/properties/controls/textfield/text-field-configuration';
import { DebounceTimeControlBase } from '../../input-controls/debounce-time-control-base';

@Component({
    selector: 'fw-form-edit-text-field',
    templateUrl: './form-edit-text-field.component.html',
    host: { 'class': 'form-field' }
})
export class FormEditTextFieldComponent extends DebounceTimeControlBase<string> implements OnInit {

    @Input()
    public TextField: TextFieldConfiguration;

    constructor(protected transloco: TranslocoService) {
        super(transloco);
    }

    ngOnInit(): void {
        this.ConfigureTextFieldValidation(this.TextField.Validation);
    }

}
