import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SavedSuccessfullyModel, UserDetailsWidgetState } from '../../AppState';
import { UserPersonViewDTO } from '../../data-transfer-objects/user/user-person-view-dto';

@Injectable()
export abstract class AbtractUserDetailsFacade{

    public abstract _userId: string;
    public abstract LoadUserDetails(): void;
    public abstract ResetUserDetails(): void;
    public abstract UpdateAndGetUser(userPersonViewDTO: UserPersonViewDTO): void;
    public abstract UpdateUser(userPersonViewDTO: UserPersonViewDTO): void;
    public abstract UpdateUserError(): Observable<SavedSuccessfullyModel>;
    public abstract UserDetailsSelector(): Observable<UserDetailsWidgetState>;
}
