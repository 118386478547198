import { Params } from '@angular/router';

export class NavigationLinkModel {
    constructor(
        public Name: string,
        public Link: string,
        public QueryParams: Params,
        public SubMenu: NavigationLinkModel[]
    ) {

    }
}