import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Message, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastApplicationService {

    constructor(private messageService: MessageService, private translocoService: TranslocoService) { }

    public showToast(messages: Message[], translateDetail: boolean = true): void {

        messages.forEach((message: Message) => {
            const detail = translateDetail === false ? message.detail : this.translocoService.translate(message.detail);
            message.life = 3000;
            message.summary = this.translocoService.translate(message.summary);
            message.detail = detail;
            this.messageService.add(message);
        });

    }
    
}
