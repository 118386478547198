import { Component, Input } from '@angular/core';
import { BaseSectionConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/base-section-configuration-view-dto';
import { ConfigurationHelper } from 'src/app/helpers/configuration-helper';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';
import { SectionConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/section-configuration-view-dto';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';
import { SectionStyle } from '../../../../enums/configuration/section-style';

@Component({
  selector: 'fw-section',
  templateUrl: './section.component.html'
})
export class SectionComponent {
  // necessary to access the properties on the template
  public sectionStyle = SectionStyle;

  @Input()
  public FormEditStyle: FormEditStyle;

  @Input()
  public sectionConfiguration: SectionConfigurationViewDTO;

  constructor() { }

}
