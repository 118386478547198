import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { loadDiscussionCommentsRequest, resetDiscussionComments } from '../../state/discussion/discussion-comments/discussion-comments.actions';
import { getDiscussionCommentsWidgetState } from '../../state/widgets.selectors';
import { AppState, DiscussionCommentsWidgetState } from '../../AppState';
import { DiscussionCommentCreateDTO } from '../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';
import { AbstractDiscussionCommentsListFacade } from '../abstract/abstract-discussion-comments-list.facade';

@Injectable()
export class DiscussionCommentsListFacade extends AbstractDiscussionCommentsListFacade {
    public _addCommentToUi: Subject<DiscussionCommentCreateDTO> = new Subject<DiscussionCommentCreateDTO>();
    public _setLoadSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public _discussionId: string;
    public _entityId: string;
    public _pageId: string;

    constructor(private store: Store<AppState>) {
        super();
    }

    public LoadDiscussionCommentsSelector(): Observable<DiscussionCommentsWidgetState> {
        const discussionComments = this.store.pipe(select(getDiscussionCommentsWidgetState));
        return discussionComments;
    }
    public LoadDiscussionComments(): void {
        this.store.dispatch(loadDiscussionCommentsRequest({
            entityId: this._entityId,
            pageId: this._pageId,
            discussionId: this._discussionId
        }));
    }

    public ResetState(): void {
        this.store.dispatch(resetDiscussionComments());
    }
}
