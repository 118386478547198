import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Message } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { GuidHelper } from 'src/app/helpers/guid-helper';
import { severity_warning } from '../../../../consts/severity-options';
import { DocumentUploadConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/document-upload-configuration-view-dto';
import { StyleVariants } from '../../../../enums/bootstrap/style-variants';
import { FileHelper } from '../../../../helpers/file-helper';
import { BytePipe } from '../../../../pipes/byte.pipe';
import { ToastApplicationService } from '../../../../services/application/toast-application.service';
import { ConfigurationHttpService } from '../../../../services/http/configuration-http.service';
import { ToastService } from '../../../../services/deprecated/toast.service';

@Component({
    selector: 'fw-file-upload',
    templateUrl: './file-upload.component.html',
    host: { 'class': 'file-uploader' },
})
export class FileUploadComponent implements OnInit, OnDestroy {

    public uploadAttachmentId: string;

    @Input()
    public UploadedOutputFiles: File[] = [];

    @Output()
    public UploadedFilesOutput: EventEmitter<File[]> = new EventEmitter<File[]>();

    private documentUploadConfiguration: DocumentUploadConfigurationViewDTO = null;
    private subcriptions: Subscription[] = [];

    constructor(public transloco: TranslocoService,
        private toastService: ToastService,
        private configurationService: ConfigurationHttpService,
        private toastApplicationService: ToastApplicationService) { }

    ngOnDestroy(): void {
        if (this.subcriptions) {
            this.subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
    }

    ngOnInit(): void {
        this.initialiseInputAttachmentFieldIds();

        const GetAllowedFileTypesSubscription = this.configurationService
            .GetAllowedFileTypesConfiguration()
            .subscribe((documentUploadConfig) => {
                this.documentUploadConfiguration = documentUploadConfig;
                this.documentUploadConfiguration.AllowedFileTypes = documentUploadConfig.AllowedFileTypes.map(aft => aft.toLowerCase());
            });

        this.subcriptions.push(GetAllowedFileTypesSubscription);
    }

    public uploadClicked() {

        const fileInput: HTMLInputElement = this.getFileElement(this.uploadAttachmentId);
        this.getUploadedAttachment(fileInput);
        if (fileInput.files.length === 0) {
            fileInput.click();
        } else {
            fileInput.dispatchEvent(new Event('change'));
        }
    }

    public getFileElement(id: string): HTMLInputElement {
        const fileElement: HTMLInputElement = document.getElementById(id) as HTMLInputElement;
        return fileElement;
    }

    private getUploadedAttachment(
        fileInput: HTMLInputElement
    ): void {
        fileInput.accept = this.documentUploadConfiguration.AllowedFileTypes.map((item) => { return '.' + item; }).toString();
        fileInput.onchange = (event: Event) => {
            const files: FileList = (event.target as HTMLInputElement).files;

            if (files.length === 0) {
                return;
            }

            if (files.length > 1) {
                const message: Message = {
                    summary: 'DocumentList.MoreThanOneFileSelected',
                    detail: 'DocumentList.MoreThanOneFileSelected',
                    severity: severity_warning,
                };

                this.toastApplicationService.showToast([message]);
            } else {
                const selectedFile = files.item(0);
                if (this.isSelectedFileValid(selectedFile)) {
                    this.UploadedOutputFiles.push(selectedFile);
                }
            }

            (document.getElementById(fileInput.id) as HTMLInputElement).value = '';
        };
    }

    private isSelectedFileValid(selectedFile: File): boolean {
        const fileExtension: string = FileHelper.getFileExtension(
            selectedFile.name
        );

        if (
            !this.documentUploadConfiguration.AllowedFileTypes.includes(fileExtension.toLowerCase())
        ) {
            const allowedFileTypes =
                this.documentUploadConfiguration.AllowedFileTypes.map((item) => {
                    return ' ' + item;
                }).toString();

            this.toastService.ShowToast(
                [
                    {
                        Message: 'DocumentList.SelectedFileTypeNotAllowed',
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,
                        MessageParameters: { allowedFileTypes: allowedFileTypes },
                    },
                ],
                StyleVariants.Warning
            );

            return false;
        }

        if (selectedFile.size === 0) {
            const message: Message = {
                summary: 'DocumentList.SelectedFileEmpty',
                detail: 'DocumentList.SelectedFileEmpty',
                severity: severity_warning,
            };

            this.toastApplicationService.showToast([message]);

            return false;
        }

        if (selectedFile.size > this.documentUploadConfiguration.MaximumFileSize) {
            const bytePipe = new BytePipe();
            const maxFileSize = bytePipe.transform(
                this.documentUploadConfiguration.MaximumFileSize
            );

            this.toastService.ShowToast(
                [
                    {
                        Message: 'DocumentList.MaximumAllowedFileSizeExceeded',
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,

                        MessageParameters: { maxFileSize: maxFileSize },
                    },
                ],
                StyleVariants.Warning
            );

            return false;
        }

        return true;
    }
    
    private initialiseInputAttachmentFieldIds() {
        this.uploadAttachmentId = `uploadAttachment_${GuidHelper.NewGuid()}`;
    }
}
