import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BearerTokenViewDTO } from '../../data-transfer-objects/authentication/bearer-token-view-dto';
import { LoginDTO } from '../../data-transfer-objects/login/login-dto';
import { BASE_URL } from '../../helpers/base-url';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationHttpService {

    constructor(private httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string) { }

    public logIn(loginDto: LoginDTO, rememberClient: boolean, languageId: string): Observable<BearerTokenViewDTO> {
        return this.httpClient.post<BearerTokenViewDTO>(`${this.baseUrl}api/authentication/authenticate?rememberClient=${rememberClient}&languageId=${languageId}`, loginDto);
    }

    public logOut(): Observable<null> {
        return this.httpClient.post<null>(`${this.baseUrl}api/authentication/logout`, null);
    }

    public refreshToken(): Observable<string> {
        return this.httpClient.post<string>(`${this.baseUrl}api/authentication/refreshToken`, null);
    }

    public sendOneTimePin(username: string): Observable<null> {
        return this.httpClient.post<null>(`${this.baseUrl}api/authentication/sendonetimepin?username=${username}`, null);
    }

    public saml2Authentication(idpName: string, returnUrl: string): Observable<string> {
        return this.httpClient.get<string>(`${this.baseUrl}api/authentication/saml2/authenticate?idpName=${idpName}&returnUrl=${returnUrl}`);
    }
}
