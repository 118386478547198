import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserRoleWidgetState } from '../../AppState';
import { UserRoleUpdateDTO } from '../../data-transfer-objects/role/user-role-update-dto';
import { FormEditStyle } from '../../enums/configuration/form-edit-style';

@Injectable({
    providedIn: 'root'
})

export abstract class AbstractUserRolesFacade {

    public abstract ReadyToLoadSubject: BehaviorSubject<boolean>;
    public abstract _userId: string;
    public abstract FormEditStyle: FormEditStyle;

    public abstract UserRolesSelector(): Observable<UserRoleWidgetState>;
    public abstract LoadRolesForUser(): void;
    public abstract ResetState(): void;
    public abstract UpdateUserRoles(roleUpdateDto: UserRoleUpdateDTO): void;


}
