import { Injectable, Type } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomDialogConfig } from '../../models/custom-dialog-config';

@Injectable({
    providedIn: 'root'
})
export class DialogApplicationService {

    constructor(private dialogService: DialogService) { }

    public showFormDialog<componentType, dataModelType, serviceType = void, configurationType = void>(component: Type<componentType>, dialogData: DialogOptions<dataModelType, configurationType>): DynamicDialogRef {

        const config: CustomDialogConfig<configurationType> = new CustomDialogConfig<configurationType>();

        if (dialogData) {
            config.data = dialogData.dataModel;
            config.header = dialogData.header;
            config.footer = dialogData.footer;
            config.closable = dialogData.closable;
            config.configuration = dialogData.configuration;
            config.showHeader = dialogData.showHeader;

            if (dialogData.styleClass) {
                config.styleClass = dialogData.styleClass;
            }
        }

        return this.dialogService.open(component, config);
    }
}

export interface DialogOptions<dataModelType, configurationType = void> {
    dataModel?: dataModelType;
    header: string;
    footer: string;
    closable: boolean;
    configuration?: configurationType;
    showHeader: boolean;
    styleClass?: string;
}

export class DialogComponentBase<dataModelType, configurationType = void> {

    protected dialogOptions: DialogOptions<dataModelType, configurationType>;

    constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {
        this.dialogOptions = {
            dataModel: this.config.data,
            closable: this.config.closable,
            showHeader: this.config.showHeader,
            header: this.config.header,
            footer: this.config.footer,
            styleClass: this.config.styleClass
        };

        if (config instanceof CustomDialogConfig) {
            const customDialogConfig = config as CustomDialogConfig<configurationType>;
            this.dialogOptions.configuration = customDialogConfig?.configuration;
        }
    }
}
