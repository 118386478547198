import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../AppState';
import { GoogleAnalyticsConfigurationViewDTO } from '../../data-transfer-objects/configuration/google-analytics-configuration-view-dto';
import { getGoogleAnalyticsConfigurationRequest, resetGoogleAnalyticsConfiguration } from '../../state/configuration/google-analytics/google-analytics.actions';
import { getGoogleAnalyticsConfiguration } from '../../state/configuration/google-analytics/google-analytics.selectors';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsConfigurationFacade {

    constructor(private store: Store<AppState>) {
    }

    public getGoogleAnalyticsConfiguration(): Observable<GoogleAnalyticsConfigurationViewDTO> {
        return this.store.pipe(select(getGoogleAnalyticsConfiguration));
    }

    public loadGoogleAnalyticsConfiguration(): void {
        this.store.dispatch(getGoogleAnalyticsConfigurationRequest());
    }

    public ResetState(): void {
        this.store.dispatch(resetGoogleAnalyticsConfiguration());
    }
}
