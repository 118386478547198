/* eslint-disable */ 

export function isNumberFinite(value: any): value is number {

    return typeof value === 'number' && isFinite(value);
}

// Not strict positive
export function isPositive(value: number): boolean {

    return value >= 0;
}

export function isInteger(value: number): boolean {

    // No rest, is an integer
    return (value % 1) === 0;
}

export function isNil(value: any): value is (null | undefined) {
    return value === null || typeof (value) === 'undefined';
}

export function toDecimal(value: number, decimal: number): number {

    return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function upperFirst(value: string): string {

    return value.slice(0, 1).toUpperCase() + value.slice(1);
}

export function createRound(method: string): Function {

    // <any>Math to suppress error
    const func: any = (<any>Math)[method];
    return function (value: number, precision: number = 0) {

        if (typeof value === 'string') {
            throw new TypeError('Rounding method needs a number');
        }

        if (typeof precision !== 'number' || isNaN(precision)) {
            precision = 0;
        }

        if (precision) {

            let pair = `${value}e`.split('e');
            const val = func(`${pair[0]}e` + (+pair[1] + precision));

            pair = `${val}e`.split('e');
            return +(pair[0] + 'e' + (+pair[1] - precision));
        }

        return func(value);
    };
}

export function sum(input: Array<number>, initial = 0): number {

    return input.reduce((previous: number, current: number) => previous + current, initial);
}

export function count(input: any): any {

    if (!Array.isArray(input) && !(input !== null && typeof input === 'object') && !(typeof input === 'string')) {
        return input;
    }

    if (input !== null && typeof input === 'object') {
        return Object.keys(input).map((value) => input[value]).length;
    }

    return input.length;
}