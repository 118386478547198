<div class="map-feedback">
  <div class="validation-spinner" *ngIf="ExecutingShapeValidation">
    <p-progressSpinner></p-progressSpinner>
    <span>{{ "Widget.Map.ShapeValidationRunning" | transloco }}</span>
  </div>
  <fw-message
    *ngIf="!ExecutingShapeValidation && ShapeValidationSeverity"
    [Severity]="ShapeValidationSeverity"
    [Message]="ShapeValidationMessage"
    [MessageLink]="ShapeValidationMessageLink"
    (ViewDetails)="ShapeValidationResultsVisibility($event)"
  ></fw-message>
</div>
<div class="map-widget" (mouseenter)="RemoveOverlapHighlight()">
  <div class="map-container">
    <div class="map-thumbnail trimble-map">
      <span #mapContent></span>
      <span #maploader></span>
    </div>
  </div>
  <div *ngIf="_mapFacade._isEditable" class="map-coords">
    <div
      class="mb-20"
      *ngIf="
        ShowCoordinateSystem ||
        ShowCalculatedArea ||
        ShowOfficialArea ||
        ShowOfficialLength
      "
    >
      <ng-template [ngIf]="ShowCoordinateSystem">
        <div class="map-details">
          <label
            >{{ "Map.MapDetailsCoordinateSystemLabel" | transloco }}:
          </label>
          <span>{{ CoordinateSystem }}</span>
        </div>
      </ng-template>
      <ng-template [ngIf]="ShowCalculatedArea">
        <div class="map-details">
          <label>{{ "Map.MapDetailsCalculatedAreaLabel" | transloco }}: </label>
          <span>{{ CalculatedArea }}</span>
        </div>
      </ng-template>
      <ng-template [ngIf]="ShowOfficialArea">
        <div class="map-details">
          <label>{{ "Map.MapDetailsOfficialAreaLabel" | transloco }}: </label>
          <span>{{ OfficialArea }}</span>
        </div>
      </ng-template>
      <ng-template [ngIf]="ShowOfficialLength">
        <div class="map-details">
          <label>{{ "Map.MapDetailsOfficialLengthLabel" | transloco }}: </label>
          <span>{{ OfficialLength }}</span>
        </div>
      </ng-template>
    </div>
    <ng-template [ngIf]="ShowCoordinatesList">
      <div #coordinates class="coord-list">
        <p-table
          #dt
          [value]="CoordinatesListEntries"
          [columns]="CoordinatesListColumns"
          [scrollable]="true"
          scrollHeight="flex"
          styleClass="p-datatable-sm"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{ col.HeaderLabelResourceId | transloco }}
              </th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-CoordinatesListEntry
            let-columns="columns"
          >
            <tr>
              <td *ngFor="let col of columns" [ngSwitch]="col.PropertyName">
                <span
                  *ngIf="
                    CoordinatesListEntry.Index === 0 &&
                    col.PropertyName === 'Index'
                  "
                  class="part"
                >
                  {{ CoordinatesListEntry.PartName }}
                </span>
                <span *ngIf="CoordinatesListEntry.Index > 0">
                  <span>
                    <span>{{ CoordinatesListEntry[col.PropertyName] }}</span>
                    <span *ngIf="col.PropertyName === 'Index'">.</span>
                  </span>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
    <div *ngIf="ShapeValidationResultsVisible" class="validation-results" (mouseout)="RemoveOverlapHighlight()">
      <div class="validation-header">
        <span>{{ "Widget.Map.ShapeValidationResults" | transloco }}</span>
        <div class="validation-spinner" *ngIf="ExecutingShapeValidation">
          <p-progressSpinner></p-progressSpinner>
          <span
            *ngIf="ExecutingShapeValidation"
            style="font-weight: normal; font-size: 14px"
            >{{ "Widget.Map.ShapeValidationResultsUpdating" | transloco }}</span
          >
        </div>

        <button class="close" (click)="ShapeValidationResultsVisibility(false)">
          <span class="pi pi-times"></span>
        </button>
      </div>
      <div class="validation-content">
        <div *ngIf="GeneralRestrictions?.size > 0" class="errors">
          <ul>
            <li *ngFor="let restriction of GeneralRestrictions | keyvalue">
              <span class="pi pi-exclamation-circle"></span>
              <span>{{ restriction.value.Message }}</span>
            </li>
          </ul>
        </div>
        <div *ngIf="OverlapRestrictions?.size > 0" class="errors">
          <div class="error-header">
            <span class="pi pi-exclamation-circle"></span>
            <span>{{
              "Widget.Map.ShapeValidationRestrictionsResultCount"
                | transloco: { overlapCount: OverlapRestrictions.size }
            }}</span>
              <span *ngIf="ClipAllRestrictionOverlapsLinkVisibility()" class="clip-link"
                ><a (click)="ClipAllRestrictionOverlaps()">{{
                  "Widget.Map.ClipAll" | transloco
                }}</a></span
              >
          </div>
          <ul class="overlap-list">
            <li *ngFor="let restriction of OverlapRestrictions | keyvalue">
              <span><a (click)="HighlightAndZoomToOverlap(restriction.value)" (mouseover)="HighlightOverlap(restriction.value)" (mouseout)="RemoveOverlapHighlight()" style="color: inherit;">{{ restriction.value.Message }}</a></span>
              <div
                *ngIf="ClipRestrictionOverlapLinkVisibility(restriction.value)"
              >
                <span
                  ><a (click)="ClipRestrictionOverlap(restriction.value)">{{
                    "Widget.Map.Clip" | transloco
                  }}</a></span
                >
              </div>
            </li>
          </ul>
        </div>
        <div *ngIf="GeneralWarnings?.size > 0" class="warnings">
          <ul>
            <li *ngFor="let warning of GeneralWarnings | keyvalue">
              <span class="pi pi-exclamation-triangle"></span>
              <span>{{ warning.value.Message }}</span>
            </li>
          </ul>
        </div>
        <div *ngIf="OverlapWarnings?.size > 0" class="warnings">
          <div class="warning-header">
            <span class="pi pi-exclamation-triangle"></span>
            <span>{{
              "Widget.Map.ShapeValidationWarningsResultCount"
                | transloco: { overlapCount: OverlapWarnings.size }
            }}</span>
              <span *ngIf="ClipAllWarningOverlapsLinkVisibility()" class="clip-link"
                ><a (click)="ClipAllWarningOverlaps()">{{
                  "Widget.Map.ClipAll" | transloco
                }}</a></span
              >
          </div>
          <ul class="overlap-list">
            <li *ngFor="let warning of OverlapWarnings | keyvalue">
              <span><a (click)="HighlightAndZoomToOverlap(warning.value)" (mouseover)="HighlightOverlap(warning.value)" (mouseout)="RemoveOverlapHighlight()" style="color: inherit;">{{ warning.value.Message }}</a></span>
              <div *ngIf="ClipWarningOverlapLinkVisibility(warning.value)">
                <span
                  ><a (click)="ClipWarningOverlap(warning.value)">{{
                    "Widget.Map.Clip" | transloco
                  }}</a></span
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<p-button
  *ngIf="_mapFacade._isEditable && !_mapFacade._isReadOnly"
  [disabled]="!MapLoaded || ExecutingShapeValidation"
  styleClass="btn btn-secondary"
  type="button"
  label="{{ 'Map.EditCoordinates' | transloco }}"
  (onClick)="SwitchToEditMode()"
></p-button>
