export class GuidHelper {
    public static EmptyGuid(): string {
        return '00000000-0000-0000-0000-000000000000';
    }

    public static Equals(guidLeft: string, guidRight: string): boolean {

        if (guidLeft === null || guidLeft === undefined || guidLeft === '') {
            guidLeft = this.EmptyGuid();
        }

        if (guidRight === null || guidRight === undefined || guidRight === '') {
            guidRight = this.EmptyGuid();
        }

        return guidLeft.toUpperCase() === guidRight.toUpperCase();

    }

    public static NewGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public static ValidGuid(guid: string): boolean {

        const regExp: RegExp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i;

        return regExp.test(guid);

    }
}