import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SessionApplicationService } from '../services/application/session-application.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {

    constructor(private sessionApplicationService: SessionApplicationService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.sessionApplicationService.isAuthenticated) {
            this.router.navigate([this.router.url]);
            return false;
        }
        else {
            return true;
        }
    }
}
