/* eslint-disable */

import {
    Component,
    ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, SimpleChanges, ViewChild, ViewContainerRef
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BootstrapHelper } from 'src/app/helpers/bootstrap-helper';
import { ModalSubmitHandler } from 'src/app/models/modal-submit-handler';
import { ModalSize } from '../../../../enums/modal-size-enum';
import { GuidHelper } from '../../../../helpers/guid-helper';
import { BaseFormComponent } from '../../classes/base-form-component';
import { PortalButtonComponent } from '../../generics/components/portal-button/portal-button.component';
import { PortalLinkComponent } from '../../generics/components/portal-link/portal-link.component';
import { PortalLoadingComponent } from '../../generics/components/portal-loading/portal-loading.component';
import { ICloseable } from '../../interfaces/i-closeable';

declare let $: any;

@Component({
  selector: 'div[form-modal-container]',
  templateUrl: './modal-container.component.html',
  host: {
    tabindex: '-1',
    role: 'dialog',
    'aria-hidden': 'true',
    class: 'modal fade',
  },
})
export class ModalContainerComponent implements ICloseable {
  private _selfComponentRef: ComponentRef<ModalContainerComponent>;
  public _modalSize: string;
  public _modalTitle: string = '';
  public _showClose: boolean = true;
  public _isPreloader: boolean = false;

  public SetPreloaderClass(): void {
    const modalElement: HTMLElement = this._modalContainer
      .nativeElement as HTMLElement;

    const className = ' modal-preloader';

    modalElement.className += className;
  }

  @ViewChild('modalBody', {
    read: ViewContainerRef,
    static: true,
  })
  private _modalBody: ViewContainerRef;

  @ViewChild('modalFooter', {
    read: ViewContainerRef,
    static: true,
  })
  private _modalFooter: ViewContainerRef;

  private _buttonFactory: ComponentFactory<PortalButtonComponent>;
  private _linkFactory: ComponentFactory<PortalLinkComponent>;

  constructor(
    private _factoryResolver: ComponentFactoryResolver,
    private _modalContainer: ElementRef
  ) {
    (this._modalContainer.nativeElement as HTMLElement).id =
      GuidHelper.NewGuid();

    this._buttonFactory = this._factoryResolver.resolveComponentFactory(
      PortalButtonComponent
    );
    this._linkFactory =
      this._factoryResolver.resolveComponentFactory(PortalLinkComponent);
  }

  private InitializeModal(
    modalTitle: string,
    closeObservable: Observable<null>,
    modalSize: ModalSize
  ): void {
    switch (modalSize) {
      case ModalSize.Small:
        this._modalSize = 'modal-sm';
        break;
      case ModalSize.Medium:
        this._modalSize = 'modal';
        break;
      case ModalSize.Large:
        this._modalSize = 'modal-lg';
        break;
      case ModalSize.XLarge:
        this._modalSize = 'modal-xl';
        break;
    }

    this._modalTitle = modalTitle;

    let close: boolean = false;

    const tempSubscription: Subscription = closeObservable.subscribe(() => {
      close = true;
    });

    BootstrapHelper.ShowModal(
      this._modalContainer.nativeElement,
      $,
      this
    ).subscribe(() => {
      tempSubscription.unsubscribe();

      if (closeObservable) {
        closeObservable.subscribe(() => {
          BootstrapHelper.HideModal(this._modalContainer.nativeElement, $);
        });
      }

      if (close) {
        BootstrapHelper.HideModal(this._modalContainer.nativeElement, $);
      }
    });
  }

  public InitializeFormModal<
    TModel,
    TFormComponent extends BaseFormComponent<TModel>
  >(
    factory: ComponentFactory<TFormComponent>,
    selfRef: ComponentRef<ModalContainerComponent>,
    modalTitle: string,
    okDelegate: (createdModel: TModel) => any,
    model: TModel,
    closeObservable: Observable<null>,
    submitHandlers: ModalSubmitHandler[],
    configurationDelegate: (form: TFormComponent) => void,
    modalSize: ModalSize
  ): void {
    this._selfComponentRef = selfRef;

    const formComponentRef: ComponentRef<TFormComponent> =
      this._modalBody.createComponent(factory);

    formComponentRef.instance.Submit.subscribe((model: TModel) => {
      okDelegate(model);
    });

    formComponentRef.instance.SubmitVisible = false;

    formComponentRef.instance.Model = model;

    if (configurationDelegate) {
      configurationDelegate(formComponentRef.instance);
    }

    submitHandlers.forEach((submitHandler: ModalSubmitHandler) => {
      if (!submitHandler.DisplayAsLink) {
        const buttonRef: ComponentRef<PortalButtonComponent> =
          this._modalFooter.createComponent(this._buttonFactory);

        (buttonRef.location.nativeElement as HTMLElement).onclick = () => {
          if (!buttonRef.instance._disabled) {
            submitHandler.FormSubmitFn(formComponentRef.instance);
          }
        };

        (
          buttonRef.location.nativeElement as HTMLElement
        ).firstChild.textContent = submitHandler.HandlerText;

        if (submitHandler.BindToFormValidity)
          formComponentRef.instance.Form.statusChanges.subscribe(
            (changes: SimpleChanges) => {
              buttonRef.instance._disabled =
                !formComponentRef.instance.Form.valid ||
                formComponentRef.instance._disabled;
            }
          );
      } else {
        const linkRef: ComponentRef<PortalLinkComponent> =
          this._modalFooter.createComponent(this._linkFactory);
        (linkRef.location.nativeElement as HTMLElement).onclick = () => {
          submitHandler.FormSubmitFn(formComponentRef.instance);
        };
        (linkRef.location.nativeElement as HTMLElement).innerHTML =
          submitHandler.HandlerText;
      }
    });

    this.InitializeModal(modalTitle, closeObservable, modalSize);
  }

  public InitializePreloaderModal(
    factory: ComponentFactory<PortalLoadingComponent>,
    selfRef: ComponentRef<ModalContainerComponent>,
    modalTitle: string,
    closeObservable: Observable<null>,
    modalSize: ModalSize
  ): void {
    this._selfComponentRef = selfRef;

    this.SetPreloaderClass();

    const portalLoadingComponentRef: ComponentRef<PortalLoadingComponent> =
      this._modalBody.createComponent(factory);
    portalLoadingComponentRef.instance.IsSmall = false;
    portalLoadingComponentRef.instance.HideText = true;
    this.InitializeModal(modalTitle, closeObservable, modalSize);
  }

  public onClose(): void {
    this._selfComponentRef.destroy();
  }
}
