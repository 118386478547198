import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Buffer } from 'buffer';
import { Message } from 'primeng/api';
import { Observable, Subscription, timer } from 'rxjs';
import { LandingPageBaseRoute } from '../../consts/portal-base-routes';
import {
    currentUserHasRepresentativesSessionKey,
    currentUserSessionKey,
    isAuthenticatedSessionKey
} from '../../consts/session-storage-keys';
import { severity_error, severity_success } from '../../consts/severity-options';
import { LoginDTO } from '../../data-transfer-objects/login/login-dto';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { SessionViewDTO } from '../../data-transfer-objects/session/session-view-dto';
import { UserViewDTO } from '../../data-transfer-objects/user/user-view-dto';
import { CurrentUserRepresentativeUpdateDTO } from '../../data-transfer-objects/users/current-user-representative-update-dto';
import { GuidHelper } from '../../helpers/guid-helper';
import { ClientUserModel } from '../../models/client-user-model';
import { PaginationResultModel } from '../../models/pagination-models';
import { SwitchRepresentationComponent } from '../../modules/static/forms/switch-representation/switch-representation.component';
import { AuthenticationHttpService } from '../http/authentication-http.service';
import { RepresentativesHttpService } from '../http/representatives-http.service';
import { RootHttpService } from '../http/root-http.service';
import { UserHttpService } from '../http/user-http.service';
import {
    DialogApplicationService,
    DialogOptions
} from './dialog-application.service';
import { ToastApplicationService } from './toast-application.service';

@Injectable({
    providedIn: 'root',
})
export class SessionApplicationService {
    public set authenticationToken(token: string) {
        localStorage.setItem(this.applicationId, token);
    }

    public get authenticationToken(): string {
        return localStorage.getItem(this.applicationId);
    }

    public set isAuthenticated(value: boolean) {
        this._isAuthenticated = value;

        localStorage.setItem(isAuthenticatedSessionKey, JSON.stringify(value));
    }

    public get isAuthenticated(): boolean {
        const value = JSON.parse(localStorage.getItem(isAuthenticatedSessionKey));

        if (this._isAuthenticated == null) {
            this._isAuthenticated = value;
        } else {
            //if local storage indicates we're not authenticated, but we think we're authenticated then assume
            //we've been logged out somewhere else (a different tab) and force a logout
            //similarly if local storage says we're actually authenticated then redirect to the landing page and refresh the menus

            if (value != this._isAuthenticated) {
                if (!value) {
                    this.logout();
                } else {
                    this.authenticationChanged.emit(value);
                    this.router.navigate([LandingPageBaseRoute]);
                }

                this._isAuthenticated = value;
            }
        }

        return value;
    }

    public set currentUser(user: UserViewDTO) {
        localStorage.setItem(currentUserSessionKey, JSON.stringify(user));
    }

    public get currentUser(): UserViewDTO {
        return JSON.parse(localStorage.getItem(currentUserSessionKey));
    }

    public set currentUserHasRepresentatives(value: boolean) {
        localStorage.setItem(
            currentUserHasRepresentativesSessionKey,
            JSON.stringify(value)
        );
    }

    public get currentUserHasRepresentatives(): boolean {
        return JSON.parse(
            localStorage.getItem(currentUserHasRepresentativesSessionKey)
        );
    }

    public authenticationChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    public oneTimePinRequired: EventEmitter<null> = new EventEmitter();
    public authenticationFailed: EventEmitter<null> = new EventEmitter();
    public applicationId: string = 'LandfolioFramework';

    private tokenExpirySubscription: Subscription;
    private _isAuthenticated?: boolean;

    constructor(
        private rootHttpService: RootHttpService,
        private authenticationHttpService: AuthenticationHttpService,
        private userHttpService: UserHttpService,
        private dialogApplicationService: DialogApplicationService,
        private toastApplicationService: ToastApplicationService,
        private translocoService: TranslocoService,
        private representativesService: RepresentativesHttpService,
        private router: Router
    ) { }

    public GetSessionInformation(): SessionViewDTO {
        return {
            RepresenteeName: this.currentUser.RepresenteeName,
            UserEmail: this.currentUser.Email,
            UserFirstName: this.currentUser.Firstname,
            UserFullName: this.currentUser.Fullname,
            UserPhoneNumber: this.currentUser.PhoneNumber,
            UserSurname: this.currentUser.Surname
        }
    }

    public RefreshToken() {
        this.userHttpService.RefreshToken().subscribe((token: string) => {
            this.authenticationToken = token;
            this.reloadLoggedInUser();
        });
    }

    public getCurrentClientUser(): Observable<ClientUserModel> {
        return this.userHttpService.GetCurrentUser();
    }

    public initalizeAutoLogoutMechanism(): void {
        this.userHttpService
            .GetExpiryInMinutes()
            .subscribe((expiryInMinutes: number) => {
                if (this.tokenExpirySubscription) {
                    this.tokenExpirySubscription.unsubscribe();
                }

                const source = timer(expiryInMinutes * 60000);

                this.tokenExpirySubscription = source.subscribe(() => {
                    if (this.tokenExpirySubscription)
                        this.tokenExpirySubscription.unsubscribe();

                    this.tokenExpirySubscription = undefined;

                    this.logout();
                });
            });
    }

    public logout(): void {
        if (this.tokenExpirySubscription) {
            this.tokenExpirySubscription.unsubscribe();
        }

        this.tokenExpirySubscription = undefined;

        this.authenticationHttpService.logOut().subscribe(() => {
            this.isAuthenticated = false;

            localStorage.removeItem(currentUserSessionKey);

            this.currentUserHasRepresentatives = false;

            localStorage.removeItem(this.applicationId);

            this.authenticationChanged.emit(this.isAuthenticated);

            this.router.navigate([LandingPageBaseRoute]);
        });
    }

    public reloadLoggedInUser(): void {
        if (this.isAuthenticated) {
            this.userHttpService.getLoggedInUser().subscribe((user: UserViewDTO) => {
                this.currentUser = user;

                // appropriate location?
                this.authenticationChanged.emit(this.isAuthenticated);
            });
        }
    }

    public tryLoginWithToken(token: string): void {
        if (this.tokenValid(token)) {
            this.authenticationToken = token;
            this.isAuthenticated = true;
            this.initalizeAutoLogoutMechanism();
            this.getLoggedInUser();
        }
        else {
            this.authenticationFailed.emit();
        }
    }

    public tryLogin(loginDto: LoginDTO, rememberClient: boolean, languageId: string): void {

            if (this.tokenValid(this.authenticationToken)) {
                this.isAuthenticated = true;
                this.RefreshToken();
            } else {
                this.authenticationHttpService.logIn(loginDto, rememberClient, languageId).subscribe(
                    (bearerTokenViewDTO) => {
                        if (bearerTokenViewDTO.TwoFactorEnabled) {
                            this.oneTimePinRequired.emit();
                        } else {
                            if (this.tokenValid(bearerTokenViewDTO.Token)) {
                                this.authenticationToken = bearerTokenViewDTO.Token;
                                this.isAuthenticated = true;
                                this.initalizeAutoLogoutMechanism();
                            }

                            if (this.isAuthenticated) {
                                this.getLoggedInUser();
                            }
                        }
                    },
                    (error) => {
                        // temporary: we will implement an interceptor to handle all API errors
                        if (this.tokenExpirySubscription)
                            this.tokenExpirySubscription.unsubscribe();
                        this.tokenExpirySubscription = undefined;
                        this.authenticationFailed.emit();
                    }
                );
            }
    }

    public getLoggedInUser() {
        this.userHttpService
            .getLoggedInUser()
            .subscribe((user: UserViewDTO) => {

                if (!user) {
                    this.DisplayToast('SessionApplicationService.Login.LoginFailedDetail', 'SessionApplicationService.Login.LoginFailedUserDetailsNotPopulated', severity_error);
                    this.logout();
                    return;
                }

                if (!user.IsDefaultRepresentation) {

                    if (!user.PersonId || user.PersonId === GuidHelper.EmptyGuid()) {
                        this.DisplayToast('SessionApplicationService.Login.LoginFailedDetail', 'SessionApplicationService.Login.LoginFailedUserPersonNotPopulated', severity_error);
                        this.logout();
                        return;
                    }

                    this.representativesService
                        .GetRepresentatives(user.PersonId)
                        .subscribe((representatives: PaginationResultModel<RepresentativeOfListViewDTO>) => {
                            if (representatives.Models.length === 1) {
                                // select the only one by default
                                const currentUserRepresentativeUpdateDTO: CurrentUserRepresentativeUpdateDTO =
                                {
                                    RepresentativeId: representatives.Models[0].Id,
                                    IsDefaultRepresentation: user.IsDefaultRepresentation,
                                };

                                this.userHttpService
                                    .updateRepresentation(currentUserRepresentativeUpdateDTO).subscribe(() => {
                                        this.RefreshToken();
                                    });

                                this.currentUserHasRepresentatives = true;

                            } else if (representatives.Models.length > 1) {
                                // show rep switch modal
                                const dialogOptions: DialogOptions<RepresentativeOfListViewDTO[]> = {
                                    closable: false,
                                    showHeader: true,
                                    footer: '',
                                    header: this.translocoService.translate(
                                        'SwitchRepresentation.Form.Title'
                                    ),
                                    dataModel: representatives.Models,
                                    styleClass: 'nested-footer dialog-md',
                                };

                                const dialogRef =
                                    this.dialogApplicationService.showFormDialog(
                                        SwitchRepresentationComponent,
                                        dialogOptions
                                    );

                                dialogRef.onClose.subscribe((currentUserRepresentativeUpdateDTO: CurrentUserRepresentativeUpdateDTO) => {
                                    if (currentUserRepresentativeUpdateDTO) {
                                        this.userHttpService
                                            .updateRepresentation(
                                                currentUserRepresentativeUpdateDTO
                                            )
                                            .subscribe(() => {
                                                this.RefreshToken();
                                            });
                                    } else {
                                        this.logout();
                                    }
                                });

                                this.currentUserHasRepresentatives = true;
                            } else {
                                this.currentUserHasRepresentatives = false;
                                this.RefreshToken();
                            }
                        });
                } else {
                    this.currentUserHasRepresentatives = true;
                    this.RefreshToken();
                }

                this.DisplayToast('SessionApplicationService.Login.LoginSuccessfulSummary', 'SessionApplicationService.Login.LoginSuccessfulDetail', severity_success);
            });
    }

    public sendOneTimePin(username: string): void {
        this.authenticationHttpService.sendOneTimePin(username).subscribe();
    }

    public saml2Authentication(idpName: string, returnUrl: string): Observable<string> {
        return this.authenticationHttpService.saml2Authentication(idpName, returnUrl);
    }

    private DisplayToast(summary: string, detail: string, severity: string): void {

        const message: Message = {
            summary: summary,
            detail: detail,
            severity: severity,
        };

        this.toastApplicationService.showToast([message]);
    }

    private tokenValid(token: string): boolean {
        let valid: boolean = false;

        if (token) {
            const expiryUnixTimeInSeconds: number = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('ascii')).exp;
            const currentUnixTimeInMilliseconds: number = new Date().getTime();
            const currentUnixTimeInSeconds: number = currentUnixTimeInMilliseconds / 1000;
            valid = currentUnixTimeInSeconds <= expiryUnixTimeInSeconds;
        }

        return valid;
    }
}
