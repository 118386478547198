import { createReducer, on } from '@ngrx/store';
import { DiscussionParticipantsWidgetState } from '../../../AppState';
import { loadDiscussionParticipantsRequestDone, loadDiscussionParticipantsRequestError, resetDiscussionParticipants } from './discussion-participants.actions';

export const initialDiscussionParticipantsState: DiscussionParticipantsWidgetState = { DiscussionParticipants: null, GetDiscussionParticipantsError: null };
export const DiscussionParticipantsReducer = createReducer<DiscussionParticipantsWidgetState>(initialDiscussionParticipantsState,
    on(loadDiscussionParticipantsRequestDone, (state, action) => action.discussionParticipantsWidgetState),
    on(loadDiscussionParticipantsRequestError, (state, action) => action.discussionParticipantsWidgetState),

    on(resetDiscussionParticipants, () => initialDiscussionParticipantsState)
);
