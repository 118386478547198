import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionApplicationService } from '../services/application/session-application.service';

export const Username: string = 'Username';
export const Token: string = 'Token';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private sessionApplicationService: SessionApplicationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.sessionApplicationService.isAuthenticated) {
            return true;
        } else {
            return false;
        }
    }
}
