import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DiscussionCommentsWidgetState } from '../../AppState';
import { DiscussionCommentCreateDTO } from '../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';

export abstract class AbstractDiscussionCommentsListFacade {

    public abstract _setLoadSubject: BehaviorSubject<boolean>;
    public abstract _addCommentToUi: Subject<DiscussionCommentCreateDTO>;
    public abstract _discussionId: string;
    public abstract _entityId: string;
    public abstract _pageId: string;

    public abstract LoadDiscussionCommentsSelector(): Observable<DiscussionCommentsWidgetState>;
    public abstract LoadDiscussionComments(): void;
    public abstract ResetState(): void;
}
