import { Component } from '@angular/core';
import { ControlBaseWrapper } from '../control-wrapper-base';

@Component({
  selector: 'fw-text-area-wrapper',
  templateUrl: './text-area-wrapper.component.html',
})
export class TextAreaWrapperComponent extends ControlBaseWrapper {
  constructor() {
    super();
  }
}
