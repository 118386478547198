import { Injectable } from '@angular/core';
import { ShapeUpdateDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-update-dto';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { AssetDocumentDraftHttpService } from 'src/app/services/http/asset-document-draft-http.service';
import {
    DocumentWidgetState,
    ShapeWidgetState
} from '../../../AppState';
import { DocumentCreateDto } from '../../../data-transfer-objects/document/document-create-dto';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentReplaceDto } from '../../../data-transfer-objects/document/document-replace-dto';
import { EntityShapeQueryDefinitionConfigurationDTO } from '../../../data-transfer-objects/shape-draft/entity-shape-query-definition-configuration-dto';
import { ShapeDraftUpdateDTO } from '../../../data-transfer-objects/shape-draft/shape-draft-dto';
import { ShapeType } from '../../../enums/shape-type';
import { AssetHttpService } from '../../../services/http/asset-http.service';
import { ShapeDraftHttpService } from '../../../services/http/shape-draft-http.service';
import { UtilitiesJobHttpService } from '../../../services/http/utilities-job-http-service';
import { UtilitiesShapeHttpService } from '../../../services/http/utilities-shape-http-service';
import {
    addDocumentDraftDone,
    addDocumentDraftError, addDocumentDraftRequest, deleteJobDone,
    deleteJobError, deleteJobRequest, getDocumentDraftsDone,
    getDocumentDraftsError, getDocumentDraftsRequest, getShapeDraftDone,
    getShapeDraftError, getShapeDraftRequest, getShapeValidationResultsDone,
    getShapeValidationResultsError, getShapeValidationResultsRequest, runShapeValidationDone,
    runShapeValidationError, runShapeValidationRequest, updateDocumentDraftDone,
    updateDocumentDraftError, updateDocumentDraftRequest, updateShapeDraftDone,
    updateShapeDraftError, updateShapeDraftRequest
} from './create-asset-details-form.actions';

@Injectable()
export class AssetPageEffects {
    addDocumentDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addDocumentDraftRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentCreateDto: DocumentCreateDto = new DocumentCreateDto(
                    action.documentViewDTO.Id,
                    action.documentViewDTO.DocumentTypeId,
                    action.parentEntityId,
                    documentViewDTO.LastUpdatedDate,
                    action.documentBlob,
                    documentViewDTO.FileName,
                    action.dataSourceId
                );

                return this.documentDraftService
                    .addDocument(documentCreateDto, action.pageId, action.dataSourceId)
                    .pipe(
                        map(() => {
                            const documentList: DocumentListViewDTO[] = Object.assign(
                                [],
                                action.documentListViewDTO
                            );

                            const documentDraftWdigetState: DocumentWidgetState = {
                                AddDocumentsError: null,
                                DocumentDrafts: documentList,
                                GetDocumentsError: null,
                                UpdateDocumentsError: null,
                                DocumentAdded: true,
                                DocumentReplaced: false,
                            };

                            return addDocumentDraftDone({
                                documentDraftWidgetState: documentDraftWdigetState,
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                            });
                        }),
                        catchError((err) => {
                            return of(
                                addDocumentDraftError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentDraftWidgetState: {
                                        AddDocumentsError: err,
                                        DocumentDrafts: null,
                                        GetDocumentsError: null,
                                        UpdateDocumentsError: null,
                                        DocumentAdded: false,
                                        DocumentReplaced: false,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    deleteJob$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJobRequest),
            switchMap((action) => {
                return this.utilitiesJobService.deleteJob(action.jobId).pipe(
                    map(() => {
                        return deleteJobDone({
                            deleteJobState: {
                                DeleteJobError: null,
                            },
                        });
                    }),
                    catchError((err) => {
                        return of(
                            deleteJobError({
                                deleteJobState: {
                                    DeleteJobError: err,
                                },
                            })
                        );
                    })
                );
            })
        )
    );    

    getDocumentDrafts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDocumentDraftsRequest),
            mergeMap((action) => {
                return this.documentDraftService
                    .getDocuments(action.pageId, action.dataSourceId)
                    .pipe(
                        map((documentDrafts) => {
                            return getDocumentDraftsDone({
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                                documentDraftWidgetState: {
                                    DocumentDrafts: documentDrafts,
                                    GetDocumentsError: null,
                                    UpdateDocumentsError: null,
                                    AddDocumentsError: null,
                                    DocumentAdded: false,
                                    DocumentReplaced: false,
                                },
                            });
                        }),
                        catchError((err) => {
                            return of(
                                getDocumentDraftsError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentDraftWidgetState: {
                                        DocumentDrafts: null,
                                        GetDocumentsError: err,
                                        UpdateDocumentsError: null,
                                        AddDocumentsError: null,
                                        DocumentReplaced: false,
                                        DocumentAdded: false,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    getShapeDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getShapeDraftRequest),
            switchMap((action) => {

                const entityShapeQueryDefinitionConfigurationDTO: EntityShapeQueryDefinitionConfigurationDTO[] = [
                    {
                        Action: null,
                        ShapeType: ShapeType.Unspecified
                    }
                ];

                return this.shapeDraftService.getDraft(action.pageId, entityShapeQueryDefinitionConfigurationDTO).pipe(
                    map((shapeDraftViewDTO) => {
                        return getShapeDraftDone({
                            shapeDraftWidgetState: {
                                GetShapeError: null,
                                ListState: [{
                                    StateModel: shapeDraftViewDTO.NewShape,
                                    dataSourceId: ShapeType.Unspecified.toString()
                                }],
                                UpdateShapeError: null
                            },
                        });
                    }),
                    catchError((err) => {
                        return of(
                            getShapeDraftError({
                                shapeDraftWidgetState: {
                                    GetShapeError: err,
                                    ListState: null,
                                    UpdateShapeError: null
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    getShapeValidationResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getShapeValidationResultsRequest),
            switchMap((action) => {
                return this.utilitiesShapeService
                    .validateShapeRetrieveJobResult(action.jobId)
                    .pipe(
                        map((jobQueryResultViewDTO) => {
                            return getShapeValidationResultsDone({
                                getShapeValidationResultsState: {
                                    JobQueryResultViewDTO: jobQueryResultViewDTO,
                                    GetShapeValidationResultsError: null,
                                },
                            });
                        }),
                        catchError((err) => {
                            return of(
                                getShapeValidationResultsError({
                                    getShapeValidationResultsState: {
                                        JobQueryResultViewDTO: null,
                                        GetShapeValidationResultsError: err,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    runShapeValidation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(runShapeValidationRequest),
            switchMap((action) => {
                return this.utilitiesShapeService
                    .validateShapeScheduleJob(action.shapeValidationCreateDTO)
                    .pipe(
                        map((jobSchedulerViewDTO) => {
                            return runShapeValidationDone({
                                runShapeValidationState: {
                                    JobSchedulerViewDTO: jobSchedulerViewDTO,
                                    RunShapeValidationError: null,
                                },
                            });
                        }),
                        catchError((err) => {
                            return of(
                                runShapeValidationError({
                                    runShapeValidationState: {
                                        JobSchedulerViewDTO: null,
                                        RunShapeValidationError: err,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );    

    updateDocumentDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDocumentDraftRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentReplaceDto: DocumentReplaceDto = new DocumentReplaceDto(
                    documentViewDTO.Id,
                    documentViewDTO.DocumentTypeId,
                    action.parentEntityId,
                    documentViewDTO.DateUploaded,
                    action.documentBlob,
                    documentViewDTO.FileName
                );

                return this.documentDraftService
                    .replaceDocument(documentReplaceDto)
                    .pipe(
                        map(() => {
                            const documentList: DocumentListViewDTO[] = Object.assign(
                                [],
                                action.documentListViewDTO
                            );

                            const documentDraftWdigetState: DocumentWidgetState = {
                                AddDocumentsError: null,
                                DocumentDrafts: documentList,
                                GetDocumentsError: null,
                                UpdateDocumentsError: null,
                                DocumentReplaced: true,
                                DocumentAdded: false,
                            };

                            return updateDocumentDraftDone({
                                documentDraftWidgetState: documentDraftWdigetState,
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                            });
                        }),
                        catchError((err) => {
                            return of(
                                updateDocumentDraftError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentDraftWidgetState: {
                                        AddDocumentsError: null,
                                        DocumentDrafts: null,
                                        GetDocumentsError: null,
                                        DocumentAdded: false,
                                        DocumentReplaced: false,
                                        UpdateDocumentsError: err,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    updateShapeDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateShapeDraftRequest),
            switchMap((action) => {
                let updateShapeDTO: ShapeUpdateDTO;
                // eslint-disable-next-line prefer-const
                updateShapeDTO = { ...updateShapeDTO, ...action.shapeViewDTO };

                const shapeUpdateWrapperDTO: ShapeDraftUpdateDTO = {
                    EntityDraftId: action.assetId,
                    ShapeType: ShapeType.Unspecified,
                    DraftBody: updateShapeDTO,
                };

                return this.shapeDraftService.updateDraft(action.pageId, shapeUpdateWrapperDTO, action.typeId).pipe(
                    map(() => {
                        const shapeWidgetState: ShapeWidgetState = {
                            GetShapeError: null,
                            ListState: [{
                                StateModel: action.shapeViewDTO,
                                dataSourceId: shapeUpdateWrapperDTO.ShapeType.toString()
                            }],
                            UpdateShapeError: null
                        };
                        return updateShapeDraftDone({
                            shapeDraftWidgetState: shapeWidgetState,
                        });
                    }),
                    catchError((err) => {
                        return of(
                            updateShapeDraftError({
                                shapeDraftWidgetState: {
                                    GetShapeError: null,
                                    ListState: null,
                                    UpdateShapeError: err
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        public assetDraftService: AssetHttpService,
        public shapeDraftService: ShapeDraftHttpService,
        public utilitiesShapeService: UtilitiesShapeHttpService,
        public utilitiesJobService: UtilitiesJobHttpService,
        public documentDraftService: AssetDocumentDraftHttpService
    ) { }    
}
