import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { PowerBIHttpService } from '../../services/http/power-bi-http.service';
import { getEmbedTokenDone, getEmbedTokenError, getEmbedTokenRequest } from './power-bi.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class PowerBIEffects {

    getEmbedAccessToken$ = createEffect(() => this.actions$.pipe(
        ofType(getEmbedTokenRequest),
        mergeMap((action) => {

            return this.powerBIHttpService.GetEmbedAccessToken(action.embedConfiguration).pipe(
                map(token => getEmbedTokenDone({ state: { EmbedAccessToken: token, GetError: null } })),
                catchError((err) => {
                    return of(getEmbedTokenError({ state: { GetError: err, EmbedAccessToken: null } }))
                })
            );

        })
    ));

    constructor(
        private actions$: Actions,
        private powerBIHttpService: PowerBIHttpService
    ) { }
}