import { createReducer, on } from '@ngrx/store';
import { PowerBIWidgetState } from '../../AppState';
import { getEmbedTokenDone, getEmbedTokenError, reset } from './power-bi.actions';

export const initialState: PowerBIWidgetState = { EmbedAccessToken: null, GetError: null };

export const PowerBIReducer = createReducer<PowerBIWidgetState>(initialState,
    on(getEmbedTokenDone, (state, action) => action.state),
    on(getEmbedTokenError, (state, action) => action.state),
    on(reset, () => initialState));
