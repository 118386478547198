import { Component, ViewChild } from '@angular/core';
import { PaginatorPageChange } from '@landadmin/structured-data/lib/models/structured-data-pagination-override';
import { TranslocoService } from '@ngneat/transloco';
import { Paginator } from 'primeng/paginator';
import { Subject } from 'rxjs';
import { TranslationHelper } from 'src/app/helpers/translation-helper';
import { UIStylingHelper } from 'src/app/helpers/ui-styling-helper';

@Component({
    selector: 'fw-paginator',
    templateUrl: './paginator.component.html',
    host: { 'class': 'form-field' }
})
export class PaginatorComponent {

    public paginationSubject: Subject<PaginatorPageChange> = new Subject<PaginatorPageChange>();
    public rows: number;
    public totalRecords: number;
    public currentPageReportTemplate: string;

    @ViewChild('paginator', { static: true })
    public paginator: Paginator
        
    constructor(private translocoService: TranslocoService) {
        this.currentPageReportTemplate = this.translocoService.translate(TranslationHelper.CurrentPageReportTemplateResourceId);
    }

    public paginate(event) : void {

        const paginatorPageChange: PaginatorPageChange = {
            First: event.first,
            Limit: event.rows,
            Page: event.page + 1,
            TotalRecords: this.totalRecords
        }

        this.paginationSubject.next(paginatorPageChange);
    }

    public updateCurrentPage(currentPage: number): void {
        setTimeout(() => this.paginator.changePage(currentPage - 1));
    }

    public updateTotalRecords(totalRecords: number): void {
        this.totalRecords = totalRecords;
    }

    public getPaginatorStyleClass(): string {
        let paginatorStyleClass = '';

        if (this.totalRecords <= this.rows) {
            paginatorStyleClass += UIStylingHelper.hidePagingStyleClass;
        }

        return paginatorStyleClass;
    }
}
