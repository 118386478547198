<fw-content-block-widget *ngIf="widgetConfiguration.WidgetType === widgetType.ContentBlock"
                         [contentBlockWidgetConfiguration]="widgetConfiguration.HtmlWidget">
</fw-content-block-widget>

<fw-tile-widget *ngIf="widgetConfiguration.WidgetType === widgetType.Tile"
                [TileWidgetConfiguration]="widgetConfiguration.TileWidget">
</fw-tile-widget>

<fw-task-widget *ngIf="widgetConfiguration.WidgetType === widgetType.Task"
                [TaskWidgetConfiguration]="widgetConfiguration.TaskWidget">
</fw-task-widget>

<fw-asset-details-widget *ngIf="widgetConfiguration.WidgetType === widgetType.AssetDetails"
                         [AssetDetailsWidgetConfiguration]="widgetConfiguration.AssetDetailsWidget"
                         [FormEditStyle]="FormEditStyle">
</fw-asset-details-widget>

<fw-user-details-widget *ngIf="widgetConfiguration.WidgetType === widgetType.UserDetails"
                        [UserDetailsWidgetConfiguration]="widgetConfiguration.UserDetailsWidget"
                        [FormEditStyle]="FormEditStyle">
</fw-user-details-widget>

<fw-user-list-widget *ngIf="widgetConfiguration.WidgetType === widgetType.UserList"
                     [UserListWidgetConfigurationViewDTO]="widgetConfiguration.UserListWidget">
</fw-user-list-widget>

<fw-map-widget *ngIf="widgetConfiguration.WidgetType === widgetType.Map"
               [MapWidgetConfigurationViewDTO]="widgetConfiguration.MapWidget">
</fw-map-widget>

<fw-representative-list-widget *ngIf="widgetConfiguration.WidgetType === widgetType.RepresentativeList"
                               [RepresentativeListWidgetConfiguration]="widgetConfiguration.RepresentativeListWidget">
</fw-representative-list-widget>

<fw-document-list-widget *ngIf="widgetConfiguration.WidgetType === widgetType.DocumentList"
                         [DocumentListWidgetConfiguration]="widgetConfiguration.DocumentListWidget">
</fw-document-list-widget>

<fw-commodities-widget *ngIf="widgetConfiguration.WidgetType === widgetType.Commodity"
                       [CommoditiesWidgetConfigurationViewDTO]="widgetConfiguration.CommoditiesWidget">
</fw-commodities-widget>

<fw-asset-list-widget *ngIf="widgetConfiguration.WidgetType === widgetType.AssetList"
                      [AssetListWidgetConfigurationViewDTO]="widgetConfiguration.AssetListWidget">
</fw-asset-list-widget>

<fw-action-details-widget *ngIf="widgetConfiguration.WidgetType === widgetType.ActionDetails"
                          [ActionDetailsWidgetConfiguration]="widgetConfiguration.ActionDetailsWidget"
                          [FormEditStyle]="FormEditStyle">
</fw-action-details-widget>

<fw-action-list-widget *ngIf="widgetConfiguration.WidgetType === widgetType.ActionList"
                       [ActionListWidgetConfigurationViewDTO]="widgetConfiguration.ActionListWidget">
</fw-action-list-widget>

<fw-structured-data-widget *ngIf="widgetConfiguration.WidgetType === widgetType.StructuredDataList"
                           [StructuredDataListWidgetConfigurationViewDTO]="widgetConfiguration.StructuredDataListWidget">
</fw-structured-data-widget>

<fw-discussions-widget *ngIf="widgetConfiguration.WidgetType === widgetType.Discussions"
                       [DiscussionListWidgetConfigurationViewDTO]="widgetConfiguration.DiscussionListWidget">
</fw-discussions-widget>

<fw-related-asset-list-widget *ngIf="widgetConfiguration.WidgetType === widgetType.RelatedAssetList"
                              [RelatedAssetListWidgetConfigurationViewDTO]="widgetConfiguration.RelatedAssetListWidget">
</fw-related-asset-list-widget>

<fw-user-roles-widget *ngIf="widgetConfiguration.WidgetType === widgetType.UserRoles"
                      [FormEditStyle]="FormEditStyle"
                      [UserRolesWidgetConfigurationViewDTO]="widgetConfiguration.UserRolesWidget">
</fw-user-roles-widget>

<fw-session-details-widget *ngIf="widgetConfiguration.WidgetType === widgetType.SessionDetails"
                           [SessionDetailsWidgetConfiguration]="widgetConfiguration.SessionDetailsWidget">
</fw-session-details-widget>

<fw-party-list-widget *ngIf="widgetConfiguration.WidgetType === widgetType.PartyList"
                           [PartyListWidgetConfigurationViewDTO]="widgetConfiguration.PartyListWidget">
</fw-party-list-widget>
<fw-power-bi-widget *ngIf="widgetConfiguration.WidgetType === widgetType.PowerBI"
                         [PowerBIWidgetConfiguration]="widgetConfiguration.PowerBIWidget">
</fw-power-bi-widget>