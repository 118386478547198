/* eslint-disable */

import {
  Component,
  OnInit,
  OnChanges,
  forwardRef,
  Input,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationComponentBase } from '../../classes/validation-component-base';

@Component({
  selector: 'portal-text-field',
  templateUrl: './portal-text-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PortalTextFieldComponent),
      multi: true,
    },
  ],
  host: {
    class: 'form-field',
  },
})
export class PortalTextFieldComponent
  extends ValidationComponentBase
  implements OnInit, ControlValueAccessor, OnChanges
{
  @Input('value') public _value: string;
  @Input('email') public _email: boolean;

  constructor(elementReference: ElementRef) {
    super(elementReference);
  }

  ngOnInit() {
    this.SetDefaults();
  }

  onChange(): void {
    this.propagateChange(this._value);
  }

  onTouch(): void {
    this.propagateTouch();
  }

  propagateChange: any = () => {};
  propagateTouch: any = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    this.propagateChange(this._value);
  }
  writeValue(obj: any): void {
    this._value = obj;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }
}
