import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StructuredDataNodeModel } from '@landadmin/structured-data/lib/models/structured-data-node-model';
import { Observable } from 'rxjs';
import { BASE_URL } from '../../helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class StructuredDataNodeHttpService extends AuthenticatedHttpServiceBase {

    constructor(
        httpClient: HttpClient,
        @Inject(BASE_URL)
        private baseUrl: string,
        errorManager: ErrorManagerService,
        toastService: ToastService
    ) {
        super(httpClient, errorManager, toastService);
    }

    public GetStructuredDataNodeConfiguration(pageId: string, entityTypeId: string): Observable<StructuredDataNodeModel[]> {

        return this.GetAuthenticated<StructuredDataNodeModel[]>(`${this.baseUrl}api/configuration/StructuredDataNode/pageId/${pageId}/entityTypeId/${entityTypeId}`, this.GetStandardHeaders());
    }
}
