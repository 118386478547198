import { ShapeViewDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-view-dto';
import { createAction, props } from '@ngrx/store';
import { DeleteJobState, DocumentWidgetState, RunShapeValidationState, ShapeValidationResultsState, ShapeWidgetState } from '../../../AppState';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentViewDTO } from '../../../data-transfer-objects/document/document-view-dto';
import { ShapeValidationCreateDTO } from '../../../data-transfer-objects/shape/shape-validation-create-dto';

//Document drafts
export const updateActionFormDocumentDraftDone = createAction(
    '[ActionDetails] Update Document Draft Done',
    props<{
        documentWidgetState: DocumentWidgetState;
        dataSourceId: string;
        widgetId: string;
    }>()
);
export const updateActionFormDocumentDraftRequest = createAction(
    '[ActionDetails] Update Document Draft Request',
    props<{
        documentListViewDTO: DocumentListViewDTO[];
        documentViewDTO: DocumentViewDTO;
        parentEntityId: string;
        assetId: string;
        documentBlob: File;
        dataSourceId: string;
        widgetId: string;
    }>()
);
export const updateActionFormDocumentDraftError = createAction(
    '[ActionDetails] Update Document Draft Error',
    props<{
        documentWidgetState: DocumentWidgetState;
        dataSourceId: string;
        widgetId: string;
    }>()
);

export const addActionFormDocumentDraftDone = createAction(
    '[ActionDetails] Add Document Draft Done',
    props<{
        documentWidgetState: DocumentWidgetState;
        dataSourceId: string;
        widgetId: string;
    }>()
);
export const addActionFormDocumentDraftRequest = createAction(
    '[ActionDetails] Add Document Draft Request',
    props<{
        documentListViewDTO: DocumentListViewDTO[];
        documentViewDTO: DocumentViewDTO;
        parentEntityId: string;
        documentBlob: File;
        pageId: string;
        assetId: string;
        dataSourceId: string;
        widgetId: string;
    }>()
);
export const addActionFormDocumentDraftError = createAction(
    '[ActionDetails] Add Document Draft Error',
    props<{
        documentWidgetState: DocumentWidgetState;
        dataSourceId: string;
        widgetId: string;
    }>()
);

export const getActionFormDocumentDraftsRequest = createAction(
    '[ActionDetails] Get Document Drafts Request',
    props<{
        pageId: string;
        assetId: string;
        dataSourceId: string;
        widgetId: string;
    }>()
);
export const getActionFormDocumentDraftsDone = createAction(
    '[ActionDetails] Get Document Drafts Done',
    props<{
        documentWidgetState: DocumentWidgetState;
        dataSourceId: string;
        widgetId: string;
    }>()
);
export const getActionFormDocumentDraftsError = createAction(
    '[ActionDetails] Get Document Drafts Error',
    props<{
        documentWidgetState: DocumentWidgetState;
        dataSourceId: string;
        widgetId: string;
    }>()
);

export const createActionDocumentDraftResetState = createAction(
    '[ActionDetails] Document Draft Reset State'
);

//Shape Drafts
export const getShapeDraftRequest = createAction('[ActionDetails] Get Shape Draft Request', props<{ pageId: string, assetId: string }>());
export const getShapeDraftDone = createAction('[ActionDetails] Get Shape Draft Done', props<{ shapeDraftWidgetState: ShapeWidgetState }>());
export const getShapeDraftError = createAction('[ActionDetails] Get Shape Draft Error', props<{ shapeDraftWidgetState: ShapeWidgetState }>());

export const updateShapeDraftRequest = createAction('[ActionDetails] Update Shape Draft Request', props<{ pageId: string, shapeViewDTO: ShapeViewDTO, actionDraftId: string, typeId: string }>());
export const updateShapeDraftDone = createAction('[ActionDetails] Update Shape Draft Done', props<{ shapeDraftWidgetState: ShapeWidgetState }>());
export const updateShapeDraftError = createAction('[ActionDetails] Update Shape Draft Error', props<{ shapeDraftWidgetState: ShapeWidgetState }>());

export const shapeDraftResetState = createAction('[AssetDetails] Shape Draft Reset State');

//Shape Validation
export const runShapeValidationRequest = createAction('[ActionDetails] Run Shape Validation Request', props<{ shapeValidationCreateDTO: ShapeValidationCreateDTO }>());
export const runShapeValidationDone = createAction('[ActionDetails] Run Shape Validation Done', props<{ runShapeValidationState: RunShapeValidationState }>());
export const runShapeValidationError = createAction('[ActionDetails] Run Shape Validation Error', props<{ runShapeValidationState: RunShapeValidationState }>());
export const shapeValidationResetState = createAction('[ActionDetails] Shape Validation Reset State');

export const getShapeValidationResultsRequest = createAction('[ActionDetails] Get Shape Validation Results Request', props<{ jobId: number }>());
export const getShapeValidationResultsDone = createAction('[ActionDetails] Get Shape Validation Results Done', props<{ getShapeValidationResultsState: ShapeValidationResultsState }>());
export const getShapeValidationResultsError = createAction('[ActionDetails] Get Shape Validation Results Error', props<{ getShapeValidationResultsState: ShapeValidationResultsState }>());
export const shapeValidationResultsResetState = createAction('[ActionDetails] Shape Validation Results Reset State');

//Job Deletion
export const deleteJobRequest = createAction('[ActionDetails] Delete Job Request', props<{ jobId: number }>());
export const deleteJobDone = createAction('[ActionDetails] Delete Job Done', props<{ deleteJobState: DeleteJobState }>());
export const deleteJobError = createAction('[ActionDetails] Delete Job  Error', props<{ deleteJobState: DeleteJobState }>());
