<div *ngIf="TaskWidgetConfiguration.Tasks.length === 0;then hideTask else showTask"></div>
<ng-template #showTask>
    <div *ngIf="TaskWidgetConfiguration.Tasks.length === 1;then single else multiple"></div>
    <ng-template #single>
        <p-button [ngClass]="getButtonStyle()" [disabled]="!IsTaskValid(TaskWidgetConfiguration.Tasks[0])" styleClass="btn btn-lg" type="button" label="{{TaskWidgetConfiguration.Tasks[0].LabelResourceId | transloco}}" (onClick)="executeBehaviours(TaskWidgetConfiguration.Tasks[0])"></p-button>
    </ng-template>
    <ng-template #multiple>
        <div class="task-dropdown" *ngIf="getTaskListStyle() === 1">
            <a [ngClass]="getButtonStyle()" data-display="static" class="btn btn-lg" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{TaskWidgetConfiguration.TaskListResourceId | transloco}}<span class="pi pi-chevron-down"></span></a>
            <ul class="dropdown-menu">
                <li *ngFor="let task of TaskWidgetConfiguration.Tasks">
                    <a [ngClass]="{'disabled': !IsTaskValid(task)}" (click)="executeBehaviours(task)">{{task.LabelResourceId | transloco}}</a>
                </li>
            </ul>
        </div>
        <div class="task-row" *ngIf="getTaskListStyle() === 2">
            <p-button *ngFor="let task of TaskWidgetConfiguration.Tasks" [ngClass]="getTaskListButtonStyle(task)" [disabled]="!IsTaskValid(task)" styleClass="btn btn-lg" type="button" label="{{task.LabelResourceId | transloco}}" (onClick)="executeBehaviours(task)"></p-button>
        </div>
    </ng-template>
</ng-template>
<ng-template #hideTask>
</ng-template>
