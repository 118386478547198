const Valid_dd_Values: string[] = ['1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31'
]

const Valid_MMM_Values: string[] = ['Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

const Valid_mm_Values: string[] = ['1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09'
];

const Valid_Month_Names: string[] = ['January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const SupportedDayTypes: string[] = [
    'dd'
];

const SupportedMonthTypes: string[] = [
    'mm',
    'MMM',
    'Month'
];

const SupportedYearTypes: string[] = [
    'yy',
    'yyyy'
];

export class DateHelper {
    public static GetDate(validDateString: string, identifiers: string[], separator: string): Date {

        const dateSegments: string[] = validDateString.split(separator);

        let day: number;
        let monthIndex: number;
        let year: number;

        identifiers.forEach(function (identifier: string, index: number) {

            if (SupportedDayTypes.includes(identifier)) {

                switch (identifier) {
                    case 'dd':
                        day = Number(dateSegments[index]);
                        break;
                }

            };

            if (SupportedMonthTypes.includes(identifier)) {
                switch (identifier) {
                    case 'mm':
                        monthIndex = Number(dateSegments[index]) - 1;
                        break;
                    case 'MMM':
                        monthIndex = Valid_MMM_Values.indexOf(dateSegments[index]);
                        break;
                    case 'Month':
                        monthIndex = Valid_Month_Names.indexOf(dateSegments[index]);
                        break;
                }
            };

            if (SupportedYearTypes.includes(identifier)) {
                switch (identifier) {
                    case 'yy':
                        year = Number(dateSegments[index]) + 2000;
                        break;
                    case 'yyyy':
                        year = Number(dateSegments[index]);
                        break;
                }
            };



        });

        return new Date(year, monthIndex, day);
    }

    public static GetDateRegularExpression(identifiers: string[], separator: string): RegExp {

        let regExpString: string = '/';

        identifiers.forEach(function (identifier: string, index: number) {

            if (SupportedDayTypes.includes(identifier)) {

                switch (identifier) {
                    case 'dd':
                        Valid_dd_Values.forEach(function (value: string) {
                            regExpString += '[' + value + ']';
                        });
                        break;
                }

            };


            if (SupportedMonthTypes.includes(identifier)) {
                switch (identifier) {
                    case 'mm':
                        Valid_mm_Values.forEach(function (value: string) {
                            regExpString += '[' + value + ']';
                        });

                        break;
                    case 'MMM':
                        Valid_MMM_Values.forEach(function (value: string) {
                            regExpString += '[' + value + ']';
                        });

                        break;
                    case 'Month':
                        Valid_Month_Names.forEach(function (value: string) {
                            regExpString += '[' + value + ']';
                        });

                        break;
                }
            };

            if (SupportedYearTypes.includes(identifier)) {
                switch (identifier) {
                    case 'yy':
                        regExpString += '/d{4}';
                        break;
                    case 'yyyy':
                        regExpString += '([1][9]|[2-9][0-9])\d{2}';
                        break;
                }
            };


            regExpString += separator;


        });

        regExpString += '/';


        return new RegExp(regExpString);
    }

    public static GetDateString(date: Date, identifiers: string[], separator: string): string {

        if (date === null)
            return '';

        if (identifiers === null) {
            identifiers = new Array<string>();
            identifiers.push('dd');
            identifiers.push('MMM');
            identifiers.push('yyyy');
        }

        let dateString: string = '';

        identifiers.forEach(function (identifier: string, index: number) {

            if (SupportedDayTypes.includes(identifier)) {

                switch (identifier) {
                    case 'dd':
                        dateString += String(date.getDate());
                        break;
                }

            };

            if (SupportedMonthTypes.includes(identifier)) {
                switch (identifier) {
                    case 'mm':
                        dateString += String(date.getMonth());
                        break;
                    case 'MMM':
                        dateString += Valid_MMM_Values[date.getMonth()];
                        break;
                    case 'Month':
                        dateString += Valid_Month_Names[date.getMonth()];
                        break;
                }
            };

            if (SupportedYearTypes.includes(identifier)) {
                switch (identifier) {
                    case 'yy':
                        dateString += String(date.getFullYear()).substring(2, 4);
                        break;
                    case 'yyyy':
                        dateString += String(date.getFullYear());
                        break;
                }
            };

            dateString += separator;


        });

        dateString = dateString.substring(0, dateString.length - 1);

        return dateString;
    }

    public static GetMinDate(): string {
        return '0001-01-01T00:00:00'
    }

    public static InitialiseDate?(date?: Date): Date {
        if (date) {
            return new Date(date);
        }
        return null;
    }

    public static mapCalendarDateFormat(dateFormat: string): string {
        const day = this.getValueFromDate(dateFormat, 'd');
        const month = this.getValueFromDate(dateFormat, 'M');
        const year = this.getValueFromDate(dateFormat, 'y');
        const period = this.getValueFromDate(dateFormat, 'g');

        const calendarDateFormat = dateFormat
            .replace(day, this.mapCalendarControlDay(day))
            .replace(month, this.mapCalendarControlMonth(month))
            .replace(year, this.mapCalendarControlYear(year));

        return calendarDateFormat;
    }

    public static mapDatePipeDateFormat(dateFormat: string): string {
        let datePipeDateFormat: string = '';

        if (typeof dateFormat != 'undefined' && dateFormat) {
            const day = this.getValueFromDate(dateFormat, 'd');

            datePipeDateFormat = dateFormat
                .replace(day, this.mapDatePipeDay(day));
        }


        return datePipeDateFormat;
    }

    private static getValueFromDate(dateFormat: string, valueToFind: string): string {
        return dateFormat.substring(dateFormat.indexOf(valueToFind), dateFormat.lastIndexOf(valueToFind) + 1)
    }

    private static mapDatePipeDay(day: string): string {
        let mappedDay = '';

        switch (day) {
            case 'd':
                mappedDay = 'd';
                break;
            case 'dd':
                mappedDay = 'dd';
                break;
            case 'ddd':
                mappedDay = 'E';
                break;
            case 'dddd':
                mappedDay = 'EEEE';
                break;
            default:
                throw new Error(`Day format ${day} is not supported.`);
        }

        return mappedDay;
    }

    private static mapCalendarControlDay(day: string): string {
        let mappedDay = '';

        switch (day) {
            case 'd':
                mappedDay = 'd';
                break;
            case 'dd':
                mappedDay = 'dd';
                break;
            case 'ddd':
                mappedDay = 'D';
                break;
            case 'dddd':
                mappedDay = 'DD';
                break;
            default:
                throw new Error(`Day format ${day} is not supported.`);
        }

        return mappedDay;
    }

    private static mapCalendarControlMonth(month: string): string {
        let mappedMonth = '';

        switch (month) {
            case 'M':
                mappedMonth = 'm';
                break;
            case 'MM':
                mappedMonth = 'mm';
                break;
            case 'MMM':
                mappedMonth = 'M';
                break;
            case 'MMMM':
                mappedMonth = 'MM';
                break;
            default:
                throw new Error(`Month format ${month} is not supported.`);
        }

        return mappedMonth;
    }

    private static mapCalendarControlYear(year: string): string {
        let mappedYear = '';

        switch (year) {
            case 'y':
            case 'yy':
                mappedYear = 'y';
                break;
            case 'yyy':
            case 'yyyy':
                mappedYear = 'yy';
                break;
            default:
                throw new Error(`Year format ${year} is not supported.`);
        }
        return mappedYear;
    }
}
