<input type="file" name="{{uploadAttachmentId}}" id="{{uploadAttachmentId}}" />
<input type="file" name="{{replaceAttachmentId}}" id="{{replaceAttachmentId}}" />
<ng-template [ngIf]="readyToLoad">
    <div *ngIf="operationEnabled(documentOperation.Add) && !abstractDocumentFacade._isReadOnly && rowOptions?.formEditStyle === FormEditStyles.Inline" class="doc-header">
        <p-button [disabled]="isUploadInProgress" styleClass="btn doc-btn-add" type="button" label="{{'Shared.Button.Add' | transloco }}" (onClick)="onAddClicked()"></p-button>
    </div>
    <p-dataView lazy="true" [loading]="isLoading" [value]="documentList" [totalRecords]="totalRecords" (onLazyLoad)="loadDocuments()" [emptyMessage]="DocumentListWidgetConfiguration.EmptyListResourceId | transloco">
        <ng-template let-document pTemplate="listItem">
            <fw-document-row [PageId]="pageId" [DocumentListViewDTO]="document" [DocumentRowOptions]="rowOptions" [StyleMode]="1" (UploadDocumentEvent)="rowUploadEventHandler($event)" (ReplaceDocumentEvent)="rowReplaceEventHandler($event)"></fw-document-row>
        </ng-template>
    </p-dataView>
    <div *ngIf="operationEnabled(documentOperation.Add) && rowOptions?.formEditStyle === FormEditStyles.Form" class="doc-footer">
        <p-button [disabled]="isUploadInProgress" styleClass="btn doc-btn-add" type="button" label="{{'Shared.Button.Add' | transloco }}" (onClick)="onAddClicked()"></p-button>
    </div>

</ng-template>
