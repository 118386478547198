import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PartyViewDTO } from '../../data-transfer-objects/party/party-view-dto';
import { BASE_URL } from '../../helpers/base-url';
import { PaginationModel, PaginationResultModel } from '../../models/pagination-models';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class PartyHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public getPartiesByAssetId(assetId: string, paginationModel: PaginationModel, pageId: string, widgetId: string): Observable<PaginationResultModel<PartyViewDTO>> {
        const headers: HttpHeaders = this.AddWidgetContextHeader(this.AddPageIdHeader(this.GetStandardHeaders(), pageId), widgetId);
        return this.GetAuthenticated<PaginationResultModel<PartyViewDTO>>(`${this.baseUrl}api/party/entityId/${assetId}`, headers, [], paginationModel);
    }    
}
