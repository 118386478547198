/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { GuidHelper } from 'src/app/helpers/guid-helper';
import { IdPsConfigurationFacade } from '../../../../facade/configuration/idp.facade';
import { SiteConfigurationFacade } from '../../../../facade/configuration/site-configuration.facade';
import { StructuredDataLookupFacade } from '../../../../facade/configuration/structured-data-lookup.facade';
import { StructuredDataNodeFacade } from '../../../../facade/configuration/structured-data-node.facade';
import { LandingPageBaseRoute } from '../../../../consts/portal-base-routes';
import { IdPConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/idp-configuration-view-dto';
import { LanguageDetailViewDTO } from '../../../../data-transfer-objects/language/language-detail-view-dto';
import { LoginDTO } from '../../../../data-transfer-objects/login/login-dto';
import { RepresentativeOfListViewDTO } from '../../../../data-transfer-objects/representative/representative-list-view-dto';
import { CurrentUserRepresentativeUpdateDTO } from '../../../../data-transfer-objects/users/current-user-representative-update-dto';
import { LoginType } from '../../../../enums/login-type';
import { UserAccountStatus } from '../../../../enums/user-account-status-enum';
import { PaginationResultModel } from '../../../../models/pagination-models';
import { DialogApplicationService, DialogOptions } from '../../../../services/application/dialog-application.service';
import { SessionApplicationService } from '../../../../services/application/session-application.service';
import { LanguageHttpService } from '../../../../services/http/language-http.service';
import { UserHttpService } from '../../../../services/http/user-http.service';
import { MapService } from '../../../../services/deprecated/map.service';
import { IdpSelectionFormComponent } from '../../forms/idp-selection-form/idp-selection-form.component';
import { LoginFormComponent } from '../../forms/login-form/login-form.component';
import { SwitchRepresentationComponent } from '../../forms/switch-representation/switch-representation.component';
import { RepresentativesHttpService } from '../../../../services/http/representatives-http.service';

@Component({
    selector: 'fw-user-menu',
    templateUrl: './user-menu.component.html',
})
export class UserMenuComponent implements OnInit, OnDestroy {
    public userName: string = '';
    public representString: string = '';
    public languages: LanguageDetailViewDTO[] = [];
    public currentLanguageId: number;
    private loginIdPsConfigurationFacadeSubscription: Subscription;
    private registerIdPsConfigurationFacadeSubscription: Subscription;
    private subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private mapService: MapService,
        private userHttpService: UserHttpService,
        private dialogApplicationService: DialogApplicationService,
        private sessionApplicationService: SessionApplicationService,
        private translocoService: TranslocoService,
        private representativesService: RepresentativesHttpService,
        public languageService: LanguageHttpService,
        public structuredDataLookupFacade: StructuredDataLookupFacade,
        public structuredDataNodeFacade: StructuredDataNodeFacade,
        private translateService: TranslateService,
        private primeNGConfig: PrimeNGConfig,
        private siteConfigurationFacade: SiteConfigurationFacade,
        private idPsConfigurationFacade: IdPsConfigurationFacade
    ) { }

    public get shouldShowLanguageSelector(): boolean {
        return this.languages.length > 1;
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    public ngOnInit(): void {
        const getAllLanguages$ = this.languageService.GetAll();
        const getCurrentLanguage$ = this.languageService.GetCurrentLanguageId();

        const allLanguagesSubscription: Subscription = getAllLanguages$
            .subscribe((languages: LanguageDetailViewDTO[]) => {
                this.languages = languages;
            });

        const currentLanguageSubscription: Subscription = getCurrentLanguage$
            .subscribe((languageId: string) => {
                this.currentLanguageId = Number(languageId);
            });

        forkJoin({
            allLanguages: getAllLanguages$,
            currentLanguageId: getCurrentLanguage$
        })
            .pipe(first())
            .subscribe((e: {
                allLanguages: LanguageDetailViewDTO[];
                currentLanguageId: string;
            }) => {
                const currentLanguage: LanguageDetailViewDTO = e.allLanguages.find(x => x.Id === this.currentLanguageId);

                if (currentLanguage) {
                    this.languageService.setCurrentLanguageLocale(currentLanguage.Locale);
                }
            });


        const calendarLanguageSubscription: Subscription = this.translateService.onTranslationChange.subscribe(() => {
            this.calendarTranslations(this.currentLanguageId.toString());
        });

        this.subscriptions = [allLanguagesSubscription, currentLanguageSubscription, calendarLanguageSubscription];

        this.sessionApplicationService.authenticationChanged.subscribe(
            (isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    this.mapService.refreshCache();

                    if (this.sessionApplicationService.currentUser.AccountStatusId === UserAccountStatus.ProfileDetailsRequired) {
                        this.profilePageRedirect();
                    } else {
                        this.landingPageRedirect();
                    }
                }
            }
        );

        this.mapService.refreshCache();
    }

    public login(): void {
        this.idPsConfigurationFacade.ResetState();
        this.idPsConfigurationFacade.loadIdPsConfiguration(false);

        this.loginIdPsConfigurationFacadeSubscription = this.idPsConfigurationFacade.getIdPsConfiguration().subscribe((idPConfigurationViewDTO: IdPConfigurationViewDTO) => {
            if (idPConfigurationViewDTO) {
                if (idPConfigurationViewDTO.InternalIdentityProviderConfiguration?.Enabled && idPConfigurationViewDTO.Saml2IdPs?.length === 0) {
                    this.router.navigate(['/page/login']);
                } else if (!idPConfigurationViewDTO.InternalIdentityProviderConfiguration?.Enabled && idPConfigurationViewDTO.Saml2IdPs?.length === 1) {
                    window.location.href = idPConfigurationViewDTO.Saml2IdPs[0].RedirectUrl;
                } else {
                    const dialogOptions: DialogOptions<LoginDTO> = {
                        closable: true,
                        showHeader: true,
                        footer: '',
                        header: this.translocoService.translate('Login.Form.SignInTitle'),
                        dataModel: {
                            logintype: LoginType.UsernamePassword,
                            username: '',
                            password: '',
                            onetimepin: ''
                        },
                    };

                    this.dialogApplicationService.showFormDialog(
                        LoginFormComponent,
                        dialogOptions
                    );
                }
            }

            if (this.loginIdPsConfigurationFacadeSubscription) {
                this.loginIdPsConfigurationFacadeSubscription.unsubscribe();
            }
        });

        this.subscriptions.push(this.loginIdPsConfigurationFacadeSubscription);
    }

    public register(): void {
        this.idPsConfigurationFacade.ResetState();
        this.idPsConfigurationFacade.loadIdPsConfiguration(true);

        this.registerIdPsConfigurationFacadeSubscription = this.idPsConfigurationFacade.getIdPsConfiguration().subscribe((idPConfigurationViewDTO: IdPConfigurationViewDTO) => {
            if (idPConfigurationViewDTO) {
                if (idPConfigurationViewDTO.InternalIdentityProviderConfiguration?.Enabled && idPConfigurationViewDTO.Saml2IdPs?.length === 0) {
                    this.router.navigate(['/page/register']);
                } else if (!idPConfigurationViewDTO.InternalIdentityProviderConfiguration?.Enabled && idPConfigurationViewDTO.Saml2IdPs?.length === 1) {
                    window.location.href = idPConfigurationViewDTO.Saml2IdPs[0].RedirectUrl;
                } else {
                    const dialogOptions: DialogOptions<IdPConfigurationViewDTO> = {
                        closable: true,
                        showHeader: true,
                        footer: '',
                        header: this.translocoService.translate('IdPSelection.Form.RegistrationOptions'),
                        dataModel: idPConfigurationViewDTO
                    };

                    this.dialogApplicationService.showFormDialog(
                        IdpSelectionFormComponent,
                        dialogOptions
                    );
                }
            }

            if (this.registerIdPsConfigurationFacadeSubscription) {
                this.registerIdPsConfigurationFacadeSubscription.unsubscribe();
            }
        });

        this.subscriptions.push(this.registerIdPsConfigurationFacadeSubscription);
    }

    public switchRepresentation(): void {
        this.representativesService
            .GetRepresentatives(
                this.sessionApplicationService.currentUser.PersonId)
            .subscribe(
                (
                    representatives: PaginationResultModel<RepresentativeOfListViewDTO>
                ) => {
                    const dialogOptions: DialogOptions<RepresentativeOfListViewDTO[]> = {
                        closable: false,
                        showHeader: true,
                        footer: '',
                        header: this.translocoService.translate(
                            'SwitchRepresentation.Form.Title'
                        ),
                        dataModel: representatives.Models,
                        styleClass: 'nested-footer dialog-md',
                    };

                    const dialogRef = this.dialogApplicationService.showFormDialog(
                        SwitchRepresentationComponent,
                        dialogOptions
                    );

                    dialogRef.onClose.subscribe(
                        (
                            currentUserRepresentativeUpdateDTO: CurrentUserRepresentativeUpdateDTO
                        ) => {
                            if (currentUserRepresentativeUpdateDTO) {
                                this.userHttpService
                                    .updateRepresentation(currentUserRepresentativeUpdateDTO)
                                    .subscribe(() => {
                                        this.sessionApplicationService.RefreshToken();
                                    });
                            }
                        }
                    );
                }
            );
    }

    public logout(): void {
        this.mapService.DestroyCachedState();
        this.sessionApplicationService.logout();
        this.structuredDataLookupFacade.ResetState();
        this.structuredDataNodeFacade.ResetState();
        this.siteConfigurationFacade.ResetState();
    }

    public profilePageRedirect(): void {
        this.router.navigate([`page/UserDetails`], {
            queryParams: { UserId: this.sessionApplicationService.currentUser.Id },
        });
    }

    public isAuthenticated(): boolean {
        return this.sessionApplicationService.isAuthenticated;
    }

    public getFullName(): string {
        if (this.sessionApplicationService.currentUser) {
            return `${this.sessionApplicationService.currentUser.Firstname} ${this.sessionApplicationService.currentUser.Surname}`;
        }
    }

    public getRepresentation(): string {
        if (this.sessionApplicationService.currentUser) {
            return this.sessionApplicationService.currentUser.RepresenteeName;
        }

        return '';
    }

    public getTitle(): string {
        if (this.userIsRepresentingSelf()) {
            return `${this.getRepresentation()}`;
        }
        else {
            return `${this.getFullName()} ${this.translocoService.translate("UserMenu.Representing")} ${this.getRepresentation()}`;
        }
    }

    public userIsRepresentingSelf(): boolean {
        return GuidHelper.Equals(this.sessionApplicationService?.currentUser?.PersonId, this.sessionApplicationService?.currentUser?.RepresenteeId);
    }

    public hasRepresentation(): boolean {
        if (this.sessionApplicationService.currentUser) {
            return this.sessionApplicationService.currentUserHasRepresentatives;
        }

        return false;
    }

    public setLanguage(language: LanguageDetailViewDTO): void {
        this.currentLanguageId = language.Id;
        this.languageService.SetCurrentLanguageId(String(language.Id));
        this.languageService.setCurrentLanguageLocale(language.Locale);

        window.location.reload();
    }

    private landingPageRedirect() {
        this.router.navigate([`/${LandingPageBaseRoute}`], { queryParams: { loggedIn: true } });
    }

    private calendarTranslations(languageId: string): void {
        this.translateService.use(`language-${languageId}`);
        this.translateService.get('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
    }
}
