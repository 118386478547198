import { createReducer, on } from '@ngrx/store';
import { ActionListWidgetStateModel, AssetListWidgetStateModel, ListState, ShapeWidgetState, UserListWidgetStateModel } from '../../../AppState';
import { ObjectHelper } from '../../../helpers/object-helper';
import { getBasicActionsBySearchDone, getBasicActionsBySearchRequestError, getBasicAssetsBySearchDone, getBasicAssetsBySearchRequestError, getBasicUsersBySearchDone, getBasicUsersBySearchRequestError, updateShape } from './basic-page.actions';


export const initialShapeDraftDTO: ShapeWidgetState = {
    GetShapeError: null,
    ListState: null,
    UpdateShapeError: null
}

export const BasicPageShapeDraftReducer = createReducer<ShapeWidgetState>(initialShapeDraftDTO,
    on(updateShape, (_, action) => action.shapeDraftWidgetState)
);


export const initialAssetListState: ListState<AssetListWidgetStateModel>[] = [];

export const AssetListReducer = createReducer<ListState<AssetListWidgetStateModel>[]>(initialAssetListState,
    on(getBasicAssetsBySearchDone, (state, action) => {

        let stateToReturn: ListState<AssetListWidgetStateModel>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find(s => s.dataSourceId === action.dataSourceId)) {

            const stateEntry = stateToReturn.find(s => s.dataSourceId === action.dataSourceId);
            stateEntry.StateModel = { paginationResult: action.paginationResult, error: null };
        }
        else {
            stateToReturn.push({ dataSourceId: action.dataSourceId, StateModel: { paginationResult: action.paginationResult, error: null } });
        }

        return stateToReturn;
    }),
    on(getBasicAssetsBySearchRequestError, (state, action) => {

        let stateToReturn: ListState<AssetListWidgetStateModel>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find(s => s.dataSourceId === action.dataSourceId)) {

            const stateEntry = stateToReturn.find(s => s.dataSourceId === action.dataSourceId);
            stateEntry.StateModel = {
                paginationResult: {
                    DisplayNextPage: false,
                    Models: [],
                    TotalRecords: 0
                },
                error: action.error
            };
        }
        else {
            stateToReturn.push({
                dataSourceId: action.dataSourceId, StateModel: {
                    paginationResult: {
                        DisplayNextPage: false,
                        Models: [],
                        TotalRecords: 0
                    }, error: action.error
                }
            });
        }

        return stateToReturn;
    })
);

export const initialActionListState: ListState<ActionListWidgetStateModel>[] = [];

export const ActionListReducer = createReducer<ListState<ActionListWidgetStateModel>[]>(initialActionListState,
    on(getBasicActionsBySearchDone, (state, action) => {

        let stateToReturn: ListState<ActionListWidgetStateModel>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find(s => s.dataSourceId === action.dataSourceId)) {

            const stateEntry = stateToReturn.find(s => s.dataSourceId === action.dataSourceId);
            stateEntry.StateModel = { paginationResult: action.paginationResult, error: null };
        }
        else {
            stateToReturn.push({ dataSourceId: action.dataSourceId, StateModel: { paginationResult: action.paginationResult, error: null } });
        }

        return stateToReturn;
    }),
    on(getBasicActionsBySearchRequestError, (state, action) => {

        let stateToReturn: ListState<ActionListWidgetStateModel>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find(s => s.dataSourceId === action.dataSourceId)) {

            const stateEntry = stateToReturn.find(s => s.dataSourceId === action.dataSourceId);
            stateEntry.StateModel = {
                paginationResult: {
                    DisplayNextPage: false,
                    Models: [],
                    TotalRecords: 0
                },
                error: action.error
            };
        }
        else {
            stateToReturn.push({
                dataSourceId: action.dataSourceId,
                StateModel:
                {
                    paginationResult: {
                        DisplayNextPage: false,
                        Models: [],
                        TotalRecords: 0
                    },
                    error: action.error
                }
            });
        }

        return stateToReturn;
    })
);

export const initialUserListState: ListState<UserListWidgetStateModel>[] = [];

export const UserListReducer = createReducer<ListState<UserListWidgetStateModel>[]>(initialUserListState,
    on(getBasicUsersBySearchDone, (state, action) => {

        let stateToReturn: ListState<UserListWidgetStateModel>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find(s => s.dataSourceId === action.dataSourceId)) {

            const stateEntry = stateToReturn.find(s => s.dataSourceId === action.dataSourceId);
            stateEntry.StateModel = { paginationResult: action.paginationResult, error: null };
        }
        else {
            stateToReturn.push({ dataSourceId: action.dataSourceId, StateModel: { paginationResult: action.paginationResult, error: null } });
        }

        return stateToReturn;
    }),
    on(getBasicUsersBySearchRequestError, (state, action) => {

        let stateToReturn: ListState<UserListWidgetStateModel>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find(s => s.dataSourceId === action.dataSourceId)) {

            const stateEntry = stateToReturn.find(s => s.dataSourceId === action.dataSourceId);
            stateEntry.StateModel = {
                paginationResult: {
                    DisplayNextPage: false,
                    Models: [],
                    TotalRecords: 0
                },
                error: action.error
            };
        }
        else {
            stateToReturn.push({
                dataSourceId: action.dataSourceId,
                StateModel:
                {
                    paginationResult: {
                        DisplayNextPage: false,
                        Models: [],
                        TotalRecords: 0
                    },
                    error: action.error
                }
            });
        }

        return stateToReturn;
    })
);
