<fw-carousel-section *ngIf="sectionConfiguration.SectionStyle === sectionStyle.Carousel"
                     [carouselSectionConfiguration]="sectionConfiguration.CarouselSection"
                     [FormEditStyle]="FormEditStyle">
</fw-carousel-section>

<fw-tab-section *ngIf="sectionConfiguration.SectionStyle === sectionStyle.Tabbed"
                [TabSectionConfiguration]="sectionConfiguration.TabSection"
                [FormEditStyle]="FormEditStyle">
</fw-tab-section>

<fw-column-section *ngIf="sectionConfiguration.SectionStyle === sectionStyle.Column"
            [columnSectionConfiguration]="sectionConfiguration.ColumnSection"
            [formEditStyle]="FormEditStyle">
</fw-column-section>