import { createAction, props } from '@ngrx/store';
import { RelatedAssetAddDTO } from 'src/app/data-transfer-objects/related-asset/related-asset-add-dto';
import { RelatedAssetListWidgetStateModel } from '../../AppState';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { RelatedAssetSearchModel } from '../../data-transfer-objects/related-asset/related-asset-search-dto';
import { PaginationModel } from '../../models/pagination-models';

export const getRelatedAssetsBySearchRequest = createAction('[RelatedAssets] Get Asset Related Assets By Search Request', props<{ fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, relatedAssetSearchDTO: RelatedAssetSearchModel, dataSourceId: string, pageId: string }>());
export const getRelatedAssetsBySearchDone = createAction('[RelatedAssets] Get Asset Related Assets By Search Done', props<{ stateModel: RelatedAssetListWidgetStateModel, dataSourceId: string }>());
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getRelatedAssetsBySearchError = createAction('[RelatedAssets] Get Asset Related Assets By Search Error', props<{ dataSourceId: string, stateModel: RelatedAssetListWidgetStateModel }>());

export const addRelatedAssets = createAction('[RelatedAssets] Add Asset Related Assets', props<{ dataSourceId: string, relatedAssets: RelatedAssetAddDTO[], pageId: string }>());
export const addRelatedAssetsDone = createAction('[RelatedAssets] Add Related Assets Done', props<{ dataSourceId: string, stateModel: RelatedAssetListWidgetStateModel }>());
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const addRelatedAssetsError = createAction('[RelatedAssets] Add Related Assets Error', props<{ dataSourceId: string, stateModel: RelatedAssetListWidgetStateModel }>());

export const removeRelatedAssets = createAction('[RelatedAssets] Remove Asset Related Assets', props<{ dataSourceId: string, relatedAssetIds: string[], pageId: string }>());
export const removeRelatedAssetsDone = createAction('[RelatedAssets] Remove Related Assets Done', props<{ dataSourceId: string, relatedAssetIds: string[] }>());
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const removeRelatedAssetsError = createAction('[RelatedAssets] Remove Related Assets Error', props<{ dataSourceId: string, stateModel: RelatedAssetListWidgetStateModel }>());
