import { Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AssetDetailsWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/asset-details-widget-configuration-view-dto';
import { Subscription } from 'rxjs';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { ConfigurableControlsHelper } from '../../../../../helpers/configurable-controls-helper';
import { AssetViewDTO } from '../../../../../data-transfer-objects/asset/asset-view-dto';
import { AbstractAssetDetailsFacade } from '../../../../../facade/abstract/abstract-asset-details.facade';
import { BaseWidget } from '../base-widget';

@Component({
    selector: 'fw-asset-details-widget',
    templateUrl: './asset-details-widget.component.html'

})
export class AssetDetailsWidgetComponent extends BaseWidget implements OnInit, OnDestroy, DoCheck {
    public FormGroup: FormGroup = new FormGroup({});
    public loaded: boolean = false;

    @Input()
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public AssetDetailsWidgetConfiguration: AssetDetailsWidgetConfigurationViewDTO;

    @Input()
    public FormEditStyle: FormEditStyle;

    private AssetViewDTO: AssetViewDTO;
    private Subscriptions: Subscription[] = [];

    constructor(
        private assetDetailsFacade: AbstractAssetDetailsFacade,
        public elementRef: ElementRef) {
        super(elementRef);
    }
    ngDoCheck(): void {
        this.assetDetailsFacade.SetValidity(this.dataSourceId, this.FormGroup.valid);
    }
    ngOnDestroy(): void {
        if (this.Subscriptions) {
            this.Subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            })
        }
    }

    ngOnInit(): void {

        this.assetDetailsFacade.LoadAssetDetails();

        const getAssetDetailsSubscription = this.assetDetailsFacade.AssetSelector().subscribe((assetWidgetState) => {
            if (assetWidgetState.AssetViewDTO) {
                this.loaded = true;
                this.assetDetailsFacade.PrimaryWidgetCompletedLoading.next(assetWidgetState.AssetViewDTO);
                assetWidgetState.AssetViewDTO.Id
                this.AssetViewDTO = assetWidgetState.AssetViewDTO;
                ConfigurableControlsHelper.PopulateFormControl(this.AssetViewDTO, this.AssetDetailsWidgetConfiguration.Properties, this.FormGroup)
            }
        });

        const acceptChangesSubscription = this.AcceptChanges.subscribe(() => {

            const clonedAsset = { ...this.AssetViewDTO, ...this.FormGroup.value };
            this.AssetViewDTO = clonedAsset;
            this.assetDetailsFacade.UpdateAsset(this.AssetViewDTO);
        });

        this.Subscriptions.push(getAssetDetailsSubscription);
        this.Subscriptions.push(acceptChangesSubscription);
    }
}
