import { createAction, props } from '@ngrx/store';
import { PaymentConnectorResponseInputContextModel } from '../../data-transfer-objects/payment/payment-connector-response-input-view-dto';

export const executeProcessPaymentResponseRequest = createAction(
    '[ActionDetails] Execute Process Payment Response Request',
    props<{
        postPaymentRedirectResponse: PaymentConnectorResponseInputContextModel
    }>()
);

export const executePaymentProcessResponseDone = createAction(
    '[ActionDetails] Execute Process Payment Response Done'
);
