import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { UserRolesWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/user-roles-widget-configuration-view-dto';
import { LookupTables } from '../../../../../enums/lookup-table-enum';
import { LookupModel } from '../../../../../models';
import { DialogApplicationService, DialogOptions } from '../../../../../services/application/dialog-application.service';
import { LookupCacheService } from '../../../../../services/deprecated/lookup-cache.service';
import { ListBoxBaseComponent } from '../../../../dynamic/controls/listbox/listbox-base.component';
import { AbstractUserRolesFacade } from '../../../../../facade/abstract/abstract-user-roles.facade';
import { SelectFromListBoxComponent } from '../../../../static/components/dialogs/select-from-listbox.component';

@Component({
    selector: 'fw-inline-edit-user-roles',
    templateUrl: './inline-user-role.component.html',
    host: { class: 'inline-edit' },
})
export class InlineEditUserRoleComponent extends ListBoxBaseComponent implements OnDestroy, OnInit {

    CurrentRoles: string = '';

    @Input()
    UserRolesWidgetConfigurationViewDTO: UserRolesWidgetConfigurationViewDTO;

    @ViewChild('formEditUserRoles') formEditUserRoleTemplate: TemplateRef<HTMLElement>;

    private _subcriptions: Subscription[] = [];

    constructor(
        protected translationService: TranslocoService,
        private lookupService: LookupCacheService,
        private dialogApplicationService: DialogApplicationService,
        protected userRolesFacade: AbstractUserRolesFacade
    ) {
        super(translationService, lookupService);
    }

    ngOnDestroy(): void {
        if (this._subcriptions) {
            this._subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
    }

    ngOnInit() {
        this._subcriptions.push(this.userRolesFacade.UserRolesSelector().subscribe((roles) => {

            if (roles.Roles && roles.Roles.length > 0) {

                const roleNames: string[] = [];

                roles.Roles.map(role => {
                    this._subcriptions.push(this.lookupService.GetLookupValue(LookupTables.Roles, role.Id).subscribe(lookupValue => {
                        roleNames.push(lookupValue);
                        this.CurrentRoles = roleNames.join(', ');
                    }));
                });
            }
            else {
                this.CurrentRoles = '';
            }
        }));

        this._subcriptions.push(this.ReadyToStorePreviousValue.subscribe((ready: boolean) => {
            if (ready) {
                this.StorePreviousValue();
                this.ReadyToStorePreviousValue.unsubscribe();
            }
        }));
    }

    OpenModal(): void {

        const dialogOptions: DialogOptions<{
            customTemplate: TemplateRef<HTMLElement>,
            options: LookupModel[],
            translations: { CancelButtonText: string, ConfirmButtonText: string }
        }> = {
            closable: true,
            footer: '',
            showHeader: true,
            header: this.translationService.translate(this.UserRolesWidgetConfigurationViewDTO.HeaderResourceId),
            dataModel: {
                customTemplate: this.formEditUserRoleTemplate,
                options: this.SelectedOptions,
                translations: {
                    CancelButtonText: this.translationService.translate(this.UserRolesWidgetConfigurationViewDTO.CancelButtonResourceId)
                    , ConfirmButtonText: this.translationService.translate(this.UserRolesWidgetConfigurationViewDTO.ConfirmButtonResourceId)
                }
            },
            styleClass: 'dialog-sm',
        };

        const dialogRef = this.dialogApplicationService.showFormDialog(
            SelectFromListBoxComponent,
            dialogOptions
        );

        this._subcriptions.push(dialogRef.onClose.subscribe((options: LookupModel[]) => {
            if (options) {
                this.onAcceptChanges();
            } else {
                this.onCancelChanges();
            }
        }));
    }
}
