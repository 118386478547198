import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PreNavigationHandlerService {

    private _navigationHandles: Observable<boolean>[] = [];

    constructor() {

    }

    public PopPreNavigationHandle(): void {

        if (this._navigationHandles.length > 0)
            this._navigationHandles.pop();

    }

    public PreNavigation(): Observable<boolean> {

        return new Observable<boolean>((subscriber: Subscriber<boolean>) => {

            if (this._navigationHandles.length > 0) {

                const navigationHandle: Observable<boolean> = this._navigationHandles.pop();

                navigationHandle.subscribe((result: boolean) => {
                    subscriber.next(result);
                });

            }
            else {
                subscriber.next(true);
            }

        });

    }

    public PushPreNavigationHandle(navigationHandle: Observable<boolean>) {
        this._navigationHandles.push(navigationHandle);
    }

}
