import { ShapeUpdateDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-update-dto';
import { MapWidgetConfiguration } from '../data-transfer-objects/configuration/map-widget-configuration-view-dto';
import { ShapeViewDTO } from '../data-transfer-objects/shape/shape-view-dto';
import { GuidHelper } from './guid-helper';
import { ValidationResult as NgMapValidationResult } from '@landadmin/ng-mapping-library/lib/models/validation-result';
import { ValidationResult } from '../models/validation/validation-result';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ComponentRef } from '@angular/core';
import { NgMapComponent } from '@landadmin/ng-mapping-library/lib/components/ng-map/ng-map.component';
import { ShapeAndSymbology } from '@landadmin/ng-mapping-library/lib/models/shape-and-symbology';
import { ShapeSymbology } from '../models/configuration/widgets/map-widget-configuration models/shape-symbology-model';
import { GeometryType } from '../enums/geometry-type';

export interface ShapeValidResult {
    IsValid: boolean;
    translationResourceId: string;
}

export class ShapeHelper {
    public static ValidateMapWidgetData(
        mapRef: ComponentRef<NgMapComponent>,
        subscriptions: Subscription[],
        mapWidgetConfigurationViewDTO: MapWidgetConfiguration
    ): Observable<ValidationResult<ShapeUpdateDTO>> {
        return new Observable<ValidationResult<ShapeUpdateDTO>>(
            (subscriber: Subscriber<ValidationResult<ShapeUpdateDTO>>) => {
                const ngMapValidationResult: NgMapValidationResult =
                    mapRef.instance.ValidateCurrentlyEditedDetails();

                const validationResult: ValidationResult<ShapeUpdateDTO> = {
                    Valid: ngMapValidationResult.Valid,
                    ValidationMessages: [ngMapValidationResult.InvalidReason],
                    Data: null,
                };

                if (validationResult.Valid) {
                    validationResult.ValidationMessages = [];

                    if (mapRef && mapRef.instance) {
                        subscriptions.push(
                            mapRef.instance
                                .GetShape()
                                .subscribe((shapeUpdateDTO: ShapeUpdateDTO) => {
                                    const updateShapeValidResult = this.ValidateUpdateShape(
                                        mapWidgetConfigurationViewDTO,
                                        shapeUpdateDTO
                                    );

                                    if (updateShapeValidResult.translationResourceId) {
                                        validationResult.ValidationMessages.push(
                                            updateShapeValidResult.translationResourceId
                                        );
                                    }

                                    validationResult.Valid = updateShapeValidResult.IsValid;

                                    validationResult.Data = shapeUpdateDTO;
                                    subscriber.next(validationResult);
                                })
                        );
                    } else {
                        subscriber.next(validationResult);
                    }
                } else {
                    subscriber.next(validationResult);
                }
            }
        );
    }

    public static ValidateUpdateShape(
        mapWidgetConfigurationViewDTO: MapWidgetConfiguration,
        shapeUpdateDTO: ShapeUpdateDTO
    ): ShapeValidResult {
        let shapeViewDTO: ShapeViewDTO;

        // eslint-disable-next-line prefer-const
        shapeViewDTO = { ...shapeViewDTO, ...shapeUpdateDTO };
        return this.ValidateViewShape(mapWidgetConfigurationViewDTO, shapeViewDTO);
    }

    public static ValidateViewShape(
        mapWidgetConfigurationViewDTO: MapWidgetConfiguration,
        shapeViewDTO: ShapeViewDTO
    ): ShapeValidResult {
        let shapeValidResult: ShapeValidResult;

        if (!mapWidgetConfigurationViewDTO.ShapeEditorConfiguration) {
            shapeValidResult = {
                IsValid: false,
                translationResourceId: null,
            };

            return shapeValidResult;
        }

        if (
            mapWidgetConfigurationViewDTO.ShapeEditorConfiguration &&
            mapWidgetConfigurationViewDTO.ShapeEditorConfiguration
                .OfficialAreaRequired
        ) {
            if (
                shapeViewDTO.OfficialAreaText === '' ||
                shapeViewDTO.OfficialAreaUnitID === GuidHelper.EmptyGuid()
            ) {
                shapeValidResult = {
                    IsValid: false,
                    translationResourceId: 'Map.ShapeOfficialAreaAndUnitRequired',
                };
                return shapeValidResult;
            }
        }

        if (
            mapWidgetConfigurationViewDTO.ShapeEditorConfiguration &&
            mapWidgetConfigurationViewDTO.ShapeEditorConfiguration
                .OfficialLengthRequired
        ) {
            if (
                shapeViewDTO.OfficialLengthText === '' ||
                shapeViewDTO.OfficialLengthUnitID === GuidHelper.EmptyGuid()
            ) {
                shapeValidResult = {
                    IsValid: false,
                    translationResourceId: 'Map.ShapeOfficialLengthAndUnitRequired',
                };

                return shapeValidResult;
            }
        }

        if (
            mapWidgetConfigurationViewDTO.ShapeEditorConfiguration &&
            mapWidgetConfigurationViewDTO.ShapeEditorConfiguration.PartsRequired
        ) {
            if (shapeViewDTO.Parts.length === 0) {
                shapeValidResult = {
                    IsValid: false,
                    translationResourceId: 'Map.ShapePartsRequired',
                };

                return shapeValidResult;
            }
        }

        shapeValidResult = {
            IsValid: true,
            translationResourceId: null,
        };

        return shapeValidResult;
    }

    public static addSymbologyToShapes(_mapRef: ComponentRef<NgMapComponent>, shapes: ShapeAndSymbology[], shapeDisplaySymbology: ShapeSymbology, shapeHighlightSymbology: ShapeSymbology): void {
        const esri = _mapRef.instance.Esri;
        const dojo = _mapRef.instance.Dojo;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let displayColorSolid: any = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let displayColorTransparent: any = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let highlightColorSolid: any = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let highlightColorTransparent: any = null;

        const displayColor = {
            red: 255,
            green: 255,
            blue: 255,
            transparency: 0.25
        }

        const highlightColor = {
            red: 255,
            green: 255,
            blue: 0,
            transparency: 0.25
        }

        if (shapeDisplaySymbology && shapeDisplaySymbology.Color) {

            const color = shapeDisplaySymbology.Color;

            displayColor.red = color[0];
            displayColor.green = color[1];
            displayColor.blue = color[2];

            if (color.length > 3) {
                displayColor.transparency = color[3];
            }
        }

        if (shapeHighlightSymbology && shapeHighlightSymbology.Color) {

            const color = shapeHighlightSymbology.Color;

            highlightColor.red = color[0];
            highlightColor.green = color[1];
            highlightColor.blue = color[2];

            if (color.length > 3) {
                highlightColor.transparency = color[3];
            }
        }

        displayColorTransparent = new dojo.Color([displayColor.red, displayColor.green, displayColor.blue, displayColor.transparency]);
        displayColorSolid = new dojo.Color([displayColor.red, displayColor.green, displayColor.blue, 1]);

        highlightColorTransparent = new dojo.Color([highlightColor.red, highlightColor.green, highlightColor.blue, highlightColor.transparency]);
        highlightColorSolid = new dojo.Color([highlightColor.red, highlightColor.green, highlightColor.blue, 1]);

        for (let shapesCounter = 0; shapesCounter < shapes.length; shapesCounter++) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let displaySymbol: any = null;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let highlightSymbol: any = null;
            const shape = shapes[shapesCounter];

            switch (shape.Shape.GeometryType) {
                case GeometryType.POINT:
                    displaySymbol = new esri.symbol.SimpleFillSymbol(esri.symbol.SimpleFillSymbol.STYLE_SOLID, new esri.symbol.SimpleMarkerSymbol(esri.symbol.SimpleLineSymbol.STYLE_SOLID, displayColorSolid, 1), displayColorTransparent);
                    highlightSymbol = new esri.symbol.SimpleFillSymbol(esri.symbol.SimpleFillSymbol.STYLE_SOLID, new esri.symbol.SimpleMarkerSymbol(esri.symbol.SimpleLineSymbol.STYLE_SOLID, highlightColorSolid, 1), highlightColorTransparent);
                    break;
                case GeometryType.POLYLINE:
                    displaySymbol = new esri.symbol.SimpleLineSymbol(esri.symbol.SimpleLineSymbol.STYLE_SOLID, displayColorSolid, 1);
                    highlightSymbol = new esri.symbol.SimpleLineSymbol(esri.symbol.SimpleLineSymbol.STYLE_SOLID, highlightColorSolid, 1);
                    break;
                case GeometryType.POLYGON:
                    displaySymbol = new esri.symbol.SimpleFillSymbol(esri.symbol.SimpleFillSymbol.STYLE_SOLID, new esri.symbol.SimpleLineSymbol(esri.symbol.SimpleLineSymbol.STYLE_SOLID, displayColorSolid, 1), displayColorTransparent);
                    highlightSymbol = new esri.symbol.SimpleFillSymbol(esri.symbol.SimpleFillSymbol.STYLE_SOLID, new esri.symbol.SimpleLineSymbol(esri.symbol.SimpleLineSymbol.STYLE_SOLID, highlightColorSolid, 1), highlightColorTransparent);
                    break;
            }

            shape.DisplaySymbol = displaySymbol;
            shape.HighlightSymbol = highlightSymbol;
        }
    }
}
