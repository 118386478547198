import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoleViewDTO } from '../../data-transfer-objects/role/role-view-dto';
import { UserRoleUpdateDTO } from '../../data-transfer-objects/role/user-role-update-dto';
import { BASE_URL } from '../../helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class UserRoleHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private _baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public GetUserRoles(userId: string): Observable<RoleViewDTO[]> {
        return this.GetAuthenticated<RoleViewDTO[]>(`${this._baseUrl}api/UserRole/${userId}`, this.GetStandardHeaders(), []);
    }

    public UpdateUserRoles(roleUpdateDto: UserRoleUpdateDTO): Observable<null> {
        return this.Post<null>(`${this._baseUrl}api/UserRole/${roleUpdateDto.UserId}`, roleUpdateDto, this.GetStandardHeaders(), [], null, []);
    }
}
