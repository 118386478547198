import { createReducer, on } from '@ngrx/store';
import { ActionWidgetState } from '../../AppState';
import { actionResetState, getActionDone, getActionError, updateActionDone, updateActionError } from './action.actions';

//Assets
export const initialCreateActionActionWidgetState: ActionWidgetState = {
    ActionViewDTO:null,
    GetActionError: null,
    UpdateActionError: null
};

export const ActionDetailsReducer = createReducer<ActionWidgetState>(initialCreateActionActionWidgetState,
    on(getActionDone, (_, action) => action.actionWidgetState),
    on(getActionError, (_, action) => action.actionWidgetState),
    on(updateActionDone, (_, action) => action.actionWidgetState),
    on(updateActionError, (_, action) => action.actionWidgetState),
    on(actionResetState, (_, action) => initialCreateActionActionWidgetState)
);


