import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentCreateDTO } from '../../../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';
import { DiscussionCreateDTO } from '../../../../data-transfer-objects/discussion/discussion-create-dto';
import { DialogComponentBase } from '../../../../services/application/dialog-application.service';

@Component({
    selector: 'fw-discussion-add-new',
    templateUrl: './discussion-add-new.component.html'
})
export class AddNewDiscussionTopicComponent extends DialogComponentBase<DiscussionCreateDTO> {

    public newDiscussion: DiscussionCreateDTO =
        {
            DiscussionCommentCreateDTO: { Comment: null, Attachments: null },
            Topic: null
        };

    public UploadedOutputFiles: File[] = [];

    constructor(private dynamicDialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig,
        public transloco: TranslocoService) {
        super(dynamicDialogRef, dynamicDialogConfig);
    }

    public cancelClicked() {
        this.dynamicDialogRef.destroy();
    }

    public addClicked() {
        this.mapAttachments();
        this.dynamicDialogRef.close(this.newDiscussion);
        this.UploadedOutputFiles = [];
    }
    private mapAttachments(): void {

        const attachments: AttachmentCreateDTO[] = [];

        this.UploadedOutputFiles.forEach((file) => { attachments.push({ DocumentBlob: file, FileName: file.name }) });

        this.newDiscussion.DiscussionCommentCreateDTO.Attachments = attachments;
    }

}
