import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpFieldModel } from 'src/app/models/field-list-model';
import { RelatedAssetHttpService } from 'src/app/services/http/related-asset-http-service';
import { addRelatedAssets, addRelatedAssetsDone, addRelatedAssetsError, getRelatedAssetsBySearchDone, getRelatedAssetsBySearchError, getRelatedAssetsBySearchRequest, removeRelatedAssets, removeRelatedAssetsDone, removeRelatedAssetsError } from './related-assets.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/AppState';
import { getRelatedAssetsListWidgetState } from '../widgets.selectors'//'../../state/widgets.selectors';

@Injectable()
export class RelatedAssetsEffects {

    getRelatedAssetsBySearch$ = createEffect(() => this.actions$.pipe(
        ofType(getRelatedAssetsBySearchRequest),
        mergeMap((action) => {
            const httpFieldModel = new HttpFieldModel();
            httpFieldModel.Fields = new Array<string>();

            action.fields.forEach(field => {
                httpFieldModel.Fields.push(field.PropertyName);
            });

            return this.relatedAssetHttpService.getRelatedAssetsBySearch(action.paginationModel, action.relatedAssetSearchDTO, httpFieldModel, action.pageId).pipe(
                map(paginationResultModel => getRelatedAssetsBySearchDone({
                    dataSourceId: action.dataSourceId,
                    stateModel: {
                        error: null,
                        paginationResult: paginationResultModel,
                        reloadList: false
                    }
                })),
                catchError((err) => {
                    return of(getRelatedAssetsBySearchError({
                        stateModel: {
                            error: err,
                            paginationResult: null,
                            reloadList: false
                        },
                        dataSourceId: action.dataSourceId
                    }))
                })
            );

        })
    ));

    addRelatedAssets$ = createEffect(() => this.actions$.pipe(
        ofType(addRelatedAssets),
        mergeMap((action) => {
            return this.relatedAssetHttpService
                .addRelatedAssets(action.relatedAssets, action.pageId)
                .pipe(
                    map((result) => {

                        return addRelatedAssetsDone({
                            stateModel: {
                                error: null,
                                paginationResult: result,
                                reloadList: true
                            },
                            dataSourceId: action.dataSourceId
                        });
                    }),
                    catchError((error => {
                        return of(addRelatedAssetsError({
                            stateModel: {
                                error,
                                paginationResult: null,
                                reloadList: false
                            },
                            dataSourceId: null
                        }));
                    }))
                );
        })
    ));

    removeRelatedAssets$ = createEffect(() => this.actions$.pipe(
        ofType(removeRelatedAssets),
        mergeMap((action) => {                       
            return this.relatedAssetHttpService
                .removeRelatedAssets(action.relatedAssetIds, action.pageId)
                .pipe(
                    map((result) => {
                        return removeRelatedAssetsDone({
                            relatedAssetIds: action.relatedAssetIds,
                            dataSourceId: action.dataSourceId
                        });
                    }),
                    catchError((error => {
                        return of(removeRelatedAssetsError({
                            stateModel: {
                                error,
                                paginationResult: null,
                                reloadList: false
                            },
                            dataSourceId: null
                        }));
                    }))
                );
        })
    ));

    constructor(
        private actions$: Actions,
        private relatedAssetHttpService: RelatedAssetHttpService,
        private store: Store<AppState>
    ) { }
}
