<div [formGroup]="CustomFormGroup">
    <fw-general-validator [PropertyName]="PropertyName"
                          [(CustomFormGroup)]="CustomFormGroup"
                          [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
    <p-dropdown [formControlName]="PropertyName"
                [options]="Options"
                optionLabel="Value"
                optionValue="Id"
                placeholder="{{Dropdown.PlaceHolderItemResourceId | transloco}}"
                (onChange)="onAcceptChanges()" appendTo="body"
                [readonly]="Dropdown.ReadOnly === true" [class.readonly]="Dropdown.ReadOnly === true">
    </p-dropdown>
    <ng-template [ngIf]="ShowLabel()">
	    <label [ngClass]="GetLabelStylingClass()">
	        {{Label | transloco}}
	        <span *ngIf="Dropdown.Validation.Required">*</span>
	    </label>
    </ng-template>
</div>
