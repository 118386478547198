import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { addRepresentativeRequest, getRepresentativeRequest, removeRepresentativeRequest, resetRepresentative } from '../../state/representative/representative.actions';
import { getRepresentativeWidgetState } from '../../state/widgets.selectors';
import { AppState, RepresentativeWidgetState } from '../../AppState';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { AbstractRepresentativeListFacade } from '../abstract/abstract-representative-list.facade';

@Injectable()
export class RepresentativeListFacade extends AbstractRepresentativeListFacade {

    constructor(private store: Store<AppState>) {
        super();
    }

    public GetRepresentatives(): Observable<RepresentativeWidgetState> {
        const representativeWidgetState = this.store.pipe(select(getRepresentativeWidgetState));
        return representativeWidgetState;
    }
    public LoadRepresentatives(): void {
        this.store.dispatch(getRepresentativeRequest({ FormEditStyle: this.FormEditStyle, UserRepresentationListWidgetConfiguration: this.UserRepresentationListWidgetConfiguration }));
    }
    public ResetState(): void {
        this.store.dispatch(resetRepresentative());
    }
    public AddRepresentative(personId: string, representativeOfDTO: RepresentativeOfListViewDTO): void {
        this.store.dispatch(addRepresentativeRequest({ personId: personId, representativeOfDTO: representativeOfDTO }));
    }
    public RemoveRepresentative(personId: string, representativeOfDTO: RepresentativeOfListViewDTO): void {
        this.store.dispatch(removeRepresentativeRequest({ personId: personId, representativeOfDTO: representativeOfDTO }));
    }    
}
