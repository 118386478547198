import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BASE_URL } from '../../helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';
import { ActionConfigurationViewDTO } from '../../models/configuration/action-configuration-view-dto';
import { LicenseConfigurationViewDTO } from '../../models/configuration/license-configuration-view-dto';
import { PortalMapConfigurationViewDTO } from '../../data-transfer-objects/configuration/portal-map-configuration-view-dto';
import { DocumentUploadConfigurationViewDTO } from '../../data-transfer-objects/configuration/document-upload-configuration-view-dto';
import { MenuItemConfigurationViewDTO } from '../../data-transfer-objects/configuration/menu-item-configuration-view-dto';
import { PageConfigurationViewDTO } from '../../data-transfer-objects/configuration/page-configuration-view.dto';
import { SiteConfigurationViewDTO } from '../../data-transfer-objects/configuration/site-configuration-view-dto';
import { RoleConfigurationViewDTO } from '../../data-transfer-objects/configuration/role-configuration-view-dto';
import { IntegrationConfigurationViewDTO } from '../../data-transfer-objects/configuration/integration-configuration-view-dto';
import { UIStyleConfigurationViewDTO } from '../../data-transfer-objects/configuration/ui-style-configuration-view-dto';
import { SiteSettingsConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/site-settings-configuration-view-dto';
import { IdPConfigurationViewDTO } from '../../data-transfer-objects/configuration/idp-configuration-view-dto';
import { GoogleAnalyticsConfigurationViewDTO } from '../../data-transfer-objects/configuration/google-analytics-configuration-view-dto';
import { HttpStatusCode } from "../../enums/http-status-codes";

@Injectable({
    providedIn: 'root'
})

export class ConfigurationHttpService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {

        super(httpClient, errorManager, toastService);

    }

    public GetActionConfiguration(key: string): Observable<ActionConfigurationViewDTO> {
        return this.GetAuthenticated<ActionConfigurationViewDTO>(`${this.baseUrl}api/config/action/${key}`, this.GetStandardHeaders());
    }

    public GetAllowedFileTypesConfiguration(): Observable<DocumentUploadConfigurationViewDTO> {
        return this.GetAuthenticated<DocumentUploadConfigurationViewDTO>(`${this.baseUrl}api/config/documentUpload`, this.GetStandardHeaders());
    }

    public GetIntegrationConfiguration(): Observable<IntegrationConfigurationViewDTO> {
        return this.GetAuthenticated<IntegrationConfigurationViewDTO>(`${this.baseUrl}api/config/site/integration`, this.GetStandardHeaders());
    }

    public GetLicenseConfiguration(key: string): Observable<LicenseConfigurationViewDTO> {
        return this.GetAuthenticated<LicenseConfigurationViewDTO>(`${this.baseUrl}api/config/license/${key}`, this.GetStandardHeaders());
    }

    public GetMapConfiguration(key: string): Observable<PortalMapConfigurationViewDTO> {
        return this.GetAuthenticated<PortalMapConfigurationViewDTO>(`${this.baseUrl}api/config/mapping/${key}`, this.GetStandardHeaders());
    }

    public GetMenuItems(): Observable<MenuItemConfigurationViewDTO[]> {
        return this.GetAuthenticated<MenuItemConfigurationViewDTO[]>(`${this.baseUrl}api/config/site/menu`, this.GetStandardHeaders(), [], null, [HttpStatusCode.INTERNAL_SERVER_ERROR], null);
    }

    public GetPageConfiguration(pageId: string): Observable<PageConfigurationViewDTO> {
        return this.GetAuthenticated<PageConfigurationViewDTO>(`${this.baseUrl}api/config/site/page`, this.AddPageIdHeader(this.GetStandardHeaders(),pageId));
    }

    public GetRoles(): Observable<RoleConfigurationViewDTO[]> {
        return this.GetAuthenticated<RoleConfigurationViewDTO[]>(`${this.baseUrl}api/config/site/role`, this.GetStandardHeaders());
    }

    public GetSiteConfiguration(): Observable<SiteConfigurationViewDTO> {
        return this.GetAuthenticated<SiteConfigurationViewDTO>(`${this.baseUrl}api/config/site`, this.GetStandardHeaders());
    }

    public GetSiteUIStyleConfiguration(): Observable<UIStyleConfigurationViewDTO> {
        return this.GetAuthenticated<UIStyleConfigurationViewDTO>(`${this.baseUrl}api/config/site/styles`, this.GetStandardHeaders());
    }

    public GetSiteSettingsConfiguration(): Observable<SiteSettingsConfigurationViewDTO> {
        return this.GetAuthenticated<SiteSettingsConfigurationViewDTO>(`${this.baseUrl}api/config/site/settings`, this.GetStandardHeaders());
    }

    public GetIdPsConfiguration(forceAuthentication: boolean): Observable<IdPConfigurationViewDTO> {
        return this.GetAuthenticated<IdPConfigurationViewDTO>(`${this.baseUrl}api/config/site/IdPs?forceAuthentication=${forceAuthentication}`, this.GetStandardHeaders());
    }

    public GetGoogleAnalyticsKey(): Observable<GoogleAnalyticsConfigurationViewDTO> {
        return this.GetAuthenticated<GoogleAnalyticsConfigurationViewDTO>(`${this.baseUrl}api/config/googleAnalyticsKey`, this.GetStandardHeaders());
    }
}
