import { Component, OnInit, TemplateRef } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LookupModel } from '../../../../models';
import { DialogComponentBase } from '../../../../services/application/dialog-application.service';

@Component({
    selector: 'fw-select-from-listbox',
    templateUrl: './select-from-listbox.component.html'
})
export class SelectFromListBoxComponent extends DialogComponentBase<{
    customTemplate: TemplateRef<HTMLElement>,
    options: LookupModel[],
    translations: { CancelButtonText: string, ConfirmButtonText: string }
}> implements OnInit {
    public customTemplate: TemplateRef<HTMLElement>;
    public ConfirmButtonText: string;
    public CancelButtonText: string;

    private Options: LookupModel[];

    constructor(
        public dynamicDialogRef: DynamicDialogRef,
        dynamicDialogConfig: DynamicDialogConfig
    ) {
        super(dynamicDialogRef, dynamicDialogConfig);
        this.Options = this.dialogOptions.dataModel.options;
    }

    ngOnInit() {
        this.customTemplate = this.dialogOptions.dataModel.customTemplate;
        this.CancelButtonText = this.dialogOptions.dataModel.translations.CancelButtonText;
        this.ConfirmButtonText = this.dialogOptions.dataModel.translations.ConfirmButtonText;
    }

    cancelClicked(): void {
        this.dynamicDialogRef.close();
        this.dynamicDialogRef.destroy();
    }

    confirmClicked(): void {
        this.dynamicDialogRef.close(this.Options);
    }
}
