import { createAction, props } from '@ngrx/store';
import { DiscussionCommentsWidgetState } from '../../../AppState';
import { DiscussionCommentCreateDTO } from '../../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';
import { DiscussionCommentViewDTO } from '../../../data-transfer-objects/discussion-comment/discussion-comment-view-dto';

//load
export const loadDiscussionCommentsRequest = createAction('[Discussions] Load Discussion Comments Request',
    props<{
        discussionId: string,
        pageId: string,
        entityId: string
    }>());

export const loadDiscussionCommentsRequestDone = createAction('[Discussions] Load Discussion Comments Done',
    props<{
        discussionCommentsWidgetState: DiscussionCommentsWidgetState
    }>());

export const loadDiscussionCommentsRequestError = createAction('[Discussions] Load Discussion Comments Error',
    props<{
        discussionCommentsWidgetState: DiscussionCommentsWidgetState
    }>());

//add
export const addDiscussionCommentRequest = createAction('[Discussions] Add Discussion Comments Request',
    props<{
        discussionId: string,
        pageId: string,
        entityId: string,
        discussionCommentCreateDto: DiscussionCommentCreateDTO
    }>());

export const addDiscussionCommentRequestDone = createAction('[Discussions] Add Discussion Comments Done',
    props<{
        discussionCommentViewDTO: DiscussionCommentViewDTO
    }>());

export const addDiscussionCommentRequestError = createAction('[Discussions] Add Discussion Comments Error',
    props<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        addError: any
    }>());

//reset
export const resetDiscussionComments = createAction('[Discussions] Reset Discussion Comments');
