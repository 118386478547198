import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState, PageConfigurationState } from '../../../AppState';
import { PageConfigurationViewDTO } from '../../../data-transfer-objects/configuration/page-configuration-view.dto';
import { ConfigurationHttpService } from '../../../services/http/configuration-http.service';
import { getPageConfigurationState } from '../configuration.selectors';
import { loadPageConfigurationDone, loadPageConfigurationError, loadPageConfigurationRequest } from './site-configuration.actions';

@Injectable()
export class SiteConfigurationEffects {


    pageConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPageConfigurationRequest),
            withLatestFrom(
                this.store.select(getPageConfigurationState)
            ),
            switchMap(([action, state]) => {

                if (state.length > 0) {
                    const pageConfigurationState = state.find(x => x.dataSourceId === action.pageId);

                    if (pageConfigurationState) {
                        return of(pageConfigurationState.StateModel).pipe(map(() => {
                            return loadPageConfigurationDone({
                                pageId: action.pageId,
                                pageConfigurationState: pageConfigurationState.StateModel
                            });
                        }))
                    }
                }

                return this.configurationService.GetPageConfiguration(action.pageId).pipe(
                    map((pageConfiguration: PageConfigurationViewDTO) => {
                        return loadPageConfigurationDone({
                            pageId: action.pageId,
                            pageConfigurationState: this.buildPageConfigurationState(null, pageConfiguration)
                        });
                    }),
                    catchError((err) => {
                        return of(
                            loadPageConfigurationError(
                                {
                                    pageId: action.pageId,
                                    pageConfigurationState: this.buildPageConfigurationState(err, null)
                                }
                            )
                        );
                    })
                );
            })
        ));

    constructor(
        private actions$: Actions,
        private configurationService: ConfigurationHttpService,
        private store: Store<AppState>
    ) { }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private buildPageConfigurationState(getError: any, pageConfigurationViewDTO: PageConfigurationViewDTO): PageConfigurationState {
        return {
            getError: getError,
            viewModel: pageConfigurationViewDTO
        }
    }

}
