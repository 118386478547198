export enum ShapeValidationCheck {
    Rectilinear = 1,
    AspectRatio,
    Grid,
    MinArea,
    MaxArea,
    MinVertexCount,
    MaxVertexCount,
    NotDonut,
    MaxPartCount,
    WithinComparisonShape,
    ComparisonShapeWithinShape,
    MinRelativeArea,
    MaxRelativeArea,
    MinRelinquishedPartArea,
    MaxRelinquishedPartArea,
    TotalLandOwnership,
    LicenseOverlaps,
    AgreementOverlaps,
    RestrictedAreaOverlaps,
    MapReferenceOverlaps,
    PossibleResultShapeSliver,
    GeometryType
}