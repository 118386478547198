import { createAction, props } from '@ngrx/store';
import { ShapeViewDTO } from 'src/app/data-transfer-objects/shape/shape-view-dto';
import { DocumentWidgetState, ShapeWidgetState } from '../../../AppState';
import { DocumentQueryDefinitionConfigurationViewDTO } from '../../../data-transfer-objects/configuration/list-configuration/query-definitions/document-query-definition-configuration-view-dto';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentViewDTO } from '../../../data-transfer-objects/document/document-view-dto';

//Documents
export const updateActionInlineDocumentDone = createAction('[ActionDetailsInline] Update Action Document Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const updateActionInlineDocumentRequest = createAction('[ActionDetailsInline] Update Action Document Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, documentBlob: File, dataSourceId: string, widgetId: string }>());
export const updateActionInlineDocumentError = createAction('[ActionDetailsInline] Update Action Document Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const addActionInlineDocumentDone = createAction('[ActionDetailsInline] Add Action Document Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const addActionInlineDocumentRequest = createAction('[ActionDetailsInline] Add Action Document Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, documentBlob: File, pageId: string, widgetId: string, dataSourceId: string }>());
export const addActionInlineDocumentError = createAction('[ActionDetailsInline] Add Action Document Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const getActionInlineDocumentsRequest = createAction('[ActionDetailsInline] Get Action Document Request', props<{ entityId: string, pageId: string, widgetId: string, dataSourceId: string, filters: DocumentQueryDefinitionConfigurationViewDTO }>());
export const getActionInlineDocumentsDone = createAction('[ActionDetailsInline] Get Action Document Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const getActionInlineDocumentsError = createAction('[ActionDetailsInline] Get Action Document Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const actionDocumentsResetState = createAction('[ActionDetailsInline] Action Document Reset State');

//Shapes
export const getActionShapeRequest = createAction('[ActionDetailsInline] Get Action Shape Request', props<{ pageId: string, actionId: string, actionTypeId: string }>());
export const getActionShapeDone = createAction('[ActionDetailsInline] Get Action Shape Done', props<{ shapeWidgetState: ShapeWidgetState }>());
export const getActionShapeError = createAction('[ActionDetailsInline] Get Action Shape Error', props<{ shapeWidgetState: ShapeWidgetState}>());

export const updateActionShapeRequest = createAction('[ActionDetailsInline] Update Action Shape Request', props < { pageId: string, shapeViewDTO: ShapeViewDTO, actionId: string, actionTypeId: string  }>());
export const updateActionShapeDone = createAction('[ActionDetailsInline] Update Action Shape Done', props<{ shapeWidgetState: ShapeWidgetState }>());
export const updateActionShapeError = createAction('[ActionDetailsInline] Update Action Shape Error', props<{ shapeWidgetState: ShapeWidgetState }>());

export const actionShapeResetState = createAction('[ActionDetailsInline] Action Shape Reset State');
