import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import {
    DocumentWidgetState,
    UserDetailsWidgetState
} from '../../../AppState';
import { DocumentCreateDto } from '../../../data-transfer-objects/document/document-create-dto';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentReplaceDto } from '../../../data-transfer-objects/document/document-replace-dto';
import { UserUpdateDTO } from '../../../data-transfer-objects/user/user-update-dto';
import { OrderByDirection } from '../../../enums/comparator-enum';
import {
    OrderByModel,
    PaginationModel
} from '../../../models/pagination-models';
import { DocumentService } from '../../../services/deprecated/document.service';
import { UserHttpService } from '../../../services/http/user-http.service';
import {
    addDocumentDone,
    addDocumentError,
    addDocumentRequest,
    getDocumentsDone,
    getDocumentsError,
    getDocumentsRequest,
    getUserDetailsDone,
    getUserDetailsError,
    getUserDetailsRequest,
    updateAndGetUserDetailsRequest,
    updateDocumentDone,
    updateDocumentError,
    updateDocumentRequest,
    updateUserDetailsDone,
    updateUserDetailsError,
    updateUserDetailsRequest
} from './user-details-inline.actions';

@Injectable()
export class UserDetailsEffects {
    //Documents
    addDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addDocumentRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentCreateDto: DocumentCreateDto = new DocumentCreateDto(
                    action.documentViewDTO.Id,
                    action.documentViewDTO.DocumentTypeId,
                    action.parentEntityId,
                    documentViewDTO.LastUpdatedDate,
                    action.documentBlob,
                    documentViewDTO.FileName
                );

                return this.documentService
                    .addDocument(documentCreateDto, action.pageId, action.widgetId)
                    .pipe(
                        map(() => {
                            const documentList: DocumentListViewDTO[] = Object.assign(
                                [],
                                action.documentListViewDTO
                            );

                            const documentDraftWdigetState: DocumentWidgetState = {
                                AddDocumentsError: null,
                                DocumentDrafts: documentList,
                                GetDocumentsError: null,
                                UpdateDocumentsError: null,
                                DocumentAdded: true,
                                DocumentReplaced: false,
                            };

                            return addDocumentDone({
                                documentDraftWidgetState: documentDraftWdigetState,
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                            });
                        }),
                        catchError((err) => {
                            return of(
                                addDocumentError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentDraftWidgetState: {
                                        AddDocumentsError: err,
                                        DocumentDrafts: null,
                                        GetDocumentsError: null,
                                        UpdateDocumentsError: null,
                                        DocumentAdded: false,
                                        DocumentReplaced: false,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    getDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDocumentsRequest),
            mergeMap((action) => {
                const orderByModels: OrderByModel[] = [];

                const orderByModel: OrderByModel = {
                    Field: 'Uploadeddate',
                    OrderByDirection: OrderByDirection.Ascending,
                };

                orderByModels.push(orderByModel);

                const paginationModel: PaginationModel = {
                    Limit: 100,
                    OrderByList: orderByModels,
                    Page: 1,
                    ShowAll: false
                };

                return this.documentService
                    .getDocumentsByWidgetContextAndSearch(
                        action.entityId,
                        paginationModel,
                        action.pageId,
                        action.widgetId,
                        action.filters
                    )
                    .pipe(
                        map((documents) => {
                            return getDocumentsDone({
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                                documentDraftWidgetState: {
                                    DocumentDrafts: documents.Models,
                                    GetDocumentsError: null,
                                    UpdateDocumentsError: null,
                                    AddDocumentsError: null,
                                    DocumentAdded: false,
                                    DocumentReplaced: false,
                                },
                            });
                        }),
                        catchError((err) => {
                            return of(
                                getDocumentsError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentDraftWidgetState: {
                                        DocumentDrafts: null,
                                        GetDocumentsError: err,
                                        UpdateDocumentsError: null,
                                        AddDocumentsError: null,
                                        DocumentReplaced: false,
                                        DocumentAdded: false,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    //User Details
    getUserDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getUserDetailsRequest),
            switchMap((action) => {
                return this.userService.getUserDetails(action.userId).pipe(
                    map((userDetailsFromServer) => {
                        return getUserDetailsDone({
                            userDetailsWidgetState: {
                                UserDetailsViewDTO: userDetailsFromServer,
                                GetUserDetailsError: null,
                                UpdateUserDetailsError: null,
                            },
                        });
                    }),
                    catchError((err) => {
                        return of(
                            getUserDetailsError({
                                userDetailsWidgetState: {
                                    UserDetailsViewDTO: null,
                                    GetUserDetailsError: err,
                                    UpdateUserDetailsError: null,
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    updateAndGetUserDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAndGetUserDetailsRequest),
            switchMap((action) => {
                let updateUserDetailsDTO: UserUpdateDTO;
                // eslint-disable-next-line prefer-const
                updateUserDetailsDTO = {
                    ...updateUserDetailsDTO,
                    ...action.userDetails,
                };
                updateUserDetailsDTO.Id = action.userId;

                return this.userService.updateUser(updateUserDetailsDTO).pipe(
                    map(() => {
                        return getUserDetailsRequest({ userId: updateUserDetailsDTO.Id });
                    }),
                    catchError((err) => {
                        return of(
                            updateUserDetailsError({
                                userDetailsWidgetState: {
                                    UserDetailsViewDTO: null,
                                    GetUserDetailsError: null,
                                    UpdateUserDetailsError: err,
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    updateDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDocumentRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentReplaceDto: DocumentReplaceDto = new DocumentReplaceDto(
                    documentViewDTO.Id,
                    documentViewDTO.DocumentTypeId,
                    action.parentEntityId,
                    documentViewDTO.DateUploaded,
                    action.documentBlob,
                    documentViewDTO.FileName
                );

                return this.documentService.replaceDocument(documentReplaceDto).pipe(
                    map(() => {
                        const documentList: DocumentListViewDTO[] = Object.assign(
                            [],
                            action.documentListViewDTO
                        );

                        const documentDraftWdigetState: DocumentWidgetState = {
                            AddDocumentsError: null,
                            DocumentDrafts: documentList,
                            GetDocumentsError: null,
                            UpdateDocumentsError: null,
                            DocumentReplaced: true,
                            DocumentAdded: false,
                        };

                        return updateDocumentDone({
                            documentDraftWidgetState: documentDraftWdigetState,
                            dataSourceId: action.dataSourceId,
                            widgetId: action.widgetId,
                        });
                    }),
                    catchError((err) => {
                        return of(
                            updateDocumentError({
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                                documentDraftWidgetState: {
                                    AddDocumentsError: null,
                                    DocumentDrafts: null,
                                    GetDocumentsError: null,
                                    DocumentAdded: false,
                                    DocumentReplaced: false,
                                    UpdateDocumentsError: err,
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    updateUserDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserDetailsRequest),
            switchMap((action) => {
                let updateUserDetailsDTO: UserUpdateDTO;
                // eslint-disable-next-line prefer-const
                updateUserDetailsDTO = {
                    ...updateUserDetailsDTO,
                    ...action.userDetails,
                };
                updateUserDetailsDTO.Id = action.userId;

                return this.userService.updateUser(updateUserDetailsDTO).pipe(
                    map(() => {
                        const userWidgetState: UserDetailsWidgetState = {
                            UserDetailsViewDTO: action.userDetails,
                            GetUserDetailsError: null,
                            UpdateUserDetailsError: null,
                        };

                        return updateUserDetailsDone({
                            userDetailsWidgetState: userWidgetState,
                        });
                    }),
                    catchError((err) => {
                        return of(
                            updateUserDetailsError({
                                userDetailsWidgetState: {
                                    UserDetailsViewDTO: action.userDetails,
                                    GetUserDetailsError: null,
                                    UpdateUserDetailsError: err,
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private userService: UserHttpService,
        private documentService: DocumentService
    ) { }
}
