import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmbedConfigurationViewDTO } from '../../data-transfer-objects/power-bi/embed-configuration-view-dto';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { BASE_URL } from '../../helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class PowerBIHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public GetEmbedAccessToken(embedConfiguration: EmbedConfigurationViewDTO): Observable<string> {
        return this.PostAuthenticated<string>(`${this.baseUrl}api/powerbiaccesstoken`, embedConfiguration, this.GetStandardHeaders(), [], null, [HttpStatusCode.INTERNAL_SERVER_ERROR]);
    }
}
