/* eslint-disable */

import {
  Component,
  HostBinding,
  EventEmitter,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { BootstrapHelper } from '../../../../../helpers/bootstrap-helper';

declare let $: any;

@Component({
  selector: 'portal-overlay',
  templateUrl: './portal-overlay.component.html',
  host: {
    class: 'overlay-panel collapse',
  },
})
export class PortalOverlayComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    window.onscroll = () => {
      if (window.scrollY > 150) {
        this.hideOverlay = false;
      } else {
        this.hideOverlay = true;
      }
    };
  }

  @HostBinding('class.hide-overlay')
  public hideOverlay: boolean = true;

  @HostBinding('class.show')
  public visible: boolean = false;

  @ViewChild('infoTooltip', {
    read: ElementRef,
  })
  private _infoTooltip: ElementRef;

  @ViewChild('loadingReference', {
    read: ViewContainerRef,
  })
  public LoadingReference: ViewContainerRef;

  public DiscardClicked: EventEmitter<any> = new EventEmitter<any>();

  public _mainText: string = '';
  public _secondaryText: string = '';
  public _tooltipTitle: string = '';

  constructor() {}

  public _discard(): void {
    this.DiscardClicked.emit(null);
  }

  public ShowOverlay(
    mainText: string,
    secondaryText: string,
    tooltipText: string
  ): void {
    this._mainText = mainText;
    this._secondaryText = secondaryText;
    this._tooltipTitle = tooltipText;
    this.visible = true;

    BootstrapHelper.InitializeTooltip(
      this._infoTooltip.nativeElement,
      $,
      this._tooltipTitle,
      'bottom'
    );
  }

  public HideOverlay(): void {
    this.visible = false;
  }
}
