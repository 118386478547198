import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getCompanyListState } from '../../state/widgets.selectors';
import { AppState, CompanyListState } from '../../AppState';
import { AbstractCompanyListFacade } from '../abstract/abstract-company-list.facade';
import { getCompaniesRequest, resetCompanies } from '../../state/company/company.actions';

@Injectable()
export class CompanyListFacade extends AbstractCompanyListFacade {
    
    constructor(private store: Store<AppState>) {
        super();
    }
    
    public ResetState(): void {
        this.store.dispatch(resetCompanies());
    }
    
    public GetRepresentativesBySearch(): Observable<CompanyListState> {
        const representativeWidgetState = this.store.pipe(select(getCompanyListState));
        return representativeWidgetState;
    }
    public LoadRepresentativesBySearch(): void {
        this.store.dispatch(getCompaniesRequest({ FormEditStyle: this.FormEditStyle, UserRepresentationListWidgetConfiguration: this.UserRepresentationListWidgetConfiguration }));
    }
}