import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GuidHelper } from 'src/app/helpers/guid-helper';
import { PaginationResultModel } from 'src/app/models/pagination-models';
import { ListState, PartyListWidgetStateModel } from '../../../../../../AppState';
import { PartyListWidgetConfigurationViewDTO } from '../../../../../../data-transfer-objects/configuration/widgets/party-list-widget-configuration-view-dto';
import { PartyViewDTO } from '../../../../../../data-transfer-objects/party/party-view-dto';
import { StyleVariants } from '../../../../../../enums/bootstrap/style-variants';
import { ToastService } from '../../../../../../services/deprecated/toast.service';
import { IListWidget, NavigateWrapper, OnLoadWrapper } from '../list-widget-interface';
import { AbstractPartyListFacade } from '../../../../../../facade/abstract/abstract-party-list.facade';

@Component({
    selector: 'fw-party-list-widget',
    templateUrl: './party-list-widget.component.html'
})
export class PartyListWidgetComponent implements OnInit, OnDestroy, IListWidget<PartyViewDTO> {

    public PaginationResultModel: PaginationResultModel<PartyViewDTO>;
    public DataSourceId: string;
    public ErrorMessageResourceId: string = '';

    @Input()
    public PartyListWidgetConfigurationViewDTO: PartyListWidgetConfigurationViewDTO;    

    private subcriptions: Subscription[] = [];
    private onLoadWrapper: OnLoadWrapper;
    private stateEntry: ListState<PartyListWidgetStateModel>;

    constructor(
        private toastService: ToastService,
        private router: Router,
        private abstractPartyListFacade: AbstractPartyListFacade
    ) { }
  
    public OnLoad(onLoadWrapper: OnLoadWrapper): void {
        this.onLoadWrapper = onLoadWrapper;
        this.abstractPartyListFacade.WidgetId = this.PartyListWidgetConfigurationViewDTO.Id;
        this.abstractPartyListFacade.LoadListItems(this.PartyListWidgetConfigurationViewDTO.Fields, onLoadWrapper.paginationModel, onLoadWrapper.searchModel, this.PartyListWidgetConfigurationViewDTO.PartyFilter, this.DataSourceId);
    }

    ngOnDestroy(): void {
        if (this.subcriptions) {
            this.subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
    }

    ngOnInit(): void {

        this.DataSourceId = GuidHelper.NewGuid();

        const getPartyListSubscription = this.abstractPartyListFacade.GetListItems().subscribe((partyListWidget: ListState<PartyListWidgetStateModel>[]) => {

            if (!partyListWidget || partyListWidget.length == 0) {
                return;
            }

            this.stateEntry = partyListWidget.find((listState) => listState.dataSourceId == this.DataSourceId);

            if (this.stateEntry) {

                if (this.stateEntry.StateModel.error) {

                    this.ErrorMessageResourceId = 'Lists.GenericGetError';
                    this.toastService.ShowToast([{
                        Message: this.ErrorMessageResourceId,
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,
                        MessageParameters: null
                    }], StyleVariants.Danger, '', true);

                    this.PaginationResultModel = this.stateEntry.StateModel.paginationResult;

                } else {

                    this.ErrorMessageResourceId = null;
                    this.PaginationResultModel = this.stateEntry.StateModel.paginationResult;
                    
                }
            }
        });

        this.subcriptions.push(getPartyListSubscription);

    }
}
