import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageHttpService } from 'src/app/services/http/language-http.service';
import { SiteSettingsService } from 'src/app/services/deprecated/site-settings.service';
import { InputControlBase } from '../../input-controls/input-control-base';

@Component({
    selector: 'fw-read-only-text-field',
    templateUrl: './read-only-text-field.component.html',
    host: { 'class': 'inline-edit' }
})
export class ReadOnlyTextFieldComponent extends InputControlBase<string> {
    constructor(
        protected transloco: TranslocoService,
        private siteSettingsService: SiteSettingsService,
        private languageHttpService: LanguageHttpService) {
        super(transloco);
    }

    public get dateFormat(): string {
        return this.siteSettingsService.dateFormat;
    }

    public get locale(): string {
        return this.languageHttpService.getCurrentLanguageLocale();
    }

}
