<div [ngStyle]="getBackground()" [ngClass]="getBackgroundWidth()"></div>
<div class="section" [ngClass]="getSectionStyling()">
    <ng-template ngFor let-container [ngForOf]="columnSectionConfiguration.Containers" let-containerIndex="index">
        <fw-container [containerConfiguration]="container"
                      [FormEditStyle]="formEditStyle"
                      [ngClass]="getContainerClass(containerIndex)"
                      [ngStyle]="getContainerStyle(containerIndex)">            
        </fw-container>        
        <div [ngClass]="{'line-break':(ShowLineBreak),'line-break-sm':(ShowLineBreakMobile),'line-break-md':(ShowLineBreakTablet)}"></div>
    </ng-template>
</div>