import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { StyleVariants } from 'src/app/enums/bootstrap/style-variants';
import { MessageRouterLink } from '../../models/message-router-link';
import { ToastComponent } from '../../modules/static/generics/components/toast/toast.component';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    private _toastFactory: ComponentFactory<ToastComponent>;
    private _viewRef: ViewContainerRef;

    constructor(private _componentFactoryResolver: ComponentFactoryResolver) {
        this._toastFactory = this._componentFactoryResolver.resolveComponentFactory(ToastComponent);
    }

    public Initialize(viewRef: ViewContainerRef): void {

        this._viewRef = viewRef;

    }

    public ShowToast(messages: MessageRouterLink[], variant: StyleVariants): ComponentRef<ToastComponent>;
    public ShowToast(messages: MessageRouterLink[], variant: StyleVariants, loadingText: string): ComponentRef<ToastComponent>;
    public ShowToast(messages: MessageRouterLink[], variant: StyleVariants, loadingText: string, autoClose: boolean): ComponentRef<ToastComponent>;
    public ShowToast(messages: MessageRouterLink[], variant: StyleVariants, loadingText: string, autoClose: boolean): ComponentRef<ToastComponent>
    public ShowToast(messages: MessageRouterLink[], variant: StyleVariants, loadingText: string, autoClose: boolean, translate: boolean): ComponentRef<ToastComponent>
    public ShowToast(messages: MessageRouterLink[], variant: StyleVariants, loadingText: string = null, autoClose: boolean = true, translate: boolean = true): ComponentRef<ToastComponent> {

        const toastRef: ComponentRef<ToastComponent> = this._viewRef.createComponent(this._toastFactory);

        toastRef.instance.LoadingText = loadingText;
        toastRef.instance.Messages = messages;
        toastRef.instance.ToastVariant = variant;
        toastRef.instance.Translate = translate;

        toastRef.instance.ComponentRef = toastRef;

        if (autoClose)
            toastRef.instance.StartCloseCount();

        return toastRef;
    }
}
