import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WidgetConfigurationViewDTO } from '../../../../data-transfer-objects/configuration/widget-configuration-view-dto';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';
import { WidgetType } from '../../../../enums/configuration/widget-type';
import { Dictionary } from '../../../../helpers/dictionary-helper';

@Component({
  selector: 'fw-widget',
  templateUrl: './widget.component.html',
})
export class WidgetComponent {
  // necessary to access the properties on the template
  public widgetType = WidgetType;

  @Input()
  public CustomFormGroup: FormGroup;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public DictionaryData: Dictionary<any>;

  @Input()
  public FormEditStyle: FormEditStyle;

  @Input()
  public widgetConfiguration: WidgetConfigurationViewDTO;

  constructor() { }

  
}
