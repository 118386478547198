import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from 'src/app/helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from './error-manager.service';
import { ToastService } from './toast.service';
import { PaginationResultModel, PaginationModel } from 'src/app/models/pagination-models';
import { DocumentListViewDTO } from '../../data-transfer-objects/document/document-list-view-dto';
import { DocumentModel } from '../../models/document-model';
import { DocumentCreateDto } from '../../data-transfer-objects/document/document-create-dto';
import { DocumentReplaceDto } from 'src/app/data-transfer-objects/document/document-replace-dto';
import { DocumentQueryDefinitionConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/document-query-definition-configuration-view-dto';
import { HttpStatusCode } from '../../enums/http-status-codes';

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private _baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public GetActionDocumentsByLicenseId(licenseId: string, paginationModel: PaginationModel, context: string): Observable<PaginationResultModel<DocumentModel>> {

        return this.GetAuthenticated<PaginationResultModel<DocumentModel>>(`${this._baseUrl}api/license/${licenseId}/actiondocuments`, this.AddSearchAuthorizedContextHeader(this.GetStandardHeaders(), context), [], paginationModel);
    }

    public GetById(documentId: string): Observable<DocumentModel> {
        return this.GetAuthenticated<DocumentModel>(`${this._baseUrl}api/documents/${documentId}`, this.GetStandardHeaders());
    }

    public GetLandManagerDocuments(paginationModel: PaginationModel, context: string): Observable<PaginationResultModel<DocumentModel>> {
        return this.GetAuthenticated<PaginationResultModel<DocumentModel>>(`${this._baseUrl}api/documents/landManagerDocuments`, this.AddSearchAuthorizedContextHeader(this.GetStandardHeaders(), context), [], paginationModel);
    }

    public addDocument(documentCreateDto: DocumentCreateDto, pageId: string, widgetId: string): Observable<object> {
        const headers: HttpHeaders = this.AddSearchAuthorizedContextHeader(this.AddWidgetContextHeader(new HttpHeaders({}), widgetId), pageId);
        return this.PostAuthenticated<Object>(`${this._baseUrl}api/documents`, this.getFormData(documentCreateDto), headers, []);
    }   

    public getDocumentsByWidgetContextAndSearch(entityId: string, paginationModel: PaginationModel, pageId: string, widgetId: string, filters: DocumentQueryDefinitionConfigurationViewDTO): Observable<PaginationResultModel<DocumentListViewDTO>> {
        const headers: HttpHeaders = this.AddSearchAuthorizedContextHeader(this.AddWidgetContextHeader(this.GetStandardHeaders(), widgetId), pageId);
        return this.Post<PaginationResultModel<DocumentListViewDTO>>(`${this._baseUrl}api/documents/entity/${entityId}/page/${pageId}`, filters, headers, [], paginationModel, [HttpStatusCode.BAD_REQUEST, HttpStatusCode.NOT_FOUND]);
    }


    public replaceDocument(documentUpdateDto: DocumentReplaceDto): Observable<object> {
        return this.PutAuthenticated<Object>(`${this._baseUrl}api/documents`, this.getFormData(documentUpdateDto), new HttpHeaders(), []);
    }    
}
