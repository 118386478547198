<div class="d-desktop">
    <div class="language" *ngIf="shouldShowLanguageSelector">
        <span class="pi pi-globe"></span>
        <a href="javascript:void(0)" *ngFor="let language of languages" (click)="setLanguage(language)" [ngClass]="{ disabled: currentLanguageId === language.Id }">{{ language.Abbreviation }}</a>
    </div>
    <a href="javascript:void(0)" (click)="register()" *ngIf="!isAuthenticated()">{{ "UserMenu.Register" | transloco }}</a>
    <a href="javascript:void(0)" (click)="login()" *ngIf="!isAuthenticated()">{{ "UserMenu.Login" | transloco }}</a>
    <div class="dropdown" *ngIf="isAuthenticated()">
        <a href="javascript:void(0)" class="mobile-nav-toggle user" data-toggle="dropdown" title="{{ getTitle() }}">
            <div class="d-inline-flex flex-column mr-15 align-middle">
                <p>{{ getFullName() }}</p>
                <p class="representative" *ngIf="!userIsRepresentingSelf()">{{ getRepresentation() }}</p>
            </div>
            <span class="pi pi-user"></span>
        </a>
        <ul class="dropdown-menu right">
            <li *ngIf="hasRepresentation()">
                <a href="javascript:void(0)" (click)="switchRepresentation()">{{ "UserMenu.SwitchRepresentation" | transloco }}</a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="profilePageRedirect()">{{ "UserMenu.Profile" | transloco }}</a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="logout()">{{ "UserMenu.Logout" | transloco }}</a>
            </li>
        </ul>
    </div>
</div>
<div class="d-mobile">
    <a pStyleClass=".mobile-user-nav" toggleClass="mobile-nav-open" href="javascript:void(0)" routerLink="/page/register" *ngIf="!isAuthenticated()">{{ "UserMenu.Register" | transloco }}</a>
    <a pStyleClass=".mobile-user-nav" toggleClass="mobile-nav-open" href="javascript:void(0)" (click)="login()" *ngIf="!isAuthenticated()">{{ "UserMenu.Login" | transloco }}</a>
    <div *ngIf="isAuthenticated()">
        <span class="username" title="{{ getFullName() }}">{{ getFullName() }}</span>
        <ul>
            <li class="representing" *ngIf="hasRepresentation() && !userIsRepresentingSelf()">
                <p>{{ "UserMenu.Representing" | transloco }}</p>
                <p class="representative">{{ getRepresentation() }}</p>
            </li>
            <li *ngIf="hasRepresentation()">
                <a pStyleClass=".mobile-user-nav" toggleClass="mobile-nav-open" (click)="switchRepresentation()">{{ "UserMenu.SwitchRepresentation" | transloco }}</a>
            </li>
            <li>
                <a pStyleClass=".mobile-user-nav" toggleClass="mobile-nav-open" href="javascript:void(0)" (click)="profilePageRedirect()">{{ "UserMenu.Profile" | transloco }}</a>
            </li>
            <li>
                <a pStyleClass=".mobile-user-nav" toggleClass="mobile-nav-open" href="javascript:void(0)" (click)="logout()">{{ "UserMenu.Logout" | transloco }}</a>
            </li>
        </ul>
    </div>
    <div class="language" *ngIf="shouldShowLanguageSelector">
        <span class="pi pi-globe"></span>
        <a pStyleClass=".mobile-user-nav" toggleClass="mobile-nav-open" *ngFor="let language of languages" (click)="setLanguage(language)" [ngClass]="{ disabled: currentLanguageId === language.Id }">{{ language.Abbreviation }}</a>
    </div>
</div>
