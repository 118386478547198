<div>
    <span class="validation">{{ _invalidText }}</span>
    <input #textbox
           type="text"
           class="p-inputtext"
           [ngClass]="{ 'was-validated': !_isValid, 'is-invalid': !_isValid }"
           (blur)="onTouch()"
           (keyup)="onChange()"
           [(ngModel)]="_value"
           value="{{_value}}"
           [email]="_email"
           [attr.id]="NewComponentId('txt')"
           [attr.disabled]="!_disabled ? null : 'disabled'" placeholder=" " />
    <label [for]="NewComponentId('txt')">{{_labelText}}</label>
</div>