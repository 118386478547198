<ng-container *ngIf="AppLoaded">
    <header (window:resize)="onResize()" [class.fullpage-map]="isNavScrollable">
        <div>
            <a class="skip-link" (click)="scrollFocus('main-content')" href="javascript:void(0)">Skip to content</a>
            <a class="mobile-toggle toggle-fixed" *ngIf="isSubNavScrollable" pStyleClass=".mobile-main-nav" toggleClass="mobile-nav-fixed" href="javascript:void(0)" aria-label="Open main menu"><span class="pi pi-bars" aria-hidden="true"></span></a>
            <a class="mobile-toggle toggle-main" pStyleClass=".mobile-main-nav" toggleClass="mobile-nav-open" href="javascript:void(0)" aria-label="Open main menu"><span class="pi pi-bars" aria-hidden="true"></span></a>
            <a [routerLink]="'/'" class="brand" href="javascript:void(0)">
                <span class="brand-logo"></span>
                <img alt="" src="assets/img/ClientLogo.png">
            </a>
            <div class="main-nav" [class.invisible]="isNavScrollable">
                <ul id="main-menu" #mainMenu tabindex="0">
                    <portal-navigation-links [navigation-links]="menuItemApplicationService.MainMenuLinks"></portal-navigation-links>
                </ul>
            </div>
            <div class="d-desktop ml-auto" id="user-menu" #userMenu tabindex="0">
                <fw-user-menu></fw-user-menu>
            </div>
            <a class="mobile-toggle toggle-user ml-auto" pStyleClass=".mobile-user-nav" toggleClass="mobile-nav-open" href="javascript:void(0)" aria-label="Open user menu"><span class="pi pi-user" aria-hidden="true"></span></a>
            <div #mobileMainNav id="mobile-main-nav" class="mobile-main-nav">
                <div class="mobile-overlay" pStyleClass="@parent" toggleClass="mobile-nav-open"></div>
                <div class="mobile-overlay toggle-fixed" pStyleClass="@parent" toggleClass="mobile-nav-fixed"></div>
                <div class="mobile-nav-panel">
                    <div class="close-panel">
                        <a pStyleClass=".mobile-main-nav" toggleClass="mobile-nav-open" class="menu-close" aria-label="Close main menu" href="javascript:void(0)">
                            <span class="pi pi-times" aria-hidden="true"></span>
                        </a>
                        <a pStyleClass=".mobile-main-nav" toggleClass="mobile-nav-fixed" class="menu-close toggle-fixed" aria-label="Close main menu" href="javascript:void(0)">
                            <span class="pi pi-times" aria-hidden="true"></span>
                        </a>
                    </div>
                    <ul>
                        <portal-navigation-links [navigation-links]="menuItemApplicationService.MainMenuLinks"></portal-navigation-links>
                    </ul>
                </div>
            </div>
            <div #mobileUserNav id="mobile-user-nav" class="mobile-user-nav">
                <div class="mobile-overlay" pStyleClass="@parent" toggleClass="mobile-nav-open"></div>
                <div class="mobile-nav-panel float-right">
                    <div class="close-panel">
                        <a pStyleClass=".mobile-user-nav" toggleClass="mobile-nav-open" class="menu-close" aria-label="Close user menu" href="javascript:void(0)">
                            <span class="pi pi-times" aria-hidden="true"></span>
                        </a>
                    </div>
                    <fw-user-menu></fw-user-menu>
                </div>
            </div>
        </div>
        <div class="sub-main-nav" *ngIf="isNavScrollable" [class.invisible]="isSubNavScrollable">
            <ul id="sub-main-menu" #subMainMenu tabindex="0">
                <portal-navigation-links [navigation-links]="menuItemApplicationService.MainMenuLinks"></portal-navigation-links>
            </ul>
        </div>
    </header>
    <main id="main-content" #mainContent tabindex="0">
        <portal-overlay #portalOverlay></portal-overlay>
        <div breadcrumb></div>
        <div id="busy-indicator" [class]="(IsBusy ? '' : 'd-none')">{{'Main.Page.BusyIndicator'|transloco}}</div>
        <router-outlet></router-outlet>
    </main>
    <footer>
        <div class="footer-content">
            <p>Useful Links</p>
            <ul>
                <portal-navigation-links [navigation-links]="FooterMenuLinks"></portal-navigation-links>
            </ul>
        </div>
    </footer>
    <p-toast position="top-right" [baseZIndex]="5000"></p-toast>
    <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
</ng-container>
<ng-container *ngIf="!AppLoaded">
    <div *ngIf="!AppLoadFailed" class="app-loader">
        <span class="pi pi-spinner pi-spin"></span>
    </div>
    <div *ngIf="AppLoadFailed" class="app-loader">
        <p><span class="pi pi-minus-circle"></span>{{AppLoadFailureReason}}</p>
    </div>
</ng-container>
<div #modalReference></div>
<div class="toast-container">
    <div #toastReference></div>
</div>
