import { Component, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';
import { PropertyConfiguration } from '../../../../models/configuration/widgets/properties/property-configuration';

@Component({
  selector: 'fw-complex-form-handler',
  templateUrl: './complex-form-handler.component.html',
})
export class ComplexFormHandlerComponent {
  @Input()
  public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public ChildFormGroup: FormGroup;

  @Input()
  public FormEditStyle: FormEditStyle;

  @Input()
  public Properties: PropertyConfiguration[];

  constructor() {}
}
