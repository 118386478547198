import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AssetWidgetState } from '../../AppState';
import { AssetUpdateDTO } from '../../data-transfer-objects/asset/asset-update-dto';
import { AssetViewDTO } from '../../data-transfer-objects/asset/asset-view-dto';
import { DateHelper } from '../../helpers/date-helper';
import { AssetHttpService } from '../../services/http/asset-http.service';
import { AssetBehaviourContextModel } from 'src/app/models/behaviours/context-models/asset/asset-draft-behaviour-context-model';
import {
    getAssetDone,
    getAssetError,
    getAssetRequest,
    updateAssetDone,
    updateAssetError,
    updateAssetRequest,
    executeAssetBehaviourRequest,
    executeAssetBehaviourDone,
} from './asset.actions';

@Injectable()
export class NewAssetEffects {

    updateAssetDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAssetRequest),
            switchMap((action) => {

                let updateAssetDraftDTO: AssetUpdateDTO;
                // eslint-disable-next-line prefer-const
                updateAssetDraftDTO = {
                    ...updateAssetDraftDTO,
                    ...action.assetViewDTO,
                };

                return this.assetHttpService.updateAsset(action.pageId, action.assetViewDTO.Id, updateAssetDraftDTO).pipe(
                    map(() => {
                        const assetWidgetState: AssetWidgetState = {
                            AssetViewDTO: action.assetViewDTO,
                            UpdateAssetError: null,
                            GetAssetError: null,
                        };

                        return updateAssetDone({
                            assetWidgetState: assetWidgetState,
                        });
                    }),
                    catchError((err) => {
                        return of(
                            updateAssetError({
                                assetWidgetState: {
                                    AssetViewDTO: null,
                                    GetAssetError: null,
                                    UpdateAssetError: err,
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    getAssetDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAssetRequest),
            switchMap((action) => {
                return this.assetHttpService.getAsset(action.pageId, action.assetId).pipe(
                    map((assetDraftFromServer) => {
                        const assetDraft: AssetViewDTO =
                            this.ConfigureAssetDraft(assetDraftFromServer);

                        return getAssetDone({
                            assetWidgetState: {
                                AssetViewDTO: assetDraft,
                                GetAssetError: null,
                                UpdateAssetError: null,
                            },
                        });
                    }),
                    catchError((err) => {
                        return of(
                            getAssetError({
                                assetWidgetState: {
                                    AssetViewDTO: null,
                                    GetAssetError: err,
                                    UpdateAssetError: null,
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    executeAssetBehaviour$ = createEffect(() =>
        this.actions$.pipe(
            ofType(executeAssetBehaviourRequest),
            switchMap((action) => {
                const assetDraftBehaviourContext: AssetBehaviourContextModel = {
                    TaskId: action.taskId,
                    PageId: action.pageId,
                    ClientId: action.clientId,
                    AssetId: action.assetId
                };

                return this.assetHttpService
                    .executeAssetBehaviour(assetDraftBehaviourContext, action.widgetId, action.pageId)
                    .pipe(
                        map(() => {
                            return executeAssetBehaviourDone();
                        })
                    );
            })
        )
    );

    constructor(
        private actions$: Actions,
        public assetHttpService: AssetHttpService,
    ) { }

    private ConfigureAssetDraft(assetDraft: AssetViewDTO): AssetViewDTO {
        assetDraft.Date01 = DateHelper.InitialiseDate(assetDraft.Date01);
        assetDraft.Date02 = DateHelper.InitialiseDate(assetDraft.Date02);
        assetDraft.Date03 = DateHelper.InitialiseDate(assetDraft.Date03);
        assetDraft.Date04 = DateHelper.InitialiseDate(assetDraft.Date04);
        assetDraft.Date05 = DateHelper.InitialiseDate(assetDraft.Date05);
        assetDraft.Date06 = DateHelper.InitialiseDate(assetDraft.Date06);
        assetDraft.Date07 = DateHelper.InitialiseDate(assetDraft.Date07);
        assetDraft.Date08 = DateHelper.InitialiseDate(assetDraft.Date08);
        assetDraft.Date09 = DateHelper.InitialiseDate(assetDraft.Date09);
        assetDraft.Date10 = DateHelper.InitialiseDate(assetDraft.Date10);
        assetDraft.Date11 = DateHelper.InitialiseDate(assetDraft.Date11);
        assetDraft.Date12 = DateHelper.InitialiseDate(assetDraft.Date12);
        assetDraft.Date13 = DateHelper.InitialiseDate(assetDraft.Date13);
        assetDraft.Date14 = DateHelper.InitialiseDate(assetDraft.Date14);
        assetDraft.Date15 = DateHelper.InitialiseDate(assetDraft.Date15);

        return assetDraft;
    }

}
