import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { LanguageDetailViewDTO } from '../../data-transfer-objects/language/language-detail-view-dto';
import { SystemSettingViewDTO } from '../../data-transfer-objects/system-setting/system-setting-view-dto';
import { SystemSetting } from '../../enums/system-setting-enum';
import { BASE_URL } from '../../helpers/base-url';
import { HttpServiceBase } from '../class-definitions/http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { SystemSettingsService } from '../deprecated/system-settings-service.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageHttpService extends HttpServiceBase {

    readonly currentLanguageId = 'currentLanguageId';
    private readonly currentLanguageLocale = 'currentLanguageLocale';

    constructor(
        httpClient: HttpClient,
        @Inject(BASE_URL)
        private baseUrl: string,
        errorManager: ErrorManagerService,
        toastService: ToastService,
        private systemSettingService: SystemSettingsService
    ) {
        super(httpClient, errorManager, toastService);
    }

    public GetAll(): Observable<Array<LanguageDetailViewDTO>> {
        return new Observable<Array<LanguageDetailViewDTO>>((observer: Subscriber<Array<LanguageDetailViewDTO>>) => {
            super
                .Get<Array<LanguageDetailViewDTO>>(`${this.baseUrl}api/Language`, this.GetStandardHeaders())
                    .subscribe((model: Array<LanguageDetailViewDTO>) => {
                        observer.next(model);
                        observer.complete();
                    }, error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public GetById(languageId: number): Observable<LanguageDetailViewDTO> {
        return this.Get<LanguageDetailViewDTO>(`${this.baseUrl}api/Language/${languageId}`, this.GetStandardHeaders());
    }

    public GetCurrentLanguageId(): Observable<string> {
        return new Observable<string>((subscriber: Subscriber<string>) => {
            const languageId: string = localStorage.getItem(this.currentLanguageId);

            if (languageId) {
                subscriber.next(languageId);
                subscriber.complete();
            } else {
                this.systemSettingService.GetById(SystemSetting.DefaultLanguageId)
                    .subscribe((setting: SystemSettingViewDTO) => {
                        try {
                            subscriber.next(setting.Setting);
                            subscriber.complete();
                        } catch (error) {
                            subscriber.error(error);
                        }
                    }, error => {
                        subscriber.error(error);
                    });
            }
        });
    }

    public SetCurrentLanguageId(languageId: string): void {
        localStorage.setItem(this.currentLanguageId, languageId);
    }

    public setCurrentLanguageLocale(locale: string): void {
        localStorage.setItem(this.currentLanguageLocale, locale ?? 'en-US');
    }


    public getCurrentLanguageLocale(): string {
        return localStorage.getItem(this.currentLanguageLocale);
    }
}
