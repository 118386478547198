import { Component, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DynamicControlType } from '../../../../../enums/configuration/dynamic-control-type';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { PropertyConfiguration } from '../../../../../models/configuration/widgets/properties/property-configuration';

@Component({
    selector: 'fw-control-wrapper',
    templateUrl: './control-wrapper.component.html',
})
export class ControlWrapperComponent implements OnDestroy {
    //need to access properties in the markup
    public ControlType = DynamicControlType;
    ControlsRequestSubscription: Subscription;

    @Input()
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public AcceptGroupChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public CustomFormGroup: FormGroup;

    @Input()
    public DiscardChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public FormEditStyle: FormEditStyle;

    @Input()
    public Properties: PropertyConfiguration[];

    constructor() { }

    public GetChildFormGroup(propertyName: string): FormGroup {
        const formGroup = this.CustomFormGroup.controls[propertyName] as FormGroup;

        return formGroup;
    }

    ngOnDestroy(): void {
        if (this.ControlsRequestSubscription) {
            this.ControlsRequestSubscription.unsubscribe();
        }
    }
}
