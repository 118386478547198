import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { addDiscussionCommentRequest } from '../../state/discussion/discussion-comments/discussion-comments.actions';
import { loadDiscussionParticipantsRequest, resetDiscussionParticipants } from '../../state/discussion/discussion-participants/discussion-participants.actions';
import { getDiscussionParticipantsWidgetState } from '../../state/widgets.selectors';
import { AppState, DiscussionParticipantsWidgetState } from '../../AppState';
import { DiscussionCommentCreateDTO } from '../../data-transfer-objects/discussion-comment/discussion-comment-create-dto';
import { DiscussionViewDTO } from '../../data-transfer-objects/discussion/discussion-view-dto';
import { AbstractDiscussionTopicCommentFacade } from '../abstract/abstract-discussion-topic-comment.facade';

@Injectable()
export class DiscussionCommentFacade extends AbstractDiscussionTopicCommentFacade {

    public _setLoadSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public _discussion: DiscussionViewDTO;
    public _entityId: string;
    public _pageId: string;

    constructor(private store: Store<AppState>) {
        super();
    }

    public LoadDiscussionParticipantsSelector(): Observable<DiscussionParticipantsWidgetState> {
        const discussionParticipants = this.store.pipe(select(getDiscussionParticipantsWidgetState));
        return discussionParticipants;
    }
    public LoadDiscussionParticipants(): void {
        this.store.dispatch(loadDiscussionParticipantsRequest({
            entityId: this._entityId,
            pageId: this._pageId,
            topicId: this._discussion.Id
        }));
    }

    public AddDiscussionComment(discussionCommentCreateDto: DiscussionCommentCreateDTO): void {
        this.store.dispatch(addDiscussionCommentRequest({
            entityId: this._entityId,
            pageId: this._pageId,
            discussionId: this._discussion.Id,
            discussionCommentCreateDto: discussionCommentCreateDto
        }));
    }

    public ResetState(): void {
        this.store.dispatch(resetDiscussionParticipants());
    }
}
