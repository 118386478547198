import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { DocumentHttpService } from '../http/document-http.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentApplicationServiceService {

    constructor(private documentHttpService: DocumentHttpService) { }

    public downloadDocument(documentId: string, fileName: string, pageId: string): void {

        this.documentHttpService.getDocumentBlob(documentId, pageId).subscribe((document: Blob) => {
            FileSaver.saveAs(document, fileName);
        });

    }

}
