import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    UserDetailsWidgetConfiguration
} from '../../../../../data-transfer-objects/configuration/user-details-widget-configuration-view-dto';
import { Subscription } from 'rxjs';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { UserPersonViewDTO } from '../../../../../data-transfer-objects/user/user-person-view-dto';
import { ToastService } from '../../../../../services/deprecated/toast.service';
import { StyleVariants } from '../../../../../enums/bootstrap/style-variants';
import { ConfigurableControlsHelper } from '../../../../../helpers/configurable-controls-helper';
import { AbtractUserDetailsFacade } from '../../../../../facade/abstract/abstract-user-details.facade';

@Component({
    selector: 'fw-user-details-widget',
    templateUrl: './user-details-widget.component.html'
})

export class UserDetailsWidgetComponent implements OnInit, OnDestroy {

    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();
    public AcceptGroupChanges: EventEmitter<void> = new EventEmitter<void>();
    public DiscardChanges: EventEmitter<void> = new EventEmitter<void>();
    public FormGroup: FormGroup = new FormGroup({});

    @Input()
    public FormEditStyle: FormEditStyle;

    @Input()
    public UserDetailsWidgetConfiguration: UserDetailsWidgetConfiguration;

    private AcceptChangesSubscription: Subscription;
    private AcceptGroupChangesSubscription: Subscription;
    private DiscardChangesSubscription: Subscription;
    private GetUserDetailsSubscription: Subscription;
    private UpdateUserError: Subscription;
    private UpdateUserDetailError: Subscription;
    private UserViewModel: UserPersonViewDTO;

    private acceptedChangedExecutedFromThisWidget: boolean = false;
    private acceptedGroupChanges: boolean = false;

    constructor(private userDetailsFacade: AbtractUserDetailsFacade,
        private toastService: ToastService) {
    }

    ngOnDestroy() {
        this.GetUserDetailsSubscription.unsubscribe();
        this.AcceptChangesSubscription.unsubscribe();
        this.AcceptGroupChangesSubscription.unsubscribe();
        this.DiscardChangesSubscription.unsubscribe();
        this.UpdateUserError.unsubscribe();
        this.UpdateUserDetailError.unsubscribe();

        this.userDetailsFacade.ResetUserDetails();
    }

    ngOnInit(): void {

        this.userDetailsFacade.LoadUserDetails();

        this.UpdateUserError = this.userDetailsFacade.UpdateUserError().subscribe(error => {
            if (error && error.successfull === false) {

                if (this.acceptedChangedExecutedFromThisWidget === true) {
                    this.toastService.ShowToast([{
                        Message: 'UserDetails.Form.GenericSaveError',
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,
                        MessageParameters: null
                    }], StyleVariants.Danger, '', true);

                    this.acceptedChangedExecutedFromThisWidget = false;
                }

                this.userDetailsFacade.LoadUserDetails();
            }
        });

        this.UpdateUserDetailError = this.userDetailsFacade.UserDetailsSelector().subscribe(state => {
            if (state.UpdateUserDetailsError) {
                this.toastService.ShowToast([{
                    Message: 'UserDetails.Form.GenericSaveError',
                    RouterLink: null,
                    RouterText: null,
                    QueryParameters: null,
                    MessageParameters: null
                }], StyleVariants.Danger, '', true)
            }
        });

        this.GetUserDetailsSubscription = this.userDetailsFacade.UserDetailsSelector().subscribe(userDetailsWidgetState => {

            this.UserViewModel = userDetailsWidgetState.UserDetailsViewDTO;

            if (userDetailsWidgetState) {
                if (userDetailsWidgetState.GetUserDetailsError === null) {

                    this.UserViewModel = userDetailsWidgetState.UserDetailsViewDTO;

                    if (this.acceptedChangedExecutedFromThisWidget === false || this.acceptedGroupChanges === true) {
                        ConfigurableControlsHelper.PopulateFormControl({ ...this.UserViewModel, ...{ FullName: this.getFullName() } }, this.UserDetailsWidgetConfiguration.Properties, this.FormGroup);
                    }

                    this.acceptedChangedExecutedFromThisWidget = false;
                    this.acceptedGroupChanges = false;
                }
            }
        });

        this.AcceptChangesSubscription = this.AcceptChanges.subscribe(() => {
            this.acceptedChangedExecutedFromThisWidget = true;
            this.saveAcceptedChanges();
            this.userDetailsFacade.UpdateUser(this.UserViewModel);
        });

        this.AcceptGroupChangesSubscription = this.AcceptGroupChanges.subscribe(() => {
            this.acceptedGroupChanges = true;
            this.saveAcceptedChanges();
            this.userDetailsFacade.UpdateAndGetUser(this.UserViewModel);
        });

        this.DiscardChangesSubscription = this.DiscardChanges.subscribe(() => {
            ConfigurableControlsHelper.PopulateFormControl(this.UserViewModel, this.UserDetailsWidgetConfiguration.Properties, this.FormGroup);
        });

    }

    private getFullName(): string {
        return this.UserViewModel.Firstname + ' ' + this.UserViewModel.Surname;
    }

    private saveAcceptedChanges(): void {
        const userDtoFromForm = ConfigurableControlsHelper.ResolveGroupPropertyValues(this.UserDetailsWidgetConfiguration, this.FormGroup);

        this.UserViewModel = {
            ...this.UserViewModel, ...userDtoFromForm,
            Address: {
                ...this.UserViewModel.Address, ...userDtoFromForm.Address
            }
        }
    }
}
