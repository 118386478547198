import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ShapeUpdateDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-update-dto';
import { Observable } from 'rxjs';
import { EntityShapeCommandDefinitionConfigurationDTO } from '../../data-transfer-objects/shape/entity-shape-command-definition-configuration-dto';
import { ShapeViewDTO, ShapeWrapperViewDTO } from '../../data-transfer-objects/shape/shape-view-dto';
import { BASE_URL } from '../../helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class ShapeHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public getShape(pageId: string, entityId: string, typeId: string): Observable<ShapeWrapperViewDTO> {
        return this.GetAuthenticated<ShapeWrapperViewDTO>(`${this.baseUrl}api/shape/page/${pageId}/entity/${entityId}/entityType/${typeId}`, this.GetStandardHeaders());
    }

    public updateShape(pageId: string, entityId: string, typeId: string, shapeUpdateDTO: ShapeUpdateDTO): Observable<null> {
        return this.PutAuthenticated<null>(`${this.baseUrl}api/shape/page/${pageId}/entity/${entityId}/entityType/${typeId}`, shapeUpdateDTO, this.GetStandardHeaders(), []);
    }

}
