/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import { Message } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { severity_success } from '../../../../consts/severity-options';
import { DialogComponentBase } from '../../../../services/application/dialog-application.service';
import { ToastApplicationService } from '../../../../services/application/toast-application.service';
import { UserHttpService } from '../../../../services/http/user-http.service';

@Component({
  selector: 'forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
})
export class ForgotPasswordFormComponent extends DialogComponentBase<string> {
  public model: string = this.dialogOptions.dataModel;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private userHttpService: UserHttpService,
    private toastApplicationService: ToastApplicationService
  ) {
    super(dynamicDialogRef, dynamicDialogConfig);
  }

  public proceedClicked() {
    this.userHttpService.ForgotPassword(this.model).subscribe(() => {
      const message: Message = {
        summary: 'ForgotPassword.Form.ConfirmFormHeading',
        detail: 'ForgotPassword.Form.ConfirmFormSubHeading',
        severity: severity_success,
      };

      this.toastApplicationService.showToast([message]);
    });

    this.dynamicDialogRef.destroy();
  }
}
