import { Observable } from 'rxjs';
import { ListState } from '../../AppState';
import { PaginationModel } from '../../models/pagination-models';
import { SearchModel } from '../../models/search-model';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { FacadeValidation } from '../../modules/dynamic/widgets/types/validation/facade-validation';

export abstract class AbstractListFacade<TEntityQueryDefinition, TWidgetStateModel> extends FacadeValidation {

    public abstract PageId: string;

    public abstract GetListItems(): Observable<ListState<TWidgetStateModel>[]>;

    public abstract LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, queryDefinition: TEntityQueryDefinition, dataSourceId: string): void;

    //Should be using the LoadListItems once the user list is reworked
    public abstract LoadUserListItems(searchModel: SearchModel, paginationModel: PaginationModel, pageId: string, widgetId: string, dataSourceId: string): void;

}
