import { IsValid } from './is-valid';

export abstract class FacadeValidation {
    private _isValid: IsValid[] = [];

    public get IsValid(): boolean {
        return this._isValid.length === 0 || this._isValid.find((adf) => adf._isValid === false) == undefined;
    }

    public SetValidity(dataSourceId: string, isValid: boolean) {
        if (this._isValid.length > 0 &&
            this._isValid.find((iv) => iv._dataSourceId == dataSourceId)) {
            this._isValid.find((adf) => adf._dataSourceId == dataSourceId)._isValid = isValid;
        }
        else {
            this._isValid.push({ _dataSourceId: dataSourceId, _isValid: isValid });
        }
    }
}