import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BASE_URL } from 'src/app/helpers/base-url';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';
import { PaginationResultModel } from '../../models/pagination-models';
import { RepresentativeOfListViewDTO } from 'src/app/data-transfer-objects/representative/representative-list-view-dto';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { RepresentativeOfDTO } from '../../data-transfer-objects/representative/representative-of-dto';

@Injectable({
    providedIn: 'root'
})
export class RepresentativesHttpService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private _baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public AddRepresentatives(representativeOfDTO : RepresentativeOfDTO): Observable<null> {
        return this.PostAuthenticated<null>(`${this._baseUrl}api/people/addrepresentation`, representativeOfDTO, this.GetStandardHeaders(), [], null, [HttpStatusCode.BAD_REQUEST, HttpStatusCode.INTERNAL_SERVER_ERROR, HttpStatusCode.NOT_FOUND]);
    }

    public GetCompanyRepresentatives(companyId: string): Observable<PaginationResultModel<RepresentativeOfListViewDTO>> {
        return this.GetAuthenticated<PaginationResultModel<RepresentativeOfListViewDTO>>(`${this._baseUrl}api/companies/${companyId}/representatives`, this.GetStandardHeaders(), []);
    }

    public GetRepresentatives(personId: string): Observable<PaginationResultModel<RepresentativeOfListViewDTO>> {
        return this.GetAuthenticated<PaginationResultModel<RepresentativeOfListViewDTO>>(`${this._baseUrl}api/people/${personId}/representations`, this.GetStandardHeaders(), []);
    }

    public RemoveRepresentatives(representativeOfDTO: RepresentativeOfDTO): Observable<null> {
        return this.PostAuthenticated<null>(`${this._baseUrl}api/people/removerepresentation`, representativeOfDTO, this.GetStandardHeaders(), [], null, [HttpStatusCode.BAD_REQUEST, HttpStatusCode.INTERNAL_SERVER_ERROR, HttpStatusCode.NOT_FOUND]);
    }
}
