import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ScriptService {
    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    public injectJsScript(renderer: Renderer2, script: string): HTMLScriptElement {
        const scriptTag = renderer.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.text = script;
        renderer.appendChild(this.document.head, scriptTag);
        return scriptTag;
    }
}
