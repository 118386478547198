import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CheckboxConfiguration } from '../../../../../models/configuration/widgets/properties/controls/checkbox/checkbox-configuration';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';

@Component({
  selector: 'fw-form-edit-checkbox',
    templateUrl: './form-edit-checkbox.component.html',
    host: { 'class': 'form-field checkbox' }
})
export class FormEditCheckboxComponent extends ValidatableInputControlBase<boolean> implements OnInit {

    @Input()
    public Checkbox: CheckboxConfiguration;

    constructor(protected transloco: TranslocoService) {
        super(transloco);
    }

    ngOnInit(): void {
        this.ConfigureCheckboxValidation(this.Checkbox.Validation);
    }
}
