import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { InputNumberConfiguration } from '../../../../../models/configuration/widgets/properties/controls/inputnumber/input-number-configuration';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';

@Component({
  selector: 'fw-inline-edit-input-number',
    templateUrl: './inline-edit-input-number.component.html',
    host: { 'class': 'inline-edit' }
})
export class InlineEditInputNumberComponent extends ValidatableInputControlBase<number> implements OnInit {

    public ShowPercentage: boolean = false;

    @Input()
    public InputNumber: InputNumberConfiguration;

    constructor(protected transloco: TranslocoService) {
        super(transloco);
    }

    ngOnInit(): void {
        if (this.InputNumber) {
            this.ConfigureInputNumberValidation(this.InputNumber.Validation);
        }
    }

    public GetSuffix() : string{
        if (this.ShowPercentage === true) {
            return '%';
        } 

        return '';
    }

}
