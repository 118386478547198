import { ShapeViewDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-view-dto';
import { createAction, props } from '@ngrx/store';
import { ShapeValidationCreateDTO } from 'src/app/data-transfer-objects/shape/shape-validation-create-dto';
import { ActionWidgetState, DeleteJobState, DocumentWidgetState, RunShapeValidationState, ShapeValidationResultsState, ShapeWidgetState } from '../../../AppState';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentViewDTO } from '../../../data-transfer-objects/document/document-view-dto';
import { ShapeEntity } from '../../../enums/shape-entity';

export const createActionFormRequest = createAction(
    '[ActionDetails] Create Action Form Request',
    props<{ pageId: string; actionId: string }>()
);
export const createActionFormDone = createAction(
    '[ActionDetails] Create Action Form Done',
    props<{ actionDraftLoaded: boolean }>()
);
export const createActionFormError = createAction(
    '[ActionDetails] Create Action Form Error',
    props<{ actionDraftWidgetState: ActionWidgetState }>()
);
export const createActionFormResetState = createAction(
    '[ActionDetails] Create Action Form Reset State'
);
export const createActionFormIsSaving = createAction(
    '[ActionDetails] Action Form is Saving',
    props<{ IsSaving: boolean }>()
);
export const createActionFormIsSavingResetState = createAction(
    '[ActionDetails] Action Form is Saving reset state'
);

//Documents
export const getActionDetailsFormDocumentDraftsRequest = createAction('[ActionDetailsForm] Get Action Document Request', props<{ actionId: string, pageId: string, widgetId: string, dataSourceId: string }>());
export const getActionDetailsFormDocumentDraftsDone = createAction('[ActionDetailsForm] Get Action Document Done', props<{ documentWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const getActionDetailsFormDocumentDraftsError = createAction('[ActionDetailsForm] Get Action Document Error', props<{ documentWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const updateActionDetailsFormDocumentDraftRequest = createAction('[ActionDetailsForm] Update Document Draft Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, actionId: string, documentBlob: File, dataSourceId: string, widgetId: string }>());
export const updateActionDetailsFormDocumentDraftDone = createAction('[ActionDetailsForm] Update Document Draft Done', props<{ documentWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const updateActionDetailsFormDocumentDraftError = createAction('[ActionDetailsForm] Update Document Draft Error', props<{ documentWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const addActionDetailsFormDocumentDraftRequest = createAction('[ActionDetailsForm] Add Document Draft Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, documentBlob: File, pageId: string, actionId: string, dataSourceId: string, widgetId: string }>());
export const addActionDetailsFormDocumentDraftDone = createAction('[ActionDetailsForm] Add Document Draft Done', props<{ documentWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const addActionDetailsFormDocumentDraftError = createAction('[ActionDetailsForm] Add Document Draft Error', props<{ documentWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const createActionDetailsFormDocumentDraftResetState = createAction('[ActionDetailsForm] Document Draft Reset State');

//Shapes
export const getActionFormShapeRequest = createAction('[ActionDetailsForm] Get Action Shape Request', props<{ pageId: string, actionId: string, shapeEntity: ShapeEntity }>());
export const getActionFormShapeDone = createAction('[ActionDetailsForm] Get Action Shape Done', props<{ shapeWidgetState: ShapeWidgetState }>());
export const getActionFormShapeError = createAction('[ActionDetailsForm] Get Action Shape Error', props<{ shapeWidgetState: ShapeWidgetState }>());

export const updateActionFormShapeRequest = createAction('[ActionDetailsForm] Update Action Shape Request', props<{ pageId: string, shapeViewDTO: ShapeViewDTO, actionDraftId: string, typeId: string }>());
export const updateActionFormShapeDone = createAction('[ActionDetailsForm] Update Action Shape Done', props<{ shapeWidgetState: ShapeWidgetState }>());
export const updateActionFormShapeError = createAction('[ActionDetailsForm] Update Action Shape Error', props<{ shapeWidgetState: ShapeWidgetState }>());

export const actionShapeFormResetState = createAction('[ActionDetailsForm] Action Shape Reset State');

//Shape Validation
export const runActionFormShapeValidationRequest = createAction('[ActionDetailsForm] Run Shape Validation Request', props<{ shapeValidationCreateDTO: ShapeValidationCreateDTO }>());
export const runActionFormShapeValidationDone = createAction('[ActionDetailsForm] Run Shape Validation Done', props<{ runShapeValidationState: RunShapeValidationState }>());
export const runActionFormShapeValidationError = createAction('[ActionDetailsForm] Run Shape Validation Error', props<{ runShapeValidationState: RunShapeValidationState }>());
export const actionFormShapeValidationResetState = createAction('[ActionDetailsForm] Shape Validation Reset State');

export const getActionFormShapeValidationResultsRequest = createAction('[ActionDetailsForm] Get Shape Validation Results Request', props<{ jobId: number }>());
export const getActionFormShapeValidationResultsDone = createAction('[ActionDetailsForm] Get Shape Validation Results Done', props<{ getShapeValidationResultsState: ShapeValidationResultsState }>());
export const getActionFormShapeValidationResultsError = createAction('[ActionDetailsForm] Get Shape Validation Results Error', props<{ getShapeValidationResultsState: ShapeValidationResultsState }>());
export const actionFormShapeValidationResultsResetState = createAction('[ActionDetailsForm] Shape Validation Results Reset State');

//Job Deletion
export const actionFormDeleteJobRequest = createAction('[ActionDetailsForm] Delete Job Request', props<{ jobId: number }>());
export const actionFormDeleteJobDone = createAction('[ActionDetailsForm] Delete Job Done', props<{ deleteJobState: DeleteJobState }>());
export const actionFormDeleteJobError = createAction('[ActionDetailsForm] Delete Job  Error', props<{ deleteJobState: DeleteJobState }>());
