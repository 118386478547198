import {
    ChangeDetectorRef,
    Component, Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AssetListWidgetStateModel, ListState } from 'src/app/AppState';
import {
    AssetListViewDTO,
    AssetListWithRelationshipViewDTO
} from 'src/app/data-transfer-objects/asset/asset-list-view-dto';
import { AssetQueryDefinitionSearchViewDTO } from 'src/app/data-transfer-objects/configuration/list-configuration/query-definitions/asset-query-definition-search-view-dto';
import { AssetListWidgetConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/widgets/asset-list-widget-configuration-view-dto';
import { StyleVariants } from 'src/app/enums/bootstrap/style-variants';
import { PaginationResultModel } from 'src/app/models/pagination-models';
import { ToastService } from 'src/app/services/deprecated/toast.service';
import { GuidHelper } from '../../../../../helpers/guid-helper';
import { ObjectHelper } from '../../../../../helpers/object-helper';
import { BaseFormComponent } from '../../../../static/classes/base-form-component';
import { AbstractAssetListFacade } from '../../../../../facade/abstract/abstract-asset-list.facade';
import { NavigateWrapper, OnLoadWrapper } from './list-widget-interface';

@Directive()
export class BaseAssetListWidgetComponent extends BaseFormComponent<AssetListViewDTO> implements OnDestroy, OnInit {
    public PaginationResultModel: PaginationResultModel<AssetListViewDTO>;
    public DataSourceId: string;
    public errorMessageResourceId: string = null;
    public Form: NgForm;
    public SubmitVisible: boolean;
    public Submit: EventEmitter<AssetListViewDTO>;
    public Model: AssetListViewDTO;
    public _disabled: boolean;
    public paginationResultModelCopy: PaginationResultModel<AssetListWithRelationshipViewDTO>;

    @Input()
    public AssetListWidgetConfigurationViewDTO: AssetListWidgetConfigurationViewDTO;

    private GetAssetListSubscription: Subscription;

    public constructor(
        private abstractAssetListFacade: AbstractAssetListFacade,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private toastService: ToastService
    ) {
        super(cdr);
    }

    public ClearForm(): void { }
    public PopulateForm(): void { }
    public SetDisabled(disabled: boolean): void { }

    public NavigateByContext(navigateWrapper: NavigateWrapper): void {
        const destinationPage: string =
            navigateWrapper.fieldConfiguration.Navigate.DestinationPageId;

        let context: string = '';

        if (navigateWrapper.fieldConfiguration.Navigate.Asset.ListWidgetAsset) {
            context = navigateWrapper.model.Id;
        }

        this.router.navigate([`page/${destinationPage}`], {
            queryParams: {
                assetId: context,
            },
        });
    }

    public ngOnDestroy() {
        this.GetAssetListSubscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.DataSourceId = GuidHelper.NewGuid();

        this.GetAssetListSubscription = this.abstractAssetListFacade
            .GetListItems()
            .subscribe((assetListWidget: ListState<AssetListWidgetStateModel>[]) => {
                if (!assetListWidget || assetListWidget.length == 0) {
                    return;
                }

                const stateEntry = assetListWidget.find(
                    (alw) => alw.dataSourceId == this.DataSourceId
                );

                if (stateEntry) {
                    if (stateEntry.StateModel.error) {
                        this.errorMessageResourceId = 'Lists.GenericGetError';
                        this.toastService.ShowToast(
                            [
                                {
                                    Message: this.errorMessageResourceId,
                                    RouterLink: null,
                                    RouterText: null,
                                    QueryParameters: null,
                                    MessageParameters: null,
                                },
                            ],
                            StyleVariants.Danger,
                            '',
                            true
                        );

                        this.PaginationResultModel = stateEntry.StateModel.paginationResult;
                    } else {
                        this.errorMessageResourceId = null;
                        this.PaginationResultModel = stateEntry.StateModel.paginationResult;

                        this.paginationResultModelCopy = ObjectHelper.deepCopy(
                            this.PaginationResultModel
                        );

                        this.paginationResultModelCopy.Models.forEach((model) => {
                            model.SelectedRelationshipTypeId = '';
                        });
                    }
                }
            });
    }

    public OnLoad(onLoadWrapper: OnLoadWrapper): void {
        const assetQueryDefinitionSearchViewDTO: AssetQueryDefinitionSearchViewDTO =
        {
            SavedSearchId:
                this.AssetListWidgetConfigurationViewDTO.AssetQueryDefinition.SavedSearchId,
            Parties:
                this.AssetListWidgetConfigurationViewDTO.AssetQueryDefinition.Parties,
            RelatedAsset:
                this.AssetListWidgetConfigurationViewDTO.AssetQueryDefinition.RelatedAsset,
            RelatedAssetId:
                this.abstractAssetListFacade.AssetId
        };

        this.abstractAssetListFacade.LoadListItems(
            this.AssetListWidgetConfigurationViewDTO.Fields,
            onLoadWrapper.paginationModel,
            onLoadWrapper.searchModel,
            assetQueryDefinitionSearchViewDTO,
            this.DataSourceId
        );
    }
}
