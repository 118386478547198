import { Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, RetryContext } from '@microsoft/signalr';
import { from, Observable, ReplaySubject, Subject, Subscriber } from 'rxjs';
import { BASE_URL } from '../../helpers/base-url';
import { SessionApplicationService } from '../application/session-application.service';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    
    private hubConnection: HubConnection;

    constructor(@Inject(BASE_URL) private baseUrl: string, private sessionApplicationService: SessionApplicationService) { }

    public closeConnection(): void {
        this.hubConnection.stop();
    }

    public getClientId(): Observable<string> {
        this.hubConnection = this.getHubConnection();
        
        return from(this.hubConnection
            .start()
            .then<string>(() => {
                return this.hubConnection.invoke('getClientId').then(
                    (clientId: string) => {
                        return clientId;
                    }
                );
            }));
    }

    public getClientIdOnReconnect(subject: ReplaySubject<string>, connected: Subject<boolean>): void {
        this.hubConnection.onreconnected((clientId) => {
            subject.next(clientId);
            connected.next(true);
        });

        this.hubConnection.onreconnecting(error => {
            connected.next(false);
        });
    }

    public getHubConnection() {

        return this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${this.baseUrl}api/jobNotifications`)
            .configureLogging(LogLevel.None)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
                    if (retryContext.previousRetryCount <= 0) {
                        return 0;
                    } else if (retryContext.previousRetryCount <= 10) {
                        return 500;
                    } else if (retryContext.previousRetryCount <= 20) {
                        return 1000;
                    } else {
                        return 5000;
                    }
                }
            })
            .build();
    }

    public registerHandler<TResponse>(methodName: string): Observable<TResponse> {

        return new Observable((subscriber: Subscriber<TResponse>) => {
            this.hubConnection.on(methodName, (argData: TResponse) => {
                subscriber.next(argData);
            });
        })
    }
}
