import { Directive, EventEmitter, Input } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { FormEditStyle } from '../../../../enums/configuration/form-edit-style';
import { CalendarValidationConfiguration } from '../../../../models/configuration/widgets/properties/controls/calendar/calendar-validation-configuration';
import { CheckboxValidationConfiguration } from '../../../../models/configuration/widgets/properties/controls/checkbox/checkbox-validation-configuration';
import { ControlValidationConfiguration } from '../../../../models/configuration/widgets/properties/controls/control-validation-configuration';
import { DropdownValidationConfiguration } from '../../../../models/configuration/widgets/properties/controls/dropdown/dropdown-validation-configuration';
import { InputNumberValidationConfiguration } from '../../../../models/configuration/widgets/properties/controls/inputnumber/input-number-validation-configuration';
import { TextAreaValidationConfiguration, TextValidationConfiguration } from '../../../../models/configuration/widgets/properties/controls/textarea/text-area-validation-configuration';
import { TextFieldValidationConfiguration } from '../../../../models/configuration/widgets/properties/controls/textfield/text-field-validation-configuration';
import { StringLengthConfiguration } from '../../../../models/configuration/widgets/properties/validators/string-length-configuration';
import { ValidationDictionaryModel } from '../../../../models/validation/validation-dictionary-model';
import { InputControlBase } from './input-control-base';

@Directive()
export abstract class ValidatableInputControlBase<T> extends InputControlBase<T> {
    public FormEditStyles = FormEditStyle;
    public ValidationDictionary: ValidationDictionaryModel[] = [];
    public editMode = false;


    @Input()
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public FormEditStyle: FormEditStyle;

    private Validators: ValidatorFn[] = [];

    constructor(protected transloco: TranslocoService) {
        super(transloco);
    }

    public IsAcceptDisabled() {
        return !this.CustomFormGroup.controls[this.PropertyName].valid;
    }


    public onAcceptChanges() {

        this.editMode = false;

        this.Data = this.CustomFormGroup.controls[this.PropertyName].value;
        this.CustomFormGroup.controls[this.PropertyName].markAsPristine();
        this.AcceptChanges.emit();

    }

    public onCancelChanges() {
        this.editMode = false;
        this.CustomFormGroup.controls[this.PropertyName].setValue(this.Data);
    }

    onFocusOut(event) {
        if (!event.relatedTarget || event.relatedTarget.nodeName === 'DIV') {
            this.onCancelChanges();
        }
    }

    public onKeyDownEnter(event) {

        this.editMode = this.FormEditStyle !== this.FormEditStyles.ReadOnly
        //on enter accept is triggered hence this check is in place to prevent this behaviour and only accept when explicitly clicked
        if (event.target.nodeName !== 'BUTTON') {
            event.preventDefault();
        }
    }

    /*ValidationConfiguration*/

    protected ConfigureCheckboxValidation(validatonRules: CheckboxValidationConfiguration): void {
        this.GetAbstractControl();

        const resolvedPromise = Promise.resolve(null);
        resolvedPromise.then(() => {

            this.ClearValidators();

            this.ConfigureControlValidation(validatonRules);

            this.SetValidators();
        });
    }

    protected ConfigureCommodityValidation(validatonRules: ControlValidationConfiguration): void {

        this.ClearValidators();

        this.ConfigureControlValidation(validatonRules);

        this.SetValidators();
    }

    protected ConfigureDateTimeValidation(validatonRules: CalendarValidationConfiguration): void {
        this.GetAbstractControl();

        const resolvedPromise = Promise.resolve(null);
        resolvedPromise.then(() => {

            this.ClearValidators();

            this.ConfigureControlValidation(validatonRules);

            this.SetValidators();
        });
    }

    protected ConfigureDropdownValidation(validatonRules: DropdownValidationConfiguration): void {
        this.GetAbstractControl();

        const resolvedPromise = Promise.resolve(null);
        resolvedPromise.then(() => {

            this.ClearValidators();

            this.ConfigureControlValidation(validatonRules);

            this.SetValidators();
        });
    }

    protected ConfigureInputNumberValidation(validatonRules: InputNumberValidationConfiguration): void {
        this.GetAbstractControl();

        const resolvedPromise = Promise.resolve(null);
        resolvedPromise.then(() => {

            this.ClearValidators();

            this.ConfigureControlValidation(validatonRules);

            this.SetValidators();
        });
    }

    protected ConfigureTextAreaValidation(validatonRules: TextAreaValidationConfiguration): void {
        this.GetAbstractControl();

        const resolvedPromise = Promise.resolve(null);
        resolvedPromise.then(() => {

            this.ClearValidators();

            this.ConfigureTextValidation(validatonRules);

            this.SetValidators();
        });
    }

    protected ConfigureTextFieldValidation(validatonRules: TextFieldValidationConfiguration): void {

        this.GetAbstractControl();

        const resolvedPromise = Promise.resolve(null);
        resolvedPromise.then(() => {

            this.ClearValidators();

            this.ConfigureTextValidation(validatonRules);

            this.SetValidators();
        });

    }

    private AddRequiredValidator() {
        this.Validators.push(Validators.required);
        this.ValidationDictionary.push({ Validation: 'required', ErrorMessage: this.transloco.translate('Validation.Message.Required', { field: this.transloco.translate(this.Label) }) });
    }

    private AddStringLengthValidaton(stringLength: StringLengthConfiguration) {
        const maxLength = stringLength.MaxLength;
        if (maxLength) {
            this.Validators.push(Validators.maxLength(maxLength));
        }

        const minLength = stringLength.MinLength;
        if (minLength) {
            this.Validators.push(Validators.minLength(minLength));
        }

        if (minLength && maxLength) {
            if (minLength === maxLength) {
                this.ValidationDictionary.push({ Validation: 'maxlength', ErrorMessage: this.transloco.translate('Validation.Message.ExactLength', { exactLength: maxLength }) });
                this.ValidationDictionary.push({ Validation: 'minlength', ErrorMessage: this.transloco.translate('Validation.Message.ExactLength', { exactLength: minLength }) });
            }
            else {
                this.ValidationDictionary.push({ Validation: 'maxlength', ErrorMessage: this.transloco.translate('Validation.Message.MinAndMaxLength', { minLength: minLength, maxLength: maxLength }) });
                this.ValidationDictionary.push({ Validation: 'minlength', ErrorMessage: this.transloco.translate('Validation.Message.MinAndMaxLength', { minLength: minLength, maxLength: maxLength }) });
            }
        } else if (minLength && !maxLength) {
            this.ValidationDictionary.push({ Validation: 'minlength', ErrorMessage: this.transloco.translate('Validation.Message.MinLength', { minLength: minLength }) });
        } else if (!minLength && maxLength) {
            this.ValidationDictionary.push({ Validation: 'maxlength', ErrorMessage: this.transloco.translate('Validation.Message.MaxLength', { maxlength: maxLength }) });
        }
    }

    private ClearValidators() {
        //remove validators that PrimeNg automatically adds because we are handling validation ourselves.
        this.CustomFormGroup.get(this.PropertyName).clearValidators();
        this.CustomFormGroup.get(this.PropertyName).updateValueAndValidity();
    }

    private ConfigureControlValidation(validatonRules: ControlValidationConfiguration): void {
        if (validatonRules.Required === true) {
            this.AddRequiredValidator();
        }
    }

    private ConfigureTextValidation(validatonRules: TextValidationConfiguration): void {
        if (validatonRules.StringLength !== null) {
            this.AddStringLengthValidaton(validatonRules.StringLength);
        }

        this.ConfigureControlValidation(validatonRules);
    }

    private SetValidators() {
        this.CustomFormGroup.get(this.PropertyName).setValidators(this.Validators);
        this.CustomFormGroup.get(this.PropertyName).updateValueAndValidity();
    }

    /*ValidationConfiguration*/
}