/* eslint-disable */

import { Input, Directive } from '@angular/core';
import { KeyValue } from '@angular/common';
import { GuidHelper } from 'src/app/helpers/guid-helper';

@Directive()
export abstract class GenericComponentBase {
  @Input('associated-id') public AssociatedId: string = '';

  private _idList: KeyValue<string, string>[] = [];

  public NewComponentId(item: string): string {
    if (
      !this._idList.find((idItem: KeyValue<string, string>) => {
        return idItem.key === item;
      })
    )
      this._idList.push({
        key: item,
        value: GuidHelper.NewGuid(),
      });

    return this._idList.find((idItem: KeyValue<string, string>) => {
      return idItem.key === item;
    }).value;
  }

  @Input('label-text') _labelText: string;

  @Input('is-disabled') _disabled?: boolean;

  public constructor() {
    this.SetDefaults();
  }

  protected SetDefaults(): void {
    this._disabled = this._disabled === undefined ? false : this._disabled;
  }
}
