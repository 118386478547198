<ng-container *ngFor="let property of Properties">
    <fw-text-field-wrapper *ngIf="property.ControlType === ControlType.TextField"
                           [FormEditStyle]="FormEditStyle"
                           [CustomFormGroup]="CustomFormGroup"
                           [Property]="property"                         
                           [AcceptChanges]="AcceptChanges"></fw-text-field-wrapper>
    <fw-input-number-wrapper *ngIf="property.ControlType === ControlType.InputNumber"
                           [FormEditStyle]="FormEditStyle"
                           [CustomFormGroup]="CustomFormGroup"
                           [Property]="property"
                           [AcceptChanges]="AcceptChanges"></fw-input-number-wrapper>
    <fw-calendar-wrapper *ngIf="property.ControlType === ControlType.Calendar"
                           [FormEditStyle]="FormEditStyle"
                           [CustomFormGroup]="CustomFormGroup"
                           [Property]="property"
                           [AcceptChanges]="AcceptChanges"></fw-calendar-wrapper>
    <fw-checkbox-wrapper *ngIf="property.ControlType === ControlType.Checkbox"
                           [FormEditStyle]="FormEditStyle"
                           [CustomFormGroup]="CustomFormGroup"
                           [Property]="property"
                           [AcceptChanges]="AcceptChanges"></fw-checkbox-wrapper>
    <fw-text-area-wrapper *ngIf="property.ControlType === ControlType.TextArea"
                           [FormEditStyle]="FormEditStyle"
                           [CustomFormGroup]="CustomFormGroup"
                           [Property]="property"
                           [AcceptChanges]="AcceptChanges"></fw-text-area-wrapper>
    <fw-dropdown-wrapper *ngIf="property.ControlType === ControlType.Dropdown"
                           [FormEditStyle]="FormEditStyle"
                           [CustomFormGroup]="CustomFormGroup"
                           [Property]="property"
                           [AcceptChanges]="AcceptChanges"></fw-dropdown-wrapper>
    <fw-complex-form-handler *ngIf="property.ControlType === ControlType.ComplexProperty"
                             [FormEditStyle]="FormEditStyle"
                             [ChildFormGroup]="GetChildFormGroup(property.PropertyName)"
                             [Properties]="property.Properties"
                             [AcceptChanges]="AcceptChanges">
    </fw-complex-form-handler>
    <fw-control-group-wrapper *ngIf="property.ControlType === ControlType.PropertyGroup"                             
                             [CustomFormGroup]="CustomFormGroup"
                             [Property]="property"
                             [AcceptChanges]="AcceptGroupChanges"
                             [DiscardChanges]="DiscardChanges">
    </fw-control-group-wrapper>
    <fw-read-only-text-field *ngIf="property.ControlType === ControlType.ReadOnlyTextField"
                            [CustomFormGroup]="CustomFormGroup"
                            [PropertyName]="property.PropertyName"
                            [Label]="property.LabelResourceId"
                            [LabelStyle]="property.LabelStyle"
                            [ValueStyle]="property.ValueStyle">

    </fw-read-only-text-field>
</ng-container>
