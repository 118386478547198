import { Injectable } from '@angular/core';
import { ShapeUpdateDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-update-dto';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { DocumentWidgetState, ShapeWidgetState } from '../../../AppState';
import { DocumentCreateDto } from '../../../data-transfer-objects/document/document-create-dto';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentReplaceDto } from '../../../data-transfer-objects/document/document-replace-dto';
import { OrderByDirection } from '../../../enums/comparator-enum';
import { ShapeType } from '../../../enums/shape-type';
import { OrderByModel, PaginationModel } from '../../../models/pagination-models';
import { DocumentService } from '../../../services/deprecated/document.service';
import { ActionHttpService } from '../../../services/http/action-http.service';
import { ShapeHttpService } from '../../../services/http/shape-http.service';
import {
    addActionInlineDocumentDone,
    addActionInlineDocumentError,
    addActionInlineDocumentRequest,
    getActionInlineDocumentsDone,
    getActionInlineDocumentsError,
    getActionInlineDocumentsRequest,
    getActionShapeDone,
    getActionShapeError,
    getActionShapeRequest,
    updateActionInlineDocumentDone,
    updateActionInlineDocumentError,
    updateActionInlineDocumentRequest,
    updateActionShapeDone,
    updateActionShapeError,
    updateActionShapeRequest
} from './action-details-inline.actions';

@Injectable()
export class ActionDetailsInlinePageEffects {
    addDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addActionInlineDocumentRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentCreateDto: DocumentCreateDto = new DocumentCreateDto(
                    action.documentViewDTO.Id,
                    action.documentViewDTO.DocumentTypeId,
                    action.parentEntityId,
                    documentViewDTO.LastUpdatedDate,
                    action.documentBlob,
                    documentViewDTO.FileName
                );

                return this.documentService
                    .addDocument(documentCreateDto, action.pageId, action.widgetId)
                    .pipe(
                        map(() => {
                            const documentList: DocumentListViewDTO[] = Object.assign(
                                [],
                                action.documentListViewDTO
                            );

                            const documentDraftWdigetState: DocumentWidgetState = {
                                AddDocumentsError: null,
                                DocumentDrafts: documentList,
                                GetDocumentsError: null,
                                UpdateDocumentsError: null,
                                DocumentAdded: true,
                                DocumentReplaced: false,
                            };

                            return addActionInlineDocumentDone({
                                documentDraftWidgetState: documentDraftWdigetState,
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                            });
                        }),
                        catchError((err) => {
                            return of(
                                addActionInlineDocumentError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentDraftWidgetState: {
                                        AddDocumentsError: err,
                                        DocumentDrafts: null,
                                        GetDocumentsError: null,
                                        UpdateDocumentsError: null,
                                        DocumentAdded: false,
                                        DocumentReplaced: false,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );


    getDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getActionInlineDocumentsRequest),
            mergeMap((action) => {
                const orderByModels: OrderByModel[] = [];

                const orderByModel: OrderByModel = {
                    Field: 'Uploadeddate',
                    OrderByDirection: OrderByDirection.Ascending,
                };

                orderByModels.push(orderByModel);

                const paginationModel: PaginationModel = {
                    Limit: 100,
                    OrderByList: orderByModels,
                    Page: 1,
                    ShowAll: false
                };

                return this.documentService
                    .getDocumentsByWidgetContextAndSearch(
                        action.entityId,
                        paginationModel,
                        action.pageId,
                        action.widgetId,
                        action.filters
                    )
                    .pipe(
                        map((documents) => {
                            return getActionInlineDocumentsDone({
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                                documentDraftWidgetState: {
                                    DocumentDrafts: documents.Models,
                                    GetDocumentsError: null,
                                    UpdateDocumentsError: null,
                                    AddDocumentsError: null,
                                    DocumentAdded: false,
                                    DocumentReplaced: false,
                                },
                            });
                        }),
                        catchError((err) => {
                            return of(
                                getActionInlineDocumentsError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentDraftWidgetState: {
                                        DocumentDrafts: null,
                                        GetDocumentsError: err,
                                        UpdateDocumentsError: null,
                                        AddDocumentsError: null,
                                        DocumentReplaced: false,
                                        DocumentAdded: false,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    getActionShape$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getActionShapeRequest),
            switchMap((action) => {

                return this.shapeHttpService.getShape(action.pageId, action.actionId, action.actionTypeId).pipe(
                    map((shapeWrapperViewDTO) => {
                        return getActionShapeDone({
                            shapeWidgetState: {
                                GetShapeError: null,
                                ListState: [
                                    {
                                        StateModel: shapeWrapperViewDTO.NewShape,
                                        dataSourceId: ShapeType.NewShape.toString()
                                    },
                                    {
                                        StateModel: shapeWrapperViewDTO.OldShape,
                                        dataSourceId: ShapeType.OldShape.toString()
                                    }
                                ],
                                UpdateShapeError: null
                            }
                        });
                    }),
                    catchError((err) => {
                        return of(
                            getActionShapeError({
                                shapeWidgetState: {
                                    GetShapeError: err,
                                    ListState: null,
                                    UpdateShapeError: null
                                }
                            })
                        );
                    })
                );
            })
        )
    );


    updateDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateActionInlineDocumentRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentReplaceDto: DocumentReplaceDto = new DocumentReplaceDto(
                    documentViewDTO.Id,
                    documentViewDTO.DocumentTypeId,
                    action.parentEntityId,
                    documentViewDTO.DateUploaded,
                    action.documentBlob,
                    documentViewDTO.FileName
                );

                return this.documentService.replaceDocument(documentReplaceDto).pipe(
                    map(() => {
                        const documentList: DocumentListViewDTO[] = Object.assign(
                            [],
                            action.documentListViewDTO
                        );

                        const documentDraftWdigetState: DocumentWidgetState = {
                            AddDocumentsError: null,
                            DocumentDrafts: documentList,
                            GetDocumentsError: null,
                            UpdateDocumentsError: null,
                            DocumentReplaced: true,
                            DocumentAdded: false,
                        };

                        return updateActionInlineDocumentDone({
                            documentDraftWidgetState: documentDraftWdigetState,
                            dataSourceId: action.dataSourceId,
                            widgetId: action.widgetId,
                        });
                    }),
                    catchError((err) => {
                        return of(
                            updateActionInlineDocumentError({
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                                documentDraftWidgetState: {
                                    AddDocumentsError: null,
                                    DocumentDrafts: null,
                                    GetDocumentsError: null,
                                    DocumentAdded: false,
                                    DocumentReplaced: false,
                                    UpdateDocumentsError: err,
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    updateActionShape$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateActionShapeRequest),
            switchMap((action) => {
                let updateShapeDTO: ShapeUpdateDTO;
                // eslint-disable-next-line prefer-const
                updateShapeDTO = { ...updateShapeDTO, ...action.shapeViewDTO };

                return this.shapeHttpService
                    .updateShape(action.pageId, action.actionId, action.actionTypeId, updateShapeDTO)
                    .pipe(
                        map(() => {
                            const shapeWidgetState: ShapeWidgetState = {
                                GetShapeError: null,
                                ListState: [{
                                    StateModel: action.shapeViewDTO,
                                    dataSourceId: ShapeType.NewShape.toString()
                                }],
                                UpdateShapeError: null
                            };

                            return updateActionShapeDone({
                                shapeWidgetState: shapeWidgetState,
                            });
                        }),
                        catchError((err) => {
                            return of(
                                updateActionShapeError({
                                    shapeWidgetState: {
                                        GetShapeError: null,
                                        ListState: null,
                                        UpdateShapeError: err
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private actionHttpService: ActionHttpService,
        private documentService: DocumentService,
        private shapeHttpService: ShapeHttpService
    ) { }
}
