import { createAction, props } from '@ngrx/store'
import { ActionWidgetState } from '../../AppState';
import { ActionViewDTO } from '../../data-transfer-objects/action/action-view-dto';


export const getActionRequest = createAction('[ActionDetails] NEW Get Action Request',
props<{ pageId: string; entityId: string; typeId: string }>()
);

export const getActionDone = createAction(
    '[ActionDetails] NEW Get Action Done',
    props<{ actionWidgetState: ActionWidgetState }>()
);
export const getActionError = createAction(
    '[ActionDetails] NEW Get Action Error',
    props<{ actionWidgetState: ActionWidgetState }>()
);

export const actionResetState = createAction(
    '[ActionDetails] NEW Action Reset State'
);



export const updateActionRequest = createAction(
    '[ActionDetails] NEW Update Action Request',
    props<{ actionViewDTO: ActionViewDTO; pageId: string }>()
);

export const updateActionDone = createAction(
    '[ActionDetails] NEW Update Action Done',
    props<{ actionWidgetState: ActionWidgetState }>()
);
export const updateActionError = createAction(
    '[ActionDetails] NEW Update Action Error',
    props<{ actionWidgetState: ActionWidgetState }>()
);

export const executeActionBehaviourRequest = createAction(
    '[ActionDetails] Execute Action Behaviour Request',
    props<{
        taskId: string;
        pageId: string;
        clientId: string;
        widgetId: string;
        assetId: string;
        actionId: string;
    }>()
);

export const executeActionBehaviourDone = createAction(
    '[ActionDetails] Execute Action Behaviour Done'
);
