<div [formGroup]="CustomFormGroup">
    <fw-general-validator [PropertyName]="PropertyName"
                          [(CustomFormGroup)]="CustomFormGroup"
                          [(ValidationDictionary)]="ValidationDictionary"></fw-general-validator>
    <p-calendar [formControlName]="PropertyName" [dateFormat]="mapDateFormat(dateFormat)" [placeholder]="dateFormat" (onSelect)="onAcceptChanges()" icon="pi pi-calendar" showIcon="true" appendTo="body">
    </p-calendar>
    <ng-template [ngIf]="ShowLabel()">
        <label [ngClass]="GetLabelStylingClass()">
            {{Label | transloco}}
            <span *ngIf="Calendar.Validation.Required">*</span>
        </label>
    </ng-template>
</div>