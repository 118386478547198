import { createReducer, on } from '@ngrx/store';
import { IdPsConfigurationState } from 'src/app/AppState';
import { getIdPsConfigurationDone, getIdPsConfigurationError, resetIdPsConfiguration } from './idp.actions';

export const initialIdPsConfigurationState: IdPsConfigurationState = {
    IdPsConfiguration: null,
    GetIdPsConfigurationError: null
}

export const IdPsConfigurationReducerMapper = createReducer<IdPsConfigurationState>(
    initialIdPsConfigurationState,
    on(getIdPsConfigurationDone, (_, action) => {
        return {
            IdPsConfiguration: action.configuration.IdPsConfiguration,
            GetIdPsConfigurationError: null
        }
    }),
    on(getIdPsConfigurationError, (_, action) => {
        return {
            IdPsConfiguration: null,
            GetIdPsConfigurationError: action.configuration.GetIdPsConfigurationError
        }
    }),
    on(resetIdPsConfiguration, () => initialIdPsConfigurationState)
);
