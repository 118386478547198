import { createReducer, on } from '@ngrx/store';
import { DiscussionsWidgetStateModel, ListState } from '../../AppState';
import { DiscussionLoadMode } from '../../data-transfer-objects/discussion/discussion-load-mode';
import { DiscussionViewDTO } from '../../data-transfer-objects/discussion/discussion-view-dto';
import { ListStateReducerHelper } from '../../helpers/list-state-reducer-helper';
import { ObjectHelper } from '../../helpers/object-helper';
import { addDiscussionRequestDone, loadDiscussionsRequestDone, loadDiscussionsRequestError, resetDiscussions, updateDiscussionRequestDone } from './discussion.actions';

export const initialDiscussionsState: ListState<DiscussionsWidgetStateModel>[] = [];

export const DiscussionsReducer = createReducer<ListState<DiscussionsWidgetStateModel>[]>(initialDiscussionsState,
    on(loadDiscussionsRequestDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<DiscussionsWidgetStateModel>(state, action.dataSourceId, action.discussionTopicListWidgetStateModel)),
    on(loadDiscussionsRequestError, (state, action) => ListStateReducerHelper.GetUpdatedListState<DiscussionsWidgetStateModel>(state, action.dataSourceId, action.discussionTopicListWidgetStateModel)),
    on(updateDiscussionRequestDone, (state, action) => GetUpdatedDiscussionListState(state, action)),
    on(addDiscussionRequestDone, (state, action) => GetUpdatedListState(state, action.dataSourceId, action.discussion)),
    on(resetDiscussions, () => initialDiscussionsState)
);

//todo: rename function names
function GetUpdatedDiscussionListState(state: ListState<DiscussionsWidgetStateModel>[], action) {
    let stateToReturn: ListState<DiscussionsWidgetStateModel>[] = [];
    stateToReturn = ObjectHelper.deepCopyJsonParse(state);

    const stateEntry = stateToReturn.find(s => s.dataSourceId === action.dataSourceId);
    stateEntry.StateModel.discussionIdToBeUpdated = action.discussionId;
    stateEntry.StateModel.latestComment = action.latestComment;
    stateEntry.StateModel.lastUpdated= action.lastUpdated;
    stateEntry.StateModel.loadMode = DiscussionLoadMode.UpdateLatestItemOnly;
    return stateToReturn;
}

function GetUpdatedListState(state: ListState<DiscussionsWidgetStateModel>[], dataSourceId: string, discussion: DiscussionViewDTO): ListState<DiscussionsWidgetStateModel>[] {
    let stateToReturn: ListState<DiscussionsWidgetStateModel>[] = [];
    stateToReturn = ObjectHelper.deepCopyJsonParse(state);

    if (stateToReturn.length > 0 && stateToReturn.find(s => s.dataSourceId === dataSourceId)) {
        const stateEntry = stateToReturn.find(s => s.dataSourceId === dataSourceId);
        stateEntry.StateModel.paginationResult.Models.unshift(discussion);
        stateEntry.StateModel.paginationResult.TotalRecords += 1;
        stateEntry.StateModel.loadMode = DiscussionLoadMode.Populate;
    }

    return stateToReturn;
}
