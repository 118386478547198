import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL } from '../helpers/base-url';
import { SessionApplicationService } from '../services/application/session-application.service';

@Injectable()
export class AuthenticationTokenInterceptor implements HttpInterceptor {

    // get from endpointsauthorisationconfiguration.json
    private unauthorizedEndpoints: string[] = [
        'information',
        'authentication/authenticate',
        'authentication/sendonetimepin',
        'authentication/saml2/authenticate',
        'language/Get',
        'language/GetById',
        'systemsetting/GetById',
        'translation/Get',
        'users/CreatePassword',
        'users/ForgotPassword',
        'users/updateEmail',
        'users/register',
        'users/userIdP'
    ]

    constructor(private sessionApplicationService: SessionApplicationService, @Inject(BASE_URL) private _baseUrl) {

    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let authRequired: boolean = true;
        this.unauthorizedEndpoints.forEach((endpoint: string) => {
            if (request.url.toLowerCase().includes(endpoint.toLowerCase())) {
                authRequired = false;
            }
        });

        if (authRequired && this.sessionApplicationService.authenticationToken) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${this.sessionApplicationService.authenticationToken}` }
            });
        }

        return next.handle(request);

    }

}
