<div class="topic-comments">
    <p-dataView [value]="discussionComments" emptyMessage="{{'Widget.Discussions.EmptyCommentsListResourceId' | transloco}}">
        <ng-template let-comment pTemplate="listItem">
            <div class="comment">
                <div class="comment-header">
                    <span class="commenter">{{comment.Commenter}}</span><span class="timestamp">{{comment.Timestamp | localeDateFormat: { stringDate: true } }}</span>
                </div>
                <span innerHtml="{{comment.Comment}}"></span>
                <div>
                    <p-dataView lazy="false" [value]="comment.Attachments" [totalRecords]="totalRecords" emptyMessage="{{'Widget.Discussions.EmptyCommentsListResourceId' | transloco}}">
                        <ng-template let-document pTemplate="listItem">
                            <fw-document-row [PageId]="pageId" [DocumentListViewDTO]="document" [DocumentRowOptions]="rowOptions"></fw-document-row>
                        </ng-template>
                    </p-dataView>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>