import { HashMap, Translation, TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';
import { TranslationViewDTO } from '../data-transfer-objects/translations/translation-view-dto';

export class TranslationHelper {

    public static CurrentPageReportTemplateResourceId: string = 'Shared.Table.CurrentPageReportTemplate';

    private static dateTranslations = [
        'DayNames',
        'DayNamesShort',
        'DayNamesMin',
        'MonthNames',
        'MonthNamesShort'
    ];

    public static PopulateTranslations(defaultLanguageId: string, translations: TranslationViewDTO[], translocoService: TranslocoService): void {
        //the language is case sensitive, and therefore needs to be cast to toLowerCase().
        translocoService.setDefaultLang(`language-${defaultLanguageId}`);

        translocoService.setActiveLang(`language-${defaultLanguageId}`);
        //for loop to load all the languages into cache for the system.
        translations.forEach(model => {
            const hashMapper: HashMap = {};

            hashMapper[model.Identifier] = model.Translation;
            translocoService.setTranslation(hashMapper, `language-${model.LanguageId}`);
        });
    }

    public static setUpDateTranslations(translate: TranslateService, translations: TranslationViewDTO[]): void {
        const dateTranslations = translations.filter(x => this.dateTranslations.includes(x.Identifier));
        const hashMapper: HashMap = {};

        dateTranslations.forEach((translation: Translation) => {
            const camelCaseIdentifier = translation.Identifier.charAt(0).toLowerCase() + translation.Identifier.slice(1)

            if (hashMapper[translation.LanguageId] == undefined) {
                hashMapper[translation.LanguageId] = {}
            }

            hashMapper[translation.LanguageId][camelCaseIdentifier] = translation.Translation.split(',')
        });
        
        Object.keys(hashMapper).forEach(element => {
            translate.setTranslation(`language-${element}`, {
                primeng: hashMapper[element]
            })
        });
    }
}
