import { Component } from '@angular/core';
import { ControlBaseWrapper } from '../control-wrapper-base';

@Component({
  selector: 'fw-calendar-wrapper',
  templateUrl: './calendar-wrapper.component.html',
})
export class CalendarWrapperComponent extends ControlBaseWrapper {
  constructor() {
    super();
  }
}
