import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LookupModel } from '../../../../../models';
import { LookupCacheService } from '../../../../../services/deprecated/lookup-cache.service';
import { AbstractCommoditiesFacade } from '../../../../../facade/abstract/abstract-commodities-facade';
import { EditCommodityBaseComponent } from '../../edit-commodity-base';

@Component({
    selector: 'fw-form-edit-commodity',
    templateUrl: './form-edit-commodity.component.html',
    host: { 'class': 'form-edit commodities' }
})
export class FormEditCommodityComponent extends EditCommodityBaseComponent {

    ViewMore: boolean = false;

    get FilteredOptions(): LookupModel[] {
        return this.Options.filter(option => this.ViewMore || option.CommonlyUsed);
    }

    constructor(
        protected translationService: TranslocoService,
        private lookupService: LookupCacheService, protected commoditiesFacade: AbstractCommoditiesFacade
    ) {
        super(translationService, lookupService, commoditiesFacade);
    }
}
