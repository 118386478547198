<div>
    <div class="view-mode">
        <ng-template [ngIf]="ShowLabel()">
            <label [ngClass]="GetLabelStylingClass()">
                {{Label | transloco}}
            </label>
        </ng-template>
        <div [formGroup]="CustomFormGroup">
            <div class="view-fields" [ngClass]="GetValueStylingClass()">
                {{CustomFormGroup.controls[PropertyName].value | localeDateFormat }}
            </div>
        </div>
    </div>
</div>