<div>
    <div class="view-mode" (keydown.enter)="OpenModal()">
        <label>
            {{UserRolesWidgetConfigurationViewDTO?.LabelResourceId | transloco}}
        </label>
        <div>
            <div class="view-fields">
                {{CurrentRoles}}
                <span (click)="OpenModal()">
                    <button *ngIf="FormEditStyle !== FormEditStyles.ReadOnly" tabindex="-1" class="pi pi-pencil"></button>
                </span>
            </div>
        </div>
    </div>
</div>
<ng-template #formEditUserRoles>
    <fw-form-edit-user-roles [UserRolesWidgetConfigurationViewDTO]="UserRolesWidgetConfigurationViewDTO"
                             [CustomFormGroup]="CustomFormGroup"
                             [AcceptChanges]="AcceptChanges"
                             [SelectedOptions]="Options"
                             [ReadyToStorePreviousValue]="readyToStorePreviousValue"
                             [FormEditStyle]="FormEditStyle"></fw-form-edit-user-roles>
</ng-template>