import { createReducer, on } from '@ngrx/store'
import { DocumentGroupWidgetState, UserDetailsWidgetState } from '../../../AppState';
import { ListStateReducerHelper } from '../../../helpers/list-state-reducer-helper';
import { addDocumentDone, addDocumentError, getDocumentsDone, getDocumentsError, getUserDetailsDone, getUserDetailsError, getUserDetailsResetState, getUserDocumentsResetState, updateDocumentDone, updateDocumentError, updateUserDetailsDone, updateUserDetailsError } from './user-details-inline.actions';

const initialUserDetails: UserDetailsWidgetState = {
    UserDetailsViewDTO: {
        Id: '-1',
        Firstname: '',
        Surname: '',
        Email: '',
        Roles: null,
        AccountStatusId: null,
        AccountStatus: '',
        TelephoneNumber: '',
        TelephoneNumber2: '',
        MobileNumber: '',
        Fax: '',
        IdentityNumber: '',
        Gender: '',
        GenderId: null,
        DateOfBirth: null,
        PrincipalPhysicalAddress: '',
        PrincipalPostalAddress: '',
        Address:
        {
            Id: '',
            PhysicalCountryId: '',
            PhysicalLine1: '',
            PhysicalLine2: '',
            PhysicalLine3: '',
            PhysicalPostalCode: '',
            PhysicalProvince: '',
            PostalCountryId: '',
            PostalLine1: '',
            PostalLine2: '',
            PostalLine3: '',
            PostalPostalCode: '',
            PostalProvince: ''
        },
        TwoFactorEnabled: false
    },
    GetUserDetailsError: null,
    UpdateUserDetailsError: null
};

export const UserDetailsReducer = createReducer<UserDetailsWidgetState>(initialUserDetails,
    on(getUserDetailsDone, (_, action) => action.userDetailsWidgetState),
    on(getUserDetailsError, (_, action) => action.userDetailsWidgetState),
    on(getUserDetailsResetState, () => initialUserDetails),

    on(updateUserDetailsError, (_, action) => action.userDetailsWidgetState),
    on(updateUserDetailsDone, (_, action) => action.userDetailsWidgetState)
);

const initialDocumentDraftsDTO: DocumentGroupWidgetState =
{
    triggeredDataSourceId: null,
    listState: [],
    widgetConfigurationId: null
};

export const DocumentsReducer = createReducer<DocumentGroupWidgetState>(initialDocumentDraftsDTO,
    on(getDocumentsDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(getDocumentsError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(addDocumentDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(addDocumentError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(updateDocumentDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(updateDocumentError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(getUserDocumentsResetState, (state, action) => { return initialDocumentDraftsDTO })
);
