import { ViewportScroller } from '@angular/common';
import {
    AfterViewInit, Component, DoCheck, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild,
    ViewContainerRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Intercom } from 'ng-intercom';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { RelatedAssetListFacade } from './facade/related-asset/related-asset-list.facade';
import { SiteSettingsFacade } from './facade/site-settings/site-settings.facade';
import { IntegrationConfigurationViewDTO } from './data-transfer-objects/configuration/integration-configuration-view-dto';
import { UIStylingHelper } from './helpers/ui-styling-helper';
import { NavigationLinkModel } from './models/navigation-link-model';
import { AbstractAssetListFacade } from './facade/abstract/abstract-asset-list.facade';
import { AbstractRelatedAssetListFacade } from './facade/abstract/abstract-related-asset-list.facade';
import { AssetListWidgetFacade } from './facade/deprecated/asset-list-widget.facade';
import { UIStyleFacade } from './facade/deprecated/ui-style.facade';
import { PortalOverlayComponent } from './modules/static/generics/components/portal-overlay/portal-overlay.component';
import { SessionApplicationService } from './services/application/session-application.service';
import { SignalRApplicationService } from './services/application/signalr-base-application-service';
import { ConfigurationHttpService } from './services/http/configuration-http.service';
import { LanguageHttpService } from './services/http/language-http.service';
import { RootHttpService } from './services/http/root-http.service';
import { TranslationHttpService } from './services/http/translation-http.service';
import { MenuItemApplicationService } from './services/deprecated/menu-item-application.service';
import { ModalService } from './services/deprecated/modal.service';
import { RoutingStateService } from './services/deprecated/routing-state.service';
import { ToastService } from './services/deprecated/toast.service';
import { ScriptService } from './services/application/script.service';
import { GoogleAnalyticsConfigurationFacade } from './facade/configuration/google-analytics.facade';
import { ErrorManagerService } from './services/deprecated/error-manager.service';
import { ErrorDetails } from './models/error-details';
import { appLoadFailedMessage } from './consts/error-messages';
import { HttpServiceBase } from "./services/class-definitions/http-service-base";

@Component({
    selector: 'fw-root',
    templateUrl: './app.component.html',
    providers: [
        { provide: UIStyleFacade },
        { provide: SiteSettingsFacade },
        { provide: AbstractAssetListFacade, useClass: AssetListWidgetFacade },
        { provide: AbstractRelatedAssetListFacade, useClass: RelatedAssetListFacade },
    ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
    public FooterMenuLinks: NavigationLinkModel[] = [];
    public mainContentString: string = 'main-content';
    public mainMenuString: string = 'main-menu';
    shortcuts: ShortcutInput[] = [];
    public userMenuString: string = 'user-menu';
    public isNavScrollable: boolean = false;
    public isSubNavScrollable: boolean = false;

    @ViewChild('portalOverlay', { static: true })
    public _overlay: PortalOverlayComponent;

    @ViewChild('mainContent') mainContent: ElementRef;
    @ViewChild('mainMenu') mainMenu: ElementRef;
    @ViewChild('userMenu') userMenu: ElementRef;
    @ViewChild('subMainMenu') subMainMenu: ElementRef;

    private _translationSubscription: Subscription

    @ViewChild('modalReference', { read: ViewContainerRef, static: true })
    private _modalReference: ViewContainerRef;

    @ViewChild('toastReference', { read: ViewContainerRef, static: true })
    private _toastRef: ViewContainerRef;
    
    public AppLoaded: boolean = false;
    public AppLoadFailed: boolean = false;
    public AppLoadFailureReason: string = appLoadFailedMessage;
    
    public IsBusy: boolean;

    constructor(
        private _modalService: ModalService,
        private routingStateService: RoutingStateService,
        private _toastService: ToastService,
        private _rootService: RootHttpService,
        private _translationService: TranslationHttpService,
        public intercom: Intercom,
        private configurationHttpService: ConfigurationHttpService,
        private primeNGConfig: PrimeNGConfig,
        public menuItemApplicationService: MenuItemApplicationService,
        private uiStyleFacade: UIStyleFacade,
        private viewportScroller: ViewportScroller,
        private siteSettingsFacade: SiteSettingsFacade,
        private languageHttpService: LanguageHttpService,
        private translateService: TranslateService,
        private signalRApplicationService: SignalRApplicationService,
        private sessionApplicationService: SessionApplicationService,
        private renderer: Renderer2,
        private scriptService: ScriptService,
        private googleAnalyticsConfigurationFacade: GoogleAnalyticsConfigurationFacade,
        private errorManagerService: ErrorManagerService
    ) {
    }

    public ngOnDestroy(): void {
        this._translationSubscription.unsubscribe();
        this.signalRApplicationService.Destroy();
    }

    public ngAfterViewInit(): void {

        this.shortcuts.push(
            {
                key: 'v m',
                command: () => this.scrollFocus(this.mainMenuString),
                preventDefault: true,
            },
            {
                key: 'v c',
                command: () => this.scrollFocus(this.mainContentString),
                preventDefault: true,
            },
            {
                key: 'v u',
                command: () => this.scrollFocus(this.userMenuString),
                preventDefault: true,
            }
        );
    }

    public ngOnInit(): void {
       
        this.signalRApplicationService.Initialise().subscribe(success => {
            if (success) {
                this.uiStyleFacade.LoadConfiguration();
                this.siteSettingsFacade.loadConfiguration();

                this.googleAnalyticsConfigurationFacade.loadGoogleAnalyticsConfiguration();

                this.googleAnalyticsConfigurationFacade.getGoogleAnalyticsConfiguration().subscribe((googleAnalyticsConfigurationViewDTO) => {
                    if (googleAnalyticsConfigurationViewDTO) {
                        const keyConstant = 'UA-XXXXX-Y';
                        const googleAnalyticsScript = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', '${keyConstant}', 'auto');ga('send', 'pageview');`;

                        if (googleAnalyticsConfigurationViewDTO.Key) {
                            const googleAnalyticsScriptWithKey = googleAnalyticsScript.replace(keyConstant, googleAnalyticsConfigurationViewDTO.Key);

                            this.scriptService.injectJsScript(this.renderer, googleAnalyticsScriptWithKey);
                        }
                    }
                });

                this._translationSubscription = this._translationService.LoadTranslations().subscribe(() => {
                    this._toastService.Initialize(this._toastRef);
                    this._modalService.Initialize(this._modalReference);

                    // todo: configurable
                    this.FooterMenuLinks = [
                        {
                            Name: 'Disclaimer',
                            Link: 'info/disclaimer',
                            QueryParams: {},
                            SubMenu: [],
                        },
                        {
                            Name: 'Privacy',
                            Link: 'info/privacy',
                            QueryParams: {},
                            SubMenu: [],
                        },
                    ];

                    this.uiStyleFacade.GetFormInputStyle().subscribe((formInputStyle) => {
                        UIStylingHelper.ApplyFormInputStyling(formInputStyle);
                    });

                    this.routingStateService.loadRouting();
                    this._rootService.GetInformation().subscribe((info) => {
                        console.log(info);
                    });

                    this.configurationHttpService
                        .GetIntegrationConfiguration()
                        .subscribe((config: IntegrationConfigurationViewDTO) => {
                            if (config?.Intercom?.AppId) {
                                this.intercom.boot({
                                    app_id: config.Intercom.AppId,
                                    widget: {
                                        activator: '#intercom',
                                    },
                                });
                            }
                        });

                    this.primeNGConfig.filterMatchModeOptions = {
                        text: [FilterMatchMode.CONTAINS, FilterMatchMode.EQUALS],
                        numeric: [
                            FilterMatchMode.EQUALS,
                            FilterMatchMode.GREATER_THAN,
                            FilterMatchMode.LESS_THAN,
                        ],
                        date: [
                            FilterMatchMode.DATE_IS,
                            FilterMatchMode.DATE_BEFORE,
                            FilterMatchMode.DATE_AFTER,
                        ],
                    };

                    this.importLocale();

                    this.menuItemApplicationService.getMenuItems();

                    if (this.sessionApplicationService.isAuthenticated) {
                        this.sessionApplicationService.initalizeAutoLogoutMechanism();
                    }

                    this.AppLoaded = true;
                    this.errorManagerService.AppConnectedToServer = true;
                    
                    this.errorManagerService.Error.subscribe(error => {
                       if (error.AnonymousErrorObject?.status === 503) {
                           this.failedToInit(error.AnonymousErrorObject);
                       } 
                    });
                    
                    this.signalRApplicationService.Connected.subscribe(connected => {
                        this.AppLoaded = connected;
                        this.AppLoadFailed = !connected;
                        this.AppLoadFailureReason = appLoadFailedMessage;
                        this.errorManagerService.AppConnectedToServer = connected;
                    });
                }, error => {
                    this.failedToInit(error);
                });
            } else {
                this.failedToInit(null);
            }
        }, error => {
            this.failedToInit(error);
        });
    }

    ngDoCheck(): void {
        this.onResize();
        this.IsBusy = !this.AppLoaded || HttpServiceBase.RequestCount > 0;
    }

    @HostListener('window:focus', ['$event'])
    onFocus(): void {
        this.sessionApplicationService.isAuthenticated;
    }

    @HostListener('window:resize', ['$event'])
    @HostListener('window:onload', ['$event'])
    onResize(): void {
        if (this.mainMenu) {
            this.isNavScrollable = this.mainMenu.nativeElement.scrollWidth > this.mainMenu.nativeElement.clientWidth;
        }

        if (this.subMainMenu) {
            this.isSubNavScrollable = this.subMainMenu.nativeElement.scrollWidth > this.subMainMenu.nativeElement.clientWidth;
        }
    }

    public scrollFocus(elementId: string): void {
        if (elementId === this.mainMenuString) {
            this.mainMenu.nativeElement.focus();
        }

        if (elementId === this.userMenuString) {
            this.userMenu.nativeElement.focus();
        } else {
            this.mainContent.nativeElement.focus();
        }

        this.viewportScroller.setOffset([0, 60]);
        this.viewportScroller.scrollToAnchor(elementId);
    }

    private importLocale(): void {
        const defaultLocale = 'en-US';
        const locale = this.languageHttpService.getCurrentLanguageLocale() ?? defaultLocale;

        if (locale !== defaultLocale) {
            // Dynamically import the correct locale from the chunk 
            // specified through the webpack magic comments.
            import(
                /* webpackExclude: /\.d\.ts$/ */
                /* webpackMode: "lazy-once" */
                /* webpackChunkName: 'i18n-base' */
                `@/../@angular/common/locales/global/${locale}`);
        }
    }
    
    private failedToInit(error: ErrorDetails): void {
        this.AppLoaded = false;
        this.AppLoadFailed = true;
        
        if (error?.status === 503 && error?.detail) {
            this.AppLoadFailureReason = error.detail;
        } else {
            this.AppLoadFailureReason = appLoadFailedMessage;
        }

        /*
        if the app component failed to init for some reason, wait 30 seconds and refresh the browser window.
        this should force the SPA to restart and hopefully the problems goes away.
        (the most likely reason this would happen is if the client cannot communicate with the server).
         */
        setTimeout(() => { window.location.reload(); }, 30000);
    }
}
