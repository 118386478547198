import { createReducer, on } from '@ngrx/store';
import { AssetWidgetState } from '../../AppState';
import { assetResetState, getAssetDone, getAssetError, updateAssetDone, updateAssetError } from './asset.actions';

//Assets
export const initialAsset: AssetWidgetState = {
    AssetViewDTO: null,
    GetAssetError: null,
    UpdateAssetError: null
};

export const AssetDetailsReducer = createReducer<AssetWidgetState>(initialAsset,
    on(getAssetDone, (_, action) => action.assetWidgetState),
    on(getAssetError, (_, action) => action.assetWidgetState),
    on(updateAssetError, (_, action) => action.assetWidgetState),
    on(updateAssetDone, (_, action) => action.assetWidgetState),
    on(assetResetState, () => initialAsset)
);


