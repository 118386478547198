import { ShapeViewDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-view-dto';
import { createAction, props } from '@ngrx/store';
import { DocumentWidgetState, ShapeWidgetState } from '../../../AppState';
import { ActionListViewDTO } from '../../../data-transfer-objects/action/action-list-view-dto';
import { ActionSearchModel } from '../../../data-transfer-objects/action/action-search-dto';
import { ListFieldConfigurationViewDTO } from '../../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { DocumentQueryDefinitionConfigurationViewDTO } from '../../../data-transfer-objects/configuration/list-configuration/query-definitions/document-query-definition-configuration-view-dto';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentViewDTO } from '../../../data-transfer-objects/document/document-view-dto';
import { PaginationModel, PaginationResultModel } from '../../../models/pagination-models';

//Shapes
export const getAssetShapeRequest = createAction('[AssetDetailsInline] Get Asset Shape Request', props<{ pageId:string, assetId: string, assetTypeId: string }>());
export const getAssetShapeDone = createAction('[AssetDetailsInline] Get Asset Shape Done', props<{ shapeWidgetState: ShapeWidgetState }>());
export const getAssetShapeError = createAction('[AssetDetailsInline] Get Asset Shape Error', props<{ shapeWidgetState: ShapeWidgetState}>());

export const updateAssetShapeRequest = createAction('[AssetDetailsInline] Update Asset Shape Request', props<{ pageId: string, assetId: string, assetTypeId: string, shapeViewDTO: ShapeViewDTO }>());
export const updateAssetShapeDone = createAction('[AssetDetailsInline] Update Asset Shape Done', props<{ shapeWidgetState: ShapeWidgetState }>());
export const updateAssetShapeError = createAction('[AssetDetailsInline] Update Asset Shape Error', props<{ shapeWidgetState: ShapeWidgetState }>());

export const assetShapeResetState = createAction('[AssetDetailsInline] Asset Shape Reset State');

//Documents
export const updateAssetDocumentDone = createAction('[AssetDetailsInline] Update Asset Document Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const updateAssetDocumentRequest = createAction('[AssetDetailsInline] Update Asset Document Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, documentBlob: File, dataSourceId: string, widgetId: string }>());
export const updateAssetDocumentError = createAction('[AssetDetailsInline] Update Asset Document Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const addAssetDocumentDone = createAction('[AssetDetailsInline] Add Asset Document Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string}>());
export const addAssetDocumentRequest = createAction('[AssetDetailsInline] Add Asset Document Request', props<{ documentListViewDTO: DocumentListViewDTO[], documentViewDTO: DocumentViewDTO, parentEntityId: string, documentBlob: File, pageId: string, widgetId: string, dataSourceId: string }>());
export const addAssetDocumentError = createAction('[AssetDetailsInline] Add Asset Document Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const getAssetDocumentsRequest = createAction('[AssetDetailsInline] Get Asset Document Request', props<{ entityId: string, pageId: string, widgetId: string, dataSourceId: string, filters: DocumentQueryDefinitionConfigurationViewDTO }>());
export const getAssetDocumentsDone = createAction('[AssetDetailsInline] Get Asset Document Done', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());
export const getAssetDocumentsError = createAction('[AssetDetailsInline] Get Asset Document Error', props<{ documentDraftWidgetState: DocumentWidgetState, dataSourceId: string, widgetId: string }>());

export const assetDocumentsResetState = createAction('[AssetDetailsInline] Asset Document Reset State');

//Actions

export const getActionsBySearchRequest = createAction('[AssetDetailsInline] Get Actions By Search Request', props<{ fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, actionSearchDTO: ActionSearchModel, dataSourceId: string }>());
export const getActionsBySearchDone = createAction('[AssetDetailsInline] Get Actions By Search Done', props<{ paginationResult: PaginationResultModel<ActionListViewDTO>, dataSourceId: string}>());
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getActionsBySearchRequestError = createAction('[AssetDetailsInline] Save Actions Request Error', props<{ error: any, dataSourceId: string}>());
