import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepresentativeWidgetState } from '../../AppState';
import { UserRepresentationListWidetConfigurationViewDTO } from '../../data-transfer-objects/configuration/user-representation-list-widget-configuration-view-dto';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { FormEditStyle } from '../../enums/configuration/form-edit-style';

@Injectable({
    providedIn: 'root'
})
export abstract class AbstractRepresentativeListFacade {
    public UserRepresentationListWidgetConfiguration: UserRepresentationListWidetConfigurationViewDTO;
    public FormEditStyle: FormEditStyle;
    public PageId: string;

    public abstract GetRepresentatives(): Observable<RepresentativeWidgetState>;
    public abstract LoadRepresentatives(): void;    
    public abstract ResetState(): void;
    public abstract AddRepresentative(personId: string, representativeOfDTO: RepresentativeOfListViewDTO): void;
    public abstract RemoveRepresentative(personId: string, representativeOfDTO: RepresentativeOfListViewDTO): void;
}
