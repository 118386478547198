import { BehaviorSubject, Observable } from 'rxjs';
import { ActionWidgetState } from '../../AppState';
import { ActionViewDTO } from '../../data-transfer-objects/action/action-view-dto';
import { FacadeValidation } from '../../modules/dynamic/widgets/types/validation/facade-validation';

export abstract class AbstractActionDetailsFacade extends FacadeValidation {

    public abstract PrimaryWidgetCompletedLoading: BehaviorSubject<ActionViewDTO>;
    public abstract _actionId: string;
    public abstract _entityId: string;
    public abstract _isSaving: boolean;
    public abstract _pageId: string;
    public abstract _typeId: string;

    public abstract ActionSelector(): Observable<ActionWidgetState>;
    public abstract LoadActionDetails(): void;
    public abstract ResetActionDetails(): void;
    public abstract SetIsSaving(isSaving: boolean): void;
    public abstract UpdateAction(actionViewDTO: ActionViewDTO): void;

    
}
