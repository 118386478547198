/* eslint-disable */

import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { DateHelper } from 'src/app/helpers/date-helper';
import { SiteSettingsService } from 'src/app/services/deprecated/site-settings.service';

@Component({
    selector: 'structured-data-date-field',
    templateUrl: './structured-data-date-field.component.html'
})
export class StructuredDataDateFieldComponent {
    public dateEmitter: EventEmitter<Date> = new EventEmitter<Date>();
    public date: Date | undefined;


    constructor(
        private siteSettingsService: SiteSettingsService
    ) {
    }

    public get dateFormat() {
        return this.siteSettingsService.dateFormat;
    }

    public setDate(date: Date) {
        this.date = date;
    }

    public onDateChange() {
        this.dateEmitter.emit(this.date);
    }

    public clearDate() {
        this.date = undefined;
        this.dateEmitter.emit(null);
    }

    public mapDateFormat(dateFormat: string): string {
        return DateHelper.mapCalendarDateFormat(dateFormat);
    }
}
