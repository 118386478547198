import { Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { StyleVariants } from 'src/app/enums/bootstrap/style-variants';
import { ToastService } from 'src/app/services/deprecated/toast.service';
import { ActionViewDTO } from '../../../../../data-transfer-objects/action/action-view-dto';
import { ActionDetailsWidgetConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/widgets/action-details-widget-configuration-view-dto';
import { FormEditStyle } from '../../../../../enums/configuration/form-edit-style';
import { ConfigurableControlsHelper } from '../../../../../helpers/configurable-controls-helper';
import { BaseWidget } from '../base-widget';
import { AbstractActionDetailsFacade } from '../../../../../facade/abstract/abstract-action-details.facade';
import { PropertyConfiguration } from 'src/app/models/configuration/widgets/properties/property-configuration';

@Component({
    selector: 'fw-action-details-widget',
    templateUrl: './action-details-widget.component.html',
})
export class ActionDetailsWidgetComponent extends BaseWidget implements OnInit, OnDestroy, DoCheck {
    public FormGroup: FormGroup = new FormGroup({});
    public loaded: boolean = false;

    @Input()
    public AcceptChanges: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public ActionDetailsWidgetConfiguration: ActionDetailsWidgetConfigurationViewDTO;

    @Input()
    public FormEditStyle: FormEditStyle;

    private ActionViewDTO: ActionViewDTO;
    private Subscriptions: Subscription[] = [];

    constructor(
        private actionDetailsFacade: AbstractActionDetailsFacade,
        public toastService: ToastService,
        private transloco: TranslocoService,
        public elementRef: ElementRef) {

        super(elementRef);
    }

    ngOnDestroy(): void {

        if (this.Subscriptions) {
            this.Subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            })
        }
    }

    ngDoCheck(): void {
        this.actionDetailsFacade.SetValidity(this.dataSourceId, this.FormGroup.valid);
    }

    ngOnInit(): void {

        this.actionDetailsFacade.LoadActionDetails();

        const getActionDetailsSubscription = this.actionDetailsFacade.ActionSelector().subscribe((actionWidgetState) => {

            if (actionWidgetState.GetActionError) {
                this.toastService.ShowToast([{
                    Message: 'ActionDetails.Form.ActionRetrievedUnsuccessfully', //todo: transloco params here e.g. `ActionDetails.Form.ActionRetrievedUnsuccessfully, ${error.Message}`
                    RouterLink: '',
                    RouterText: '',
                    QueryParameters: null,
                    MessageParameters: null
                }], StyleVariants.Danger, '', false);
            }
            else if (actionWidgetState.UpdateActionError) {
                this.toastService.ShowToast([{
                    Message: 'ActionDetails.Form.ActionUpdatedUnsuccessfully', //todo: transloco params here e.g. `ActionDetails.Form.ActionUpdatedUnsuccessfully, ${error.Message}`
                    RouterLink: '',
                    RouterText: '',
                    QueryParameters: null,
                    MessageParameters: null
                }], StyleVariants.Danger, '', false);
            }
            else if (actionWidgetState.ActionViewDTO) {

                this.loaded = true;
                this.actionDetailsFacade.PrimaryWidgetCompletedLoading.next(actionWidgetState.ActionViewDTO);

                this.ActionViewDTO = actionWidgetState.ActionViewDTO;

                const decisionResultIdPropertyName = 'DecisionResultID';
                const decisionResultIdProperty: PropertyConfiguration = this.ActionDetailsWidgetConfiguration.Properties?.find(property => property.PropertyName == decisionResultIdPropertyName);

                if (decisionResultIdProperty && decisionResultIdProperty.Dropdown) {

                    if (actionWidgetState.ActionViewDTO.TypeId) {
                        decisionResultIdProperty.Dropdown.Filter = actionWidgetState.ActionViewDTO.TypeId;
                    }
                    else {
                        decisionResultIdProperty.Dropdown.Filter = this.actionDetailsFacade._typeId;
                    }
                }

                ConfigurableControlsHelper.PopulateFormControl(this.ActionViewDTO, this.ActionDetailsWidgetConfiguration.Properties, this.FormGroup);
            }
        });

        const acceptChangesSubscription = this.AcceptChanges.subscribe(() => {

            const clonedAction = { ...this.ActionViewDTO, ...this.FormGroup.value };
            this.ActionViewDTO = clonedAction;
            this.actionDetailsFacade.UpdateAction(this.ActionViewDTO);
        });

        this.Subscriptions.push(getActionDetailsSubscription);
        this.Subscriptions.push(acceptChangesSubscription);
    }
}
