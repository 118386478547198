import { createReducer, on } from '@ngrx/store'
import { ShapeWidgetState, RunShapeValidationState, DeleteJobState, DocumentGroupWidgetState, ShapeValidationResultsState } from '../../../AppState';
import { ListStateReducerHelper } from '../../../helpers/list-state-reducer-helper';
import { updateShapeDraftDone, getShapeDraftDone, getShapeDraftError, updateShapeDraftError, getDocumentDraftsDone, getDocumentDraftsError, addDocumentDraftDone, updateDocumentDraftDone, updateDocumentDraftError, addDocumentDraftError, shapeDraftResetState, documentDraftResetState, runShapeValidationDone, runShapeValidationError, getShapeValidationResultsError, getShapeValidationResultsDone, deleteJobDone, deleteJobError, shapeValidationResetState, shapeValidationResultsResetState } from './create-asset-details-form.actions';


export const initialShapeDraftDTO: ShapeWidgetState = {
    GetShapeError: null,
    ListState: null,
    UpdateShapeError: null
}

export const ShapeDraftReducer = createReducer<ShapeWidgetState>(initialShapeDraftDTO,
    on(updateShapeDraftDone, (_, action) => action.shapeDraftWidgetState),
    on(updateShapeDraftError, (_, action) => action.shapeDraftWidgetState),
    on(getShapeDraftDone, (_, action) => action.shapeDraftWidgetState),
    on(getShapeDraftError, (_, action) => action.shapeDraftWidgetState),
    on(shapeDraftResetState, () => initialShapeDraftDTO)
);

export const initialDocumentDraftsDTO: DocumentGroupWidgetState =
{
    triggeredDataSourceId: null,
    listState: [],
    widgetConfigurationId: null
};

export const DocumentDraftsReducer = createReducer<DocumentGroupWidgetState>(initialDocumentDraftsDTO,
    on(getDocumentDraftsDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(getDocumentDraftsError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(addDocumentDraftDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(addDocumentDraftError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(updateDocumentDraftDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(updateDocumentDraftError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),

    on(documentDraftResetState, () => initialDocumentDraftsDTO)
);

export const initialShapeValidationState: RunShapeValidationState = {
    JobSchedulerViewDTO: null,
    RunShapeValidationError: null
};

export const RunShapeValidationReducer = createReducer<RunShapeValidationState>(initialShapeValidationState,
    on(runShapeValidationDone, (_, action) => action.runShapeValidationState),
    on(runShapeValidationError, (_, action) => action.runShapeValidationState),
    on(shapeValidationResetState, () => initialShapeValidationState)
);

export const initialShapeValidationResultsState: ShapeValidationResultsState = {
    JobQueryResultViewDTO: null,
    GetShapeValidationResultsError: null
};

export const GetShapeValidationResultsReducer = createReducer<ShapeValidationResultsState>(initialShapeValidationResultsState,
    on(getShapeValidationResultsDone, (_, action) => action.getShapeValidationResultsState),
    on(getShapeValidationResultsError, (_, action) => action.getShapeValidationResultsState),
    on(shapeValidationResultsResetState, () => initialShapeValidationResultsState)
);

export const initialDeleteJobState: DeleteJobState = {
    DeleteJobError: null
};

export const DeleteJobReducer = createReducer<DeleteJobState>(initialDeleteJobState,
    on(deleteJobDone, (_, action) => action.deleteJobState),
    on(deleteJobError, (_, action) => action.deleteJobState)
);
