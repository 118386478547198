import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WidgetState, WidgetStateConst } from '../AppState';

export const getWidgetState = createFeatureSelector<WidgetState>(WidgetStateConst);

export const getStructuredDataWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.structuredDataListWidgetState
);

export const getDiscussionTopicListWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.discussionTopicListWidgetState
);

export const getDiscussionCommentsWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.discussionCommentsWidgetState
);

export const getDiscussionParticipantsWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.discussionParticipantsWidgetState
);

export const getAssetWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.assetWidgetState
);

export const getRelatedAssetsListWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.relatedAssetListWidgetState
);

export const getActionWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.actionWidgetState
);

export const getUserRoleWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.userRoleWidgetState
);

export const getPartyListWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.partyListWidgetState
);

export const getCommodityWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.commodityWidgetState
);

export const getRepresentativeWidgetState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.representativeWidgetState
);

export const getCompanyListState = createSelector(
    getWidgetState,
    (state: WidgetState) => state.companyListState
);