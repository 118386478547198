import { HttpClient } from '@angular/common/http';
import {
    TRANSLOCO_LOADER,
    Translation,
    TranslocoLoader,
    TRANSLOCO_CONFIG,
    translocoConfig,
    TranslocoModule, TranslocoMissingHandler, TranslocoConfig, TRANSLOCO_MISSING_HANDLER
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';

const AVAILABLE_LANGUAGES = Array.from(Array(10).keys()).map(i => `language-${i + 1}`);

export class CustomHandler implements TranslocoMissingHandler {
    handle(key: string, config: TranslocoConfig) {

        if (config.prodMode) {
            return '';
        }
        else {
            return key;
        }
    }
}

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) { }

    getTranslation(lang: string) {
        return this.http.get<Translation>(`./assets/i18n/${lang}.json`);
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: AVAILABLE_LANGUAGES,
                defaultLang: AVAILABLE_LANGUAGES[0],
                fallbackLang: AVAILABLE_LANGUAGES[0],
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: environment.production
            })
        },
        { provide: TRANSLOCO_MISSING_HANDLER, useClass: CustomHandler },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ]
})
export class TranslocoRootModule { }




