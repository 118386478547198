import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getRelatedAssetsListWidgetState } from 'src/app/state/widgets.selectors';
import { RelatedAssetAddDTO } from 'src/app/data-transfer-objects/related-asset/related-asset-add-dto';
import { addRelatedAssets, getRelatedAssetsBySearchRequest, removeRelatedAssets } from '../../state/related-asset/related-assets.actions';
import { AppState, ListState, RelatedAssetListWidgetStateModel } from '../../AppState';
import { ListFieldConfigurationViewDTO } from '../../data-transfer-objects/configuration/list-configuration/list-field-configuration-view-dto';
import { RelatedAssetQueryDefinitionSearchViewDTO } from '../../data-transfer-objects/configuration/list-configuration/query-definitions/related-asset-query-definition-search-view-dto';
import { RelatedAssetSearchModel } from '../../data-transfer-objects/related-asset/related-asset-search-dto';
import { PaginationModel } from '../../models/pagination-models';
import { SearchModel } from '../../models/search-model';
import { AbstractRelatedAssetListFacade } from '../abstract/abstract-related-asset-list.facade';

@Injectable()
export class RelatedAssetListFacade extends AbstractRelatedAssetListFacade {

    public PageId: string;
    public EntityId: string;
    public showAddButton: boolean = false;

    constructor(private store: Store<AppState>) {
        super();
    }

    public GetListItems(): Observable<ListState<RelatedAssetListWidgetStateModel>[]> {
        const relatedAssetListWidgetState = this.store.pipe(select(state => state.widgetState.relatedAssetListWidgetState));

        return relatedAssetListWidgetState;
    }

    public LoadListItems(fields: ListFieldConfigurationViewDTO[], paginationModel: PaginationModel, searchModel: SearchModel, relatedAssetQueryDefinition: RelatedAssetQueryDefinitionSearchViewDTO, dataSourceId: string): void {

        const relatedAssetSearchDTO: RelatedAssetSearchModel = {
            relatedAssetQueryDefinition: relatedAssetQueryDefinition,
            searchModel: searchModel
        };

        this.store.dispatch(getRelatedAssetsBySearchRequest({
            fields: fields,
            relatedAssetSearchDTO: relatedAssetSearchDTO,
            dataSourceId: dataSourceId,
            paginationModel: paginationModel,
            pageId: this.PageId
        }));
    }

    public addRelatedAssets(dataSourceId: string, relatedAssets: RelatedAssetAddDTO[], pageId: string): void {
        this.store.dispatch(addRelatedAssets({
            dataSourceId,
            relatedAssets,
            pageId
        }))
    }

    public removeRelatedAssets(dataSourceId: string, relatedAssetIds: string[], pageId: string): void {
        this.store.dispatch(removeRelatedAssets({
            dataSourceId,
            relatedAssetIds,
            pageId
        }));
    }

    public reloadListDataSelector(): Observable<ListState<RelatedAssetListWidgetStateModel>[]> {
        return this.store.pipe(select(getRelatedAssetsListWidgetState))
        /*
        public LoadStructuredDataEntrySelector(): Observable<ListState<StructuredDataListWidgetState>[]> {

        const structuredDataWidgetState = this.store.pipe(select(getStructuredDataWidgetState));
        return structuredDataWidgetState;
    } */
    }

    //TODO: to be removed as part of LFF-1320
    public LoadUserListItems() {
    }

}
