import { Injectable, NgZone } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject, Subscriber, Subscription } from 'rxjs';
import { severity_error } from 'src/app/consts/severity-options';
import { ReportDocumentDTO } from 'src/app/data-transfer-objects/signal-r/report-document-dto';
import { ErrorDetails } from 'src/app/models/error-details';
import { LandingPageRelativeRoute } from '../../consts/portal-base-routes';
import { PaymentConnectorRequestOutputViewDTO } from '../../data-transfer-objects/payment/payment-connector-request-output-view-dto';
import { StyleVariants } from '../../enums/bootstrap/style-variants';
import { FileProcessingHelper } from '../../helpers/file-processing-helper';
import { NavigationResponseModel } from '../../models/behaviours/response-models/navigation-response-model';
import { SignalRService } from '../http/signal-r.service';
import { ToastService } from '../deprecated/toast.service';
import { SessionApplicationService } from './session-application.service';
import { ToastApplicationService } from './toast-application.service';

@Injectable({
    providedIn: 'root'
})
export class SignalRApplicationService {

    private _connected = new Subject<boolean>();
    private _clientIdSubject = new ReplaySubject<string>(1);
    private subscriptions: Subscription[] = [];

    public get ClientId(): Observable<string> {
        return this._clientIdSubject.asObservable();
    }
    
    public get Connected(): Subject<boolean> {
        return this._connected;
    }
    
    constructor(private signalRService: SignalRService,
        private toastApplicationService: ToastApplicationService,
        private toastService: ToastService,
        private sessionApplicationService: SessionApplicationService,
        private router: Router,
        private zone: NgZone) {
    }

    public ResetPage(): Observable<null> {
        return new Observable((subscriber: Subscriber<null>) => {
            this.signalRService.registerHandler('resetPage').subscribe(() => {
                subscriber.next();
            });
        });
    }

    public InterceptPaymentRequest(): Observable<PaymentConnectorRequestOutputViewDTO> {
        return new Observable((subscriber: Subscriber<PaymentConnectorRequestOutputViewDTO>) => {
            this.subscriptions.push(this.signalRService.registerHandler('paymentRequest').subscribe((output: PaymentConnectorRequestOutputViewDTO) => {
                subscriber.next(output);
            }));
        });
    }

    public RefreshClientContext(): void {
        this.subscriptions.push(this.signalRService.registerHandler('refreshClientContext').subscribe(() => {

            //unable to reference the menu item service directly as this cause a cyclic redundancy error
            this.sessionApplicationService.authenticationChanged.emit(this.sessionApplicationService.isAuthenticated);

            let params: Params = null;

            if (this.sessionApplicationService.isAuthenticated) {
                params = {};
                params['loggedIn'] = true;
            }

            this.DoNavigate(params, LandingPageRelativeRoute);

        }));
    }

    public HandleNavigation(): void {
        this.subscriptions.push(this.signalRService.registerHandler<NavigationResponseModel>('navigation').subscribe((navigation: NavigationResponseModel) => {
            let params: Params = null;

            if (navigation.queryParameters) {
                params = {};
                navigation.queryParameters.forEach(queryParameter => {
                    params[queryParameter.name] = queryParameter.value
                });
            };

            this.DoNavigate(params, navigation.destinationPageId);

        }));
    }

    public FinishedExecutingBehaviour(): Observable<string> {
        return new Observable((subscriber: Subscriber<string>) => {
            this.subscriptions.push(this.signalRService.registerHandler('finishedExecutingBehaviour').subscribe((taskId: string) => {
                subscriber.next(taskId);
            }));
        });
    }

    /**
     * This function should only be called from the main entry component, like app component*/
    public Initialise(): Observable<boolean> {
        return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
            try {
                this.subscriptions.push(this.signalRService.getClientId().subscribe((clientId: string) => {
                    this._clientIdSubject.next(clientId);
                    this.signalRService.getClientIdOnReconnect(this._clientIdSubject, this._connected);
                    this.SetupGlobalHandlers();
                    this._connected.next(true);
                    subscriber.next(true);
                }, () => {
                    subscriber.next(false);
                }));
            } catch {
                subscriber.next(false);
            }
        });
    }

    /**
     * This function should only be called from the main entry component, like app component*/
    public Destroy(): void {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    private DoNavigate(params: Params, destinationPageId: string): void {
        if (params) {
            this.zone.run(() => {
                this.router.navigate([`page/${destinationPageId}`], { queryParams: params });
            });
        } else {
            this.zone.run(() => {
                this.router.navigate([`page/${destinationPageId}`]);
            });
        }
    }

    private HandleError(): void {
        //todo: subscription cleanup pattern
        this.subscriptions.push(this.signalRService.registerHandler<ErrorDetails>('handleError').subscribe((errorDetails: ErrorDetails) => {
            this.toastApplicationService.showToast([
                {
                    summary: errorDetails.errorMessageResourceId,
                    detail: errorDetails.detail,
                    severity: severity_error,
                }
            ], false);
        }));
    }

    private RegisterInitialiseReportGenerate(): void {
        this.subscriptions.push(this.signalRService.registerHandler('initialiseReportGenerate').subscribe((messageTranslationResourceId: string) => {

            this.zone.run(() => {
                this.toastService.ShowToast([{
                    Message: messageTranslationResourceId,
                    RouterLink: '',
                    RouterText: '',
                    QueryParameters: null,
                    MessageParameters: null
                }], StyleVariants.Info, '', true);
            });


        }));
    }

    private RegisterDownloadReport(): void {
        this.subscriptions.push(this.signalRService.registerHandler('downloadReport').subscribe((reportOutput: { messageTranslationResourceId: string, downloadDocumentTranslationResourceId: string, reportDocument: ReportDocumentDTO }) => {

            this.zone.run(() => {
                this.toastService.ShowToast([{
                    Message: reportOutput.messageTranslationResourceId,
                    RouterLink: '.',
                    RouterText: '',
                    RouterTextTranslationResourceId: reportOutput.downloadDocumentTranslationResourceId,
                    QueryParameters: null,
                    MessageParameters: null,
                    PreventNavigate: true
                }], StyleVariants.Success, '', false)
                    .instance.OnCloseSubject.subscribe(() => {
                        const blob = FileProcessingHelper.ConvertBase64ToBlob(reportOutput.reportDocument.reportBlob);
                        const file = FileProcessingHelper.ConvertBlobToFile(blob, reportOutput.reportDocument.reportName);
                        FileProcessingHelper.DownloadFile(file, reportOutput.reportDocument.reportName);
                    });
            });

        }));
    }

    private RegisterErrorGeneratingReport(): void {
        this.subscriptions.push(this.signalRService.registerHandler('errorGeneratingReport').subscribe((messageTranslationResourceId: string) => {

            this.zone.run(() => {
                this.toastService.ShowToast([{
                    Message: messageTranslationResourceId,
                    RouterLink: '',
                    RouterText: '',
                    QueryParameters: null,
                    MessageParameters: null
                }], StyleVariants.Danger, '', false);
            });

        }));
    }

    //these are non page specific handlers and are available to all pages
    private SetupGlobalHandlers(): void {
        this.RegisterInitialiseReportGenerate();
        this.RegisterDownloadReport();
        this.RegisterErrorGeneratingReport();
        this.HandleError();
        this.HandleNavigation();
        this.RefreshClientContext();
    }
}
