import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { LandfolioLookupTables, LookupTables } from '../../../../../enums/lookup-table-enum';
import { LookupModel } from '../../../../../models';
import { DropdownConfiguration } from '../../../../../models/configuration/widgets/properties/controls/dropdown/dropdown-configuration';
import { LookupCacheService } from '../../../../../services/deprecated/lookup-cache.service';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';

@Component({
    selector: 'fw-form-edit-dropdown',
    templateUrl: './form-edit-dropdown.component.html',
    host: { 'class': 'form-field' }
})

export class FormEditDropdownComponent extends ValidatableInputControlBase<number | string> implements OnInit {
    public Options: LookupModel[];
    public ReadOnly: boolean;

    @Input()
    public Dropdown: DropdownConfiguration;

    constructor(protected transloco: TranslocoService, private lookupManagerService: LookupCacheService) {
        super(transloco);

    }

    InitialiseLookup() {
        if (this.Dropdown.IsLandfolioLookup === true) {
            const lookupTable: LandfolioLookupTables = LandfolioLookupTables[this.Dropdown.LookupKey];

            const getLandfolioLookupTableObservable: Observable<LookupModel[]> = this.Dropdown.Filter ?
                this.lookupManagerService.GetFilteredLandfolioLookupTable(LandfolioLookupTables[lookupTable], this.Dropdown.Filter) :
                this.lookupManagerService.GetLandfolioLookupTable(LandfolioLookupTables[lookupTable]);

            getLandfolioLookupTableObservable.subscribe((lookupModels: LookupModel[]) => {
                this.Options = lookupModels;
                this.ConfigureDropdownValidation(this.Dropdown.Validation);
            });
        }
        else {
            const lookupTable: LookupTables = LookupTables[this.Dropdown.LookupKey];

            this.lookupManagerService.GetLookupTable(lookupTable).subscribe((lookupModels: LookupModel[]) => {
                this.Options = lookupModels;
                this.ConfigureDropdownValidation(this.Dropdown.Validation);
            });
        }
    }

    ngOnInit(): void {
        this.InitialiseLookup();
    }
}
