import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SignalRApplicationService } from '../../services/application/signalr-base-application-service';

@Injectable()
export abstract class AbstractTaskFacade<T> {
    public _behaviourContext: T;
    public _isValid: boolean = true;
    public _pageAssetId: string;
    public singalRLoadedObservable: Observable<SignalRApplicationService>;

    public abstract ExecuteBehaviour(taskId: string, widgetId: string): void;
}
