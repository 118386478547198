import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DateHelper } from 'src/app/helpers/date-helper';
import { SiteSettingsFacade } from 'src/app/facade/site-settings/site-settings.facade';
import { CalendarConfiguration } from '../../../../../models/configuration/widgets/properties/controls/calendar/calendar-configuration';
import { ValidatableInputControlBase } from '../../input-controls/validatable-input-control-base';
import { SiteSettingsService } from 'src/app/services/deprecated/site-settings.service';

@Component({
    selector: 'fw-inline-edit-calendar',
    templateUrl: './inline-edit-calendar.component.html',
    host: { 'class': 'inline-edit' },
    providers: [
        { provide: SiteSettingsFacade }
    ],
})
export class InlineEditCalendarComponent extends ValidatableInputControlBase<Date> implements OnInit {

    @Input()
    public Calendar: CalendarConfiguration;

    constructor(
        protected transloco: TranslocoService,
        private siteSettingsService: SiteSettingsService
    ) {
        super(transloco);
    }

    public get dateFormat(): string {
        return this.siteSettingsService.dateFormat;
    }

    ngOnInit(): void {
        if (this.Calendar) {
            this.ConfigureDateTimeValidation(this.Calendar.Validation);
        }
    }

    public onCancelChanges() {
        this.editMode = false;

        if (this.Data) {
            this.CustomFormGroup.controls[this.PropertyName].setValue(new Date(this.Data));
        }
    }

    //override functionality
    onFocusOut(event) {

        if (!event.sourceCapabilities &&
            (event.target && (event.target.nodeName === 'SPAN' || event.target.nodeName === 'BUTTON'))
        ) {
            return;
        }

        if (!event.relatedTarget || (event.relatedTarget.className && event.relatedTarget.className === 'view-mode')) {
            this.onCancelChanges();
        }
    }

    public mapDateFormat(dateFormat: string): string {
        return DateHelper.mapCalendarDateFormat(dateFormat);
    }
}
