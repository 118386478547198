import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PowerBIWidgetState } from '../../AppState';
import { EmbedConfigurationViewDTO } from '../../data-transfer-objects/power-bi/embed-configuration-view-dto';

@Injectable()
export abstract class AbstractPowerBIFacade {
    public abstract GetEmbedAccessToken(): Observable<PowerBIWidgetState>;
    public abstract LoadEmbedAccessToken(embedConfiguration: EmbedConfigurationViewDTO): void;
    public abstract ResetState(): void;
}