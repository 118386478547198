import { Injectable } from '@angular/core';
import { ShapeUpdateDTO } from '@landadmin/ng-mapping-library/lib/data-transfer-objects/shape-update-dto';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { UtilitiesJobHttpService } from 'src/app/services/http/utilities-job-http-service';
import { UtilitiesShapeHttpService } from 'src/app/services/http/utilities-shape-http-service';
import { DocumentWidgetState, ShapeWidgetState } from '../../../AppState';
import { DocumentCreateDto } from '../../../data-transfer-objects/document/document-create-dto';
import { DocumentListViewDTO } from '../../../data-transfer-objects/document/document-list-view-dto';
import { DocumentReplaceDto } from '../../../data-transfer-objects/document/document-replace-dto';
import { EntityShapeQueryDefinitionConfigurationDTO } from '../../../data-transfer-objects/shape-draft/entity-shape-query-definition-configuration-dto';
import { ShapeDraftUpdateDTO } from '../../../data-transfer-objects/shape-draft/shape-draft-dto';
import { OrderByDirection } from '../../../enums/comparator-enum';
import { ShapeType } from '../../../enums/shape-type';
import { OrderByModel, PaginationModel } from '../../../models/pagination-models';
import { ActionHttpService } from '../../../services/http/action-http.service';
import { ActionDocumentDraftHttpService } from '../../../services/http/action-document-draft-http.service';
import { ShapeDraftHttpService } from '../../../services/http/shape-draft-http.service';
import {
    actionFormDeleteJobDone,
    actionFormDeleteJobError,
    actionFormDeleteJobRequest, addActionDetailsFormDocumentDraftDone,
    addActionDetailsFormDocumentDraftError, addActionDetailsFormDocumentDraftRequest, getActionDetailsFormDocumentDraftsDone,
    getActionDetailsFormDocumentDraftsError, getActionDetailsFormDocumentDraftsRequest,
    getActionFormShapeDone, getActionFormShapeError, getActionFormShapeRequest, getActionFormShapeValidationResultsDone, getActionFormShapeValidationResultsError, getActionFormShapeValidationResultsRequest, runActionFormShapeValidationDone, runActionFormShapeValidationError, runActionFormShapeValidationRequest, updateActionDetailsFormDocumentDraftDone, updateActionDetailsFormDocumentDraftError, updateActionDetailsFormDocumentDraftRequest,
    updateActionFormShapeDone,
    updateActionFormShapeError,
    updateActionFormShapeRequest
} from './action-details-form.actions';

@Injectable()
export class ActionDetailsPageFormEffects {
    addDocumentDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addActionDetailsFormDocumentDraftRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentCreateDto: DocumentCreateDto = new DocumentCreateDto(
                    action.documentViewDTO.Id,
                    action.documentViewDTO.DocumentTypeId,
                    action.parentEntityId,
                    documentViewDTO.LastUpdatedDate,
                    action.documentBlob,
                    documentViewDTO.FileName,
                    action.dataSourceId
                );

                //todo: new add document service function to cater for action
                return this.actionDocumentDraftHttpService
                    .addDocument(
                        documentCreateDto,
                        action.pageId,
                        action.actionId,
                        action.dataSourceId
                    )
                    .pipe(
                        map(() => {
                            const documentList: DocumentListViewDTO[] = Object.assign(
                                [],
                                action.documentListViewDTO
                            );

                            const documentWidgetState: DocumentWidgetState = {
                                AddDocumentsError: null,
                                DocumentDrafts: documentList,
                                GetDocumentsError: null,
                                UpdateDocumentsError: null,
                                DocumentAdded: true,
                                DocumentReplaced: false,
                            };

                            return addActionDetailsFormDocumentDraftDone({
                                documentWidgetState: documentWidgetState,
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                            });
                        }),
                        catchError((err) => {
                            return of(
                                addActionDetailsFormDocumentDraftError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentWidgetState: {
                                        AddDocumentsError: err,
                                        DocumentDrafts: null,
                                        GetDocumentsError: null,
                                        UpdateDocumentsError: null,
                                        DocumentAdded: false,
                                        DocumentReplaced: false,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    //Action Documents Effects
    getActionDocumentDrafts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getActionDetailsFormDocumentDraftsRequest),
            mergeMap((action) => {

                const orderByModels: OrderByModel[] = [];

                const orderByModel: OrderByModel = {
                    Field: 'Uploadeddate',
                    OrderByDirection: OrderByDirection.Ascending,
                };

                orderByModels.push(orderByModel);

                const paginationModel: PaginationModel = {
                    Limit: 100,
                    OrderByList: orderByModels,
                    Page: 1,
                    ShowAll: false
                };

                return this.actionDocumentDraftHttpService
                    .getActionDocuments(action.pageId, action.actionId, action.widgetId, paginationModel)
                    .pipe(
                        map((documentDrafts) => {
                            return getActionDetailsFormDocumentDraftsDone({
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                                documentWidgetState: {
                                    DocumentDrafts: documentDrafts,
                                    GetDocumentsError: null,
                                    UpdateDocumentsError: null,
                                    AddDocumentsError: null,
                                    DocumentAdded: false,
                                    DocumentReplaced: false,
                                },
                            });
                        }),
                        catchError((err) => {
                            return of(
                                getActionDetailsFormDocumentDraftsError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentWidgetState: {
                                        DocumentDrafts: null,
                                        GetDocumentsError: err,
                                        UpdateDocumentsError: null,
                                        AddDocumentsError: null,
                                        DocumentReplaced: false,
                                        DocumentAdded: false,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );


    updateDocumentDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateActionDetailsFormDocumentDraftRequest),
            concatMap((action) => {
                const documentViewDTO = action.documentViewDTO;

                const documentReplaceDto: DocumentReplaceDto = new DocumentReplaceDto(
                    documentViewDTO.Id,
                    documentViewDTO.DocumentTypeId,
                    action.parentEntityId,
                    documentViewDTO.DateUploaded,
                    action.documentBlob,
                    documentViewDTO.FileName
                );

                return this.actionDocumentDraftHttpService
                    .replaceDocument(documentReplaceDto)
                    .pipe(
                        map(() => {
                            const documentList: DocumentListViewDTO[] = Object.assign(
                                [],
                                action.documentListViewDTO
                            );

                            const documentWdigetState: DocumentWidgetState = {
                                AddDocumentsError: null,
                                DocumentDrafts: documentList,
                                GetDocumentsError: null,
                                UpdateDocumentsError: null,
                                DocumentReplaced: true,
                                DocumentAdded: false,
                            };

                            return updateActionDetailsFormDocumentDraftDone({
                                documentWidgetState: documentWdigetState,
                                dataSourceId: action.dataSourceId,
                                widgetId: action.widgetId,
                            });
                        }),
                        catchError((err) => {
                            return of(
                                updateActionDetailsFormDocumentDraftError({
                                    dataSourceId: action.dataSourceId,
                                    widgetId: action.widgetId,
                                    documentWidgetState: {
                                        AddDocumentsError: null,
                                        DocumentDrafts: null,
                                        GetDocumentsError: null,
                                        DocumentAdded: false,
                                        DocumentReplaced: false,
                                        UpdateDocumentsError: err,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    //Shape
    getShapeDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getActionFormShapeRequest),
            switchMap((action) => {
                const entityShapeQueryDefinitionConfigurations: EntityShapeQueryDefinitionConfigurationDTO[] = [{
                    Action: {
                        ActionId: action.actionId
                    },
                    ShapeType: ShapeType.NewShape
                },
                {
                    Action: {
                        ActionId: action.actionId
                    },
                    ShapeType: ShapeType.OldShape
                }];

                return this.shapeDraftService.getDraft(action.pageId, entityShapeQueryDefinitionConfigurations).pipe(
                    map((shapeDraftViewDTO) => {
                        return getActionFormShapeDone({
                            shapeWidgetState: {
                                GetShapeError: null,
                                ListState: [
                                    {
                                        StateModel: shapeDraftViewDTO.NewShape,
                                        dataSourceId: ShapeType.NewShape.toString()
                                    },
                                    {
                                        StateModel: shapeDraftViewDTO.OldShape,
                                        dataSourceId: ShapeType.OldShape.toString()
                                    }
                                ],
                                UpdateShapeError: null
                            }
                        });
                    }),
                    catchError((err) => {
                        return of(
                            getActionFormShapeError({
                                shapeWidgetState: {
                                    GetShapeError: err,
                                    ListState: null,
                                    UpdateShapeError: null
                                }
                            })
                        );
                    })
                );
            })
        ));

    updateShapeDraft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateActionFormShapeRequest),
            switchMap((action) => {
                let updateShapeDTO: ShapeUpdateDTO;
                // eslint-disable-next-line prefer-const
                updateShapeDTO = { ...updateShapeDTO, ...action.shapeViewDTO };

                const shapeUpdateWrapperDTO: ShapeDraftUpdateDTO = {
                    EntityDraftId: action.actionDraftId,
                    ShapeType: ShapeType.NewShape,
                    DraftBody: updateShapeDTO
                };

                return this.shapeDraftService.updateDraft(action.pageId, shapeUpdateWrapperDTO, action.typeId).pipe(
                    map(() => {
                        const shapeWidgetState: ShapeWidgetState = {
                            GetShapeError: null,
                            ListState: [{
                                StateModel: action.shapeViewDTO,
                                dataSourceId: shapeUpdateWrapperDTO.ShapeType.toString()
                            }],
                            UpdateShapeError: null
                        };
                        return updateActionFormShapeDone({
                            shapeWidgetState: shapeWidgetState,
                        });
                    }),
                    catchError((err) => {
                        return of(
                            updateActionFormShapeError({
                                shapeWidgetState: {
                                    GetShapeError: null,
                                    ListState: null,
                                    UpdateShapeError: err
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    getShapeValidationResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getActionFormShapeValidationResultsRequest),
            switchMap((action) => {
                return this.utilitiesShapeService
                    .validateShapeRetrieveJobResult(action.jobId)
                    .pipe(
                        map((jobQueryResultViewDTO) => {
                            return getActionFormShapeValidationResultsDone({
                                getShapeValidationResultsState: {
                                    JobQueryResultViewDTO: jobQueryResultViewDTO,
                                    GetShapeValidationResultsError: null,
                                },
                            });
                        }),
                        catchError((err) => {
                            return of(
                                getActionFormShapeValidationResultsError({
                                    getShapeValidationResultsState: {
                                        JobQueryResultViewDTO: null,
                                        GetShapeValidationResultsError: err,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    runShapeValidation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(runActionFormShapeValidationRequest),
            switchMap((action) => {
                return this.utilitiesShapeService
                    .validateShapeScheduleJob(action.shapeValidationCreateDTO)
                    .pipe(
                        map((jobSchedulerViewDTO) => {
                            return runActionFormShapeValidationDone({
                                runShapeValidationState: {
                                    JobSchedulerViewDTO: jobSchedulerViewDTO,
                                    RunShapeValidationError: null,
                                },
                            });
                        }),
                        catchError((err) => {
                            return of(
                                runActionFormShapeValidationError({
                                    runShapeValidationState: {
                                        JobSchedulerViewDTO: null,
                                        RunShapeValidationError: err,
                                    },
                                })
                            );
                        })
                    );
            })
        )
    );

    deleteJob$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionFormDeleteJobRequest),
            switchMap((action) => {
                return this.utilitiesJobService.deleteJob(action.jobId).pipe(
                    map(() => {
                        return actionFormDeleteJobDone({
                            deleteJobState: {
                                DeleteJobError: null,
                            },
                        });
                    }),
                    catchError((err) => {
                        return of(
                            actionFormDeleteJobError({
                                deleteJobState: {
                                    DeleteJobError: err,
                                },
                            })
                        );
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private actionDocumentDraftHttpService: ActionDocumentDraftHttpService,
        public actionDraftService: ActionHttpService,
        public shapeDraftService: ShapeDraftHttpService,
        public utilitiesShapeService: UtilitiesShapeHttpService,
        public utilitiesJobService: UtilitiesJobHttpService
    ) { }

}
