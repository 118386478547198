export enum MapModule {
    // Bookmarks = 0,
    // ChooseExistingShape = 1,
    // Configuration = 2,
    Diagnostics = 3,
    ExpandMap = 4,
    Identify = 5,
    Legend = 6,
    // MapSwitcher = 7,
    Measure = 8,
    // ModuleVisibilitySwitcher = 9,
    // Preferences = 10,
    // Print = 11,
    Search = 12,
    Selection = 13,
    ShapeEditor = 14,
    // Zoom = 15
}
