import { createReducer, on } from '@ngrx/store';
import { SiteSettingsConfigurationState } from 'src/app/AppState';
import { getSiteSettingsConfigurationDone } from './site-settings.actions';

export const initialSitSettingsConfigurationState: SiteSettingsConfigurationState = {
    dateFormat: 'dd MMM yyyy'
}

export const siteSettingsDateFormatReducerMapper = createReducer<SiteSettingsConfigurationState>(
    initialSitSettingsConfigurationState,
    on(getSiteSettingsConfigurationDone, (_, action) => {
        return {
            dateFormat: action.configuration.dateFormat
                ? action.configuration.dateFormat 
                : initialSitSettingsConfigurationState.dateFormat
        };
    })
);
